import React, {useState, useEffect} from "react";

import "./projectHome.scss"
import {Breadcrumb, Button, Col, Icon, Input, message, Row, Tabs, Radio, Upload, Modal, List} from "antd";
import qiemanReq from "../../request/qieManReq"
import Column from "antd/es/table/Column";
import indexReq from "../../request/indexReq";


const exampleTemplateBody = {
    "first": {
        "value": "恭喜你购买成功！"
    },
    "keyword1": {
        "value": "巧克力",
        "color": "#5d5ae9"
    },
    "keyword2": {
        "value": "39.8元",
        "color": "#665542"
    },
    "keyword3": {
        "value": "2014年9月22日",
        "color": "#173177"
    },
    "remark": {
        "value": "欢迎再次购买！",
        "color": "#f3467b"
    }
}
export default props => {


    const [currentTab, setCurrentTab] = useState("base-info")
    const [project, setProject] = useState({})
    const [tempMsg, setTempMsg] = useState({})
    const [sending, setSending] = useState(false)
    const [articlePreviewUplading, setArticlePreviewUplading] = useState(false)
    const [mediaPage, setMediaPage] = useState(0)
    const [followReplyMsg, setFollowReplyMsg] = useState({})
    const [mediaList, setMediaList] = useState([])
    const [mediaTotal, setMediaTotal] = useState(0)
    const [showMediaModal, setShowMediaModal] = useState(false)
    const [loadingMediaList, setLoadingMediaList] = useState(false)


    useEffect(() => {
        getProjectInfo()
        getSettingReplyMessageAfterScan()
    }, [props.match.params.projectId])

    const getProjectInfo = () => {
        qiemanReq.getProjectInfo(props.match.params.projectId)
            .then(res => {
                setProject(res)
            })
            .catch(e => {
                return message.error(`发生错误：${e & e.message || ""}`)
            })
    }
    const getSettingReplyMessageAfterScan = () => {
        qiemanReq.getSettingReplyMessageAfterScan(props.match.params.projectId)
            .then(res => {
                setFollowReplyMsg(res)
            })
            .catch(e => {
                return message.error(`${e & e.message || ""}`)
            })
    }

    const getMediaList = (page) => {
        setLoadingMediaList(true)
        qiemanReq.getMediaList(followReplyMsg.msgType, page)
            .then(res => {
                setMediaTotal(res.total_count);
                setMediaList(res.item)
                setLoadingMediaList(false)
            })
            .catch(e => {
                setLoadingMediaList(false)
                message.error(`加载失败：${e?.message || ""}`)
            })
    }


    return <div className='page-project-home'>
        <Breadcrumb>
            <Breadcrumb.Item>主页</Breadcrumb.Item>
            <Breadcrumb.Item>
                <a>{project?.project?.title || ""}</a>
            </Breadcrumb.Item>
        </Breadcrumb>

        <div className={'page-header'}>
            <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                props.history.goBack();
            }}/>
            <h1>{project?.project?.title || ""}</h1>
        </div>
        <Tabs defaultActiveKey="base-info" onChange={(key) => {
            setCurrentTab(key)
        }} activeKey={currentTab}>

            <Tabs.TabPane tab="基本信息" key="base-info">

                <Row>

                    <Col span={12}>
                        <div className="card">
                            <h4 className="static-count">
                                成员人数： {project?.memberCount || 0}人
                            </h4>
                        </div>

                        <div className="card">
                            <h4>发送模板消息</h4>
                            <div className="item">
                                <div className="item-title">模板ID</div>
                                <Input className="input" onChange={e => {
                                    tempMsg.tempId = e.currentTarget.value;
                                    setTempMsg({...tempMsg})
                                }}/>
                            </div>
                            <div className="item">
                                <div className="item-title">链接</div>
                                <Input className="input" placeholder={`用户点击模板消息，打开的链接`} onChange={e => {
                                    tempMsg.url = e.currentTarget.value;
                                    setTempMsg({...tempMsg})
                                }}/>
                            </div>

                            <div className="item">
                                <div className="item-title">模板消息体</div>
                                <Input.TextArea className="textarea" onChange={e => {
                                    tempMsg.tempBody = e.currentTarget.value;
                                    setTempMsg({...tempMsg})
                                }} defaultValue={JSON.stringify(exampleTemplateBody,0,4)}/>
                            </div>
                            <div className="footer">

                                <Button type="primary" loading={sending} onClick={() => {

                                    if (!tempMsg.tempId) {
                                        return message.warn(`请填写模板id`)
                                    }
                                    if (!tempMsg.tempBody) {
                                        return message.warn(`请填写模板消息体`)
                                    }
                                    let bodyJSON = {}
                                    try {
                                        bodyJSON = JSON.parse(tempMsg.tempBody)
                                    } catch (e) {
                                        return message.warn(`不能解析模板消息体，请检查消息体格式`)
                                    }
                                    let info = {
                                        tempId: tempMsg.tempId,
                                        tempBody: bodyJSON,
                                        projectId: props?.match?.params?.projectId,
                                        url: tempMsg.url || ""
                                    }
                                    setSending(true)
                                    qiemanReq.sendProjectTemplateMessage(info)
                                        .then(res => {
                                            setSending(false)
                                            console.log(res)
                                            message.success(`正在发送`)
                                        })
                                        .catch(e => {
                                            setSending(false)
                                            message.error(`发生错误：${e?.message || ""}`)
                                        })
                                }}>发送</Button>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab="自动回复设置" key="auto-setting">

                <Row>

                    <Col span={12}>


                        <div className="card">
                            <h4>扫项目二维码后自动回复</h4>
                            <div className="item">
                                <div className="item-title">消息类型</div>
                                <Radio.Group onChange={e => {
                                    followReplyMsg.msgType = e.target.value
                                    followReplyMsg.media = null;
                                    followReplyMsg.text = null;
                                    setFollowReplyMsg({
                                        ...followReplyMsg
                                    })
                                }} value={followReplyMsg?.msgType || ""}>
                                    <Radio value="text">文本</Radio>
                                    <Radio value="news">图文</Radio>

                                    <Radio value="image">图片</Radio>
                                    <Radio value="video">视频</Radio>
                                    <Radio value="voice">语音</Radio>

                                </Radio.Group>
                            </div>
                            <div className="item" hidden={followReplyMsg.msgType !== "text"}>
                                <div
                                    className="item-title">消息文本内容
                                </div>
                                <Input.TextArea className="textarea" onChange={e => {
                                    followReplyMsg.text = e.currentTarget.value
                                    setFollowReplyMsg({
                                        ...followReplyMsg
                                    })
                                }} value={followReplyMsg.text}/>
                            </div>
                            <div className="item" hidden={!followReplyMsg.msgType || followReplyMsg.msgType === "text"}>
                                <Button type="dashed" onClick={() => {
                                    setShowMediaModal(true)
                                    setMediaList([])
                                    getMediaList(0)
                                }}>{followReplyMsg.media ? followReplyMsg.msgType === "news" ? followReplyMsg.media.content.news_item[0].title : followReplyMsg.media.name : "从素材中选择"}</Button>

                                <Modal
                                    title="选择素材"
                                    visible={showMediaModal}
                                    destroyOnClose={true}
                                    width={`80%`}
                                    onCancel={() => {
                                        setShowMediaModal(false)
                                    }}
                                    footer={null}
                                >
                                    <List
                                        className="list"
                                        itemLayout="horizontal"
                                        loading={loadingMediaList}
                                        dataSource={mediaList || []}
                                        rowKey={'id'}
                                        pagination={{
                                            onChange: page => {
                                                getMediaList(followReplyMsg.msgType, page - 1)
                                            },
                                            pageSize: 20,
                                            total: mediaTotal,
                                            size: "small"
                                        }}
                                        bordered={true}
                                        renderItem={(item, index) => (
                                            <List.Item
                                                actions={[<a onClick={() => {
                                                    setShowMediaModal(false)
                                                    followReplyMsg.media = {
                                                        ...item
                                                    }
                                                    setFollowReplyMsg({...followReplyMsg})
                                                }}>选择</a>]}

                                            >

                                                <List.Item.Meta
                                                    avatar={null}
                                                    title={`${followReplyMsg.msgType === "news" ? item.content.news_item[0].title || "" : item.name || ""}`}
                                                    description={followReplyMsg.msgType === "news" ? item.content.news_item[0].digest : null}
                                                />
                                            </List.Item>
                                        )}

                                        split={false}
                                    />


                                </Modal>
                            </div>

                            {/*{*/}
                            {/*    followReplyMsg?.msgType === "news" ? (*/}
                            {/*        <div className="articles">*/}
                            {/*            <div className="item-title">文章列表</div>*/}
                            {/*            {*/}

                            {/*                followReplyMsg?.articles?.map((article, articleIndex) => {*/}

                            {/*                    return <div className='article'>*/}
                            {/*                        <div className="flex-l">*/}
                            {/*                            <div className="item">*/}

                            {/*                                <Upload className={'app-icon-uploader'} accept={'image/*'}*/}
                            {/*                                        showUploadList={false}*/}
                            {/*                                        beforeUpload={(fileInfo) => {*/}
                            {/*                                            setArticlePreviewUplading(true)*/}
                            {/*                                            indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {*/}
                            {/*                                                success: (url) => {*/}
                            {/*                                                    followReplyMsg.articles[articleIndex].picUrl = url;*/}
                            {/*                                                    setFollowReplyMsg({*/}
                            {/*                                                        ...followReplyMsg*/}
                            {/*                                                    })*/}

                            {/*                                                },*/}
                            {/*                                                progress: (e) => {*/}

                            {/*                                                },*/}
                            {/*                                                error: (error) => {*/}
                            {/*                                                    message.error('上传发生错误，请重试');*/}
                            {/*                                                    setArticlePreviewUplading(false)*/}
                            {/*                                                },*/}
                            {/*                                            })*/}
                            {/*                                        }}*/}
                            {/*                                        disabled={articlePreviewUplading}>*/}
                            {/*                                    <div className={'app-icon-add'}*/}
                            {/*                                         hidden={article.picUrl}>*/}
                            {/*                                        封面图*/}
                            {/*                                    </div>*/}
                            {/*                                    <img*/}
                            {/*                                        hidden={!article.picUrl}*/}
                            {/*                                        src={article.picUrl}*/}
                            {/*                                        alt={''}/>*/}
                            {/*                                </Upload>*/}
                            {/*                            </div>*/}

                            {/*                        </div>*/}
                            {/*                        <div className="flex-r">*/}

                            {/*                            <div className="item">*/}
                            {/*                                /!*<div className="item-title">标题</div>*!/*/}
                            {/*                                <Input className="input" placeholder="文章标题" onChange={e => {*/}
                            {/*                                    followReplyMsg.articles[articleIndex].title = e.currentTarget.value*/}
                            {/*                                    setFollowReplyMsg({*/}
                            {/*                                        ...followReplyMsg*/}
                            {/*                                    })*/}
                            {/*                                }} value={article.title || ""}/>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="item">*/}
                            {/*                                /!*<div className="item-title">描述</div>*!/*/}
                            {/*                                <Input.TextArea className="textarea" placeholder="文章描述"*/}
                            {/*                                                onChange={e => {*/}
                            {/*                                                    followReplyMsg.articles[articleIndex].description = e.currentTarget.value*/}
                            {/*                                                    setFollowReplyMsg({*/}
                            {/*                                                        ...followReplyMsg*/}
                            {/*                                                    })*/}
                            {/*                                                }} value={article.description}/>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="item">*/}
                            {/*                                /!*<div className="item-title">跳转链接</div>*!/*/}
                            {/*                                <Input className="input" placeholder="跳转链接" onChange={e => {*/}
                            {/*                                    followReplyMsg.articles[articleIndex].link = e.currentTarget.value*/}
                            {/*                                    setFollowReplyMsg({*/}
                            {/*                                        ...followReplyMsg*/}
                            {/*                                    })*/}
                            {/*                                }} value={article.link}/>*/}
                            {/*                            </div>*/}

                            {/*                        </div>*/}
                            {/*                        <div className="flex-a">*/}
                            {/*                            <Button type="link" style={{color:"red"}}>移除</Button>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                })*/}

                            {/*            }*/}

                            {/*            <Button type="link" hidden={followReplyMsg?.articles?.length >= 8}*/}
                            {/*                    onClick={() => {*/}
                            {/*                        if (!followReplyMsg.articles || !followReplyMsg.articles.length) {*/}
                            {/*                            followReplyMsg.articles = [{}]*/}
                            {/*                        } else {*/}
                            {/*                            followReplyMsg.articles.push({})*/}
                            {/*                        }*/}
                            {/*                        setFollowReplyMsg({...followReplyMsg})*/}
                            {/*                    }}>添加文章（最多8个）</Button>*/}
                            {/*        </div>*/}
                            {/*    ) : null*/}

                            {/*}*/}


                            <div className="footer">

                                <Button type="primary" loading={sending} onClick={() => {

                                    if (!followReplyMsg.msgType) {
                                        return message.warn(`请选择消息类型`)
                                    }

                                    if (followReplyMsg.msgType === "text" && !followReplyMsg.text) {
                                        return message.warn(`请填写文本消息内容`)
                                    }

                                    if (followReplyMsg.msgType !== "text" && !followReplyMsg.media) {
                                        return message.warn(`请指定素材`)
                                    }


                                    setSending(true)
                                    qiemanReq.saveReplyMessageAfterScan({
                                        projectId: props.match.params.projectId,
                                        msgType: followReplyMsg.msgType,
                                        media: followReplyMsg.media,
                                        text: followReplyMsg.text
                                    })
                                        .then(res => {
                                            setSending(false)
                                            console.log(res)
                                            message.success(`已保存`)
                                        })
                                        .catch(e => {
                                            setSending(false)
                                            message.error(`发生错误：${e?.message || ""}`)
                                        })
                                }}>保存</Button>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Tabs.TabPane>
        </Tabs>

    </div>
}