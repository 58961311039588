import {Avatar, Input, message, Modal, Spin,Button,Col,Row} from "antd";
import React from "react";
import accountReq from "../../request/accountReq";
import './userSearcherModal.scss'
import IconFont from "../iconfont/icon-font";

const {Search} = Input;
class UserSearcherModal extends Modal {
    constructor(props) {
        super(props);
        let defaultSelected = props.defaultSelected || [];
        let selectedUserIds = defaultSelected.map(user => user.id);
        Object.assign(this.state,props);
        this.state.selectedUserIds = selectedUserIds;
    }
    state = {
        loading:false,
        users:[],
        total:0,
        selectedUserIds:[],
    }
    static  getDerivedStateFromProps(nextProps,prevState){
        let newState = {
            ...nextProps
        }
         if(nextProps.visible !== prevState.visible){
             newState.total = 0;
             newState.users = [];
         }

        let defaultSelected = nextProps.defaultSelected || [];
        newState.selectedUserIds = defaultSelected.map(user => user.id);
        delete newState.defaultSelected;
        return newState;
    }

    onCancel = ()=>{
        this.setState({
            visible:false
        })
    }

    onSearch = (value)=>{
        this.setState({
            loading:true
        })


        accountReq.getAllTeacher(value)
            .then(res =>{
                console.log(`users`,res)
                this.setState({
                    loading:false,
                    total:res.length,
                    users:res
                })
            })
            .catch(error =>{
                message.error(`搜索失败，请重试`)
            })
    }
    changeSelected = ()=>{

    }
    onCancel = ()=>{

    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevState.visible &&  this.state.visible){
            this.onSearch();
        }
    }

    render() {

        let {users,selectedUserIds} = this.state;
        let userItems = [];
        selectedUserIds = selectedUserIds || [];
        if(users && users.length) {
            userItems = users.map((item,index)=>{
                return <Col span={6} key={index}>
                    <div className={`searched-user`} >
                        <div className={`searched-user-inner`}>
                            {/*<IconFont hidden={item.userRoleKeys.indexOf(`teacher`) < 0} type={`iconlaoshi`} className={'teacher-icon'}/>*/}
                            <Avatar className={'avatar'} src={item.avatar}/>
                            <div className={`nickname`}>{item.nickname}</div>
                            <Button typeof={'primary'} disabled={selectedUserIds.indexOf(item.id) >= 0} onClick={()=>{
                                let selectedUserIds = this.state.selectedUserIds;
                                selectedUserIds.push(item.id);
                                this.setState({
                                    selectedUserIds
                                })
                                if(this.props.changedSelected){

                                    this.props.changedSelected(item)
                                }
                            }}>{selectedUserIds.indexOf(item.id) >= 0 ? this.state.selectText || "已选":this.state.normalText || "选择"} </Button>
                        </div>

                    </div>
                </Col>
            })
        }


        return <Modal

            visible={ this.state.visible}
            // onOk={}
            onCancel={this.props.onCancel  || this.onCancel}
            destroyOnClose={true}
            footer={null}
            className={'comp-modal-user-searcher'}
            centered={true}
        >
            <h2>{this.state.title || '搜索用户'}</h2>
            <div className={'searcher-container'}>


                <div className={'search-result'}>
                    <Row>  {userItems}</Row>

                </div>
            </div>

        </Modal>



    }

}

export default  UserSearcherModal;

