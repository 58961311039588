import React from 'react';
import {Row, Col, Statistic, List, message, Modal, Input, Button, Avatar, Checkbox} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './userDash.scss'
import {connect} from 'react-redux'
import UserListItem from "../../components/userListItem/UserListItem";
import accountReq from "../../request/accountReq";

const PerPage = 30;
const {Search} = Input;

class UserDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {
        users:[],
        page:1,
        userCount:0,
        listLoading: false,
        showUserModel:false,
        editUser:{},
        roleList:[],
        powerList:[],
        submitting:false,
        searchKeyword:null,
        userActiveStatistics:{},
        userRegisterStatistics:{}
    }


    /* life circle method */

    componentDidMount(){
        this.getUserList();
        this.getRolePowerList();
        this.getStatistics();
    }

    getStatistics(){
        accountReq.getUserActiveStatistics()
            .then(res =>{
                this.setState({
                   userActiveStatistics:res
                })
            })
            .catch(error =>{
                message.error(`获取用户活跃统计失败：${error ? error.message:""}`)
            })

        accountReq.getUserRegisterStatistics()
            .then(res =>{
                this.setState({
                    userRegisterStatistics:res
                })
            })
            .catch(error =>{
                message.error(`获取新增用户统计失败：${error ? error.message:""}`)
            })
    }

    getUserList(){

        let condition = {
            perPage:PerPage,
            page:this.state.page -1
        }
        if(this.state.searchKeyword){
            condition.keyWord = this.state.searchKeyword;
        }
        this.setState({
            listLoading:true
        })
        accountReq.getUserList(condition)
            .then(res =>{
                let rows = res.rows;
                let count = res.count;
                this.setState({
                    users:rows,
                    userCount:count,
                    listLoading:false
                })
            })
            .catch(error =>{
                this.setState({
                    listLoading:false
                })
                message.error(`获取用户列表失败：${error ? error.message:""}`)
            })
    }

    handlerUserModalOk = ()=>{

    }

    handlerUserModalCancel = ()=>{
        this.setState({
            editUser: {

            },
            showUserModel: false
        })
    }

    powerOnChange = e =>{
        console.log(`powerOnChange`,e)
        let editUser = this.state.editUser;
        editUser.powerKeys = e;
        this.setState({
            editUser
        })
    }
    roleOnChange = e =>{
        console.log(`roleOnChange`,e);
        let editUser = this.state.editUser;
        editUser.rolesKeys = e;
        this.setState({
            editUser
        })
    }

    getRolePowerList(){
        accountReq.getAllRoleList()
            .then(res =>{
                if(res && res.length){

                    this.setState({
                        roleList:res
                    })
                }
            })

        accountReq.getUserPowerList()
            .then(res =>{
                if(res && Object.getOwnPropertyNames(res).length){
                    let list = [];
                    for(let key in res){
                        list.push(res[key]);
                    }
                    this.setState({
                        powerList:list
                    })
                }
            })
    }

    submitChange = ()=>{

       let {editUser,powerList,roleList} = this.state;
       let origin = editUser.origin;

       let roleKeys = editUser.rolesKeys;
        let powers = null;
       if(editUser.powerKeys){
           powers = {}
           let selectPowerKeys = editUser.powerKeys;

           for(let power of powerList) {
               powers[power.key] = selectPowerKeys.indexOf(power.key) >= 0;
           }
       }

        let info = {
            userId:origin.id,
        };
       if(roleKeys){
           info.roleKeys = roleKeys;
       }
        if(powers){
            info.powers = powers;
        }
        this.setState({
            submitting:true
        })
        accountReq.changeUserRoleAndPower(info)
            .then(res =>{

                if(roleKeys){
                    origin.roles = {};
                   for(let roleKey of roleKeys){
                       let index = roleList.findIndex(item => item.roleKey === roleKey);
                       origin.roles[roleKey] = {...roleList[index],value:true};
                   }
                }
                if(powers){

                    for(let key in powers){
                        let index = powerList.findIndex(item => item.key === key);
                        origin.userPower[key] = {...powerList[index],value:powers[key]};
                    }
                }
                let users = this.state.users;
                users.splice(origin.rowIndex,1,origin);
                this.setState({
                    users,
                    showUserModel:false,
                    submitting:false,
                    editUser:{}
                })
               message.success(`保存成功`)
            })
            .catch(error =>{
                this.setState({
                    submitting:false
                })
                message.error(`保存修改失败：${error ? error.message:""}`)
            })
    }

    render() {
        let {users,userCount,showUserModel,editUser,roleList,powerList,submitting,listLoading,searchKeyword,userRegisterStatistics,userActiveStatistics} = this.state;
        let rolesKeys = [];
        if( editUser.hasOwnProperty(`rolesKeys`)){
            rolesKeys = editUser.rolesKeys || [];
        }else if(editUser.origin && editUser.origin.roles){
            for(let key in editUser.origin.roles){
                if(editUser.origin.roles[key]){
                    rolesKeys.push(key);
                }
            }

        }
        let powerKeys = [];
        if( editUser.hasOwnProperty(`powerKeys`)){
            powerKeys = editUser.powerKeys || [];
        }else if(editUser.origin && editUser.origin.userPower){
            for(let key in editUser.origin.userPower){
                if(editUser.origin.userPower[key].value){
                    powerKeys.push(key);
                }
            }

        }
        return (
            <div className='page-user-board' >
                <h2>用户活跃统计</h2>

                <Row gutter={16} className={'statistic-section'}>
                    <Col span={4}>
                        <Statistic title="用户总数" value={userActiveStatistics.totalCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="今日活跃" value={userActiveStatistics.todayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="昨日活跃" value={userActiveStatistics.yesterdayCount} />
                    </Col>

                    <Col span={4}>
                        <Statistic title="最近7天" value={userActiveStatistics.latestWeekDayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近30天" value={userActiveStatistics.latestMonthDayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近90天" value={userActiveStatistics.latestSeasonDayCount} />
                    </Col>
                </Row>

                <h2>新增用户统计</h2>
                <Row gutter={16} className={'statistic-section'}>
                    <Col span={4}>
                        <Statistic title="用户总数" value={userRegisterStatistics.totalCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="今日新增" value={userRegisterStatistics.todayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="昨日新增" value={userRegisterStatistics.yesterdayCount} />
                    </Col>

                    <Col span={4}>
                        <Statistic title="最近7天" value={userRegisterStatistics.latestWeekDayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近30天" value={userRegisterStatistics.latestMonthDayCount} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近90天" value={userRegisterStatistics.latestSeasonDayCount} />
                    </Col>
                </Row>
                <div className={`list-header`}>
                    <h2>用户列表</h2>
                    <div className={`flex-r`}>
                        <Search  placeholder="输入用户名或者昵称搜索" size="large" onSearch={value => {

                            this.setState({
                                searchKeyword:value,
                                page:1
                            },()=>{
                                this.getUserList();
                            })
                        }} enterButton={true}
                                 className={'search-input'} loading={!!searchKeyword && !!listLoading}/>
                    </div>

                </div>


                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={users}
                    loading={listLoading}
                    renderItem={(item,index) => (
                        <List.Item
                            rowkey={index}
                            key={index}
                            actions={[<a key="list-loadmore-edit" onClick={()=>{
                                this.setState({
                                    editUser: {
                                        origin:{...item,rowIndex:index}
                                    },
                                    showUserModel: true
                                })
                            }}>管理</a>]}
                        >
                            <UserListItem data={item} />
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            this.setState({
                                page
                            },()=>{
                                this.getUserList()
                            })
                        },
                        pageSize: PerPage,
                        total:userCount
                    }}
                />

                <Modal

                    visible={showUserModel}
                    onOk={this.handlerUserModalOk}
                    onCancel={this.handlerUserModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-user-modal'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{"用户管理"}</h2>

                    <div className={'flex-t'}>
                        <Avatar className={`avatar`}  src={editUser && editUser.origin ? editUser.origin.avatar:""}/>
                        <div className={`flex-t-r`}>
                            <div className={'nickname'}>{editUser && editUser.origin ? editUser.origin.nickname:""}</div>
                            <div className={'username'}>{editUser && editUser.origin ? editUser.origin.username:""}</div>
                            <div className={'email'}>{editUser && editUser.origin ? editUser.origin.email:""}</div>
                        </div>

                    </div>

                    <h3>网站权限</h3>
                    <Checkbox.Group style={{ width: '100%' }} value={powerKeys} onChange={this.powerOnChange}>
                        <Row gutter={[15,15]}>
                            {powerList.map((p,m)=>{
                                return <Col span={8} key={m}>
                                    <Checkbox value={p.key}>{p.name}</Checkbox>
                                </Col>
                            })}

                        </Row>
                    </Checkbox.Group>

                    <h3>后台角色</h3>
                    <Checkbox.Group style={{ width: '100%' }} value={rolesKeys} onChange={this.roleOnChange}>
                        <Row gutter={[15,15]}>
                            {roleList.map((r,m)=>{
                                return <Col span={8} key={m}>
                                    <Checkbox value={r.roleKey}>{r.name}</Checkbox>
                                </Col>
                            })}

                        </Row>
                    </Checkbox.Group>

                    <div className={'flex-b'}>
                        <Button type={'primary'} disabled={!editUser || (!editUser.rolesKeys && !editUser.powerKeys)} onClick={this.submitChange} loading={submitting}>保存</Button>
                    </div>


                </Modal>

            </div>

        )
    }
}

const mapStateToProps = (state,ownProps)=>{

    return {

    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserDash);
