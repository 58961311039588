
import accountTypes  from '../actionTypes/accountTypes'
const  {  ACCOUNT_ADD_LOGIN_USER_INFO,
    ACCOUNT_REMOVE_LOGIN_USER_INFO,
    ACCOUNT_FLAG_LOGIN_STATUS,ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,ACCOUNT_USER_MENUS } = accountTypes
function accountAddLoginUserInfo(info) {
    return { type: ACCOUNT_ADD_LOGIN_USER_INFO, payload:info }
}

function accountRemoveLoginUserInfo(){
    return { type: ACCOUNT_REMOVE_LOGIN_USER_INFO,payload:null }
}

function accountUserMenus(menus){
    return { type: ACCOUNT_USER_MENUS,payload:menus }
}

function accountFlagLoginStatus(isLogin){
    return { type: ACCOUNT_FLAG_LOGIN_STATUS,payload:!!isLogin }
}

function accountShowLoginRegisterModal(status) {
    // status : "none','login','register'
    return { type: ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,payload:status }
}

export  default {
    accountAddLoginUserInfo,
    accountRemoveLoginUserInfo,
    accountFlagLoginStatus,
    accountShowLoginRegisterModal,
    accountUserMenus
}