import React from 'react';
import {List, Modal, Input, Button, Popconfirm, message, Icon, Tree, Checkbox, Row, Col} from 'antd'
import './roleAdmin.scss'
import CategoryListItem from "../../components/categoryListItem/categoryListItem";
import accountReq from "../../request/accountReq";
import developRequest from "../../request/developRequest";
import indexMenus from '../index/indexMenu.json'

const PerPage = 30;

const {TreeNode} = Tree

class RoleAdmin extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        roles: [],
        showEditModal: false,
        editRole: null,
        showPowerEditModal: false,
        apiList: [],
        localMenus: []

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount() {
        this.getAllRoles();
        this.getAllUrl()

        this.setState({
            localMenus: indexMenus
        })
    }

    componentWillUnmount() {

    }

    getAllUrl = () => {
        developRequest.getAllApi()
            .then(res => {
                this.setState({
                    apiList: res
                })
            })
            .catch(error => {

            })
    }


    handlerEditItem = (e) => {
        // console.log('handlerEditItem', e.target.dataset)
        let item = this.state.roles[e.target.dataset.index];
        this.setState({
            editRole: {origin: item},
            showEditModal: true
        })


    }
    willDeleteRole = null;
    deleteRole = () => {
        accountReq.deleteRole(this.willDeleteRole.id)
            .then(res => {

                this.getAllRoles()
                message.success(`已删除角色`);
            })
            .catch(error => {
                message.error(`删除角色失败：${error && error.message ? error.message : ""}`)
            })
    }

    showPowerEditModal = (e) => {
        let index = e.currentTarget.dataset.index;
        let role = this.state.roles[index]
        this.setState({
            editRole: {origin: role},
            showPowerEditModal: true
        })
        this.getRoleApiList(role.roleKey)
        this.getRoleMenu(role.roleKey)
    }


    getRoleApiList(roleKey) {
        accountReq.getRoleApiList(roleKey)
            .then((res) => {
                let editRole = this.state.editRole
                if (editRole && editRole.origin) {

                    let selectApiIds = res.map(item => item.apiId);
                    editRole.apis = selectApiIds
                    this.setState({
                        editRole,
                        selectApiIds
                    })
                }
            })
    }

    getRoleMenu(roleKey) {
        accountReq.getRoleMenu(roleKey)
            .then((res) => {
                let editRole = this.state.editRole
                if (editRole && editRole.origin) {
                    let selectMenuIds = res.map(item => item.menuId);

                    let localMenus = this.state.localMenus;
                    for (let key of selectMenuIds) {
                        for (let g of localMenus) {
                            g.checkedChild =  g.checkedChild || [];
                            if (g.child) {
                                for (let c of g.child) {
                                    if (c.key == key && g.checkedChild.indexOf(key) < 0) {
                                        g.checkedChild.push(key);
                                    }

                                }
                                g.checked = !!(g.checkedChild.length && g.checkedChild.length >= g.child);
                            }

                        }
                    }
                    editRole.menuKeys = selectMenuIds
                    console.log(`ahahahh`,localMenus,selectMenuIds)
                    this.setState({
                        localMenus,
                        editRole
                    })
                }
            })
    }

    handlerPowerEditModalCancel = e => {
        let localMenus =  this.state.localMenus
        for(let g of localMenus){
            g.checkedChild = [];
            g.checked = false
        }
        this.setState({
            editRole: {},
            localMenus,
            showPowerEditModal: false
        })
    }
    sortableItem = (sortableInfo, index) => {
        const {id} = sortableInfo;
        return (
            <List.Item
                actions={[<a key="list-loadmore-edit" onClick={this.handlerEditItem} data-index={index}>编辑</a>,
                    <a key="list-loadmore-edit" data-index={index} onClick={this.showPowerEditModal}
                       data-index={index}>权限管理</a>,
                    <Popconfirm
                        placement="leftBottom"
                        title={'确认删除？'}
                        onConfirm={this.deleteRole}
                        okType={`danger`}
                        okText="删除"
                        cancelText="取消"
                    ><a key="list-loadmore-more" onClick={() => {
                        this.willDeleteRole = {...sortableInfo, rowIndex: index}
                    }}>删除</a></Popconfirm>]}
                className={'list-item'}

            >

                <CategoryListItem data={sortableInfo}/>
            </List.Item>

        )
    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editRole: null,
            showEditModal: false
        })
    }

    getAllRoles() {
        accountReq.getAllRoleList()
            .then(res => {
                if(res && res.length){
                    res = res.filter(item => item.roleKey !== 'admin')
                }
                this.setState({
                    roles: res
                })
            })
            .catch(error => {
                message.error(`获取角色列表失败：${error ? error.message : ""}`)
            })
    }

    save = () => {
        let {editRole} = this.state;

        if (editRole.origin) {
            if (editRole.hasOwnProperty(`name`) && !editRole.name) {
                return message.warning(`角色名称不能为空`)
            }
            if (editRole.hasOwnProperty(`roleKey`) && !editRole.roleKey) {
                return message.warning(`角色Key不能为空`)
            }

            if (!editRole.name && !editRole.roleKey) {
                return message.warning(`没有任何修改`)
            }
            let body = {
                ...editRole,
                id: editRole.origin.id
            }
            delete body.origin
            accountReq.updateRole(body)
                .then(role => {

                    this.setState({

                        showEditModal: false,
                        editRole: {}

                    })
                    this.getAllRoles()
                    message.success(`已更新角色信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名角色，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {

            if (!editRole.name) {
                return message.warning(`请填写角色名称`)

            }
            if (!editRole.name) {
                return message.warning(`请填写角色key`)

            }
            let body = {
                name: editRole.name,
                roleKey: editRole.roleKey
            }
            accountReq.createRole(body)
                .then(role => {

                    this.setState({

                        showEditModal: false,
                        editRole: {}
                    })
                    this.getAllRoles()
                    message.success(`已创建新角色`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名角色，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    apiTreeOnCheck = (e, target) => {
        console.log(`apiTreeOnCheck`, e, target)
        this.setState({
            selectApiIds: e
        })


    }

    powerSave = async () => {
        let {selectApiIds, editRole, localMenus} = this.state;
        if (selectApiIds) {
            let apiIds = selectApiIds.filter(item => item.indexOf(`G#`) < 0);
            let deleteApis = [];
            let originApiIds = editRole.apis || [];
            for (let id of originApiIds) {
                if (apiIds.indexOf(id) < 0) {
                    deleteApis.push(id);
                }

            }

            let addApis = [];
            for (let id of apiIds) {
                if (originApiIds.indexOf(id) < 0) {
                    addApis.push(id);
                }
            }
            if (addApis.length) {
                try {
                    await accountReq.addApiPowerToRole(editRole.origin.roleKey, addApis)
                    message.success(`角色已新增${addApis.length}个api权限`)
                } catch (e) {
                    message.error(`添加新api权限失败`)
                }

            }
            if (deleteApis.length) {
                try {
                    await accountReq.removeApiPowerFromRole(editRole.origin.roleKey, deleteApis)
                    message.success(`角色已移除${deleteApis.length}个api权限`)

                } catch (e) {
                    message.error(`移除api权限失败`)
                }

            }
            this.getRoleApiList(editRole.origin.roleKey)

        }

        let selectMenuKeys = []
        for (let g of localMenus) {
            selectMenuKeys = selectMenuKeys.concat(g.checkedChild || []);
        }

        let addKeys = [], removeKeys = [];
        let originMenuKeys = editRole.menuKeys || []
        for (let k of originMenuKeys) {
            if (selectMenuKeys.indexOf(k) < 0) {
                removeKeys.push(k)
            }
        }

        for (let k of selectMenuKeys) {
            if (originMenuKeys.indexOf(k) < 0) {
                addKeys.push(k)
            }
        }
        if (addKeys && addKeys.length) {
            try {
                await accountReq.addMenuOptionToRoleMenu(editRole.origin.roleKey, addKeys)
                message.success(`角色已新增${addKeys.length}个菜单项`)

            } catch (e) {
                message.error(`添加新菜单项失败`)
            }
        }

        if (removeKeys && removeKeys.length) {
            try {
                await accountReq.removeMenuOptionFromRoleMenu(editRole.origin.roleKey, removeKeys)
                message.success(`角色已移除${removeKeys.length}个菜单项`)
            } catch (e) {
                message.error(`移除菜单项失败`)
            }
        }
        this.getRoleMenu(editRole.origin.roleKey);


    }

    menuChildOnChange = e => {
        console.log(`menuChildOnChange`, e)
    }

    render() {

        let {roles, showEditModal, editRole, apiList, showPowerEditModal, selectApiIds, localMenus} = this.state;

        let menuGroup = localMenus.map((g, gIndex) => {
            let child = []
            if (g.child && g.child.length) {
                child = g.child.map((c, cIndex) => {
                    return {
                        label: c.name,
                        value: c.key
                    }
                })
            }


            return (
                <div className={`menu-group`}>
                    <div className={`group-check`}>
                        <Checkbox className={`check-g`}
                                  indeterminate={!g.checked && g.checkedChild && g.child && g.checkedChild.length && g.checkedChild.length < g.child.length}
                                  checked={!!g.checked || g.checkedChild && g.child && g.checkedChild.length >= g.child.length}
                                  onChange={(e) => {
                                      let checked = e.target.checked;
                                      let localMenus = this.state.localMenus
                                      let g = localMenus[gIndex];
                                      if (checked) {
                                          g.checkedChild = []
                                          if (g.child) {
                                              g.checkedChild = g.child.map(item => item.key);
                                          }
                                      } else {
                                          g.checkedChild = []

                                      }
                                      g.checked = checked
                                      this.setState({
                                          localMenus
                                      })
                                  }}>
                            {g.name}
                        </Checkbox>
                    </div>
                    <div className={`child`}>
                        <Checkbox.Group
                            options={child}

                            value={g.checkedChild || []}
                            onChange={keys => {
                                let localMenus = this.state.localMenus
                                let g = localMenus[gIndex];
                                g.checkedChild = keys;
                                g.checked = g.checkedChild.length >= g.child.length
                                this.setState({
                                    localMenus
                                })
                            }}
                        />
                    </div>

                </div>
            )
        })

        let loop = (group, key, absolutePath) => {
            let urls = [];
            let child = [];

            if (group.urls && group.urls.length) {
                urls = group.urls.map((item, index) => {
                    return <TreeNode icon={<Icon type="carry-out"/>} title={
                        <div className={`tree-node-container`}>
                            <div className={`flex-t`}>
                                <div className={`title`}>{item.name}</div>
                            </div>
                        </div>
                    } key={item.id}/>
                })


            }
            group.absolutePath = absolutePath
            if (group.child && group.child.length) {
                child = group.child.map((item, index) => {
                    return loop(item, `${key}-g${index}`, absolutePath + "/" + item.basePath)
                })

            }


            return <TreeNode icon={<Icon type="carry-out"/>} title={
                <div className={`tree-node-container`}>
                    <div className={`title group-title`}>{group.name}</div>
                </div>
            } key={`G#` + group.id}>
                {urls}
                {child}
            </TreeNode>


        }


        apiList = apiList || [];
        let treeNodes = apiList.map((g, idx) => {
            return loop(g, `g${idx}`, g.basePath);
        })


        return (
            <div className='page-role-admin'>

                <div className={'list-header'}>
                    <h2>后台角色</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editRole: {},
                                showEditModal: true
                            })
                        }}>+新角色</Button>
                    </div>
                </div>


                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={roles}
                    footer={null}
                    renderItem={(item, index) => {
                        return this.sortableItem(item, index)
                    }}
                />

                {/*权限*/}
                <Modal

                    visible={showPowerEditModal}
                    onCancel={this.handlerPowerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-role'}
                    centered={true}
                    maskClosable={false}
                    width={"90%"}
                >
                    <Row gutter={40}>

                        <Col span={12}>
                            <h2>{`菜单栏`}</h2>

                            {menuGroup}
                        </Col>

                        <Col span={12}>
                            <h2>{`API访问权限`}</h2>
                            <Tree className={`api-tree`}
                                  onCheck={this.apiTreeOnCheck}
                                  checkedKeys={selectApiIds}
                                  checkable
                            >
                                {treeNodes}
                            </Tree>
                        </Col>
                    </Row>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.powerSave}>保存</Button>
                    </div>

                </Modal>

                {/*编辑模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-role'}
                    centered={true}
                >
                    <h2>{editRole && editRole.origin ? "编辑角色" : "创建角色"}</h2>
                    <Input className={'input'}
                           value={editRole && editRole.hasOwnProperty('name') ? editRole.name : editRole && editRole.origin ? editRole.origin.name : ""}
                           placeholder={"角色名称"} onChange={(e) => {
                        let editRole = this.state.editRole;
                        editRole.name = e.target.value;
                        this.setState({
                            editRole
                        })
                    }}/>

                    <Input className={'input'}
                           value={editRole && editRole.hasOwnProperty('roleKey') ? editRole.roleKey : editRole && editRole.origin ? editRole.origin.roleKey : ""}
                           placeholder={"角色key"} onChange={(e) => {
                        let editRole = this.state.editRole;
                        editRole.roleKey = e.target.value;
                        this.setState({
                            editRole
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.save}>保存</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default RoleAdmin;
