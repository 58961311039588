const dateformat = require("dateformat")

function parseTimelineDate(dateString){
    let date
    try{
        date = new Date(dateString);
    }catch(error){
        return "";
    }
    let now = new Date();
   console.log('now',now);
;    let dateTime = date.getTime() /1000;
    let nowTime = now.getTime()/ 1000;
    
    if(nowTime - dateTime < 60 * 5){
        return "刚刚"
    }
    if(nowTime - dateTime < 60 * 60){
         return `${parseInt((nowTime - dateTime) / 60)}分钟前`
    }

    if(nowTime - dateTime < 60 * 60 * 12){
        return `${parseInt((nowTime - dateTime) / 3600)}小时前`
    }
    //  今天
    if(now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate()){
        return dateformat(date,'HH:MM')
    }
    // 不超过20天
    if(nowTime - dateTime < 60 * 60 * 24 * 20){
        return `${Math.ceil((nowTime - dateTime) / 3600 / 24)}天前`
    }

    if(now.getFullYear() === date.getFullYear()){
        return dateformat(date,'mm.dd')
    }

    return dateformat(date,'yyyy-mm-dd')

}

 function randomNum(minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
            return 0;
    }
}

export  {
    parseTimelineDate,
    randomNum
}