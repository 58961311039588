import React from 'react';
import { Icon, Popconfirm} from 'antd'
import './appCardItem.scss'

class AppCardItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        data: {},
        iconKey:'preview'
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            data: nextProps.data,
            className: nextProps.className,
            style: nextProps.style,
            rowKey: nextProps.rowKey,
            draggable: nextProps.draggable,
            iconKey:nextProps.iconKey
        };
    }


    /* life circle method */

    componentDidMount() {

    }
    handlerDeleteApp = () => {
        if(this.props.handlerDeleteApp){
            this.props.handlerDeleteApp(this.state.data,this.state.rowKey)
        }
    }

    render() {
        let { rowKey,  data, className, style,draggable } = this.state;

        return (
            <div className={`comp-app-card-item ${className || ""}`} style={style || {}} key={`comp-app-card-${rowKey || new Date().getTime()}`}

                 draggable={draggable}
                 onDragStart={this.props.onDragStart}
                 onDragEnter={this.props.onDragEnter}
                 onDragLeave={this.props.onDragLeave}
                 onDragEnd={this.props.onDragEnd}

                 onDrop={this.props.onDrop}

            >
                <div className={'flex-t'}>
                    <img
                        src={data[this.state.iconKey ? this.state.iconKey:"preview"] || ""}
                        alt={''}/>
                    <div className={'name'}>{data.name || ""}</div>
                </div>
                <div className={'actions'}>
                    <Icon className={'icon'} onClick={this.props.onEdit} type={'edit'}/>
                    <Popconfirm title={`你确定删除该工具吗？`} onConfirm={this.handlerDeleteApp}> <Icon className={'icon'} type={'delete'}/></Popconfirm>
                </div>

            </div>
        )
    }
}



export default (AppCardItem);
