import AppRequest from './AppRequest'


const prefixPath = "/api_v1/admin-api/"
class SoReq extends AppRequest {

    getAllGroup(){
        return this.get(prefixPath + "so-uigreat-com/category/post")
    }
    updateGroup(info){
        return this.put(prefixPath + "so-uigreat-com/category/post",info)
    }

    createGroup(info){
        return this.post(prefixPath + "so-uigreat-com/category/post",info)
    }

    deleteGroup(id){
        return this.delete(prefixPath + "so-uigreat-com/category/post",{id})
    }

    addNewSiteToGroup(info){
        return this.post(prefixPath + "so-uigreat-com/website/post",info)
    }

    updateSite(info){
        return this.put(prefixPath + "so-uigreat-com/website/post",info)
    }

    deleteSite(id){
        return this.delete(prefixPath + 'so-uigreat-com/website/post',{id})
    }

    saveGroupAndSiteSort(data){
        return this.post(prefixPath + "so-uigreat-com/save-sort",{newData:data})
    }




}

const  soReq = new SoReq();
export default soReq;

