import React from 'react';
import {List, Modal, Input, Button, Popconfirm, message} from 'antd'
import './friendLinks.scss'
import CategoryListItem from "../../components/categoryListItem/categoryListItem";
import linkReq from "../../request/linkReq";

const PerPage = 30;

class FriendLinks extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        links:[],
        showEditModal: false,
        editLink: null,

    }
    //
    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     return {
    //         ...nextProps
    //     };
    // }


    /* life circle method */

    componentDidMount() {
        this.addGlobalListener();
        this.getFriendLinks();
    }

    componentWillUnmount() {
        this.removeGlobalListener();
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {

        let item = this.state.links[e.target.dataset.index];
        this.setState({
            editLink: {...item, origin: item},
            showEditModal: true
        })

    }
    willDeleteLink = null;
    deleteLink = ()=>{
        linkReq.removeFriendLink(this.willDeleteLink.id)
            .then(res =>{
                let links =  this.state.links;
                links.splice(this.willDeleteLink.rowIndex,1);
                this.setState({
                    links
                })
                message.success(`已删除链接`);
            })
            .catch(error =>{
                message.error(`删除链接失败：${error && error.message ? error.message:""}`)
            })
    }
    sortableItem = (sortableInfo, index) => {
        const {id} = sortableInfo;
        return (
            <List.Item
                actions={[<a key="list-loadmore-edit" onClick={this.handlerEditItem} data-index={index}>编辑</a>,
                    <Popconfirm
                        placement="leftBottom"
                        title={'确认删除？'}
                        onConfirm={this.deleteLink}
                        okText="删除"
                        cancelText="取消"
                    ><a key="list-loadmore-more" onClick={()=>{
                        this.willDeleteLink = {...sortableInfo,rowIndex:index}
                    }}>删除</a></Popconfirm>]}
                className={'list-item'}
                draggable
                onDragStart={() => {
                    this.setState({
                        dragElement: sortableInfo,
                    });
                }}
                onDragEnd={this.onDragEnd}
                onDragEnter={() => {
                    if (id !== this.state.dragElement.id) {
                        const oldDragIndex = this.state.links.findIndex(item => item.id === this.state.dragElement.id);
                        const oldEnterIndex = this.state.links.findIndex(item => item.id === sortableInfo.id);
                        if (oldDragIndex > oldEnterIndex) {
                            const newDataArray = this.state.links.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id);
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({links: newDataArray});
                        } else {
                            const newDataArray = this.state.links.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id) + 1;
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({links: newDataArray});
                        }
                        this.setState({
                            isConfigDirty: true,
                        });
                    }
                }}
                onDragLeave={() => {
                    if (sortableInfo.id !== this.state.dragElement.id) {
                        if (this.state.lock && sortableInfo.id === this.state.links[this.state.links.length - 1]) {
                            const newDataArray = this.state.links.filter(item => item.id !== this.state.dragElement.id);
                            newDataArray.push(this.state.dragElement);
                            this.setState({
                                lock: false,
                            }, () => {
                                this.setState({
                                    links: newDataArray,
                                });
                            });
                        } else {
                            this.setState({
                                lock: true,
                            });
                        }
                    }
                }}

            >

                <CategoryListItem data={sortableInfo}/>
            </List.Item>

        )
    }
    categorySortTimer = null;
    clearSortTimer = ()=>{
        if(this.categorySortTimer){
            clearTimeout(this.categorySortTimer);
        }
    }
    startSortTimer = ()=>{
        this.clearSortTimer();
        this.categorySortTimer = setTimeout(()=>{
            linkReq.saveFriendLinkSorts(this.state.links.map(item =>item.id))
                .then(res =>{
                    message.success(`已保存排序`)
                })
                .catch(error =>{
                    message.error(`排序保存失败：${error ? error.message:""}`)
                })
        },1000)
    }
    onDragEnd = (e) => {
        this.startSortTimer();
    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editLink: null,
            showEditModal: false
        })
    }

    getFriendLinks(){
        linkReq.getFriendLinks()
            .then(res =>{
                this.setState({
                    links:res
                })
            })
            .catch(error =>{
                message.error(`获取友情链接列表失败：${error ? error.message:""}`)
            })
    }

    saveLink = () => {
        let {editLink} = this.state;
        if (!editLink.name) {
            return message.warning(`名称不能为空`)
        }
        if (editLink.origin) {
            if(!editLink.name && !editLink.url){
                return message.warning(`没有任何修改可以提交`)
            }
            if (editLink.name === editLink.origin.name && editLink.url === editLink.origin.url) {
                return message.warning(`没有任何修改可以提交`)
            }
            let body = {
                id: editLink.origin.id,

            }
            if(editLink.name){
                body.name = editLink.name;
            }
            if(editLink.url){
                body.url = editLink.url;
            }
            linkReq.changeFriendLink(body)
                .then(res => {
                    let links = this.state.links;
                    if (links && links.length) {
                        for (let i = 0; i < links.length; i++) {
                            let link = links[i];
                            if (link.id === editLink.origin.id) {
                                if(editLink.name){
                                    link.name = editLink.name;
                                }
                                if(editLink.url){
                                    link.url = editLink.url;
                                }
                                break;
                            }
                        }
                    }

                    this.setState({
                        links,
                        showEditModal: false,
                        editLink: {}
                    })
                    message.success(`已更新链接信息`)
                })
                .catch(error => {
                    // if (error && error.code === 7208) {
                    //     return message.error("已存在同名分类，请使用其他名称")
                    // }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            if(!editLink.name){
               return message.warn(`请填写链接名称`)
            }
            if(!editLink.url){
                return message.warn(`请填写链接地址`)
            }
            let body = {
                name: editLink.name,
                url:editLink.url
            }
            linkReq.addNewFriendLink(body)
                .then(link => {
                    let links = this.state.links
                    links.push(link);
                    this.setState({
                        links,
                        showEditModal: false,
                        editLink: {}
                    })
                    message.success(`已创建新链接`)
                })
                .catch(error => {

                    return message.error(`发生错误，请重试`)
                })
        }
    }

    render() {

        let {links, showEditModal, editLink} = this.state;

        return (
            <div className='page-friend-links'>

                <div className={'list-header'}>
                    <h2>友情链接</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editLink: {},
                                showEditModal: true
                            })
                        }}>+新链接</Button>
                    </div>
                </div>


                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={links}
                    footer={null}
                    renderItem={(item, index) => {
                        return this.sortableItem(item, index)
                    }}
                />
                {/*编辑模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-link'}
                    centered={true}
                >
                    <h2>友情链接</h2>
                    <Input className={'input'}
                           value={editLink && editLink.hasOwnProperty('name') ? editLink.name : editLink && editLink.origin ? editLink.origin.name : ""}
                           placeholder={"链接名称"} onChange={(e) => {
                        let editLink = this.state.editLink;
                        editLink.name = e.target.value;
                        this.setState({
                            editLink
                        })
                    }}/>
                    <Input className={'input'}
                           value={editLink && editLink.hasOwnProperty('url') ? editLink.url : editLink && editLink.origin ? editLink.origin.url : ""}
                           placeholder={"链接地址"} onChange={(e) => {
                        let editLink = this.state.editLink;
                        editLink.url = e.target.value;
                        this.setState({
                            editLink
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.saveLink}>保存</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default FriendLinks;
