import React from 'react';
import { Input, List, Button, Breadcrumb} from 'antd'
import  {Link} from 'react-router-dom'
import './AudioSeriesDash.scss'
import {connect} from 'react-redux'
import audioReq from "../../request/audioReq";
import AudioSeriesListItem from "../../components/AudioSeriesListItem/AudioSeriesListItem";

const {Search} = Input;
const PerPage = 30;


class AudioSeriesDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        courseTotalNum:0,
        audioSeriesList:[],
        seriesCount:0,
        loadingList:false,
        condition:{
            perPage:PerPage,
            page:0,
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    /* life circle method */
    componentDidMount() {

        this.getSeries();
    }


    getSeries = ()=>{

        this.setState({
            loadingList:true
        })
        let condition = {...this.state.condition};

        audioReq.getAudioSeriesList(condition)
            .then( res=>{
                console.log(`getAudioSeriesList`,res,res.rows)
                this.setState({
                    loadingList:false,
                    audioSeriesList:res.rows,
                    seriesCount:res.count
                })
            })
            .catch(error =>{
                this.setState({
                    loadingList:false
                })
            })
    }



    render() {
        let {audioSeriesList,loadingList,seriesCount} = this.state;


        return (
            <div className='page-course-board'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{"播客系列列表"}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <h1>{"播客系列列表"}</h1>
                </div>

                <div className={'header'}>
                    <div className={'flex-l'}>
                        <h2>全部系列</h2>
                    </div>
                    <div className={'flex-r'}>
                        <Button type={'primary'} style={{height: 40}} onClick={() => {
                            this.props.history.push('/audio-series/create')
                        }}>创建新系列</Button>
                    </div>
                </div>

                <List
                    className="course-list"
                    itemLayout="horizontal"
                    loading={loadingList}
                    dataSource={audioSeriesList || []}
                    rowKey={'id'}
                    renderItem={(item,index) => (
                        <List.Item
                        >
                            <AudioSeriesListItem
                                data={item}
                                actions={[<Link key="list-edit" to={`/audio-series/${item.id}`}>编辑</Link>,<a key="list-publish" data-index={index} onClick={this.changePublish}>{item.status === 'published' ? '下架':"发布"}</a>,
                                    <a key="list-delete" hidden={item.purchaseNum  > 0}>删除</a>]}/>
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            let condition = this.state.condition;
                            condition.page = page;
                            this.setState({
                                condition
                            },()=>{
                                this.getCourses()
                            })

                        },
                        pageSize: PerPage,
                        total:seriesCount
                    }}
                    split={false}
                />

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AudioSeriesDash);
