import React from 'react';
import {Avatar} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './commentListItem.scss'
import IconFont from "../iconfont/icon-font";
import config from '../../config.json'
const greenLabelMap = {
spam:"含垃圾信息",
ad:"广告",
politics:"涉政",
terrorism:"暴恐",
abuse:"辱骂",
porn:"色情",
flood:"灌水",
contraband:"违禁",
meaningless:"无意义",
}

class CommentListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        data: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            key: nextProps.key,
            data: nextProps.data,
            type:nextProps.type
        };
    }


    /* life circle method */

    componentDidMount() {

    }



    render() {
        let { data,type} = this.state;
        return (
            <div className='comp-comment-list-item' >
                <div className={`flex-l`}>
                    <Avatar src={data && data.userInfo ? data.userInfo.avatar :""} />
                    <div className={`nickname`}>{ data && data.userInfo ? data.userInfo.nickName || data.userInfo.username || "":""}</div>
                </div>
                <div className={'flex-m'}>
                    <div className={'content'}>
                        {data.content}
                    </div>
                    <div className={'flex-m-b'}>
                        <div className={`flex-m-b-l`}>
                            <div>{data && data.createdAt ? parseTimelineDate(data.createdAt):""}发布于{type === 'course' ? "课程":"作品"} <a href={type === 'course' ? `${config.domainSiteHost}/course/${data && data.course ? data.course.id : ""}`:`${config.domainSiteHost}/shot/${data && data.shot ? data.shot.id : ""}`} target={"_blank"}>《{data  ? type === 'course' && data.course ? data.course.title : type === 'shot' && data.shot ? data.shot.title:"":""}》</a></div>
                        </div>

                        <div className={'flex-m-b-r'}>
                            <div className={'statistic'}>
                                <div className={`stat-item`}>赞 {data.likeNum || 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex-r'}>
                    <div className={'status'}>
                        <IconFont type={'iconjiance'} style={{fontSize: 15,color:'red'}} hidden={!data || data.pass !== 'block'} />
                        <div className={`label`}>{data.label ? greenLabelMap[data.label]:""}</div>
                    </div>
                </div>
                <div className={'actions'}>
                    {this.props.actions || {}}
                </div>

            </div>
        )
    }
}


export default (CommentListItem);
