import React from 'react';
import {List, Row, Col, Statistic, Modal, Input, Button, Popconfirm, message} from 'antd'
import './shotCategory.scss'
import CategoryListItem from "../../components/categoryListItem/categoryListItem";
import courseReq from "../../request/courseReq";
import shotReq from "../../request/shotReq";

const PerPage = 30;


class ShotCategory extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        categories: [],
        showEditModal: false,
        editCategory: null

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount() {
        this.addGlobalListener();
        this.getCategories();
    }

    componentWillUnmount() {
        this.removeGlobalListener();
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.categories[e.target.dataset.index];
        this.setState({
            editCategory: {...item, origin: item},
            showEditModal: true
        })

    }
    willDeleteCategory = null;
    deleteCategory = ()=>{
        shotReq.deleteShotCategory(this.willDeleteCategory.id)
            .then(res =>{
                let categories =  this.state.categories;
                categories.splice(this.willDeleteCategory.rowIndex,1);
                this.setState({
                    categories
                })
                message.success(`已删除分类`);
            })
            .catch(error =>{
                message.error(`删除分类失败：${error && error.message ? error.message:""}`)
            })
    }
    sortableItem = (sortableInfo, index) => {
        const {id} = sortableInfo;
        return (
            <List.Item
                actions={[<a key="list-loadmore-edit" onClick={this.handlerEditItem} data-index={index}>编辑</a>,
                    <Popconfirm
                        placement="leftBottom"
                        title={'确认删除？'}
                        onConfirm={this.deleteCategory}
                        okText="删除"
                        cancelText="取消"
                    ><a key="list-loadmore-more" onClick={()=>{
                        this.willDeleteCategory = {...sortableInfo,rowIndex:index}
                    }}>删除</a></Popconfirm>]}
                className={'list-item'}
                draggable
                onDragStart={() => {
                    this.setState({
                        dragElement: sortableInfo,
                    });
                }}
                onDragEnd={this.onDragEnd}
                onDragEnter={() => {
                    if (id !== this.state.dragElement.id) {
                        const oldDragIndex = this.state.categories.findIndex(item => item.id === this.state.dragElement.id);
                        const oldEnterIndex = this.state.categories.findIndex(item => item.id === sortableInfo.id);
                        if (oldDragIndex > oldEnterIndex) {
                            const newDataArray = this.state.categories.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id);
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({categories: newDataArray});
                        } else {
                            const newDataArray = this.state.categories.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id) + 1;
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({categories: newDataArray});
                        }
                        this.setState({
                            isConfigDirty: true,
                        });
                    }
                }}
                onDragLeave={() => {
                    if (sortableInfo.id !== this.state.dragElement.id) {
                        if (this.state.lock && sortableInfo.id === this.state.categories[this.state.categories.length - 1]) {
                            const newDataArray = this.state.categories.filter(item => item.id !== this.state.dragElement.id);
                            newDataArray.push(this.state.dragElement);
                            this.setState({
                                lock: false,
                            }, () => {
                                this.setState({
                                    categories: newDataArray,
                                });
                            });
                        } else {
                            this.setState({
                                lock: true,
                            });
                        }
                    }
                }}

            >

                <CategoryListItem data={sortableInfo}/>
            </List.Item>

        )
    }
    categorySortTimer = null;
    clearSortTimer = ()=>{
        if(this.categorySortTimer){
            clearTimeout(this.categorySortTimer);
        }
    }
    startSortTimer = ()=>{
        this.clearSortTimer();
        this.categorySortTimer = setTimeout(()=>{
            shotReq.saveShotCategorySort(this.state.categories.map(item =>item.id))
                .then(res =>{
                    message.success(`已保存分类排序`)
                })
                .catch(error =>{
                    message.error(`分类排序保存失败：${error ? error.message:""}`)
                })
        },1000)
    }
    onDragEnd = (e) => {
        this.startSortTimer();
    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editCategory: null,
            showEditModal: false
        })
    }

    getCategories(){
        shotReq.getShotCategories()
            .then(res =>{
                this.setState({
                    categories:res
                })
            })
            .catch(error =>{
                message.error(`获取作品分类失败：${error ? error.message:""}`)
            })
    }

    saveCategory = () => {
        let {editCategory} = this.state;
        if (!editCategory.name) {
            return message.warning(`分类名称不能为空`)
        }
        if (editCategory.origin) {
            if (editCategory.name === editCategory.origin.name) {
                return message.warning(`没有任何修改可以提交`)
            }
            let body = {
                id: editCategory.origin.id,
                name: editCategory.name
            }
            shotReq.updateShotCategory(body)
                .then(category => {
                    let categories = this.state.categories;
                    if (categories && categories.length) {
                        for (let i = 0; i < categories.length; i++) {
                            let category = categories[i];
                            if (category.id === editCategory.origin.id) {
                                category.name = editCategory.name;
                                break;
                            }
                        }
                    }

                    this.setState({
                        categories,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已更新分类信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            let body = {
                name: editCategory.name
            }
            shotReq.createShotCategory(body)
                .then(category => {
                    let categories = this.state.categories;
                    categories.push(category);
                    this.setState({
                        categories,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已创建新分类`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    render() {

        let {categories, showEditModal, editCategory} = this.state;

        return (
            <div className='page-shot-category'>

                <div className={'list-header'}>
                    <h2>作品分类</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editCategory: {},
                                showEditModal: true
                            })
                        }}>+新分类</Button>
                    </div>
                </div>


                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={categories}
                    footer={null}
                    renderItem={(item, index) => {
                        return this.sortableItem(item, index)
                    }}
                />
                {/*编辑模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                >
                    <h2>编辑分类</h2>
                    <Input className={'input'}
                           value={editCategory && editCategory.hasOwnProperty('name') ? editCategory.name : editCategory && editCategory.origin ? editCategory.origin.name : ""}
                           placeholder={"分类名称"} onChange={(e) => {
                        let editCategory = this.state.editCategory;
                        editCategory.name = e.target.value;
                        this.setState({
                            editCategory
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.saveCategory}>保存</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default ShotCategory;
