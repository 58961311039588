import React from 'react';
import {Row, Col, Input, Statistic, List, Radio, Button, Breadcrumb, PageHeader, message} from 'antd'
import  {Link} from 'react-router-dom'
import {parseTimelineDate} from '../../utils/dataformat'
import './courseDash.scss'
import {connect} from 'react-redux'
import CourseListItem from "../../components/courseListItem/CourseListItem";
import courseReq from "../../request/courseReq";

const {Search} = Input;
const PerPage = 30;


class CourseDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        courseTotalNum:0,
        groups:[],
        courses:[],
        courseCount:0,
        loadingCourse:false,
        condition:{
            perPage:PerPage,
            page:0,
        },
        selectedGroup:null,
        selectedCategory:null,
        sortKey:'latest',
        searchKeyword:'',
        courseType: "online-course", // "live-course"

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let courseType = 'online-course'
        if(nextProps.location.pathname === '/live-course-dash'){
            courseType = 'live-course'
        }

        return {
            courseType
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.courseType !== this.state.courseType){
            this.setState({
                courseTotalNum:0,
                groups:[],
                courses:[],
                courseCount:0,
                loadingCourse:false,
                condition:{
                    perPage:PerPage,
                    page:0,
                },
                selectedGroup:null,
                selectedCategory:null,
                sortKey:'latest',
                searchKeyword:'',
            },()=>{
                this.getAllGroups();
                this.getCourses();
            })

        }
    }

    /* life circle method */
    componentDidMount() {
        this.getAllGroups();
        this.getCourses();
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData:true
        })
        courseReq.getAllGroup(this.state.courseType)
            .then(resGroups => {
                this.setState({
                    loadingGroupsData:false
                })
                let totalCourseNum = 0;
                if(resGroups && resGroups.length){
                    resGroups = resGroups.filter(item => item.id)
                    for(let i =0 ;i < resGroups.length;i++){
                        let group = resGroups[i];
                        let count  = 0;
                        if(group.categories && group.categories.length){
                            for(let j = 0;j< group.categories.length;j++){
                                let cate = group.categories[j];
                                count += cate.courseTypeNum;
                                totalCourseNum += cate.courseTypeNum;
                            }

                        }
                        group.courseNum =  count;
                    }
                }
                this.setState({
                    groups:resGroups,
                    courseTotalNum:totalCourseNum
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData:false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    getCourses = ()=>{

        this.setState({
            loadingCourse:true
        })
        let condition = {...this.state.condition};
        if(this.state.selectedGroup){
            condition.courseCategoryGroupId = this.state.selectedGroup.id
        }
        if(this.state.selectedCategory){
            condition.categoryId = this.state.selectedCategory.id
        }
        if(this.state.sortKey){
            switch (this.state.sortKey) {
                case "watch-number":
                    condition.watchNum = true
                    break;
                case "view-number":
                    condition.viewNum = true
                    break;
                case "purchase-number":
                    condition.purchaseNum = true
                    break;
                default:
                    break;
            }
        }
        if(this.state.searchKeyword){
            condition.teacherName = this.state.searchKeyword;
            condition.title = this.state.searchKeyword
        }
        condition.courseType = this.state.courseType;
        courseReq.getCourses(condition)
            .then( res=>{
                this.setState({
                    loadingCourse:false,
                    courses:res.rows,
                    courseCount:res.count
                })
            })
            .catch(error =>{
                this.setState({
                    loadingCourse:false
                })
            })
    }

    sortConditionOnChange = (e)=>{

        let key = e.target.value;
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            sortKey:key,
            condition
        },()=>{
            this.getCourses()
        })
    };
    onClickAll = ()=>{
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            selectedGroup:null,
            selectedCategory:null,
            condition
        },()=>{
            this.getCourses()
        })
    }
    onClickGroup = e=>{
        let groupIndex = e.target.dataset.groupindex;
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            selectedGroup:this.state.groups[groupIndex],
            selectedCategory:null,
            condition
        },()=>{
            this.getCourses()
        })
    };

    changePublish = (e) =>{
        let index = e.currentTarget.dataset.index;

    }
    render() {
        let {groups,courseTotalNum,courses,loadingCourse,courseCount,sortKey,selectedCategory,selectedGroup,searchKeyword,courseType} = this.state;

        let groupCards = groups.map((group,index)=>{

            let childs = null;
            if(group.categories && group.categories.length){
                childs = group.categories.map( (child,j)=>{
                    return <div className={`col-child ${selectedCategory && selectedCategory.id === child.id ? 'group-selected':""}`} key={child.id} onClick={(e)=>{
                        let condition = this.state.condition;
                        condition.page = 0;
                        this.setState({
                            selectedCategory:child,
                            selectedGroup:null,
                            condition
                        },()=>{
                            this.getCourses()
                        })
                    }} data-groupindex={index} data-categoryindex={j}>
                        <div className={'name'}>{child.name}</div>
                        <div className={'count'}>{child.courseTypeNum || 0}</div>
                    </div>
                })
            }
            return  <Col span={4} key={group.id}>
                <div className={'col-card'}>
                    <div className={`col-group ${selectedGroup && selectedGroup.id === group.id ? 'group-selected':""}`}
                      onClick={this.onClickGroup} data-groupindex={index}>
                        <div className={'name'}>{group.name || ""}</div>
                        <div className={'count'}>{group.courseNum || 0}</div>
                    </div>
                    {childs}
                </div>
            </Col>
        })

        return (
            <div className='page-course-board'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{courseType === 'live-course' ? "直播课":"课程"}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <h1>{courseType === 'live-course' ? "直播课":"课程"}</h1>
                </div>
                <h2>分类统计</h2>

                <Row gutter={16} className={'statistic-section'}>
                    <Col span={4}>
                        <div className={'col-card'}>
                            <div className={`col-group ${ !selectedGroup && !selectedCategory ? 'group-selected':""}`} onClick={this.onClickAll}>
                                <div className={'name'}>全部</div>
                                <div className={'count'}>{courseTotalNum}</div>
                            </div>
                        </div>
                    </Col>
                    {groupCards}
                </Row>
                <div className={'header'}>
                    <div className={'flex-l'}>
                        <h2>全部{courseType === 'live-course' ? "直播课":"课程"}</h2>
                        <Radio.Group className={'presort-group'}  value={sortKey} onChange={this.sortConditionOnChange}>
                            <Radio.Button value="latest">最新</Radio.Button>
                            <Radio.Button value="view-number" >浏览量</Radio.Button>
                            <Radio.Button value="purchase-number" disabled={courseType === 'live-course'} >购买量</Radio.Button>
                            <Radio.Button value="watch-number" disabled={courseType === 'live-course'}>观看数</Radio.Button>
                        </Radio.Group>
                    </div>

                    <div className={'flex-r'}>
                        <Search placeholder="输入标题或老师姓名进行搜索" size="large" onSearch={value => {
                            let condition = this.state.condition;
                            condition.page = 0;
                            this.setState({
                                searchKeyword:value,
                                condition
                            },()=>{
                                this.getCourses()
                            })
                        }} enterButton={true}
                                className={'search-input'} loading={!!searchKeyword && !!loadingCourse}/>
                        <Button type={'primary'} style={{height: 40}} onClick={() => {
                            if(courseType === 'live-course'){
                                this.props.history.push(`/live-course/create`)
                            }else {
                                this.props.history.push('/course/create')
                            }

                            }}>创建{courseType === 'live-course' ? "直播课":"课程"}</Button>
                    </div>
                </div>

                <List
                    className="course-list"
                    itemLayout="horizontal"
                    loading={loadingCourse}
                    dataSource={courses || []}
                    renderItem={(item,index) => (
                        <List.Item
                        >
                            <CourseListItem
                                data={item}
                                actions={[<Link key="list-edit" to={`/${item.courseType === 'live-course' ? 'live-course':"course" }/${item.id}`}>编辑</Link>]}/>
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            let condition = this.state.condition;
                            condition.page = page;
                            this.setState({
                                condition
                            },()=>{
                                this.getCourses()
                            })

                        },
                        pageSize: PerPage,
                        total:courseCount
                    }}
                    split={false}
                />

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDash);
