import React from 'react';
import {
    Row,
    Col,
    Input,
    Icon,
    Radio,
    Button,
    Breadcrumb,
    Upload,
    Select,
    Cascader,
    Avatar,
    Tag,
    InputNumber,
    Switch,
    Modal, Spin, message,
    Progress,
    Popconfirm, DatePicker,
    Tabs
} from 'antd'
import UserSearcherModal from "../../components/SearchUserModal/UserSearcherModal";

import courseReq from "../../request/courseReq";

import './courseEdit.scss'
import { connect } from 'react-redux'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'
import 'braft-extensions/dist/code-highlighter.css'
import 'braft-extensions/dist/color-picker.css'
import CodeHighlighter from 'braft-extensions/dist/code-highlighter'
import Markdown from 'braft-extensions/dist/markdown'
import Table from 'braft-extensions/dist/table'
import ColorPicker from 'braft-extensions/dist/color-picker'
import HeaderId from 'braft-extensions/dist/header-id'
import indexReq from '../../request/indexReq'
import * as qiniu from 'qiniu-js'
import storage from "../../storage/storage";
import moment from "moment";
const uuidv4 = require('uuid/v4');


const { TabPane } = Tabs;
const editorOptions = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
};


const CPOptions = {
    theme: 'light', // 指定取色器样式主题，支持dark和light两种样式
}

const editorControls = [
    'undo', 'redo', 'separator', 'line-height', 'letter-spacing', 'separator',
    'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
    'link', 'separator', 'hr', 'separator',
    'media', 'separator', "fullscreen"
];

BraftEditor.use(ColorPicker(CPOptions));

BraftEditor.use(Table(editorOptions));
const MKOptions = {};
const codeHOptions = {};
BraftEditor.use(Markdown(MKOptions));
BraftEditor.use(CodeHighlighter(codeHOptions));
BraftEditor.use(HeaderId({}));


const { Option } = Select;

// 富文本编辑器媒体上传函数，接入七牛上传
const myUploadFn = (param) => {

    const progressFn = (event) => {
        param.progress(event.total.percent)
    };

    const errorFn = (response) => {
        param.error({
            msg: '上传失败'
        })
    };

    indexReq.getFileUploadToken()
        .then(info => {
            const successFn = (response) => {
                console.log(response.key);
                param.success({
                    url: `${info.host}${response.key}`
                })
            };
            let paths = param.file.name.split('.');
            let key = null
            if (paths.length > 1) {
                key = uuidv4() + "." + paths[paths.length - 1];
            }
            let observable = qiniu.upload(param.file, key, info.uploadToken, null, null);
            observable.subscribe(progressFn, errorFn, successFn);
        })
        .catch(error => {
            return errorFn(error);
        })
};

const { Dragger } = Upload;
const PerPage = 30;

class CourseEdit extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        showTeacherAddModal: false,
        uploadingPreviewStatus: 'init', // ing ,error,
        previewProgress: 0,
        apps: [],
        categoryCascaderValues: [], // 分类选择器当前值
        categoryCascaderOptions: [],// 类级联选择器的数据
        seriesCascaderOptions: [],// 系列选择器数据
        seriesCascaderValues: [],// 系列选择器当前值
        loadingGroupsData: false, // 是否在加载课程分类数据
        courseId: 0,
        noFound: false,
        pageLoading: true,
        editorState: BraftEditor.createEditorState(null),
        showSoftAddModal: false,
        courseInfo: {},
        changedInfo: {},
        editType: 'create', // 'create','update'
        loadingCourseStatus: false,
        saving: false,
        courseType: "online-course", // 'live-course'
        courseSeriesList: [],
        currentTab:"base-info"
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {

        this.parsePathName();
        window.onbeforeunload = this.routerWillLeave;
        this.addGlobalListener();

        this.props.history.block((location, action) => {
            console.log('您要放弃修改吗', location)
            if(this.routerWillLeave){
                return this.routerWillLeave(location)
            }else {
                return  null
            }

        });

    }

    getAllSeries() {
        courseReq.getAllSeries()
            .then(res => {

                let seriesCascaderOptions = [];
                let seriesCascaderValues = []
                if (res && res.length) {
                    for (let series of res) {
                        seriesCascaderOptions.push({
                            value: series.id,
                            label: series.name,
                        })
                    }
                }
                seriesCascaderValues = this.state.courseInfo && this.state.courseInfo.courseSeriesId ?[ this.state.courseInfo.courseSeriesId ]:[]
                this.setState({
                    courseSeriesList: [],
                    seriesCascaderOptions,
                    seriesCascaderValues
                })
            })
            .catch(error => {
                message.error(`获取课程系列发生错误`)
            })
    }


    componentWillUnmount() {
        this.removeGlobalListener()
        this.routerWillLeave = null;
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    routerWillLeave = (nextLocation) => {

        if (this.state.changedInfo && Object.getOwnPropertyNames(this.state.changedInfo).length) {
            return '您有修改未保存，您要放弃修改吗？'
        } else {

            return null;
        }
    };

    parsePathName = () => {
        let pathname = this.props.location.pathname;
        if (pathname === '/course/create' || pathname === '/live-course/create') {
            this.setState({
                editType: "create",
                pageLoading: false,
                courseType: pathname === '/course/create' ? "online-course" : "live-course"
            })
            this.getAllGroups();
            this.getAllSeries()
        } else {
            let courseId = this.props.match.params.courseId;
            let hash = this.props.location.hash || "#base-info"
            if (courseId && parseInt(courseId)) {
                this.setState({
                    editType: "update",
                    courseId: courseId,
                    courseType: pathname === `/course/${courseId}` ? "online-course" : "live-course",
                    currentTab:hash.replace(`#`,"")
                }, () => {
                    this.getCourseDetail()
                })

            } else {
                this.setState({
                    pageLoading: false,
                    noFound: true
                })
            }
        }
    }

    previewUploaderOnChange = () => {

    };
    previewUploaderBeforeUpload = (fileInfo) => {

        if (fileInfo.size > 1024 * 1024 * 10) {
            Modal.error({
                title: '图片大小不能超过10M',
            });
            return false;
        }

        let reader = new FileReader(fileInfo);
        reader.onload = (e) => {
            let image = new Image();
            image.onload = () => {
                let height = image.height;
                let width = image.width;

                if (width > 2000) {
                    Modal.error({
                        title: '图片宽度不能超过2000px',
                    });
                   return false
                }
                this.setState({
                    uploadingPreviewStatus: "ing", previewProgress: 0
                })
                indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
                    success: (url) => {
                        let changedInfo = this.state.changedInfo || {};
                        changedInfo.preview = url;
                        this.setState({
                            changedInfo,
                            uploadingPreviewStatus: "init", previewProgress: 0
                        })

                    },
                    progress: (e) => {

                        this.setState({
                            uploadingPreviewStatus: "ing", previewProgress: parseInt(e.total.percent)
                        })
                    },
                    error: (error) => {
                        message.error('上传发生错误，请重试');
                        this.setState({
                            uploadingPreviewStatus: "error"
                        })
                    },
                })
            };
            image.src = e.target.result;
        }
        reader.readAsDataURL(fileInfo)


    };

    categorySelectorOnChange = (value) => {
        let changedInfo = this.state.changedInfo;
        if (value && value.length === 2) {
            changedInfo.courseCategoryId = value[1];
        }

        this.setState({
            categoryCascaderValues: value,
            changedInfo
        })
    }

    seriesSelectorOnChange = (value) => {
        let changedInfo = this.state.changedInfo;
        if (value && value.length) {
            changedInfo.courseSeriesId = value[0];
        } else {
            changedInfo.courseSeriesId = null;
        }

        this.setState({
            seriesCascaderValues: value,
            changedInfo
        })
    }
    handleEditorChange = (editorState) => {
        let changedInfo = this.state.changedInfo;
        let html = editorState.toHTML();
        changedInfo.description = html;
        this.setState({
            changedInfo,
            editorState
        })


    }

    submitContent = () => {

    }

    tagsHandleChange = (e) => {
        let { changedInfo } = this.state;
        changedInfo = changedInfo || {};
        changedInfo.tags = e;
        this.setState({
            changedInfo
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        courseReq.getAllGroup()
            .then(resGroups => {
                let cascaderOptions = [];
                let categoryCascaderValues = this.state.categoryCascaderValues || [];
                if (resGroups && resGroups.length) {
                    resGroups =  resGroups.filter(item => item.id);
                    for (let i = 0; i < resGroups.length; i++) {
                        let group = resGroups[i];
                        if (group.categories && group.categories.length) {
                            let p = {
                                value: group.id,
                                label: group.name,
                                children: []
                            }
                            let courseInfo = this.state.courseInfo;
                            for (let j = 0; j < group.categories.length; j++) {
                                let cate = group.categories[j];
                                if (courseInfo && courseInfo.courseCategoryId && courseInfo.courseCategoryId == cate.id) {
                                    categoryCascaderValues = [group.id, cate.id];
                                }
                                p.children.push({
                                    value: cate.id,
                                    label: cate.name,
                                })
                            }
                            cascaderOptions.push(p)

                        }
                    }
                }
                this.setState({
                    groups: resGroups,
                    categoryCascaderOptions: cascaderOptions,
                    loadingGroupsData: false,
                    categoryCascaderValues
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    getApps = () => {
        courseReq.getAppsList()
            .then(apps => {
                let { changedInfo, courseInfo } = this.state;
                let selectedApps = changedInfo.softWareInfo || courseInfo.softWareInfo || []
                let ids = selectedApps.map(item => item.id);
                if (apps && apps.length) {
                    for (let app of apps) {
                        app.checked = ids.indexOf(app.id) >= 0
                    }
                }
                this.setState({
                    apps
                })
            })
            .catch(error => {
                message.error(`获取课程软件，发生错误`)
            })
    };

    getCourseDetail = () => {

        courseReq.getCourseDetail(this.state.courseId)
            .then(res => {
                res = this.fetchCourseDetailInfo(res);
                this.setState({
                    courseInfo: res,
                    pageLoading: false,

                    editorState: BraftEditor.createEditorState(res.description || null)

                })
                this.getAllGroups();
                this.getAllSeries()

            })
            .catch(error => {
                message.error(`获取课程详情发生错误，请刷新页面重试`)
            })
    };

    fetchCourseDetailInfo(info) {
        if (info && info.tags && typeof info.tags === 'string') {
            info.tags = info.tags.split(',')
        }

        return info;
    }

    saveCourse = () => {
        let { changedInfo, editType, courseInfo } = this.state;
        let info = { ...changedInfo };
        if (info.softWareInfo && info.softWareInfo.length) {
            info.softWareInfo = info.softWareInfo.map(item => item.id);
        }
        if (info.teacherInfo && info.teacherInfo.length) {
            info.teacherInfo = info.teacherInfo.map(item => item.id);
        }
        if (info.tags) {
            info.tags = info.tags.toString();
        }
        if (editType === 'create') {
            if (!info.title) {
                return message.warning(`课程的标题不能为空`)
            }
            if(!info.courseCategoryId){
                return message.warning(`必需指定课程分类`)
            }
            this.setState({
                saving: true
            })

            info.courseType = this.state.courseType
            courseReq.createCourse(info)
                .then(res => {
                    let courseInfo = this.fetchCourseDetailInfo(res.courseInfo);
                    let status = res.status;

                    if (status == 1) {
                        this.setState({
                            changedInfo: {},
                            editType: 'update',
                            saving: false
                        },()=>{
                            this.props.history.replace(`/${this.state.courseType === 'online-course' ? "course" : "live-course"}/${courseInfo.id}`)
                            this.getCourseDetail()
                        })


                    } else {
                        this.setState({
                            changedInfo: {},
                            courseInfo: courseInfo,
                            editorState: BraftEditor.createEditorState(courseInfo.description || null),
                            editType: 'update',
                            saving: false
                        }, () => {
                            this.setState({ changedInfo: {}, },()=>{
                                this.props.history.replace(`/${this.state.courseType === 'online-course' ? "course" : "live-course"}/${courseInfo.id}`)
                            })
                        })
                    }

                    message.success(`课程已创建`)

                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`课程创建失败，请重试`)
                })
        }

        if (editType === 'update') {

            if (info.hasOwnProperty(`title`) && !info.title) {
                return message.warning(`课程的标题不能为空`)
            }

            info.courseId = courseInfo.id;
            this.setState({
                saving: false
            })
            courseReq.saveUpdatedCourse(info)
                .then(res => {
                    let courseInfo = this.fetchCourseDetailInfo(res.courseInfo);
                    let status = res.status;
                    if (status == 1) {
                        this.setState({
                            changedInfo: {},
                            saving: false
                        })
                        this.getCourseDetail()

                    } else {
                        this.setState({

                            courseInfo: courseInfo,
                            editorState: BraftEditor.createEditorState(courseInfo.description || null),
                            saving: false
                        }, () => {
                            this.setState({
                                changedInfo: {},
                            })
                        })
                    }

                    message.success(`修改已保存`)
                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`保存失败，请重试`)
                })
        }
    };

    changeCoursePublish = value => {
        this.setState({
            loadingCourseStatus: true,
        })
        courseReq.changeCoursePublish(value, this.state.courseInfo.id)
            .then(() => {
                let courseInfo = this.state.courseInfo;
                courseInfo.status = value ? 'published' : "unpublished"
                this.setState({
                    loadingCourseStatus: false,
                })
                message.success(`操作成功`)
            }).catch(error => {
                this.setState({
                    loadingCourseStatus: false,
                })
                message.error(`操作失败，请重试`)
            });
    }
    lessonSortSaveTimer = null;
    clearLessonSortSaveTimer = () => {
        if (this.lessonSortSaveTimer) {
            clearTimeout(this.lessonSortSaveTimer);
        }
    };

    startLessonSortSaveTimer = () => {
        this.clearLessonSortSaveTimer();
        this.lessonSortSaveTimer = setTimeout(() => {
            let lessonInfo = this.state.courseInfo.lessonInfo;
            courseReq.saveLessonSort(lessonInfo.map(item => item.id))
                .then(res => {
                    message.success(`保存课时排序成功`)
                })
                .catch(error => {
                    message.success(`保存软件排序失败`)
                })
        }, 1000)
    }

    tabOnChange = (key) => {
        console.log(`path`,this.props.location)
        this.setState({
            currentTab:key
        })
        this.props.history.replace(this.props.location.pathname + "#" + key);
    }


    render() {
        let { seriesCascaderValues, seriesCascaderOptions, editorState, showSoftAddModal, noFound, pageLoading, changedInfo, courseInfo, editType, categoryCascaderOptions, categoryCascaderValues, apps, previewProgress, uploadingPreviewStatus, showTeacherAddModal, loadingCourseStatus, courseType, courseSeriesList } = this.state;

        if (pageLoading) {
            return <div className={'page-course-create'}>
                <Spin size={'large'} />
            </div>
        }

        if (noFound) {
            return <div className={'page-course-create'}>
                <div className={'no-found'}>
                    <p>课程不存在或已被删除</p>
                    <Button type={'primary'}>查看所有课程</Button>
                </div>
            </div>
        }
        changedInfo = changedInfo || {};
        courseInfo = courseInfo || {};

        let selectedApps = changedInfo.softWareInfo || courseInfo.softWareInfo || [];
        let linkedApps = selectedApps.map((a, index) => {
            return <div className={'item'} key={index} style={{ marginRight: 10, marginBottom: 10 }}>
                <img className={'avatar'} src={a.iconUrl} alt={''} />
                <p className='nickname'>{a.name}</p>
                <Icon className={'close-icon'} type={'close'} onClick={() => {
                    let softWareInfo = this.state.changedInfo.softWareInfo || this.state.courseInfo.softWareInfo || [];
                    let changedInfo = this.state.changedInfo;
                    softWareInfo.splice(index, 1);
                    changedInfo.softWareInfo = softWareInfo;
                    this.setState({
                        changedInfo,
                    })
                }} />
            </div>
        });
        let teachers = changedInfo.teacherInfo || courseInfo.teacherInfo || []
        let courseTeachers = teachers.map((one, index) => {
            return <div className={'item'} key={index} style={{ marginRight: 10, marginBottom: 10 }}>
                <Avatar className={'avatar'}
                    src={one.avatar} />
                <p className='nickname'>{one.nickname}</p>
                <Icon className={'close-icon'} type={'close'} onClick={() => {
                    let changedInfo = this.state.changedInfo || {};
                    let teachers = changedInfo.teacherInfo || courseInfo.teacherInfo || [];
                    let idx = teachers.findIndex(item => item.id === one.id);
                    teachers = [...teachers];
                    teachers.splice(idx, 1);
                    changedInfo.teacherInfo = teachers;
                    this.setState({
                        changedInfo
                    })
                }} />
            </div>
        })
        let lessons = courseInfo.lessonInfo || []
        let lessonList = lessons.map((one, index) => {
            let courseFree = changedInfo.hasOwnProperty(`free`) ? !!changedInfo.free : !!courseInfo.free;
            let showFree = courseFree || !!one.free;
            return <div className={'lesson-item'} key={index}

                draggable={true}
                onDragStart={() => {
                    this.setState({
                        draggingLesson: one,
                    });
                    this.clearLessonSortSaveTimer()

                }}
                onDragEnter={() => {
                    console.log('app onDragEnter');

                    let draggingLesson = this.state.draggingLesson;
                    if (one.id !== this.state.draggingLesson.id) {
                        let courseInfo = this.state.courseInfo;
                        const oldDragIndex = courseInfo.lessonInfo.findIndex(item => item.id === draggingLesson.id);
                        const oldEnterIndex = this.state.groups.findIndex(it => it.id === one.id);
                        if (oldDragIndex > oldEnterIndex) {
                            const newDataArray = courseInfo.lessonInfo.filter(item => item.id !== this.state.draggingLesson.id);
                            const insertIndex = newDataArray.findIndex(it => it.id === one.id);

                            newDataArray.splice(insertIndex, 0, this.state.draggingLesson);
                            courseInfo.lessonInfo = newDataArray;
                            this.setState({ courseInfo });
                        } else {
                            const newDataArray = courseInfo.lessonInfo.filter(item => item.id !== this.state.draggingLesson.id);
                            const insertIndex = newDataArray.findIndex(it => it.id === one.id) + 1;
                            newDataArray.splice(insertIndex, 0, this.state.draggingLesson);
                            courseInfo.lessonInfo = newDataArray;
                            this.setState({ courseInfo });
                        }
                        this.setState({
                            isConfigDirty: true,
                        });
                    }
                }}
                onDragLeave={() => {

                    console.log('app onDragLeave')
                    if (one.id !== this.state.draggingLesson.id) {
                        let courseInfo = this.state.courseInfo;
                        if (this.state.appLock && apps.id === courseInfo.lessonInfo[courseInfo.lessonInfo.length - 1]) {
                            const newDataArray = courseInfo.lessonInfo.filter(item => item.id !== this.state.draggingLesson.id);
                            newDataArray.push(this.state.draggingLesson);
                            courseInfo.lessonInfo = newDataArray;
                            this.setState({
                                appLock: false,
                            }, () => {
                                this.setState({
                                    courseInfo
                                });
                            });
                        } else {
                            this.setState({
                                appLock: true,
                            });
                        }
                    }
                }}
                onDragEnd={() => {
                    console.log(`onDragEnd`)
                    this.setState({
                        draggingLesson: null,

                    })

                }}

                onDrop={() => {
                    this.setState({
                        draggingLesson: null,

                    })
                    this.startLessonSortSaveTimer()
                }}

            >
                <div className={'title'}>{one.title}</div>
                <div className={'flex-r'}>
                    <div className={'item-props'}>
                        <div className={'duration'}>{one.duration || ""}</div>
                        <div className={'status'}>
                            <Tag color={'gold'} hidden={!showFree}>免费</Tag>
                            <Tag color={'gray'} hidden={one.status === 'published'}>已下架</Tag>
                        </div>

                    </div>
                    <div className={'item-actions'}>
                        <a onClick={() => {
                            let changedInfo = this.state.changedInfo;
                            if (changedInfo && Object.getOwnPropertyNames(changedInfo).length) {
                                return Modal.warning({
                                    title: '请保存你的课程',
                                    content: '当前课程已被修改，请保存或取消保存后，再进行操作',
                                });
                            } else {
                                storage.setCached(`course-${courseInfo.id}`, courseInfo);
                                this.props.history.push(`/course/${courseInfo.id}/lesson/${one.id}`)
                            }
                        }}>编辑</a>
                        <a  onClick={e => {

                            let courseInfo = this.state.courseInfo;
                            let lessonInfo = courseInfo.lessonInfo[index]
                            if (lessonInfo) {
                                lessonInfo.status = lessonInfo.status === 'published' ? "unpublished" : "published"
                                this.setState({
                                    courseInfo,
                                })
                                courseReq.changeLessonStatus(lessonInfo.id, lessonInfo.status)
                                    .then(lesson => {

                                    })
                                    .catch(error => {
                                        lessonInfo.status = lessonInfo.status === 'published' ? "unpublished" : "published"
                                        this.setState({
                                            courseInfo
                                        })
                                        message.error(`操作失败，请重试`)
                                    })

                            }
                        }}>{one.status === 'published' ? "下架" : "发布"}</a>
                        <a hidden={courseFree}>{showFree ? "免费试看" : "取消免费"}</a>
                        <Popconfirm
                            placement="leftBottom"
                            title="你确定要删除这个课时吗？"
                            onConfirm={() => {
                                courseReq.removeLesson(one.id)
                                    .then(() => {
                                        let course = this.state.courseInfo;
                                        course.lessonInfo.splice(index, 1);
                                        this.setState({
                                            courseInfo: course
                                        })
                                        message.success(`课时已删除！`)
                                    })
                                    .catch(error => {
                                        message.error(`删除失败！`)
                                    })

                            }}
                            okText="确认删除"
                            cancelText="取消"
                        >
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        })


        let showLiveUrl ;
        if(changedInfo.hasOwnProperty(`playOrigin`) ) {
            showLiveUrl = changedInfo.playOrigin === "other"
        }else {
            showLiveUrl = courseInfo.playOrigin === "other"
        }
        return (
            <div className='page-course-create'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{editType === "create" ? `${courseType === 'online-course' ? "创建课程" : "创建直播课程"}` : `${courseType === 'online-course' ? "课程" : "直播课程"}：${changedInfo.title || courseInfo.title || `${courseType === 'online-course' ? "编辑课程" : "编辑直播课程"}`}`}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                        this.props.history.goBack();
                    }} />
                    <h1>{editType === "create" ? `${courseType === 'online-course' ? "创建课程" : "创建直播课程"}` : `${courseType === 'online-course' ? "课程" : "直播课程"}：${changedInfo.title || courseInfo.title || `${courseType === 'online-course' ? "编辑课程" : "编辑直播课程"}`}`}</h1>
                </div>

                <div className={`page-content`}>

                    <Tabs defaultActiveKey="base-info" onChange={this.tabOnChange} activeKey={this.state.currentTab}>

                        <TabPane tab="基本信息" key="base-info">
                            <Row gutter={16}>
                                <Col span={12}>

                                    <div className={'form-item'}>
                                        <h3>课程名称</h3>
                                        <Input
                                            value={changedInfo.hasOwnProperty(`title`) ? changedInfo.title : courseInfo.title || ""}
                                            className={'input'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.title = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                    </div>
                                    <div className={'form-item'} hidden={courseType !== 'online-course'}>
                                        <h3>课程摘要</h3>
                                        <Input.TextArea
                                            value={changedInfo.hasOwnProperty(`subtitle`) ? changedInfo.subtitle : courseInfo.subtitle || ""}
                                            className={'input textarea'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.subtitle = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                    </div>

                                    <div className={'form-item'} hidden={courseType !== 'live-course'}>
                                        <h3>直播地址</h3>
                                        <Input
                                            placeholder={`课程直播平台链接地址`}
                                            value={changedInfo.hasOwnProperty(`liveUrl`) ? changedInfo.liveUrl : courseInfo.liveUrl || ""}
                                            className={'input'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.liveUrl = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />

                                    </div>
                                    <div className={'form-item'} hidden={courseType !== 'live-course'}>
                                        <h3>上课时段</h3>
                                        <div className={`time-range`}>
                                            <DatePicker placeholder={`开课时间`} value={changedInfo.hasOwnProperty(`startAt`) ? changedInfo.startAt ? moment(changedInfo.startAt, 'YYYY-MM-DD') : null : courseInfo.startAt ? moment(courseInfo.startAt, 'YYYY-MM-DD') : null} onChange={e => {
                                                console.log(`开始时间`, e)
                                                let changedInfo = this.state.changedInfo;
                                                if (e) {
                                                    changedInfo.startAt = e.format('YYYY-MM-DD');
                                                } else {
                                                    changedInfo.startAt = null;
                                                }
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                            <div className={`symbol`}>~</div>
                                            <DatePicker placeholder={`结课时间`} value={changedInfo.hasOwnProperty(`endAt`) ? changedInfo.endAt ? moment(changedInfo.endAt, 'YYYY-MM-DD') : null : courseInfo.endAt ? moment(courseInfo.endAt, 'YYYY-MM-DD') : null} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                if (e) {
                                                    if ((changedInfo.hasOwnProperty(`startAt`) && changedInfo.startAt) || (courseInfo && courseInfo.startAt)) {
                                                        let startDate = new Date(changedInfo.startAt || courseInfo.startAt).getTime()
                                                        let endAt = e.format('YYYY-MM-DD');
                                                        let endDate = new Date(endAt).getTime();
                                                        if (endDate < startDate) {
                                                            message.warn(`结课时间不能小于开课时间`)
                                                        } else {
                                                            changedInfo.endAt = endAt;
                                                        }
                                                    } else {
                                                        changedInfo.endAt = e.format('YYYY-MM-DD');
                                                    }
                                                } else {
                                                    changedInfo.endAt = null;
                                                }
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                        <h3>课程摘要</h3>
                                        <Input.TextArea
                                            placeholder={`上课时间说明，例如:每周三、周六晚08：00`}
                                            value={changedInfo.hasOwnProperty(`timeRemark`) ? changedInfo.timeRemark : courseInfo.timeRemark || ""}
                                            className={'input textarea'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.timeRemark = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />

                                    </div>
                                    <div className={'form-item'}>
                                        <h3>课程难度</h3>
                                        <Radio.Group className="filter-option" value={changedInfo.hasOwnProperty(`level`) ? changedInfo.level || "primary" : courseInfo.level || "primary"} onChange={e => {
                                            let changedInfo = this.state.changedInfo;
                                            changedInfo.level = e.target.value;
                                            this.setState({
                                                changedInfo
                                            })
                                        }}>
                                            <Radio value={"primary"} className="filter-item">初级</Radio>
                                            <Radio value={"middle"} className="filter-item">中级</Radio>
                                            <Radio value={"high"} className="filter-item">高级</Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className={'form-item'}>
                                        <h3>播放平台</h3>
                                        <Radio.Group className="filter-option" value={changedInfo.hasOwnProperty(`playOrigin`) ? changedInfo.playOrigin || "local" : courseInfo.playOrigin || "local"} onChange={e => {
                                            let changedInfo = this.state.changedInfo;
                                            changedInfo.playOrigin = e.target.value;
                                            this.setState({
                                                changedInfo
                                            })
                                        }}>
                                            <Radio value={"local"} className="filter-item">本站</Radio>
                                            <Radio value={"other"} className="filter-item">其它平台</Radio>
                                        </Radio.Group>
                                    </div>

                                    { showLiveUrl && <div className={'form-item'} >
                                        <h3>其它平台链接地址：</h3>
                                        <Input
                                            placeholder={`请填写其它平台的链接地址`}
                                            value={changedInfo.hasOwnProperty(`liveUrl`) ? changedInfo.liveUrl : courseInfo.liveUrl || ""}
                                            className={'input'} onChange={e => {
                                            let changedInfo = this.state.changedInfo;
                                            changedInfo.liveUrl = e.currentTarget.value;
                                            this.setState({
                                                changedInfo
                                            })
                                        }} />
                                    </div>}
                                    <div className={'form-item'}>
                                        <h3>分类</h3>
                                        <Cascader options={categoryCascaderOptions} value={categoryCascaderValues}
                                            onChange={this.categorySelectorOnChange} placeholder="请指定课程分类"
                                            className={'category-selector'} allowClear={false} />
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>系列</h3>
                                        <Cascader options={seriesCascaderOptions} value={seriesCascaderValues}
                                            onChange={this.seriesSelectorOnChange} placeholder="选填。请指定课程所属系列"
                                            className={'category-selector'} />
                                    </div>
                                    <div className={'form-item'}>
                                        <h3>标签</h3>
                                        <Select
                                            mode="tags"
                                            size={'large'}
                                            placeholder="课程标签，以逗号或回车分隔"
                                            value={changedInfo.tags || courseInfo.tags || []}
                                            onChange={this.tagsHandleChange}
                                            style={{ width: '100%' }}
                                            allowClear={true}
                                            tokenSeparators={['\n', ',', '，']}
                                        >

                                        </Select>
                                    </div>
                                    {/*课程软件*/}
                                    <div className={'form-item'}>
                                        <h3>课程软件</h3>
                                        <div className={'course-owners'}>
                                            {linkedApps}
                                            <div className={'item owner-add'} onClick={() => {
                                                this.setState({
                                                    showSoftAddModal: true
                                                })
                                                this.getApps()
                                            }}>
                                                <Icon type={'plus'} style={{ color: "#3340ff" }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>课程简介</h3>
                                        <BraftEditor className={'editor'}
                                            controls={editorControls}
                                            value={editorState}
                                            onChange={this.handleEditorChange}
                                            onSave={this.submitContent}
                                            placeholder={''}

                                            contentClassName={"editor-content"}
                                            componentBelowControlBar={<div ref={(ele) => {
                                                this.editBelowRef = ele;
                                            }} />}
                                            media={{ uploadFn: myUploadFn }}
                                        />
                                    </div>

                                </Col>
                                <Col span={12}>
                                    <div className={'form-item'}>
                                        <h3>封面图</h3>
                                        <Dragger className={'uploader'}
                                            accept={'image/jpeg,image/png,image/jpg,image/gif'}
                                            showUploadList={false}
                                            beforeUpload={this.previewUploaderBeforeUpload} disabled={this.state.uploading}>
                                            <div className={'uploader-container'}
                                            >
                                                <div className={'tip'} hidden={changedInfo.preview || courseInfo.preview}>

                                                    <p className="ant-upload-drag-icon">
                                                        <Icon type="inbox" />
                                                    </p>
                                                    <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                                    <p className="ant-upload-hint">
                                                        仅支持单张图片上传
                                                    </p>
                                                </div>

                                                <div className={'course-preview'}
                                                    hidden={!changedInfo.preview && !courseInfo.preview}>
                                                    <img src={changedInfo.preview || courseInfo.preview || ""} alt={''} />
                                                </div>
                                                <Progress className={'progress'} hidden={uploadingPreviewStatus === 'init'}
                                                    strokeColor='#3340ff' type="circle"
                                                    percent={previewProgress} width={80}
                                                    status={uploadingPreviewStatus === 'error' ? "exception" : "normal"} />
                                            </div>


                                        </Dragger>
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>课程价格</h3>
                                        <Row>
                                            <Col span={12}>
                                                <div className={'price-item'}>
                                                    <div>售价</div>
                                                    <InputNumber
                                                        min={0.01}
                                                        disabled={changedInfo.hasOwnProperty(`free`) ? changedInfo.free : courseInfo.free}
                                                        className={'price-input'}
                                                        defaultValue={1000}
                                                        value={changedInfo.hasOwnProperty(`price`) ? parseFloat(changedInfo.price / 100).toFixed(2) : parseFloat(courseInfo.price / 100).toFixed(2)}
                                                        formatter={value => `￥ ${value}`}
                                                        parser={value => value.replace("￥", '')} precision={2}
                                                        onChange={value => {
                                                            let changedInfo = this.state.changedInfo;
                                                            changedInfo.price = parseInt(value * 100)
                                                            this.setState({
                                                                changedInfo
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className={'price-item'}>
                                                    <div>原价</div>
                                                    <InputNumber
                                                        min={0.01}
                                                        disabled={changedInfo.hasOwnProperty(`free`) ? changedInfo.free : courseInfo.free}
                                                        className={'price-input'}
                                                        defaultValue={1000}
                                                        precision={2}
                                                        value={changedInfo.hasOwnProperty(`originalPrice`) ? parseFloat(changedInfo.originalPrice / 100).toFixed(2) : parseFloat(courseInfo.originalPrice / 100).toFixed(2)}
                                                        formatter={value => `￥ ${value}`}
                                                        parser={value => value.replace("￥", '')}
                                                        onChange={value => {
                                                            let changedInfo = this.state.changedInfo;
                                                            changedInfo.originalPrice = parseInt(value * 100);
                                                            this.setState({
                                                                changedInfo
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Switch className={'publish-switch'} style={{ width: 60, marginTop: 15 }} checkedChildren="免费"
                                            unCheckedChildren="免费" size={'large'}
                                            checked={changedInfo.hasOwnProperty(`free`) ? changedInfo.free : courseInfo.free}
                                            onChange={(value) => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.free = value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>课程老师</h3>
                                        <div className={'course-owners'}>
                                            {courseTeachers}

                                            <div className={'item owner-add'} onClick={() => {
                                                this.setState({
                                                    showTeacherAddModal: true
                                                })
                                            }}>
                                                <Icon type={'plus'} style={{ color: "#3340ff" }} />
                                            </div>
                                        </div>
                                    </div>

                                </Col>

                            </Row>
                        </TabPane>
                        {/*<TabPane tab="价格设置" key="collection">*/}

                        {/*</TabPane>*/}
                        <TabPane tab="课时" key="lesson-list" hidden={courseType === 'live-course'} >
                            <div className={'form-item'} hidden={courseType === 'live-course'}>
                                <h3>课程总课时</h3>
                                <InputNumber
                                    defaultValue={1}
                                    value={changedInfo.hasOwnProperty(`lessonTotalNum`) ? changedInfo.lessonTotalNum : courseInfo.lessonTotalNum}
                                    min={1}
                                    step={1}
                                    onChange={value => {
                                        let changedInfo = this.state.changedInfo;
                                        changedInfo.lessonTotalNum = value;
                                        this.setState({
                                            changedInfo
                                        })
                                    }}
                                />
                            </div>


                            <div className={'form-item'} hidden={courseType === 'live-course'}>
                                <h3>课程课时（{courseInfo && courseInfo.lessonInfo ? courseInfo.lessonInfo.length : 0}节）</h3>

                                <div className={'lesson-list'}>
                                    {lessonList}
                                    <div className={'flex-b'}>
                                        <div className={'add-lesson'} onClick={() => {
                                            let changedInfo = this.state.changedInfo;
                                            if (changedInfo && Object.getOwnPropertyNames(changedInfo).length) {
                                                return Modal.warning({
                                                    title: '请保存你的课程',
                                                    content: '当前课程已被修改，请保存或取消保存后，再进行操作',
                                                });
                                            } else {
                                                storage.setCached(`course-${courseInfo.id}`, courseInfo);
                                                this.props.history.push(`/course/${courseInfo.id}/lesson/create`)
                                            }
                                        }}> + 添加课时
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </TabPane>

                        {/*<TabPane tab="作品" key="shot-bucket">*/}

                        {/*</TabPane>*/}

                    </Tabs>

                </div>



                <div className={'bottom-bar'}>
                    <Switch className={'publish-switch'} checkedChildren="上架" unCheckedChildren="下架"
                        checked={courseInfo && courseInfo.status === 'published'}
                        disabled={!courseInfo || editType === 'create'}
                        size={'large'}
                        hidden={editType === 'create'}
                        loading={loadingCourseStatus}
                        onChange={this.changeCoursePublish}
                    />
                    <Button type={'danger'} style={{ marginRight: 15 }}
                        hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                            Modal.confirm({
                                title: "你确定要放弃所有修改记录吗？",
                                okText: "确定放弃",
                                onOk: () => {
                                    this.setState({
                                        changedInfo: {}
                                    })
                                }
                            })
                        }}>放弃所有修改</Button>
                    <Button type={'primary'} disabled={!changedInfo || !Object.getOwnPropertyNames(changedInfo).length}
                        onClick={this.saveCourse} loading={this.state.saving}>保存</Button>
                </div>
                {/*添加课程软件弹窗*/}
                <Modal

                    visible={showSoftAddModal}
                    // onOk={}
                    onCancel={() => {
                        this.setState({
                            showSoftAddModal: false
                        })
                    }}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                >
                    <h2>课程软件</h2>
                    <div className={'course-owners modal-course-owners'}>
                        {
                            apps.map((a, index) => {
                                return <div className={'item'}>
                                    <img className={'avatar'} src={a.iconUrl} alt={'FailLoaded'} />
                                    <p className='nickname'>{a.name}</p>
                                    <Button className={'add-btn'} disabled={a.checked} onClick={() => {
                                        let apps = this.state.apps;
                                        a.checked = true;
                                        let softWareInfo = this.state.changedInfo.softWareInfo || this.state.courseInfo.softWareInfo || [];
                                        let changedInfo = this.state.changedInfo;
                                        softWareInfo.push(a);
                                        changedInfo.softWareInfo = softWareInfo;
                                        this.setState({
                                            changedInfo,
                                            apps
                                        })
                                    }}>{a.checked ? '已添加' : "添加"}</Button>
                                </div>
                            })
                        }

                    </div>
                </Modal>
                {/*    添加老师*/}
                <UserSearcherModal visible={showTeacherAddModal} title={'添加老师'} selectText={'已添加'} normalText={`添加`}

                    onCancel={() => {
                        this.setState({
                            showTeacherAddModal: false
                        })
                        Modal.destroyAll()
                    }} defaultSelected={changedInfo.teacherInfo || courseInfo.teacherInfo || []}
                    changedSelected={selectedUser => {
                        let changedInfo = this.state.changedInfo || {};
                        let teacherInfo = changedInfo.teacherInfo || courseInfo.teacherInfo || [];
                        teacherInfo = [...teacherInfo];
                        teacherInfo.push(selectedUser);
                        changedInfo.teacherInfo = teacherInfo;
                        this.setState({
                            changedInfo
                        })
                    }} />
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CourseEdit);
