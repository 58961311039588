import AppRequest from './AppRequest'

const urlGetCommentList = '/api_v1/admin-api/comment/list';
const urlChangeCommentStatus = "/api_v1/admin-api/comment/post";
const urlDeleteComment = '/api_v1/admin-api/comment/post';
class CommentReq extends AppRequest {

    getCommentList(condition){
        return this.get(urlGetCommentList,{...condition})
    }

    changeCommentStatus(commentId,type,isPublish){
        return this.put(urlChangeCommentStatus,{id:commentId,type,status:isPublish})
    }

    deleteComment(commentId,type){
        return this.delete(urlDeleteComment,{id:commentId,type});
    }

}

const  commentReq = new CommentReq();
export default commentReq;

