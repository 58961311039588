/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from 'antd'
import LoginRegister from '../../components/login-register/LoginRegister'
import "./DevLoginRegisterModal.scss"
import { connect } from 'react-redux'
import actions from "../../redux/actions";



class DevLoginRegisterModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            ...props
        }
    }


    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.isLogin){
            this.props.history.replace('/');
        }
    }
    componentDidMount() {
        window.hideInitLoading();
    }

    render() {
        let { showLoginRegisterModal,isLogin} = this.state;


        return (

            <div>
                <Modal visible={ true} centered footer={null} bodyStyle={{
                    background: "rgba(0,0,0,0)",
                    padding: 0
                }} width={490} closable={false} onCancel={() => {
                }} maskClosable={false} destroyOnClose={true}>
                    <LoginRegister hiddenRegister={true} action={'login'} onSuccessLogin={
                        () => {
                            window.location.href = "/"
                        }
                    } />
                </Modal>

            </div>


        )
    }
}

const mapStateToProps = (state, ownProps) => ({

    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,

})


export default connect(mapStateToProps,{})(DevLoginRegisterModal);