import React from 'react';
import {
    Progress,
    Row,
    Col,
    Input,
    Icon,
    Checkbox,
    List,
    Radio,
    Button,
    Breadcrumb,
    Upload,
    Select,
    Cascader,
    Avatar,
    Tag,
    InputNumber,
    Switch,
    Modal, Spin, message, Popconfirm
} from 'antd'

import './courseLessonEdit.scss'
import {connect} from 'react-redux'
import storage from "../../storage/storage";
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import IconFont from "../../components/iconfont/icon-font";

const dateformat = require('dateformat');


const {Dragger} = Upload;

const playerParams = {
    "id": "lesson-edit-player-container",
    "qualitySort": "asc",
    "format": "m3u8",
    "mediaType": "video",
    "encryptType": 1,
    "width": "100%",
    "autoplay": false,
    "isLive": false,
    "rePlay": false,
    "playsinline": false,
    "preload": true,
    "controlBarVisibility": "hover",
    "useH5Prism": true,
    "skinLayout": [
        {
            "name": "bigPlayButton",
            "align": "blabs",
            "x": 30,
            "y": 80
        },
        {
            "name": "H5Loading",
            "align": "cc"
        },
        {
            "name": "errorDisplay",
            "align": "tlabs",
            "x": 0,
            "y": 0
        },
        {
            "name": "infoDisplay"
        },
        {
            "name": "tooltip",
            "align": "blabs",
            "x": 0,
            "y": 56
        },
        {
            "name": "thumbnail"
        },
        {
            "name": "controlBar",
            "align": "blabs",
            "x": 0,
            "y": 0,
            "children": [
                {
                    "name": "progress",
                    "align": "blabs",
                    "x": 0,
                    "y": 44
                },
                {
                    "name": "playButton",
                    "align": "tl",
                    "x": 15,
                    "y": 12
                },
                {
                    "name": "timeDisplay",
                    "align": "tl",
                    "x": 10,
                    "y": 7
                },
                {
                    "name": "fullScreenButton",
                    "align": "tr",
                    "x": 10,
                    "y": 12
                },
                {
                    "name": "setting",
                    "align": "tr",
                    "x": 15,
                    "y": 12
                },
                {
                    "name": "volume",
                    "align": "tr",
                    "x": 5,
                    "y": 10
                }
            ]
        }
    ]
}

class CourseLessonEdit extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
        this.playerContainer = React.createRef();
    }

    state = {
        showAddMaterialModal: false,
        playerHeight: 270,
        playerWidth: 480,
        courseId: null,
        courseInfo: null,
        lessonId: null,
        lessonInfo: null,
        changedInfo: {},
        pageLoading: true,
        editType: "create", // update
        videoUploadInfo: {
            status: 'init',// uploading,error,end,
            progress: 0,
            fileSize: 0,
            duration: 0,
        },
        remoteVideoStatus: null,
        showEdit: false,
        submitting: false,
        lessonStatusLoading: false,
        sourceProgress:0,
        uploadingSourceStatus:"init",
        sourceUploading:false,
        editSource:{
            origin:null,
        },
        linkVideoInfo:null
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.parsePatchName();
        this.initVideoUploader();
    }

    parsePatchName() {
        let courseId = this.props.match.params.courseId;
        let lessonId = this.props.match.params.lessonId;
        let courseInfo = null;
        if (!courseId || !parseInt(courseId)) {
            return this.setState({
                noFound: true
            })
        } else {
            courseId = parseInt(courseId);
            courseInfo = storage.getCached(`course-${courseId}`);
        }
        if (!lessonId) {
            return this.setState({
                noFound: true
            })
        }
        if (lessonId === 'create') {
            return this.setState({
                pageLoading: false,
                courseInfo,
                courseId,
                editType: "create",
            }, () => {
                if (!courseInfo) {
                    this.getCourseDetail();
                }
            })
        } else if (!parseInt(courseId)) {
            return this.setState({
                noFound: true
            })
        } else {
            return this.setState({
                pageLoading: false,
                courseInfo,
                courseId,
                lessonId,
                editType: "update",
                showEdit: true
            }, () => {
                if (!courseInfo) {
                    this.getCourseDetail();
                }
                this.getLessonDetail()
            })
        }

    }

    getCourseDetail = () => {

        courseReq.getCourseDetail(this.state.courseId)
            .then(res => {
                this.setState({
                    courseInfo: res,
                })
            })
            .catch(error => {
                message.error(`无法获取课程信息，请刷新页面重试`)
            })
    };

    getLessonDetail() {
        this.setState({
            lessonStatusLoading: true
        })
        courseReq.getLessonDetail(this.state.lessonId)
            .then(res => {
                this.setState({
                    lessonInfo: res,
                    pageLoading: false,
                    lessonStatusLoading: false
                }, () => {
                    if(this.state.lessonInfo.videoId){
                        this.checkVideoStatus()
                        this.playVideo();
                    }

                })

            })
            .catch(error => {
                this.setState({
                    lessonStatusLoading: false
                })
                message.error(`无法获取课时信息，请刷新页面重试`)
            })
    }



    previewUploaderOnChange = (files) => {
        console.log(`files`, files)
    };
    videoUploaderBeforeUpload = (file) => {
        this.setState({
            uploadingPreviewStatus: "ing", previewProgress: 0
        });


        if (this.videoUploader) {
            this.videoUploader.stopUpload();
            this.videoUploader.cleanList();
            this.videoUploader.addFile(file, null, null, null, null);
            this.voideoUploading = true;
            let videoUploadInfo = this.state.videoUploadInfo;
            videoUploadInfo.status = 'ing';
            videoUploadInfo.progress = 0;
            videoUploadInfo.fileSize = file.size;
            this.setState({
                videoUploadInfo,
                showEdit: true
            })
            this.videoUploader.startUpload();
        } else {
            message.error('发生错误：视频上传器未能正常初始化，请刷新页面重试')
        }


    };

    sourceUpload = (fileInfo)=>{
        this.setState({
            uploadingSourceStatus: "ing", sourceProgress: 0,
            sourceUploading:true
        })
        indexReq.uploadFileToPrivateZone(fileInfo, fileInfo.name, {
            success: (url) => {
                let editSource = this.state.editSource || {};
                editSource.url = url;
                editSource.size = fileInfo.size;

                this.setState({
                    editSource,
                    uploadingSourceStatus: "init", previewProgress: 0
                })

            },
            progress: (e) => {
                console.log(`上传素材`, e)
                this.setState({
                    uploadingSourceStatus: "ing", sourceProgress: parseInt(e.total.percent),
                    sourceUploading:false
                })
            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    uploadingSourceStatus: "error",
                    sourceUploading:false
                })
            },
        })
    };

    submitSource = ()=>{
        let editSource = this.state.editSource;
        if(editSource.origin){
            if(editSource.hasOwnProperty('name') && !editSource.name){
                return message.warn('素材名称不能为空')
            }
            let sourceId = this.state.editSource.origin.id + "";
            let info = {
                ...editSource,
                id:sourceId
            };
            delete info.origin;
            courseReq.updateSourceInfo(info)
                .then(res =>{
                    message.success('素材已更新');
                    let {lessonInfo} = this.state;
                    let sourceInfo = lessonInfo.sourceInfo || [];
                    let source = sourceInfo[editSource.origin.rowIndex];
                    source.name =  editSource.name || source.name;
                    source.url = editSource.url || source.url;
                    source.size = editSource.size || source.size;
                    lessonInfo.sourceInfo = sourceInfo
                    this.setState({
                        showAddMaterialModal:false,
                        editSource:{},
                        lessonInfo
                    })

                })
                .catch(error=>{
                    message.error(`素材更新失败：${error ? error.message:""}`)
                })

        } else {
            let editSource = this.state.editSource;
            if( !editSource.name){
                return message.warn('素材名称不能为空')
            }
            if(!editSource.url){
                return  message.warn('素材文件不存在，请上传素材文件')
            }
            courseReq.createNewSource(editSource)
                .then(res=>{
                    message.success('素材已创建');
                    courseReq.linkSourceToLesson(this.state.lessonInfo.id,res.id)
                        .then(linkRes =>{
                            message.success('素材已关联至课程');
                            let {lessonInfo} = this.state;
                            let sourceInfo = lessonInfo.sourceInfo || [];
                            lessonInfo.sourceInfo = sourceInfo.concat(res);
                            this.setState({
                                showAddMaterialModal:false,
                                editSource:{},
                                lessonInfo

                            })
                        })
                        .catch(error=>{
                            message.error(`课程关联素材失败：${error ? error.message:""}`)
                        })
                })
                .catch(error=>{
                    message.error(`素材添加失败：${error ? error.message:""}`)
                })


        }

    }



    initVideoUploader() {
        courseReq.getAliyunUploaderUserId().then((res) => {
            // eslint-disable-next-line no-undef
            this.videoUploader = new AliyunUpload.Vod({
                userId: res,
                partSize: 1048576,
                parallel: 5,
                retryCount: 3,
                retryDuration: 2,
                // 开始上传
                'onUploadstarted': (uploadInfo) => {
                    console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object);
                    if (uploadInfo.videoId) {
                        courseReq.refreshUploadAuth(uploadInfo.videoId)
                            .then(res => {
                                console.log('refreshUploadAuth', res)
                                this.videoUploader.setUploadAuthAndAddress(uploadInfo, res.UploadAuth, res.UploadAddress, uploadInfo.videoId);
                            })
                            .catch(error => {
                                console.log('refreshUploadAuth', error)
                                return message.error("发生错误，视频上传失败")
                            })
                    } else {

                        let paths = uploadInfo.file.name.split('.');
                        let extensionName = null
                        if (paths.length > 1) {
                            extensionName = paths[paths.length - 1];
                        } else {
                            return message.error('未能获取视频文件的后缀名，请检查视频文件是否有后缀')
                        }
                        let title = `tempUploadLessonVideo-${dateformat(new Date(), 'yyyymmddHHMMssl')}`;
                        let fileName = `${title}.${extensionName}`;
                        courseReq.createUploadVideo(title, fileName, this.state.courseInfo.id)
                            .then(res => {
                                this.videoUploader.setUploadAuthAndAddress(uploadInfo, res.UploadAuth, res.UploadAddress, res.VideoId);
                            })
                            .catch(error => {
                                return message.error("发生错误，视频上传失败")
                            })
                    }

                    let videoUploadInfo = this.state.videoUploadInfo;
                    videoUploadInfo.status = 'ing';
                    videoUploadInfo.progress = 0;
                    this.setState({
                        videoUploadInfo
                    })


                },
                // 文件上传成功
                'onUploadSucceed': (uploadInfo) => {
                    console.log('onUploadSucceed', uploadInfo)
                    this.voideoUploading = false;
                    let videoUploadInfo = this.state.videoUploadInfo;
                    videoUploadInfo.status = 'init';
                    videoUploadInfo.progress = 0;
                    this.setState({
                        videoUploadInfo
                    })
                    let changedInfo = this.state.changedInfo;
                    changedInfo.videoId = uploadInfo.videoId;
                    changedInfo.duration = this.state.videoUploadInfo.duration;
                    this.checkVideoStatus();
                    this.setState({
                        changedInfo
                    },()=>{
                        this.onSubmitLesson();
                    })
                    message.success(`视频上传成功`);
                },
                // 文件上传失败
                'onUploadFailed': (uploadInfo, code, msg) => {
                    console.log(`onUploadFailed`, uploadInfo, code, msg)
                    let videoUploadInfo = this.state.videoUploadInfo;
                    videoUploadInfo.status = 'error';
                    this.setState({
                        videoUploadInfo
                    })
                    message.error(`视频上传失败，请重新上传`)
                },
                // 文件上传进度，单位：字节
                'onUploadProgress': (uploadInfo, totalSize, loadedPercent) => {
                    console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(loadedPercent * 100) + "%");
                    let videoUploadInfo = this.state.videoUploadInfo;
                    videoUploadInfo.status = 'ing';
                    videoUploadInfo.fileSize = totalSize;
                    videoUploadInfo.progress = Math.ceil(loadedPercent * 100);
                    this.setState({
                        videoUploadInfo
                    })
                },
                // 上传凭证超时
                'onUploadTokenExpired': (uploadInfo) => {
                    courseReq.refreshUploadAuth(uploadInfo.videoId).then(res => {
                        console.log('refreshUploadAuth', res)
                        this.videoUploader.resumeUploadWithAuth(res.UploadAuth);
                    })
                        .catch(error => {
                            console.log('refreshUploadAuth', error);
                            let videoUploadInfo = this.state.videoUploadInfo;
                            videoUploadInfo.status = 'error';
                            this.setState({
                                videoUploadInfo
                            })
                            message.error(`上传超时，请重新上传`)
                        })


                },
                //全部文件上传结束
                'onUploadEnd': function (uploadInfo) {
                    console.log("onUploadEnd: uploaded all the files", uploadInfo);
                }


            })

        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // // FIXME: noFound值多次切换，可能会出现多个player的情况
        // if (!this.state.noFound && this.playerContainer.current) {
        //     this.initPlayer();
        // }

    }

    parseVideoDuration(duration) {
        let minute = parseInt(duration / 60);
        if (minute < 1) {
            minute = '00';
        } else if (minute < 10) {
            minute = `0${minute}`;
        }
        let second = duration % 60;
        if (second < 1) {
            second = '00';
        } else if (second < 10) {
            second = `0${second}`;
        }
        return `${minute}:${second}`
    }

    createPlayerTimer = null;
    initPlayer = (vid, playAuth) => {
        this.emptyPreviousPlayerHtml();
        if (!document.getElementById('lesson-edit-player-container')) {
            if (this.createPlayerTimer) {
                return
            } else {
                this.createPlayerTimer = setTimeout(() => {
                    this.initPlayer(vid, playAuth);

                }, 300)
            }
            return console.log(`无播放容器`)
        }
        if (this.createPlayerTimer) {
            clearTimeout(this.createPlayerTimer);
        }
        // eslint-disable-next-line no-undef
        this.player = new Aliplayer({
            ...playerParams, vid,
            playauth: playAuth,
        }, (player) => {
            console.log('播放器创建好了。')
        });

        let handleReady = function (e) {
            console.log('handleReady', e);
        }
        this.player.on('ready', handleReady);
        let handleError = function (e) {
            console.log('handleError', e);
        }
        this.player.on('error', handleError);
        console.log(this.player);
    }

    checkVideoStatus() {
        let videoId = this.state.changedInfo.videoId || this.state.lessonInfo.videoId;
        if (!videoId) {
            if (this.checkTimer) {
                clearInterval(this.checkTimer);
            }
            return;
        }
        courseReq.checkVideoStatus(videoId)
            .then(res => {
                this.setState({
                    remoteVideoStatus: courseReq.remoteVideoStatus[res.status],
                    remoteVideoDuration:res.duration
                })
                if (res.status === 'Normal') {
                    this.playVideo();
                    if (this.checkTimer) {
                        clearInterval(this.checkTimer)
                    }
                }

            })
            .catch(error => {

            })
        if (this.checkTimer) {
            return;
        }
        this.checkTimer = setInterval(() => {
            if (this.state.remoteVideoStatus === "Normal") {
                clearInterval(this.checkTimer);
            } else {
                this.checkVideoStatus()
            }
        }, 10 * 1000);

    }

    emptyPreviousPlayerHtml = () => {
        if (document.getElementById('lesson-edit-player-container')) {
            document.getElementById('lesson-edit-player-container').innerHTML = ""
        }
    }


    playVideo() {
        let {changedInfo, lessonInfo} = this.state;
        let videoId = changedInfo.videoId || lessonInfo.videoId || null;
        if (!videoId) {
            return message.error(`课时无视频不能播放`)
        }
        courseReq.getVideoPlayerAuth(videoId)
            .then(info => {
                if (this.player && this.player.replayByVidAndPlayAuth && typeof this.player.replayByVidAndPlayAuth === 'function') {
                    console.log(`播放器存在`)
                    this.player.replayByVidAndPlayAuth(info.videoId, info.playAuth);
                } else {
                    console.log(`播放器不存在`)
                    this.initPlayer(info.videoId, info.playAuth)
                }
            })
            .catch(error => {
                // console.log(error)
                // message.error(`获取播放凭据时发生错误`);
            })


    }

    onSubmitLesson = () => {
        let {editType, lessonInfo, changedInfo, courseInfo} = this.state;
        let info = {...changedInfo};

        if (editType === 'create') {
            if (!info.title) {
                return message.warn('请填写课时标题');
            }
            this.setState({
                submitting: true
            })
            info.courseId = courseInfo.id;
            courseReq.createLesson(info)
                .then(newLesson => {
                    this.setState({
                        lessonInfo: newLesson,
                        editType: 'update',
                        changedInfo: {},
                        submitting: false

                    });
                    this.props.history.replace(`/course/${this.state.courseInfo.id}/lesson/${newLesson.id}`);
                    message.success(`课时已成功创建`);
                })
                .catch(error => {
                    this.setState({
                        submitting: false
                    })
                    message.warn(`创建课时失败，请重试`)
                })
        }

        if (editType === 'update') {

            this.setState({
                submitting: true
            })
            info.lessonId = lessonInfo.id;
            courseReq.updateLesson(info)
                .then(res => {
                    let {needReload, lesson} = res

                    this.setState({
                        lessonInfo: lesson,
                        submitting: false,
                        changedInfo: {}
                    }, () => {
                        if (needReload) {
                            this.getLessonDetail();
                        }
                    });
                    message.success(`已成功保存课时修改`);
                })
                .catch(error => {
                    this.setState({
                        submitting: false
                    })
                    message.warn(`保存修改失败，请稍后重试`)
                })
        }
    }
    deletingSource = null;
    confirmDeleteSource = ()=>{
        courseReq.removeSource(this.deletingSource.id)
            .then(res =>{
                let {lessonInfo} = this.state;
                let sourceInfo = lessonInfo.sourceInfo;
                sourceInfo.splice(this.deletingSource.rowIndex,1);
                this.setState({
                    lessonInfo
                })
                message.success(`已删除素材`);
                this.deletingSource = null;
            })
            .catch(error=>{
                message.error(`素材删除失败：${error ? error.message:""}`);
                this.deletingSource = null;
            })
    }
    render() {
        let {lessonStatusLoading, editType, changedInfo, courseInfo, lessonInfo, showAddMaterialModal, playerHeight, playerWidth, noFound, pageLoading, videoUploadInfo, showEdit, submitting,editSource,uploadingSourceStatus,sourceProgress,remoteVideoDuration} = this.state;
        courseInfo = courseInfo || {};
        lessonInfo = lessonInfo || {};
        changedInfo = changedInfo || {};

        if (pageLoading) {
            return <div className={'page-course-create'}>
                <Spin size={'large'}/>
            </div>
        }

        if (noFound) {
            return <div className={'page-course-create'}>
                <div className={'no-found'}>
                    <p>课程不存在或已被删除</p>
                    <Button type={'primary'}>查看所有课程</Button>
                </div>
            </div>
        }
        let videoSize = null
        if(videoUploadInfo && videoUploadInfo.fileSize){
            videoSize  = `${parseInt(videoUploadInfo.fileSize / 1024 / 1024)}M`;
        }

        let videoDuration = this.parseVideoDuration(remoteVideoDuration ?  parseInt(remoteVideoDuration) : videoUploadInfo.duration);
        let sourceInfo = lessonInfo.sourceInfo || [];
        return (
            <div className='page-course-lesson-create'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a onClick={() => {
                            if (courseInfo.id) {
                                this.props.history.push(`/course/${courseInfo.id}#lesson-list`);
                            }
                        }}>课程：{courseInfo.title}</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{editType === 'create' ? "创建课时" : `课时:${changedInfo.title || lessonInfo.title || "课时编辑"}`}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <Icon type="arrow-left" className={`back-icon`} onClick={()=>{
                        this.props.history.goBack();
                    }} />  <h1>{editType === 'create' ? "创建课时" : "编辑课时"}</h1>
                </div>

                <Row gutter={16}>
                    <Col span={12}>
                        <div className={'course-form-item '}>
                            <h3>课时视频 (时长：{videoDuration} { videoSize ? `文件大小：${videoSize}`:''} 视频状态：{this.state.remoteVideoStatus})</h3>
                            <div className={'upload-item'} hidden={videoUploadInfo.status !== 'ing'}>
                                <Progress className={`progress`} percent={videoUploadInfo.progress}
                                          status={videoUploadInfo.status === 'error' ? "exception" : "active"}/>
                                <Button className={'cancel-upload'} onClick={() => {
                                    if (this.videoUploader) {
                                        this.videoUploader.stopUpload();
                                        this.videoUploader.cleanList();
                                    }
                                    this.voideoUploading = false;
                                    let videoUploadInfo = this.state.videoUploadInfo;
                                    videoUploadInfo.status = 'init';
                                    videoUploadInfo.progress = 0;
                                    videoUploadInfo.duration = 0;
                                    videoUploadInfo.fileSize = 0;
                                    this.setState({
                                        videoUploadInfo
                                    })
                                }}>取消上传</Button>
                            </div>
                            <div className={'dragger-container'} style={{width: playerWidth, height: playerHeight}}
                                 hidden={this.state.videoUploadInfo.status === 'ing' || changedInfo.videoId || lessonInfo.videoId}>
                                <Dragger className={'uploader'}
                                         accept={'video/*,.flv,.FLV'}
                                         showUploadList={false} onChange={this.previewUploaderOnChange}
                                         beforeUpload={this.videoUploaderBeforeUpload}
                                         disabled={this.state.videoUploadInfo.status === 'ing'}>
                                    <div className={'uploader-container'}>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox"/>
                                        </p>
                                        <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                        <p className="ant-upload-hint">
                                            仅支持单个视频文件上传
                                        </p>
                                    </div>
                                </Dragger>
                            </div>
                            <div id='lesson-edit-player-container'
                                 hidden={!lessonInfo.videoId && this.state.videoUploadInfo.status !== 'init'}/>
                            <Upload
                                showUploadList={false} onChange={this.previewUploaderOnChange}
                                beforeUpload={this.videoUploaderBeforeUpload}
                                disabled={this.state.videoUploadInfo.status === 'ing'}
                               ><Button
                                type={'primary'}  hidden={!lessonInfo ||  !lessonInfo.videoId || this.state.videoUploadInfo.status !== 'init'} style={{marginTop:15}}>重新上传</Button></Upload>

                        </div>
                        <div className={'course-form-item '} hidden={!showEdit}>
                            <h3>课时名称</h3>
                            <Input value={changedInfo.hasOwnProperty(`title`) ? changedInfo.title : lessonInfo.title}
                                   className={'input'} onChange={e => {
                                let changedInfo = this.state.changedInfo;
                                changedInfo.title = e.currentTarget.value;
                                this.setState({
                                    changedInfo
                                })
                            }}/>
                        </div>
                        <div className={'course-form-item'} hidden={!showEdit}>
                            <h3>课时描述</h3>
                            <Input.TextArea
                                value={changedInfo.hasOwnProperty(`subtitle`) ? changedInfo.subtitle : lessonInfo.subtitle}
                                className={'input textarea'} onChange={e => {
                                let changedInfo = this.state.changedInfo;
                                changedInfo.subtitle = e.currentTarget.value;
                                this.setState({
                                    changedInfo
                                })
                            }}/>
                        </div>


                    </Col>
                    <Col span={12}>
                        <div className={'course-form-item '} hidden={!showEdit}>
                            <div style={{display: "flex", flexDirection: "row"}}><h3>免费试看</h3><label
                                style={{color: "#9db6bf", fontSize: 13}}>（课程收费时可设置）</label></div>

                            <Switch disabled={courseInfo.free} className={'publish-switch'}
                                    style={{width: 60, marginTop: 15}} checkedChildren="免费"
                                    unCheckedChildren="免费" size={'large'}
                                    value={changedInfo.hasOwnProperty(`free`) ? changedInfo.free : lessonInfo.free}
                                    onChange={value => {
                                        let changedInfo = this.state.changedInfo;
                                        changedInfo.free = value;
                                        this.setState({
                                            changedInfo
                                        })
                                    }}/>
                        </div>


                        <div className={'course-form-item '} hidden={!showEdit}>
                            <h3>课时素材</h3>

                            <div className={'lesson-list'}>


                                {
                                    sourceInfo.map((item,index)=>{
                                        return <div className={'lesson-item'}>
                                            <div className={'title'}>{item.name}</div>
                                            <div className={'flex-r'}>
                                                <div className={'item-props'}>
                                                    <div className={'duration'}>{parseFloat(item.size / 1024 /1024 ).toFixed(2)}M</div>
                                                </div>
                                                <div className={'item-actions'}>
                                                    <a onClick={()=>{
                                                        let editSource =  {};
                                                        editSource.origin = {...item,rowIndex:index};
                                                        this.setState({
                                                            editSource,
                                                            showAddMaterialModal: true
                                                        })
                                                    }}>编辑</a>
                                                    {/*<a>隐藏</a>*/}
                                                    <Popconfirm title={`确定要删除该素材吗？`}
                                                    okText={`删除`}
                                                    onConfirm={this.confirmDeleteSource}
                                                    onCancel={()=>{

                                                        this.deletingSource = null;
                                                    }}>
                                                        <a onClick={()=>{
                                                            this.deletingSource = {...item,rowIndex:index};
                                                        }}>删除</a>
                                                    </Popconfirm>

                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                <div className={'flex-b'} hidden={sourceInfo && sourceInfo.length}>
                                    <div className={'add-lesson'} onClick={() => {
                                        this.setState({
                                            showAddMaterialModal: true
                                        })
                                    }}> + 添加素材
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Col>

                </Row>

                <div className={'bottom-bar'} hidden={!showEdit}>
                    <Switch className={'publish-switch'} checkedChildren="课时已发布" unCheckedChildren="课时已下架"
                            size={'large'} hidden={editType === 'create'} checked={lessonInfo.status === 'published'}
                            loading={lessonStatusLoading} onChange={value => {
                        console.log('value', value)
                        let lessonInfo = this.state.lessonInfo;
                        if (lessonInfo) {
                            lessonInfo.status = lessonInfo.status === 'published' ? "unpublished" : "published"
                            this.setState({
                                lessonInfo,
                                lessonStatusLoading: true
                            })
                            courseReq.changeLessonStatus(lessonInfo.id, lessonInfo.status)
                                .then(lesson => {
                                    this.setState({
                                        lessonStatusLoading: false
                                    })
                                })
                                .catch(error => {
                                    lessonInfo.status = lessonInfo.status === 'published' ? "unpublished" : "published"
                                    this.setState({
                                        lessonStatusLoading: false
                                    })
                                    message.error(`操作失败，请重试`)
                                })

                        }
                    }}/>
                    <Button type={'danger'} style={{marginRight: 15}}
                            hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                        Modal.confirm({
                            title: "你确定要放弃所有修改记录吗？",
                            okText: "确定放弃",
                            onOk: () => {
                                this.setState({
                                    changedInfo: {}
                                })
                            }
                        })
                    }}>放弃所有课时修改</Button>
                    <Button type={'primary'} onClick={this.onSubmitLesson}
                            disabled={!Object.getOwnPropertyNames(changedInfo).length}
                            loading={submitting}>保存课时</Button>
                </div>
                {/*添加素材弹窗*/}
                <Modal

                    visible={showAddMaterialModal}
                    // onOk={}
                    onCancel={() => {
                        this.setState({
                            showAddMaterialModal: false
                        })
                    }}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category modal-edit-source'}
                    centered={true}
                >
                    <h2>添加素材</h2>
                    <div className={'course-form-item '}>
                        <h3>素材名称</h3>
                        <Input value={editSource.hasOwnProperty('name') ? editSource.name:editSource.origin ? editSource.origin.name :""} className={'input'} onChange={e =>{
                            let editSource = this.state.editSource || {};
                            editSource.name = e.currentTarget.value;
                            this.setState({
                                editSource,
                            })
                        }}/>
                    </div>

                    <div className={'course-form-item '}>
                        <h3>素材文件</h3>

                        <Dragger className={'uploader'}
                                 accept={'.zip'}
                                 showUploadList={false} onChange={this.previewUploaderOnChange}
                                 beforeUpload={this.sourceUpload} disabled={this.state.sourceUploading}>
                            <div className={'progress-mask'} hidden={uploadingSourceStatus === 'init'}>
                                <Progress className={'progress'}
                                          strokeColor='#3340ff' type="circle"
                                          percent={sourceProgress} width={80}
                                          status={uploadingSourceStatus === 'error' ? "exception" : "normal"}/>
                            </div>
                            <div className={'uploader-container'}>
                                <p className="ant-upload-drag-icon" hidden={editSource.url || (editSource.origin && editSource.origin.url) }>
                                    <Icon type="inbox"/>
                                </p>
                                <div className={'source-file'} hidden={!editSource.url && (!editSource.origin ||!editSource.origin.url)}>
                                    <IconFont className={'zip-icon'} type={'iconZIP'}/>
                                    <div className={'bottom'}>
                                        <div className={'size'}>文件大小:{editSource && editSource.hasOwnProperty('size') ? parseFloat(editSource.size / 1024 /1024 ).toFixed(2) : editSource.origin &&  editSource.origin.size ? parseFloat(editSource.origin.size / 1024 /1024 ).toFixed(2) : 0}M </div>
                                        <div className={'time'}>创建时间：{editSource.origin  ? editSource.origin.updatedAt:""}</div>
                                    </div>

                                </div>
                                <div hidden={editSource && editSource.url}>
                                    <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                    <p className="ant-upload-hint">
                                        仅支持单个zip文件上传
                                    </p>

                                </div>
                                <div hidden={!editSource || !editSource.url}>
                                    <p className="ant-upload-hint">
                                        点击或拖拽zip文件到此处，重新上传
                                    </p>
                                </div>

                            </div>

                        </Dragger>
                        <Button className={'form-btn'} type={'primary'} onClick={this.submitSource} disabled={!editSource || (editSource.origin && Object.getOwnPropertyNames(editSource).length < 2) || (!editSource.origin && ( !editSource.name || !editSource.url))}>确认添加</Button>
                    </div>


                </Modal>


            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        windowSize: state.system.windowSize,
    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CourseLessonEdit);
