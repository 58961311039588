import React from 'react';
import {Modal, Input, Button, Popconfirm, message, Spin, Tree, Icon, Tag, Radio, Switch} from 'antd'
import './debugSetting.scss'
import developRequest from "../../request/developRequest";

class DebugSetting extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        qiemanDebug:null,
        weixinShareDebug:null

    }

    componentDidMount() {
        this.getWeixinShareDebugStatus();
        this.getQiemanDebugStatus()
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    getWeixinShareDebugStatus = ()=>{
        developRequest.getWeixinShareDebugStatus()
            .then(res =>{
                this.setState({
                    weixinShareDebug: res.shareDebug ? res.shareDebug === 'true':false
                })
            })
    }

    getQiemanDebugStatus = ()=>{
        developRequest.getQiemanCheckingModeStatus()
            .then(res =>{
                this.setState({
                    qiemanDebug:!!res.qimanDebug ? res.qimanDebug === 'true':false
                })
            })
    }

    changeWeixinShareDebug = checked =>{
        this.setState({
            weixinShareDebugDebugLoading:true
        })
        developRequest.changeWeixinShareDebugStatus(checked ? "true":"false")
            .then(res =>{
                this.setState({
                    weixinShareDebug:checked,
                    weixinShareDebugDebugLoading:false
                })
            })
            .catch(error =>{
                this.setState({
                    weixinShareDebugDebugLoading:false
                })
                message.error(error && error.message ? error.message :"操作失败")
            })
    }
    changeQieManDebug = checked =>{
        this.setState({
            qiemanDebugLoading:true
        })
        developRequest.changeQiemanCheckingModeStatus(checked ? "true":"false")
            .then(res =>{
                this.setState({
                    qiemanDebug:checked,
                    qiemanDebugLoading:false
                })
            })
            .catch(error =>{
                this.setState({
                    qiemanDebugLoading:false
                })
                message.error(error && error.message ? error.message :"操作失败")
            })
    }

    render() {

        let {weixinShareDebug,qiemanDebug} = this.state;

        return (
            <div className='page-debug-setting'>

                <div className={'list-header'}>
                    <h2>调试设置</h2>
                    <div className={'flex-r'}>

                    </div>
                </div>
                <div className={'list'}>

                    <div className="item">
                        <h3>打开uigreat.com网站微信分享调试：</h3>
                        <Switch disabled={!weixinShareDebug && weixinShareDebug !== false} checked={weixinShareDebug || false} onChange={this.changeWeixinShareDebug} loading={this.state.weixinShareDebugDebugLoading}/>
                    </div>
                    <div className="item">
                        <h3>打开且曼播客小程序审核模式：</h3>
                        <Switch disabled={ !qiemanDebug && qiemanDebug !== false} checked={qiemanDebug || false} loading={this.state.qiemanDebugLoading} onChange={this.changeQieManDebug}/>
                    </div>

                </div>
            </div>
        )
    }
}


export default DebugSetting;
