import React, {useState, useEffect,useRef} from "react";

import "./replySetting.scss"
import {
    Breadcrumb,
    Button,
    Col,
    Icon,
    Input,
    message,
    Row,
    Radio,
    List,
    Modal,
    Tag,
    Divider,
    Pagination,
    Switch
} from "antd";
import qiemanReq from "../../request/qieManReq"
import Column from "antd/es/table/Column";
import indexReq from "../../request/indexReq";
import {Link} from "react-router-dom";
const pageSize = 6;
export default props => {

    const insProps = useRef({});
    const [showMediaModal, setShowMediaModal] = useState(false)
    const [loadingMediaList, setLoadingMediaList] = useState(false)
    const [sending, setSending] = useState(false);
    const [saveImageSettingSending, setSaveImageSettingSending] = useState(false);
    const [followReplyMsg, setFollowReplyMsg] = useState({});
    const [imageReplyMsg, setImageReplyMsg] = useState({});
    const [newKeyword, setNewKeyword] = useState({})
    const [mediaList, setMediaList] = useState([])
    const [mediaTotal, setMediaTotal] = useState(0)
    const [handlerType, setHandlerType] = useState("") // follow , keyword,default,new-keyword,image;
    const [mediaType, setMediaType] = useState("")
    const [showCreateKeywordModal,setShowCreateKeywordModal] = useState(false)
    const [keywordListData,setKeywordListData] = useState({});
    const [currentEditKeyword,setCurrentEditKeyword] = useState(null)

    useEffect(() => {
        getSettingReplyMessageAfterFollow()
        getSettingReplyImageMessage()
        getKeywordList()
    }, [])

    const getKeywordList = (page,keyword) =>{
        qiemanReq.getKeywordList({
            keyword:keyword || insProps.current.keyword || null,
            page,
            perPage:pageSize
        })
            .then(res =>{
                if(res && res.rows){
                    res.rows.forEach(item =>{
                        if(item.media){
                            try{
                                item.media = JSON.parse(item.media)
                            }catch (e){}
                        }
                    })
                }
                setKeywordListData(res)
            })
            .catch(e =>{

            })
    }

    const getMediaList = (page,mType) => {
        setLoadingMediaList(true)
        qiemanReq.getMediaList(mType || mediaType || "", page)
            .then(res => {
                setMediaTotal(res.total_count);

                setMediaList(res.item)
                setLoadingMediaList(false)
            })
            .catch(e => {
                setLoadingMediaList(false)
                message.error(`加载失败：${e?.message || ""}`)
            })
    }


    const getSettingReplyMessageAfterFollow = () => {
        qiemanReq.getSettingReplyMessageAfterFollow()
            .then(res => {
                if(res && res.media){
                    res.media = JSON.parse(res.media)
                }
                setFollowReplyMsg(res)
            })
            .catch(e => {
                return message.error(`${e & e.message || ""}`)
            })
    }

    const getSettingReplyImageMessage = () => {
        qiemanReq.getReplyImageMessage()
            .then(res => {
                if(res && res.media){
                    res.media = JSON.parse(res.media)
                }
                setImageReplyMsg(res)
            })
            .catch(e => {
                return message.error(`${e & e.message || ""}`)
            })
    }

    function onSearchKeyword( value) {
        insProps.current.keyword = value;
        getKeywordList()
    }

    function MessageTypeTag(props) {
        switch (props.type) {
            case "text":
                return <Tag className="tag">文本</Tag>
            case "image":
                return <Tag className="tag">图片</Tag>
            case "voice":
                return <Tag className="tag">语音</Tag>
            case "news":
                return <Tag className="tag"> 图文</Tag>
            case "video":
                return <Tag className="tag">视频</Tag>
            default:
                return <Tag>未定义</Tag>
        }
    }

    function handlerSaveNewKeyword(){
        if(!newKeyword.keyword){
            return  message.warn("请填写关键词")
        }
        if(!newKeyword.msgType){
            return   message.warn("请选择消息类型")
        }
        if(newKeyword.msgType === "text" && !newKeyword.text){
            return   message.warn("请填写消息内容")
        }
        if(newKeyword.msgType !== "text" && !newKeyword.media){
            return   message.warn("请选择素材")
        }

        qiemanReq.createNewKeyword(newKeyword)
            .then(res =>{
                message.success(`创建成功`)
                setShowCreateKeywordModal(false)
                setNewKeyword({})
                getKeywordList()

            })
            .catch(e=>{
                message.success(e?.message || "发生错误")
            })

    }


    function handlerFollowReplyEnable(){

        if(!followReplyMsg.enable && !followReplyMsg.msgType){
            return   message.warn("如需开启，请先选择消息类型")
        }
        if( !followReplyMsg.enable && followReplyMsg.msgType === "text" && !followReplyMsg.text){
            return   message.warn("如需开启，请填写消息内容")
        }
        if(!followReplyMsg.enable && followReplyMsg.msgType !== "text" && !followReplyMsg.media){
            return   message.warn("如需开启，请选择素材")
        }
        followReplyMsg.enable = !followReplyMsg.enable
        qiemanReq.saveReplyMessageAfterFollow(followReplyMsg)
            .then(res =>{
                setFollowReplyMsg({...followReplyMsg})
                message.success(`自动回复已${followReplyMsg.enable ? "开启":"关闭"}`)
            })
            .catch((e)=>{
                message.error(`操作失败：${e?.message || ""}`)
            })
    }

    function handlerImageReplyEnable(){

        if(!imageReplyMsg.enable && !imageReplyMsg.msgType){
            return   message.warn("如需开启，请先选择消息类型")
        }
        if( !imageReplyMsg.enable && imageReplyMsg.msgType === "text" && !imageReplyMsg.text){
            return   message.warn("如需开启，请填写消息内容")
        }
        if(!imageReplyMsg.enable && imageReplyMsg.msgType !== "text" && !imageReplyMsg.media){
            return   message.warn("如需开启，请选择素材")
        }
        imageReplyMsg.enable = !imageReplyMsg.enable
        qiemanReq.saveReplyImageMessage(imageReplyMsg)
            .then(res =>{
                setImageReplyMsg({...imageReplyMsg})
                message.success(`自动回复已${imageReplyMsg.enable ? "开启":"关闭"}`)
            })
            .catch((e)=>{
                message.error(`操作失败：${e?.message || ""}`)
            })
    }


    return <div className='page-qieman-replay-setting'>
        <Breadcrumb>
            <Breadcrumb.Item>主页</Breadcrumb.Item>
            <Breadcrumb.Item>
                <a>自动回复设置</a>
            </Breadcrumb.Item>
        </Breadcrumb>

        <div className={'page-header'}>
            <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                props.history.goBack();
            }}/>
            <h1>自动回复设置</h1>
        </div>

        <Row gutter={20}>

            <Col span={12}>
                <div className="card">
                    <div className="header">
                        <h4>关注公众号后自动回复</h4>
                        <Switch size="small" checked={followReplyMsg.enable} onChange={handlerFollowReplyEnable} checkedChildren={<span>开启</span>} unCheckedChildren={<span>关闭</span>} />
                    </div>


                    <div className="item">
                        <div className="item-title">消息类型</div>
                        <Radio.Group onChange={e => {
                            followReplyMsg.msgType = e.target.value
                            followReplyMsg.media = null;
                            followReplyMsg.text = null;
                            setFollowReplyMsg({
                                ...followReplyMsg
                            })
                        }} value={followReplyMsg?.msgType || ""}>
                            <Radio value="text">文本</Radio>
                            <Radio value="image">图片</Radio>
                            <Radio value="voice">语音</Radio>
                            <Radio value="news">图文</Radio>
                            <Radio value="video">视频</Radio>
                        </Radio.Group>
                    </div>
                    <div className="item" hidden={followReplyMsg.msgType !== "text"}>
                        <div
                            className="item-title">消息文本内容
                        </div>
                        <Input.TextArea className="textarea" onChange={e => {
                            followReplyMsg.text = e.currentTarget.value
                            setFollowReplyMsg({
                                ...followReplyMsg
                            })
                        }} value={followReplyMsg.text}/>
                    </div>
                    <div className="item" hidden={!followReplyMsg.msgType || followReplyMsg.msgType === "text"}>
                        <Button type="dashed" onClick={() => {
                            setShowMediaModal(true)
                            setHandlerType("follow")
                            setMediaType(followReplyMsg.msgType)
                            setMediaList([])
                            getMediaList(0,followReplyMsg.msgType)
                        }}>{followReplyMsg.media ? followReplyMsg.msgType === "news" ? followReplyMsg.media.content.news_item[0].title : followReplyMsg.media.name : "从素材中选择"}</Button>

                    </div>
                    <div className="footer">

                        <Button type="primary" loading={sending} onClick={() => {
                            if (!followReplyMsg.msgType) {
                                return message.warn(`请选择消息类型`)
                            }

                            if (followReplyMsg.msgType === "text" && !followReplyMsg.text) {
                                return message.warn(`请填写文本消息内容`)
                            }

                            if (followReplyMsg.msgType !== "text" && !followReplyMsg.media) {
                                return message.warn(`请指定素材`)
                            }


                            setSending(true)
                            qiemanReq.saveReplyMessageAfterFollow({
                                ...followReplyMsg,
                                msgType: followReplyMsg.msgType,
                                media: followReplyMsg.media,
                                text: followReplyMsg.text
                            })
                                .then(res => {
                                    setSending(false)
                                    console.log(res)
                                    message.success(`已保存`)
                                })
                                .catch(e => {
                                    setSending(false)
                                    message.error(`发生错误：${e?.message || ""}`)
                                })
                        }}>保存</Button>
                    </div>
                </div>

            </Col>

            <Col span={12}>
                <div className="card">
                    <div className="header">
                        <h4>收到图片后自动回复</h4>
                        <Switch size="small" checked={imageReplyMsg.enable} onChange={handlerImageReplyEnable} checkedChildren={<span>开启</span>} unCheckedChildren={<span>关闭</span>} />
                    </div>

                    <div className="item">
                        <div className="item-title">消息类型</div>
                        <Radio.Group onChange={e => {
                            imageReplyMsg.msgType = e.target.value
                            imageReplyMsg.media = null;
                            imageReplyMsg.text = null;
                            setImageReplyMsg({
                                ...imageReplyMsg
                            })
                        }} value={imageReplyMsg?.msgType || ""}>
                            <Radio value="text">文本</Radio>
                            <Radio value="image">图片</Radio>
                            <Radio value="voice">语音</Radio>
                            <Radio value="news">图文</Radio>
                            <Radio value="video">视频</Radio>
                        </Radio.Group>
                    </div>
                    <div className="item" hidden={imageReplyMsg.msgType !== "text"}>
                        <div
                            className="item-title">消息文本内容
                        </div>
                        <Input.TextArea className="textarea" onChange={e => {
                            imageReplyMsg.text = e.currentTarget.value
                            setImageReplyMsg({
                                ...imageReplyMsg
                            })
                        }} value={imageReplyMsg.text}/>
                    </div>
                    <div className="item" hidden={!imageReplyMsg.msgType || imageReplyMsg.msgType === "text"}>
                        <Button type="dashed" onClick={() => {
                            setShowMediaModal(true)
                            setHandlerType("image")
                            setMediaType(imageReplyMsg.msgType)
                            setMediaList([])
                            getMediaList(0,imageReplyMsg.msgType)
                        }}>{imageReplyMsg.media ? imageReplyMsg.msgType === "news" ? imageReplyMsg.media.content.news_item[0].title : imageReplyMsg.media.name : "从素材中选择"}</Button>

                    </div>
                    <div className="footer">

                        <Button type="primary" loading={saveImageSettingSending} onClick={() => {
                            if (!imageReplyMsg.msgType) {
                                return message.warn(`请选择消息类型`)
                            }

                            if (imageReplyMsg.msgType === "text" && !imageReplyMsg.text) {
                                return message.warn(`请填写文本消息内容`)
                            }

                            if (imageReplyMsg.msgType !== "text" && !imageReplyMsg.media) {
                                return message.warn(`请指定素材`)
                            }


                            setSaveImageSettingSending(true)
                            qiemanReq.saveReplyImageMessage({
                                ...imageReplyMsg,
                                msgType: imageReplyMsg.msgType,
                                media: imageReplyMsg.media,
                                text: imageReplyMsg.text

                            })
                                .then(res => {
                                    setSaveImageSettingSending(false)
                                    console.log(res)
                                    message.success(`已保存`)
                                })
                                .catch(e => {
                                    setSaveImageSettingSending(false)
                                    message.error(`发生错误：${e?.message || ""}`)
                                })
                        }}>保存</Button>
                    </div>
                </div>

            </Col>

        </Row>

        <div className="card keyword-card">
            <div className="card_header">
                <h4>关键词自动回复</h4>
                <Input.Search className="search-input" placeholder="搜索关键字" onSearch={onSearchKeyword}/>
            </div>

            {
                keywordListData?.rows?.map(item =>(
                    <div className="item">
                        <div className="item-body">
                            <div className="header">
                                <div className="item-title" style={{color: "#333"}}>{item.keyword || ""}</div>
                                <MessageTypeTag type={item.msgType}/>
                            </div>

                            <p className="media">{item.msgType === "text" ? item.text : item.msgType === "news" ? item.media.content.news_item[0].title : item.media.name }</p>
                            <div className="actions">
                                {/*<Button size="small" hidden={currentEditKeyword && currentEditKeyword.id === item.id} onClick={() => {*/}
                                {/*    setCurrentEditKeyword({*/}
                                {/*        ...item,*/}
                                {/*    })*/}
                                {/*}}>编辑</Button>*/}
                                <Button type="danger" size="small" hidden={currentEditKeyword && currentEditKeyword.id === item.id} onClick={()=>{
                                    Modal.confirm({
                                        title:"确定删除这个关键词回复吗？",
                                       okButtonProps:{
                                           type:"danger"

                                       },
                                        okText:"删除",
                                        onOk:()=>{
                                            qiemanReq.deleteKeyword(item.id)
                                                .then(res =>{
                                                    message.success(`已删除`)
                                                    getKeywordList()
                                                })
                                                .catch(e =>{
                                                    message.error(`删除失败`)
                                                })
                                        }
                                    })

                                }}>删除</Button>
                                <Button size="small" hidden={!currentEditKeyword || currentEditKeyword.id !== item.id} onClick={() => {
                                    setCurrentEditKeyword(null)
                                }}>取消</Button>
                                <Button type="primary" size="small" hidden={!currentEditKeyword || currentEditKeyword.id !== item.id}>保存</Button>
                            </div>
                        </div>
                        {/*{*/}
                        {/*    followReplyMsg.showEdit && <div className="edit">*/}
                        {/*        <div className="edit-item">*/}
                        {/*            <div className="item-title">消息类型</div>*/}
                        {/*            <Radio.Group onChange={e => {*/}
                        {/*                followReplyMsg.msgType = e.target.value*/}
                        {/*                followReplyMsg.media = null;*/}
                        {/*                followReplyMsg.text = null;*/}
                        {/*                setFollowReplyMsg({*/}
                        {/*                    ...followReplyMsg*/}
                        {/*                })*/}
                        {/*            }} value={followReplyMsg?.msgType || ""}>*/}
                        {/*                <Radio value="text">文本</Radio>*/}
                        {/*                <Radio value="image">图片</Radio>*/}
                        {/*                <Radio value="voice">语音</Radio>*/}
                        {/*                <Radio value="news">图文</Radio>*/}
                        {/*                <Radio value="video">视频</Radio>*/}
                        {/*            </Radio.Group>*/}
                        {/*        </div>*/}
                        {/*        <div className="edit-item" hidden={followReplyMsg.msgType !== "text"}>*/}
                        {/*            <div*/}
                        {/*                className="item-title">消息文本内容*/}
                        {/*            </div>*/}
                        {/*            <Input.TextArea className="textarea" onChange={e => {*/}
                        {/*                followReplyMsg.text = e.currentTarget.value*/}
                        {/*                setFollowReplyMsg({*/}
                        {/*                    ...followReplyMsg*/}
                        {/*                })*/}
                        {/*            }} value={followReplyMsg.text}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="edit-item"*/}
                        {/*             hidden={!followReplyMsg.msgType || followReplyMsg.msgType === "text"}>*/}
                        {/*            <Button type="dashed" onClick={() => {*/}
                        {/*                setShowMediaModal(true)*/}
                        {/*                setMediaList([])*/}
                        {/*                getMediaList(0)*/}
                        {/*            }}>{followReplyMsg.media ? followReplyMsg.msgType === "news" ? followReplyMsg.media.content.news_item[0].title : followReplyMsg.media.name : "从素材中选择"}</Button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}


                    </div>
                ))
            }


            <div className="footer">
                <Button type="primary" onClick={()=>{
                    setShowCreateKeywordModal(true)
                }}>新增关键词</Button>
                <Pagination className="pagination" size="small" total={keywordListData && keywordListData.count || 0} pageSize={pageSize}  showTotal={total => `共 ${total} 条`}  onChange={(page)=>{
                    getKeywordList(page -1)
                }}/>
            </div>


        </div>


        <Modal
            title="选择素材"
            visible={showMediaModal}
            destroyOnClose={true}
            width={`80%`}
            onCancel={() => {
                setShowMediaModal(false)
            }}
            footer={null}
        >
            <List
                className="list"
                itemLayout="horizontal"
                loading={loadingMediaList}
                dataSource={mediaList || []}
                rowKey={'id'}
                pagination={{
                    onChange: page => {
                        getMediaList( page - 1)
                    },
                    pageSize: 20,
                    total: mediaTotal,
                    size: "small"
                }}
                bordered={true}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[<a onClick={() => {

                            setShowMediaModal(false)
                            if(handlerType ==="follow"){
                                followReplyMsg.media = {
                                    ...item
                                }
                                setFollowReplyMsg({...followReplyMsg})
                            }else if(handlerType === "new-keyword"){
                                newKeyword.media = {...item}
                                setNewKeyword({...newKeyword})
                            }else if(handlerType === "image"){
                                imageReplyMsg.media = {...item}
                                setNewKeyword({...imageReplyMsg})
                            }

                        }}>选择</a>]}

                    >

                        <List.Item.Meta
                            avatar={null}
                            title={`${mediaType === "news" ? item.content.news_item[0].title || "" : item.name || ""}`}
                            description={mediaType === "news" ? item.content.news_item[0].digest : null}
                        />
                    </List.Item>
                )}

                split={false}
            />


        </Modal>

        <Modal
            title="新增关键词回复"
            visible={showCreateKeywordModal}
            destroyOnClose={true}
            width={`80%`}
            onCancel={() => {
                setShowCreateKeywordModal(false)

            }}
            maskClosable={false}
            okText="保存"
            onOk={handlerSaveNewKeyword}
            className="modal-create"
        >

            <div className="edit">
                <div className="edit-item">
                    <div className="item-title">关键词</div>
                    <Input onChange={e =>{
                        newKeyword.keyword = e.currentTarget.value
                        setNewKeyword({...newKeyword})
                    }}/>
                </div>
                <div className="edit-item">
                    <div className="item-title">消息类型</div>
                    <Radio.Group onChange={e => {
                        newKeyword.msgType = e.target.value
                        newKeyword.media = null;
                        newKeyword.text = null;
                        setNewKeyword({
                            ...newKeyword
                        })
                    }} value={newKeyword?.msgType || ""}>
                        <Radio value="text">文本</Radio>
                        <Radio value="image">图片</Radio>
                        <Radio value="voice">语音</Radio>
                        <Radio value="news">图文</Radio>
                        <Radio value="video">视频</Radio>
                    </Radio.Group>
                </div>
                <div className="edit-item" hidden={newKeyword.msgType !== "text"}>
                    <div
                        className="item-title">消息文本内容
                    </div>
                    <Input.TextArea className="textarea" onChange={e => {
                        newKeyword.text = e.currentTarget.value
                        setNewKeyword({
                            ...newKeyword
                        })
                    }} value={newKeyword.text}/>
                </div>
                <div className="edit-item"
                     hidden={!newKeyword.msgType || newKeyword.msgType === "text"}>
                    <Button type="dashed" onClick={() => {
                        setHandlerType("new-keyword")
                        setMediaType(newKeyword.msgType)
                        setShowMediaModal(true)
                        setMediaList([])
                        getMediaList(0,newKeyword.msgType)

                    }}>{newKeyword.media ? newKeyword.msgType === "news" ? newKeyword.media.content.news_item[0].title : newKeyword.media.name : "从素材中选择"}</Button>
                </div>
            </div>

        </Modal>
    </div>
}