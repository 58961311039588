import React from 'react';
import {Switch, Route} from 'react-router-dom'
import './index.scss'
import Menus from "../../components/menus/Menus";
import {connect} from 'react-redux'
import storage from "../../storage/storage";
import Dashboard from "../dashboard/Dashboard";
import UserDash from "../userDash/UserDash";
import BulletinDash from "../bulletinDash/BulletinDash";
import NoFound from "../noFound/NoFound";
import Nav from "../../components/nav/Nav";
import CourseDash from "../courseDash/CourseDash";
import CourseCategory from "../courseCategory/CourseCategory";
import CourseEdit from "../courseEdit/CourseEdit";
import CourseLessonEdit from "../courseLessonEdit/CourseLessonEdit";
import BannerDash from "../bannerDash/BannerDash";
import BannerEdit from "../bannerEdit/BannerEdit";
import ToolDash from "../toolDash/ToolDash";
import ShotDash from "../shotDash/ShotDash";
import ShotCategory from "../shotCategory/ShotCategory";
import Comments from "../commnets/comments";
import CourseSeries from '../courseSeries/CourseSeries';
import FooterLinks from "../footerLinks/footerLinks";
import FriendLinks from "../friendLinks/FriendLinks";
import menuData from "./indexMenu.json"
import AdminApi from "../adminApi/AdminApi";
import RoleAdmin from "../roleAdmin/RoleAdmin";
import accountReq from "../../request/accountReq";
import AudioSeriesDash from "../audioSeriesDash/AudioSeriesDash";
import AudioSeriesEdit from "../audioSeriesEdit/AudioSeriesEdit";
import AudioEdit from "../audioEdit/AudioEdit";
import SoIndex from '../soIndex/SoIndex'
import DebugSetting from "../DebugSetting/DebugSetting";
import QieManProject from "../qieManWeiXinServe/project"
import QieManProjectHome from "../qieManWeiXinServe/projectHome"
import QieManReplySetting from "../qieManWeiXinServe/replySetting"
import QieManMenu from  "../qieManWeiXinServe/menu"

class Index extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        windowSize: {width: 0, height: 0},
        menusCollapsed: storage.getStorage(storage.keys.hasFoldMenus),
        pageName: null,
        menusOpenKeys: [],
        menusSelectedKey: null,
        pathname: "",
         userMenu:[]
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */
     componentDidMount() {
        console.log(`componentDidMount`, this.props.router)
        this.listenWindowScroll();

        let pathname = this.props.location.pathname;
        let obj = this.findMenuItemByPathname(pathname);
        this.setState({
            menusSelectedKey: obj.key,
            menusOpenKeys: this.state.menusCollapsed ? [] : [obj.parentKey],
            pageName: obj.name,
            pathname
        })


    }

    getUserMenu(){
        if(this.state.loginUserInfo && this.state.loginUserInfo.roles && this.state.loginUserInfo.roles.admin){
            this.setState({
                userMenu:menuData
            },()=>{
                this.props.history.push("/")
            })
        }else {
            accountReq.getUserMenu()
                .then(res =>{
                    let menuKeys = res.map(item => item.menuId)
                    let userMenu = [];
                    for(let g of menuData){
                        let copyGroup = {...g}
                        let copyChild =  [].concat(copyGroup.child || [])
                        if(g.child && g.child.length){
                            for(let index = 0;index < g.child.length;index++){
                                let c = g.child[index]
                                if(menuKeys.indexOf(c.key) < 0){
                                    copyChild.splice(index,1);
                                }
                            }
                        }
                        if(copyChild.length){
                            copyGroup.child = copyChild
                            userMenu.push(copyGroup);
                        }
                    }
                    this.setState({
                        userMenu
                    },()=>{
                        this.props.history.push("/")
                    })
                })
        }


    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pathname !== this.state.pathname) {
            let pathname = this.props.location.pathname;
            let obj = this.findMenuItemByPathname(pathname);
            this.setState({
                menusSelectedKey: obj.key,
                menusOpenKeys: this.state.menusCollapsed ? [] : [obj.parentKey],
                pageName: obj.name,
                pathname
            })
        }


    }

    findMenuItemByPathname = (pathName) => {
        let key = '';
        let parentKey = ""
        let name = null
        for (let item of this.state.userMenu) {
            if (item.href === pathName) {
                console.log('childItem', item)
                key = item.key
                parentKey = item.key
                name = item.name
                break;
            }
            if (item.child) {
                for (let childItem of item.child) {
                    if (childItem.href === pathName) {
                        console.log('childItem', childItem)
                        key = childItem.key
                        parentKey = item.key
                        name = childItem.name
                        break;
                    }
                }
            }
        }

        return {key, parentKey, name};
    }

    listenWindowScroll() {

        window.onscroll = () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let scrollHeight = document.body.scrollHeight
            let clientHeight = document.body.clientHeight || document.documentElement.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                this.onReachBottom();
            }
        }
    }

    onReachBottom() {

        if (this.state.loadingMoreStatus === 'none') {
            // this.getAsks();
        }
    }

    menuOnSelect = (key, href, name) => {
        console.log('key,href', key, href)
        this.setState({
            menusSelectedKey: key,
            pageName: name
        })
        this.props.history.push(href);
    };

    menusOnOpenChange = openKeys => {
        this.setState({
            menusOpenKeys: openKeys
        })
    }
    menuOnChangeCollapsed = hasCollapsed => {

        this.setState({
            menusCollapsed: hasCollapsed,
            menusOpenKeys: hasCollapsed ? [] : this.state.menusOpenKeys
        })
        storage.setStorage(storage.keys.hasFoldMenus, hasCollapsed);
    }

    render() {
        let {menusCollapsed, menusSelectedKey, menusOpenKeys,userMenu} = this.state
        return (

            <div className='index' style={{width: this.state.windowSize.width, height: this.state.windowSize.height}}>
                <Nav/>
                <div className={'index-container'}>
                    <Menus data={userMenu || []} openKeys={menusOpenKeys || []} onOpenChange={this.menusOnOpenChange}
                           onChangeCollapsed={this.menuOnChangeCollapsed} collapsed={menusCollapsed}
                           menuOnSelect={this.menuOnSelect} selectedKeys={[menusSelectedKey]}/>
                    <div className={'index-body'}>
                        <Switch>
                        <Route path="/so-uigreat-com/index" exact component={SoIndex}/>
                            <Route path="/qm-wx/auto-reply" exact component={QieManReplySetting}/>
                            <Route path="/qm-wx/project/:projectId" exact component={QieManProjectHome}/>
                            <Route path="/qm-wx/project" exact component={QieManProject}/>
                            <Route path="/qm-wx/menu" exact component={QieManMenu}/>
                            <Route path="/audio-series/:audioSeriesId" exact component={AudioSeriesEdit}/>
                            <Route path="/audioEdit/:audioSeriesId/audio/:audioId" exact component={AudioEdit}/>
                            <Route path="/audio-series-list" exact component={AudioSeriesDash}/>
                            <Route path="/role-admin" exact component={RoleAdmin}/>
                            <Route path="/development/api" exact component={AdminApi}/>
                            <Route path="/development/debug" exact component={DebugSetting}/>
                            <Route path="/course/series" exact component={CourseSeries}/>
                            <Route path="/course-comments" exact component={Comments}/>
                            <Route path="/shot-comments" exact component={Comments}/>
                            <Route path="/friend-links" exact component={FriendLinks}/>
                            <Route path="/footer-links" exact component={FooterLinks}/>
                            <Route path="/bulletin" exact component={BulletinDash}/>
                            <Route path={"/shot-dash"} exact component={ShotDash}/>
                            <Route path="/shot/category" exact component={ShotCategory}/>
                            <Route path="/banner/create" exact component={BannerEdit}/>
                            <Route path="/banner/:bannerId" exact component={BannerEdit}/>
                            <Route path="/course/create" exact component={CourseEdit}/>
                            <Route path={"/banner-dash"} exact component={BannerDash}/>
                            <Route path={"/tool-dash"} exact component={ToolDash}/>
                            <Route path={"/course-dash"} exact component={CourseDash}/>
                            <Route path="/course/category" exact component={CourseCategory}/>
                            <Route path="/course/create" exact component={CourseEdit}/>
                            <Route path="/course/:courseId" exact component={CourseEdit}/>
                            <Route path={"/live-course-dash"} exact component={CourseDash}/>
                            <Route path="/live-course/create" exact component={CourseEdit}/>
                            <Route path="/live-course/:courseId" exact component={CourseEdit}/>
                            <Route path="/course/:courseId/lesson/:lessonId" exact component={CourseLessonEdit}/>
                            <Route path="/user" component={UserDash}/>
                            <Route path="/statistics" component={Dashboard}/>
                            <Route component={NoFound}/>
                        </Switch>
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        windowSize: state.system.windowSize,
        userMenu: state.account.userMenus || []
    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
