import AppRequest from './AppRequest'
import * as qiniu from "qiniu-js";
const uuidv4 = require('uuid/v4');
const  urlGetFileUploadToken = '/api_v1/public/file/upload/token';
const  urlGetPrivateFileUploadToken = '/api_v1/public/source/upload/token';

class IndexRequest extends AppRequest {

    getFileUploadToken(){
        return this.get(urlGetFileUploadToken);
    }

    getPrivateFileUpdateToken(){
        return this.get(urlGetPrivateFileUploadToken)
    }

    uploadFileToPrivateZone(file,fileName,callback){
        this.baseUploadFileToQiniu(file,fileName,true,callback);
    }

    uploadFileToQiniu(file,fileName,callback){
       this.baseUploadFileToQiniu(file,fileName,false,callback);
    }

    baseUploadFileToQiniu(file,fileName,isPrivate,callback){
        fileName = fileName || '';
        callback = callback || {};
        let responseToken = info=>{
            console.log(`info`,info)
            const successFn = (response) => {
                console.log(response.key);
                if(callback.success && typeof callback.success ==='function'){
                    callback.success(`${info.host}${response.key}`);
                }
            };
            let paths = fileName.split('.');
            let key = null
            if (paths.length > 1) {
                key = uuidv4() + "." + paths[paths.length - 1];
            }
            let observable = qiniu.upload(file, key, info.uploadToken, null, null);
            observable.subscribe(callback.progress, callback.error, successFn);
        }

        let catchCallback = error =>{
            if(callback.error && typeof callback.error ==='function'){
                callback.error(error);
            }
        }

        if(isPrivate){
            this.getPrivateFileUpdateToken()
                .then(responseToken)
                .catch(catchCallback)
        }else {
            this.getFileUploadToken()
                .then(responseToken)
                .catch(catchCallback)
        }

    }
}

const  indexReq = new IndexRequest();
export default indexReq