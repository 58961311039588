import Quill from 'quill'
import React from 'react';

import "./bksQuillEditor.scss"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],

    ['clean']                                         // remove formatting button
];


class BKSQuillEditor extends React.Component {
    constructor(props) {
        super(props);
        this.editorContainerRef = React.createRef();
        this.quill = null;

        this.savedDelta  = {"ops":[{"insert":"社交媒体又被竞选玩出新花样，网红发政治性内容怎么管？"},{"attributes":{"align":"justify","header":1},"insert":"\n"},{"attributes":{"color":"#4285f4","link":"https://36kr.com/user/375349"},"insert":"36氪的朋友们"},{"attributes":{"color":"#a7a7a7"},"insert":" · 33分钟前"},{"attributes":{"align":"justify"},"insert":"\n"},{"insert":"对于政治类广告，Twitter、脸书态度大不同\n编者按：本文来自微信公众号"},{"attributes":{"color":"#262626","link":"https://mp.weixin.qq.com/s/dyG-iorlQ0l9UMhpEWigSw"},"insert":"“iFeng科技”（ID:ifeng_tech）"},{"insert":"，作者：凤凰网科技频道，36氪经授权发布。"},{"attributes":{"align":"justify"},"insert":"\n"}]}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.quill && this.editorContainerRef && this.editorContainerRef.current) {
            this.initQuill()
        }
    }

    initQuill = () => {
        this.quill = new Quill(this.editorContainerRef.current, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: 'snow'
        });
       this.quill.on('text-change', (delta, oldDelta, source)=> {
            if (source == 'api') {
                console.log("An API call triggered this change.");
            } else if (source == 'user') {
                this.contentChange(delta,oldDelta,source);
            }
        });

       this.quill.setContents(this.savedDelta,'api')
    }

    contentChange = (delta,oldDelta,source)=>{
        console.log(JSON.stringify(delta),this.quill.root.innerHTML);

    }


    render() {
        return <div className={`bks-quill-editor-container`} ref={this.editorContainerRef}>

        </div>
    }

}

export default BKSQuillEditor;
