import React from 'react';
import {Avatar, Tag} from 'antd'
import './UserListItem.scss'
import IconFont from "../iconfont/icon-font";
import dateformat from 'dateformat'
class UserListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {}

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {

    }


    render() {
        let {key, data} = this.state
        data = data || {}
        let roleTags = [];
        if(data.roles && Object.getOwnPropertyNames(data.roles).length){
            let keys = Object.getOwnPropertyNames(data.roles);
           for(let i = 0 ;i < keys.length;i++){
               if(data.roles[keys[i]]){
                   roleTags.push(<Tag key={i} color={'gold'}>{data.roles[keys[i]].name}</Tag>)
               }
           }
        }
        let powers = [];
        if(data.userPower && Object.getOwnPropertyNames(data.userPower).length){
            let keys = Object.getOwnPropertyNames(data.userPower);
            for(let i = 0 ;i < keys.length;i++){
                let key = keys[i];
                if(!data.userPower[key].value){
                    powers.push(<div className={'ban-item'} key={i}>
                        <IconFont className={'ban-icon'} type={'iconjinyong'}/>
                        <div>{data.userPower[key].name}</div>
                    </div>)
                }


            }
        }

        return (
            <div className='comp-user-list-item' key={`comp-user-list-item-${key || ""}`}>
                <div className={'flex-l'}>
                    <Avatar className={'avatar'}
                            src={data.avatar}/>
                    <label className={'nickname'}>{ data.nickname || data.username || ""}</label>
                </div>
                <div className={'flex-m'}>
                    <div className={`role-list`}>
                        {roleTags}
                    </div>
                    <div className={'ban-list'}>
                        {powers}

                    </div>

                    <p className={`recent-login`} >最近登录：{data.latestLoginTime ? dateformat(new Date(data.latestLoginTime),'yyyy/mm/dd HH:MM'):"无"}</p>
                </div>
                <div className={'flex-r'}>
                    {/*<IconFont type={'icon-zhengchang'} style={{fontSize: 30}}/>*/}


                </div>

            </div>
        )
    }
}


export default (UserListItem);
