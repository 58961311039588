import AppRequest from './AppRequest'


const urlGetAllFooterNavGroups = '/api_v1/admin-api/navigation/group/post';
const urlCreateFooterNavGroup = '/api_v1/admin-api/navigation/group/post';
const urlUpdateFooterNavGroup = '/api_v1/admin-api/navigation/group/post';
const urlDeleteFooterNavGroup = "/api_v1/admin-api/navigation/group/post";
const urlCreateNewLinkAndAddToGroup = "/api_v1/admin-api/navigation/category";
const urlUpdateLink = "/api_v1/admin-api/navigation/category";
const urlDeleteLink = "/api_v1/admin-api/navigation/category";
const urlUpdateFooterNavGroupSort = "/api_v1/admin-api/navigation/sort";

const urlGetFriendLinks = '/api_v1/admin-api/navigation/friend/link';
const urlFriendLink = '/api_v1/admin-api/navigation/friend/link';
// const urlChangeLinkStatus = '/api_v1/admin-api/navigation/navigation/status';
const urlSaveFriendLinkSorts = '/api_v1/admin-api/navigation/friend/link/sort';
class LinkReq extends AppRequest {

    getAllFooterNavGroups(){
        return this.get(urlGetAllFooterNavGroups,{});
    }

    createFooterNavGroup(info){
        return this.post(urlCreateFooterNavGroup,{...info})
    }

    updateFooterNavGroup(info){
        return this.put(urlUpdateFooterNavGroup,{...info});
    }
    deleteFooterNavGroup(groupId) {
        return this.delete(urlDeleteFooterNavGroup,{id:groupId});
    }

    createNewLinkAndAddToGroup(info){
        return this.post(urlCreateNewLinkAndAddToGroup,{...info});
    }

    updateLink(info){
        return this.put(urlUpdateLink,{...info});
    }

    deleteLink(linkId) {
        return this.delete(urlDeleteLink,{id:linkId});
    }

    updateFooterNavGroupSort(data){
        return this.post(urlUpdateFooterNavGroupSort,{newData:data})
    }


    getFriendLinks(){
        return this.get(urlGetFriendLinks,{type:"link"});
    }
    addNewFriendLink(info){
        info.type = "link";
        return this.post(urlFriendLink,info)
    }
    changeFriendLink(info){
        return this.put(urlFriendLink,info)
    }
    removeFriendLink(linkId){
        return this.delete(urlFriendLink,{id:linkId})
    }
    // changeLinkStatus(linkId,isPublish){
    //     return this.post(urlChangeLinkStatus,{id:linkId,status:isPublish})
    // }
    saveFriendLinkSorts(linkIds){
        return this.post(urlSaveFriendLinkSorts,{ids:linkIds})
    }

}

const  linkReq = new LinkReq();
export default linkReq;

