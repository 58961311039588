import React from "react";
import {Menu, Icon, Button} from 'antd';
import './menus.scss'
import IconFont from "../iconfont/icon-font";

const {SubMenu} = Menu;


class Menus extends React.Component {
    constructor(props) {
        super(props);

        Object.assign(this.state, props)

    }

    state = {
        data: [],
        openKeys: [],
        collapsed: false,
        selectedKeys: []
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    rootSubmenuKeys = [];

    componentDidMount() {


    }

    composeMenuData = () => {

        let data = this.state.data || [];
        this.rootSubmenuKeys = [];
        return data.map((item, index) => {

            if (item.child && item.child.length) {
                this.rootSubmenuKeys.push(item.key);
                let childItems = item.child.map((childItem, childIndex) => {
                    return <Menu.Item key={childItem.key} name={childItem.name} href={childItem.href}>{childItem.name || ""}</Menu.Item>
                })
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
                                <IconFont style={{fontSize: 18}} type={item.iconfont || "unordered-list"}/>
                                <span style={{fontSize: 16}}>{item.name || ""}</span>
                             </span>
                        }
                    >
                        {childItems}
                    </SubMenu>
                )
            } else {
                this.rootSubmenuKeys.push(item.key);
                return <Menu.Item key={item.key}> <span>
                                  <IconFont style={{fontSize: 18}} type={item.iconfont || "unordered-list"}/>
                                <span style={{fontSize: 16}}>{item.name || ""}</span>
                             </span></Menu.Item>
            }

        })
    };

    onOpenChange = openKeys => {
        if (this.props.onOpenChange) {
            this.props.onOpenChange(openKeys);
        }
        this.setState({
            openKeys
        });

    };

    handlerMenuCollapsed = () => {
        if (this.props.onChangeCollapsed) {
            this.props.onChangeCollapsed(!this.state.collapsed)
        }
        this.setState({
            collapsed: !this.state.collapsed
        })

    }

    menuItemOnSelect = (obj) => {

        if (this.props.menuOnSelect) {
            this.props.menuOnSelect(obj.key, obj.item.props.href,obj.item.props.name);
        }
    }

    render() {
        let {collapsed, selectedKeys} = this.state;
        return (
            <div className={`bks-menus ${this.state.className || ""}`} style={this.state.style || {}}>
                <Button className={'collapse-btn'}
                        onClick={this.handlerMenuCollapsed}><IconFont style={{fontSize:20}} type={ collapsed ? 'iconzhankai':"iconzhankai-fold"}/></Button>

                    <Menu

                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{width: this.state.collapsed ? 'auto' : 150}}
                        inlineCollapsed={this.state.collapsed}
                        onClick={this.menuItemOnSelect}
                        selectedKeys={selectedKeys}
                        className={`inner-menus`}
                    >
                        {this.composeMenuData()}
                    </Menu>



            </div>
        )


    }
}

export default Menus;