import React, {useEffect, useState} from "react";

import "./menu.scss"
import {Breadcrumb, Button, Col, Icon, Input, message, Modal, Radio, Row} from "antd";
import qiemanReq from "../../request/qieManReq";

export default props => {
    const [sending, setSending] = useState(false)
    const [menu, setMenu] = useState("")

    useEffect(()=>{
        qiemanReq.getMenu()
            .then(res =>{
                if(res ){
                    setMenu(JSON.stringify(res.selfmenu_info,null,4))
                }
            })
            .catch(e =>{
                message.error(`发生错误：${e?.message || ""}`)
            })
    },[])
    return (
        <div className="page-qm-wx-menu">
            <Breadcrumb>
                <Breadcrumb.Item>主页</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a>菜单栏设置</a>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className={'page-header'}>
                <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                    props.history.goBack();
                }}/>
                <h1>菜单栏设置</h1>
            </div>

            <Row>

                <Col span={12}>

                    <div className="card">
                        <h4>公众号菜单栏</h4>

                        <Input.TextArea className="textarea"
                         value={menu}
                                        onChange={e =>{
                                            setMenu(e.currentTarget.value)
                                        }}
                        />

                        <div className="footer">
                            <Button type="link" style={{color:"#e54e33",marginRight:20}}
                                    onClick={()=>{
                                        Modal.confirm({
                                            okButtonProps:{
                                                type:"danger"
                                            },
                                            title:`确定删除公众号的菜单栏`,
                                            okText:"删除",
                                            onOk:()=>{
                                                qiemanReq.deleteMenu()
                                                    .then(e =>{
                                                        setMenu("")
                                                        message.success(`已删除`)
                                                    })
                                                    .catch(e =>{
                                                        message.error(`删除失败：${e?.message || ""}`)
                                                    })
                                            }
                                        })
                                    }}
                            >删除菜单栏</Button>
                            <Button type="primary" loading={sending} onClick={() => {
                                let m = {}
                                if(!menu){
                                    return message.warn(`请填写菜单栏配置`)
                                }
                                try {
                                    m = JSON.parse(menu)
                                }catch (e) {
                                    return  message.warn(`配置格式不正确`)
                                }
                                setSending(true)
                                qiemanReq.setMenu({
                                    menu:m
                                })
                                    .then(res =>{
                                        setSending(false)
                                        message.success(`设置成功`)
                                    })
                                    .catch(e =>{
                                        setSending(false)
                                        message.error(`设置失败：${e?.message || ""}`)
                                    })

                            }}>保存</Button>
                        </div>

                    </div>

                </Col></Row>

        </div>


    )
}