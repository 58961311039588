import React from 'react';
import { Avatar,Divider} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './AudioSeriesListItem.scss'
import IconFont from "../iconfont/icon-font";

class AudioSeriesListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {
    data:{}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }
    /* life circle method */

    componentDidMount(){

    }


    render() {
        let {key,data} = this.state;
        let users = []
        if( data && data.teacherInfo && data.teacherInfo.length){
            users = data.teacherInfo.map( (user,index)=>{
                return  <div className={'user'} key={index}>
                    <Avatar src={user.avatar} className={'avatar'}/>
                    <label>{user.nickname}</label>
                </div>
            })
        }

        return (
            <div className='comp-course-list-item'  key={`comp-shot-list-item-${key || ""}`}>
                <img className={'shot-preview'} src={data.preview}/>
                <div className={'flex-m'}>
                    <div className={'flex-m-t'}>
                        <div className={'title'}>{data.title}</div>
                        <p >{data.description}</p>
                    </div>
                    {/*<div className={'price'}>{ data.free ? "免费": data.price ? `￥${parseFloat(data.price / 100).toFixed(2)}`:0}</div>*/}
                    <div className={'flex-m-b'}>
                        <div className={'users'}>
                            {users}
                        </div>
                        <div className={'flex-m-b-r'}>
                            <div className={'statistic'}>
                                <span><IconFont className={'icon'} type={'iconviews-'}/>{data.viewNum || 0}</span> <Divider type="vertical" />
                                <span hidden={data.courseType !== 'online-course'}><IconFont className={'icon'} type={'iconguankanrenshu'}/>{data.watchNum || 0}</span> <Divider hidden={data.courseType !== 'online-course'} type="vertical" />
                                <span><IconFont className={'icon'} type={'iconcomments-'}/>{data.commentNum || 0}</span> <Divider type="vertical" />
                                <span><IconFont className={'icon'} type={'iconshoucang'}/>{data.collectNum || 0}</span> <Divider type="vertical"  hidden={data.courseType !== 'online-course'}/>
                                <span hidden={data.courseType !== 'online-course'}><IconFont className={'icon'} type={'iconweibiaoti3'} style={{fontSize:16}}/>{data.purchaseNum || 0}</span>
                            </div>
                            <div className={'lesson-number'} hidden={data.courseType !== 'online-course'}>
                                <div>共{data.lessonTotalNum || 0}个音频</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex-r'}>
                    <div className={'status'}>
                        <IconFont type={'iconzhengchang1'} hidden={data.status !== 'published'} style={{fontSize:30}}/>
                        <IconFont type={'icontuijian1'} hidden={!data.recommended } style={{color:"#ffb226",fontSize:30}}/>
                        <IconFont type={'iconjinzhichakan1'} hidden={data.status !== 'unpublished'} style={{color:"#ff5113",fontSize:30}}/>
                    </div>

                </div>
                <div className={'actions'}>
                    {this.props.actions ||{}}
                </div>

            </div>
        )
    }
}



export default (AudioSeriesListItem);
