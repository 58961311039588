import React from 'react';
import { Avatar,Divider} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './bulletinListItem.scss'
import IconFont from "../iconfont/icon-font";
import dateformat from 'dateformat'
const PerPage = 30;


class BulletinListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {
        data:{}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            data:nextProps.data || {}
        };
    }


    /* life circle method */

    componentDidMount(){

    }


    render() {
        let {key,data} = this.state

        return (
            <div className='comp-bulletin-list-item'  key={`comp-bulletin-list-item-${key || ""}`}>
                <p className={'content'}>{data && data.description ? data.description : ""}</p>
                <div className={'duration'}>
                    <div>{`${data.startAt ? dateformat(new Date(data.startAt),'yyyy.mm.dd HH:MM'):""} - ${data.endAt ? dateformat(new Date(data.endAt),'yyyy.mm.dd HH:MM'):""}`}</div>
                </div>
                {/*<div className={'status'}>*/}
                {/*    <IconFont type={'icon-zhengchang'} style={{fontSize:30}}/>*/}
                {/*    <IconFont type={'icon-guoqi'} style={{color:"#ffb226",fontSize:40}}/>*/}
                {/*    <IconFont type={'icon-jinzhichakan'} style={{color:"#ff5113",fontSize:30}}/>*/}
                {/*</div>*/}
            </div>
        )
    }
}



export default (BulletinListItem);
