import React from 'react';
import { List, Popconfirm, Breadcrumb, DatePicker, message} from 'antd'
import './comments.scss'
import {connect} from 'react-redux'
import CommentListItem from "../../components/commentListItem/commentListItem";
import commentReq from "../../request/commentReq"
const { RangePicker } = DatePicker;
const PerPage = 10;
class Comments extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
        this.datePickerRef = React.createRef()
    }

    state = {
        courseTotalNum: 0,
        groups: [],
        comments: [],
        commentCount: 0,
        loadingComments: false,
        condition: {
            perPage: PerPage,
            page: 0,
            type:'course'
        },
        selectedGroup: null,
        selectedCategory: null,
        sortKey: 'latest',
        searchKeyword: '',
        editShot: null,
        showShotModal: false,
        timeRange:[],


    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            pathname:nextProps.match.path
        };
    }

    /* life circle method */
    componentDidMount() {
        let condition = this.state.condition;

        if(this.props.match.path === '/course-comments'){
            condition.type = 'course';
            this.setState({
                condition,
                timeRange:[]
            },()=>{
                this.getComments()
            })
        }else if(this.props.match.path === '/shot-comments'){
            condition.type = 'shot';
            this.setState({
                condition,
                timeRange:[]
            },()=>{
                this.getComments()
            })
        }else {
            this.props.history.redirect('/course-comments')
        }


    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.pathname  !== this.state.pathname){
            let condition = this.state.condition;

            if(this.props.match.path === '/course-comments'){
                condition.type = 'course';
                condition.page = 0
                this.setState({
                    condition,
                    timeRange:[]
                },()=>{
                    this.getComments()
                })
            }else if(this.props.match.path === '/shot-comments'){
                condition.type = 'shot';
                condition.page = 0
                this.setState({
                    condition,
                    timeRange:[]
                },()=>{
                    this.getComments()
                })
            }else {
                this.props.history.redirect('/course-comments')
            }
        }
    }


    getComments = () => {

        this.setState({
            loadingComments: true
        })
        let condition = {...this.state.condition}
        if(this.state.timeRange && this.state.timeRange.length === 2){
            condition.startTime = this.state.timeRange[0].format('YYYY/MM/DD HH:mm:ss');
            condition.endTime = this.state.timeRange[1].format('YYYY/MM/DD HH:mm:ss');
        }
        if (this.state.searchKeyword) {
            condition.keyword = this.state.searchKeyword;
        }
        commentReq.getCommentList(condition)
            .then(res => {
                this.setState({
                    loadingComments: false,
                    comments: res.rows,
                    commentCount: res.count
                })
            })
            .catch(error => {
                this.setState({
                    loadingComments: false
                })
            })
    }

    deleteComment = () => {

        commentReq.deleteComment(this.willDeleteComment.id, this.state.condition.type)
            .then(res => {
                let comments = this.state.comments;
                comments.splice(this.willDeleteComment.rowIndex,1);
                this.setState({
                    comments
                })
                message.success(`已删除`);
                this.willDeleteComment = null;
            })
            .catch(error => {
                message.error(`操作失败：${error && error.message ? error.message : ""}`)
            })
    };

    dateRangePickerOnChange = (res)=>{
        if(!res || !res.length || res.length !== 2 || !res[0] || !res[1]){
            let condition = this.state.condition;
            condition.page = 0;
            this.setState({
                timeRange:[]
            },()=>{
                this.getComments()
            })
        }else {
            this.setState({
                timeRange:res
            })
        }
    }


    onSelectDateRange = (range)=>{
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            timeRange:range,
            condition
        },()=>{
            this.getComments()
        })
    }

    render() {
        let {
            comments, loadingComments, commentCount,condition
        } = this.state;


        return (
            <div className='page-comment-board'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{condition.type === 'course' ? "课程":"作品"}评论</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <h1>{condition.type === 'course' ? "课程":"作品"}评论</h1>

                </div>

                <div className={'header'}>
                    <div className={'flex-l'}>
                        <h2>评论</h2> <div className={`total`}>({commentCount || 0})</div>
                    </div>

                    <div className={'flex-r'}>
                        <RangePicker  ref={this.datePickerRef} showTime  onOk={this.onSelectDateRange}  onChange={this.dateRangePickerOnChange} value={this.state.timeRange}/>
                    </div>
                </div>

                <List
                    className="course-list"
                    itemLayout="horizontal"
                    loading={loadingComments}
                    dataSource={comments || []}
                    renderItem={(item, rowIndex) => (
                        <List.Item
                            rowkey={item.id}
                        >
                            <CommentListItem
                                type={condition.type}
                                data={item}
                                actions={<span>
                                    <a onClick={()=>{
                                      let isPublish = item.status === 'unpublished'
                                      commentReq.changeCommentStatus(item.id,this.state.condition.type,isPublish)
                                          .then(res =>{
                                              let comments = this.state.comments;
                                              comments[rowIndex].status = isPublish ? "published":"unpublished"
                                              this.setState({
                                                  comments
                                              })
                                              message.success(`操作成功`)
                                          })
                                          .catch(error =>{
                                              message.error(`操作失败${error && error.message ? error.message :""}`)
                                          })
                                    }}>{item.status === 'unpublished' ? "取消屏蔽":"屏蔽"}</a>
                                    <Popconfirm title={`确定删除该评论吗？`}
                                                okText={`删除`}
                                                cancelText={'取消'}
                                                onConfirm={this.deleteComment}
                                    >
                                        <a key="list-ban" onClick={() => {
                                            this.willDeleteComment = {...item, rowIndex}
                                        }}>{`删除`}</a>
                                    </Popconfirm>
                                </span>
                                }
                            />
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            let condition = this.state.condition;
                            condition.page = page - 1;
                            this.setState({
                                condition
                            }, () => {
                                this.getComments()
                            })

                        },
                        current:condition.page + 1,
                        pageSize: PerPage,
                        total: commentCount
                    }}
                    split={false}
                />

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
