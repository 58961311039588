import React from 'react';
import {
    Breadcrumb,
    Button,
    Icon,
    Input,
    message,
    Modal,
    Progress,
    Spin,
    Switch,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox
} from 'antd'
import UserSearcherModal from "../../components/SearchUserModal/UserSearcherModal";
import courseReq from "../../request/courseReq";
import './bannerEdit.scss'
import {connect} from 'react-redux'
import indexReq from '../../request/indexReq'
import storage from "../../storage/storage";
import bannerReq from "../../request/bannerReq";

// 引入编辑器样式


const {Dragger} = Upload;

class BannerEdit extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        uploadingPreviewStatus: 'init', // ing ,error,
        previewProgress: 0,
        bannerId: 0,
        noFound: false,
        pageLoading: true,
        bannerInfo: {},
        changedInfo: {},
        editType: 'create', // 'create','update'
        loadingStatus: false,
        saving: false,
        location: null,
        locationOptions: [],
        bannerGroups:[],
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {};
    }

    /* life circle method */
    componentDidMount() {

        this.parsePathName();
        window.onbeforeunload = this.routerWillLeave;
        this.getBannerGroups();
    }
    getBannerGroups(){
        bannerReq.getBannerGroupList()
            .then(res =>{
                this.setState({
                    bannerGroups:res
                })
            })
    }


    componentWillUnmount() {

    }




    routerWillLeave = (nextLocation) => {
        if (this.state.changedInfo && Object.getOwnPropertyNames(this.state.changedInfo).length) {
            return '您有修改未保存，您要放弃修改吗？'
        } else {
            return null;
        }
    };
    parsePathName = () => {
        let pathname = this.props.location.pathname;
        if (pathname === '/banner/create') {
            this.setState({
                editType: "create",
                pageLoading: false
            })
        } else {
            let bannerId = this.props.match.params.bannerId;
            if (bannerId && parseInt(bannerId)) {
                this.setState({
                    editType: "update",
                    bannerId: bannerId
                }, () => {
                    this.getBannerDetail()
                })

            } else {
                this.setState({
                    pageLoading: false,
                    noFound: true
                })
            }
        }
    }

    previewUploaderOnChange = () => {

    };
    previewUploaderBeforeUpload = (fileInfo) => {
        this.setState({
            uploadingPreviewStatus: "ing", previewProgress: 0
        })
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let changedInfo = this.state.changedInfo || {};
                changedInfo.preview = url;
                this.setState({
                    changedInfo,
                    uploadingPreviewStatus: "init", previewProgress: 0
                })

            },
            progress: (e) => {
                console.log(`上传封面图`, e)
                this.setState({
                    uploadingPreviewStatus: "ing", previewProgress: parseInt(e.total.percent)
                })
            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    uploadingPreviewStatus: "error"
                })
            },
        })
    };


    getBannerDetail = () => {
        bannerReq.getBannerDetail(this.state.bannerId)
            .then(res => {
                if(res && res.groupInfo){
                    res.bannerGroupIds = res.groupInfo.map(g =>g.id);
                }
                this.setState({
                    bannerInfo: res,
                    pageLoading: false,
                });
            })
            .catch(error => {
                message.error(`获取banner详情发生错误，请刷新页面重试`)
            })
    };

    saveBanner = () => {
        let {changedInfo, editType, bannerInfo} = this.state;
        let info = {...changedInfo};

        if (editType === 'create') {
            if (!info.name) {
                return message.warning(`banner的标题不能为空`)
            }
            if (!info.preview) {
                return message.warning(`预览图不能为空`)
            }
            if (!info.url) {
                return message.warning(`链接不能为空`)
            }
            this.setState({
                saving: true
            })
            bannerReq.createBanner(info)
                .then(res => {
                    let bannerInfo = res;
                    this.props.history.replace(`/banner/${bannerInfo.id}`)

                    this.setState({
                        changedInfo: {},
                        bannerInfo: bannerInfo,
                        editType: 'update',
                        saving: false
                    })


                    message.success(`banner已创建`)

                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`banner创建失败，请重试`)
                })
        }

        if (editType === 'update') {

            if (info.hasOwnProperty(`name`) && !info.name) {
                return message.warning(`标题不能为空`)
            }
            if (info.hasOwnProperty(`preview`) && !info.preview) {
                return message.warning(`预览图不能为空`)
            }
            if (info.hasOwnProperty(`url`) && !info.url) {
                return message.warning(`链接不能为空`)
            }
            info.id = bannerInfo.id;
            this.setState({
                saving: false
            })
            bannerReq.updateBanner(info)
                .then(res => {
                    let bannerInfo = this.state.bannerInfo;
                    bannerInfo = {...bannerInfo,...info};
                    this.setState({
                        bannerInfo,
                        saving: false
                    }, () => {
                        this.setState({
                            changedInfo: {},
                        })
                    })
                    message.success(`修改已保存`)
                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`保存失败，请重试`)
                })
        }
    };

    changeBannerPublish = value => {
        this.setState({
            loadingStatus: true,
        })
        bannerReq.changeBannerPublish(value, this.state.bannerInfo.id)
            .then(() => {
                let bannerInfo = this.state.bannerInfo;
                bannerInfo.status = value ? 'published' : "unpublished"
                this.setState({
                    loadingStatus: false,
                    bannerInfo
                })
                message.success(`操作成功`)
            }).catch(error => {
            this.setState({
                loadingStatus: false,
            })
            message.error(`操作失败，请重试`)
        });
    }

    locationSelectorOnChange = value => {

    }

    openMethodOnChange = value =>{

    }

    bannerLocationOnChange = (values) =>{
        // console.log("bannerLocationOnChange",values,p1,p2)
        let {changedInfo} = this.state;
        changedInfo.bannerGroupIds = values;
        this.setState({
            changedInfo
        })
    }

    render() {
        let {noFound, pageLoading, changedInfo, bannerInfo, editType, previewProgress, uploadingPreviewStatus, loadingStatus,bannerGroups, location, locationOptions} = this.state;

        if (pageLoading) {
            return <div className={'page-course-create'}>
                <Spin size={'large'}/>
            </div>
        }

        if (noFound) {
            return <div className={'page-course-create'}>
                <div className={'no-found'}>
                    <p>课程不存在或已被删除</p>
                    <Button type={'primary'}>查看所有课程</Button>
                </div>
            </div>
        }
        changedInfo = changedInfo || {};
        bannerInfo = bannerInfo || {};

        return (
            <div className='page-banner-create'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{editType === "create" ? "创建Banner" : `Banner：${changedInfo.title || bannerInfo.name || "编辑Banner"}`}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                        this.props.history.goBack();
                    }}/>
                    <h1>{editType === "create" ? "创建Banner" : `课程：${changedInfo.name || bannerInfo.name || "编辑Banner"}`}</h1>
                </div>

                <div className={'form-item'}>
                    <h3>封面图</h3>
                    <Dragger className={'uploader'}
                             accept={'image/jpeg,image/png,image/jpg,image/gif'}
                             showUploadList={false}
                             beforeUpload={this.previewUploaderBeforeUpload} disabled={this.state.uploading}>
                        <div className={'uploader-container'}
                        >
                            <div className={'tip'} hidden={changedInfo.preview || bannerInfo.preview}>

                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox"/>
                                </p>
                                <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                <p className="ant-upload-hint">
                                    仅支持单张图片上传
                                </p>
                            </div>

                            <div className={'course-preview'}
                                 hidden={!changedInfo.preview && !bannerInfo.preview}>
                                <img src={changedInfo.preview || bannerInfo.preview || ""} alt={''}/>
                            </div>
                            <Progress className={'progress'} hidden={uploadingPreviewStatus === 'init'}
                                      strokeColor='#3340ff' type="circle"
                                      percent={previewProgress} width={80}
                                      status={uploadingPreviewStatus === 'error' ? "exception" : "normal"}/>
                        </div>


                    </Dragger>
                </div>



                <div className={'form-item'}>
                    <h3>标题</h3>
                    <Input
                        value={changedInfo.hasOwnProperty(`name`) ? changedInfo.name : bannerInfo.name || ""}
                        className={'input'} onChange={e => {
                        let changedInfo = this.state.changedInfo;
                        changedInfo.name = e.currentTarget.value;
                        this.setState({
                            changedInfo
                        })
                    }}/>
                </div>
                <div className={'form-item'}>
                    <h3>链接</h3>
                    <Input
                        value={changedInfo.hasOwnProperty(`url`) ? changedInfo.url : bannerInfo.url || ""}
                        className={'input'} onChange={e => {
                        let changedInfo = this.state.changedInfo;
                        changedInfo.url = e.currentTarget.value;
                        this.setState({
                            changedInfo
                        })
                    }}/>
                </div>


                <div className={'form-item'}>
                    <h3>展示区域</h3>
                    <Checkbox.Group style={{ width: '100%' }} value={changedInfo.bannerGroupIds || bannerInfo.bannerGroupIds || []} onChange={this.bannerLocationOnChange}>
                        <Row gutter={[15,15]}>
                            {bannerGroups.map((group,m)=>{
                               return <Col span={8} key={m}>
                                    <Checkbox value={group.id}>{group.name}</Checkbox>
                                </Col>
                            })}

                        </Row>
                    </Checkbox.Group>
                </div>

                <div className={'form-item'}>
                    <h3>打开方式</h3>
                    <div className={`checkboxes`}>
                        <Radio.Group onChange={e=>{
                            console.log(`radio`,e)
                            let changedInfo = this.state.changedInfo;
                            changedInfo.openMethod = e.target.value;
                            this.setState({
                                changedInfo
                            })
                        }} value={changedInfo.openMethod || bannerInfo.openMethod || "new-tab"} >
                            <Radio value={'new-tab'} defaultChecked={true}>在新标签页打开</Radio>
                            <Radio value={'self'}>在当前页打开</Radio>
                        </Radio.Group>
                    </div>
                </div>


                <div className={'bottom-bar'}>
                    <Button type={'danger'} style={{marginRight: 15}}
                            hidden={editType !== 'update'} onClick={() => {
                        Modal.confirm({
                            title: "你确定要删除该banner吗？",
                            okText: "删除",
                            onOk: () => {
                                bannerReq.deleteBanner(this.state.bannerInfo.id)
                                    .then(res =>{

                                        message.success(`banner已删除`);
                                        this.props.history.goBack();
                                    })
                                    .catch(error =>{
                                        message.error(error && error.message ? error.message : "banner删除失败")
                                    })
                            }
                        })
                    }}>删除</Button>
                    < Switch
                        className={'publish-switch'}
                        checkedChildren="上架"
                        unCheckedChildren="下架"
                        checked={bannerInfo && bannerInfo.status === 'published'
                        }
                        disabled={
                            !bannerInfo || editType === 'create'
                        }
                        size={'large'}
                        hidden={editType === 'create'
                        }
                        loading={loadingStatus}
                        onChange={this.changeBannerPublish}
                    />
                    <Button type={'danger'} style={{marginRight: 15}}
                            hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                        Modal.confirm({
                            title: "你确定要放弃所有修改记录吗？",
                            okText: "确定放弃",
                            onOk: () => {
                                this.setState({
                                    changedInfo: {}
                                })
                            }
                        })
                    }}>放弃所有修改</Button>
                    <Button
                        type={'primary'}
                        disabled={!changedInfo || !Object.getOwnPropertyNames(changedInfo).length
                        }
                        onClick={this.saveBanner}
                        loading={this.state.saving}> 保存 </Button>
                </div>
            </div>

        )
    }

}


const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BannerEdit);
