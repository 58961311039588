import React from 'react';
import { Avatar, Divider } from 'antd'
import './bannerListItem.scss'
import IconFont from "../iconfont/icon-font";


class BannerListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        data: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            data: nextProps.data,
            className: nextProps.className,
            style: nextProps.style,
            rowKey: nextProps.rowKey,
            draggable: nextProps.draggable
        };
    }


    /* life circle method */

    componentDidMount() {

    }


    render() {
        let { rowKey, data, className, style } = this.state;

        return (
            <div className={`comp-banner-list-item ${className || ""}`} key={`comp-banner-list-item-${rowKey}`} style={style} draggable={this.state.draggable} onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter}
                onDragLeave={this.props.onDragLeave} onDragEnd={this.props.onDragEnd} onDrop={this.props.onDrop}>
                <img className={'shot-preview'} draggable={false} src={data.preview} />
                <div className={'shot-text'}>
                    <div className={'flex-m'}>
                        <div className={'flex-m-t'}>
                            <div className={'title'}>{data.name}</div>
                        </div>
                        <div>{data.locationName || data.location}</div>
                        <div className={'flex-m-b'}>

                            <div className={'flex-m-b-r'}>
                                <div className={'statistic'}>
                                    <span><IconFont className={'icon'} type={'iconviews-'} />{data.showNum || 0}</span> <Divider type="vertical" />
                                    <span><IconFont className={'icon'} type={'icondianjiliang'} />{data.hitNum || 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'flex-r'}>
                        <div className={'status'}>
                            <IconFont type={'iconzhengchang1'} hidden={data.status !== 'published'} style={{ fontSize: 30 }} />
                            <IconFont type={'iconjinzhichakan1'} hidden={data.status !== 'unpublished'} style={{ color: "#ff5113", fontSize: 30 }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default (BannerListItem);
