/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./nav.scss"
import { connect } from 'react-redux'
import {Avatar, Dropdown, Menu, Modal, Input, Button, message} from 'antd'
import IconFont from '../iconfont/icon-font'
import {Link} from 'react-router-dom'
import actions from "../../redux/actions";
import  config from  '../../config.json'
import accountReq from "../../request/accountReq";


const defaultDropMenus = [{
    name: "账户设置",
    key: "account-setting"
},{
    name: "注销登录",
    key: "logout"
}];

// const navItems = [
//     {
//         name:"问答",
//         href:"/",
//
//     },
//     {
//         name:"作品",
//         href:"/shot",
//     },
//     {
//         name:"文章",
//         href:"/article",
//     },
//
// ];

class Nav extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showLoginAndRegister: false,
            loginAndRegisterAction: "login",
            ...props
        }
    }

    state = {
        changeInfo:{}
    }


    static  getDerivedStateFromProps(nextProps,prevState){

      return nextProps;
    }



    composeAccountArea(isLogin, loginUserInfo) {
            let { avatar, username } = loginUserInfo;
            let userAvatar = <Avatar icon={<IconFont type='icon-icon30' />} style={{ width: 40, height: 40 }} />
            if (avatar) {
                userAvatar = <Avatar src={avatar} style={{ width: 40, height: 40 }} />
            } else if (username) {
                let str = username[0];
                userAvatar = <Avatar style={{ fontSize: 20, background: '#F7F9FC', color: "#59B6FF" }}  >{str.toUpperCase()}</Avatar>
            }
           const accountArea = (
                <div className='flex-r'>
                    <Dropdown overlay={this.composeDropMenus(loginUserInfo)} placement='bottomRight'>
                        {userAvatar}
                    </Dropdown>
                </div>
            )
        return accountArea;
    }

    composeDropMenus = (loginUserInfo) =>{
        let menuItems = defaultDropMenus.map((item,index)=>{
            let a = null;
            switch (item.key) {
                case 'logout':
                    if(config.environment === 'development'){
                        a = <a href={config.devLogoutURL}>{item.name}</a>
                    }else {
                        a = <a href={config.logoutURL}>{item.name}</a>
                    }

                    break;
                case 'account-setting':
                   a=  <a onClick={()=>{
                        this.setState({
                            showAccountSetting:true
                        })
                    }}>{item.name}</a>

                    break;
                default:
                    break;
            }

            return  <Menu.Item key={index}>{a}</Menu.Item>
        })
        return  <Menu>{menuItems}</Menu>;
    }

    handlerAccountSettingModalOk = ()=>{

    }

    handlerAccountSettingModalCancel = ()=>{
        this.setState({
            showAccountSetting:false,
            changeInfo:{}
        })
    }

    submitChange = ()=>{
        let changeInfo = this.state.changeInfo;
        this.setState({
            loadingInfo:true
        })
        accountReq.updateMyInfo(changeInfo)
            .then(res =>{
                this.props.addLoginUserInfo(res)
                this.setState({
                    showAccountSetting:false,  loadingInfo:false,
                    changeInfo:{}
                })
                message.success(`已保存修改`)
            })
            .catch(error =>{
                this.setState({
                    loadingInfo:false
                })
                message.error(error && error.message || `发生错误`)
            })
    }
    render() {
        let { isLogin, loginUserInfo,changeInfo ,showAccountSetting} = this.state;

        let accountArea = this.composeAccountArea(isLogin, loginUserInfo);
        changeInfo = changeInfo || {};
        loginUserInfo = loginUserInfo || {}
        return (
            <div className={`bks-nav ${this.state.className || ''}`}>
                <div className='container'>
                    <img className='logo' src="/logo.svg" alt='' />
                    <div className={'nav-item'}>
                    </div>
                    {accountArea}
                </div>
                
                <Modal

                    visible={showAccountSetting}
                    onOk={this.handlerAccountSettingModalOk}
                    onCancel={this.handlerAccountSettingModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-account'}
                    centered={true}
                    maskClosable={false}
                >

                    <h3>账号社交资料</h3>
                    <div className={'social-item'}>
                        <div className={'social-title'}>微信</div>
                        <Input className={'info-input'}  placeholder={`请输入微信号`}    value={ changeInfo.hasOwnProperty('wechat') ? changeInfo.wechat : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.wechat ? loginUserInfo.userSocialAccount.wechat : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.wechat = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }} />
                    </div>
                    <div className={'social-item'}>
                        <div className={'social-title'}>QQ</div>
                        <Input className={'info-input'}  placeholder={`请输入QQ号`}   value={ changeInfo.hasOwnProperty('qq') ? changeInfo.qq : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.qq ? loginUserInfo.userSocialAccount.qq : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.qq = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }}/>
                    </div>
                    <div className={'social-item'}>
                        <div className={'social-title'}>微博</div>
                        <Input className={'info-input'}   placeholder={`请输入微博主页链接`}   value={ changeInfo.hasOwnProperty('weibo') ? changeInfo.weibo : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.weibo ? loginUserInfo.userSocialAccount.weibo : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.weibo = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }} />
                    </div>
                    <div className={'social-item'}>
                        <div className={'social-title'}>Dribbble</div>
                        <Input className={'info-input'}  placeholder={`请输入Dribbble主页链接`}   value={ changeInfo.hasOwnProperty('dribbble') ? changeInfo.dribbble : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.dribbble ? loginUserInfo.userSocialAccount.dribbble : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.dribbble = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }} />
                    </div>
                    <div className={'social-item'}>
                        <div className={'social-title'}>Behance</div>
                        <Input className={'info-input'}  placeholder={`请输入Behance主页链接`}   value={ changeInfo.hasOwnProperty('behance') ? changeInfo.behance : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.behance ? loginUserInfo.userSocialAccount.behance : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.behance = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }}/>
                    </div>
                    <div className={'social-item'}>
                        <div className={'social-title'}>站酷</div>
                        <Input className={'info-input'}  placeholder={`请输入站酷主页链接`}   value={ changeInfo.hasOwnProperty('zcool') ? changeInfo.zcool : loginUserInfo && loginUserInfo.userSocialAccount &&  loginUserInfo.userSocialAccount.zcool ? loginUserInfo.userSocialAccount.zcool : ""} onChange={(e)=>{
                            let changeInfo = this.state.changeInfo || {};
                            changeInfo.zcool = e.target.value;
                            this.setState({
                                changeInfo
                            })
                        }}/>
                    </div>

                    <div className={'flex-b'}>
                        <Button type={'primary'} disabled={!Object.keys(changeInfo).length} onClick={this.submitChange} loading={this.state.loadingInfo}>保存修改</Button>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({

    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo

})
const mapDispatchToProps = {
    handlerShowLoginRegisterModal:actions.accountShowLoginRegisterModal,
    addLoginUserInfo: actions.accountAddLoginUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);