import React from 'react';
import {Modal, Input, Button, Popconfirm, Popover, message, Spin, Upload, Row, Col, Icon, Progress} from 'antd'
import './soIndex.scss'
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import soReq from '../../request/soReq'
import AppCardItem from "../../components/appCardItem/AppCardItem";

class SoIndex extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    dragTimer = null
    state = {
        groups: [],
        showEditModal: false,
        previewProgress: 0,
        editCategory: null,
        editGroup: null,
        editCourseApp: null,
        loadingGroupsData: false,
        showEditGroupModal: false,
        showEditCourseAppModal: false,
        appIconUploading: false,
        apps: [],
        uploadingPreviewStatus: 'init', // ing ,error,
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.addGlobalListener();
        this.getAllGroups();

    }

    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };


    onDragEnd = (e) => {

    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editCategory: null,
            showEditModal: false
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        soReq.getAllGroup()
            .then(resGroups => {
                if (resGroups && resGroups.length) {
                    resGroups = resGroups.filter(item => item.id)
                }

                this.setState({
                    loadingGroupsData: false
                })
                this.setState({
                    groups: resGroups
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };


    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };
    handlerEditGroupModalOk = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };

    submitCategory = () => {
        let {editCategory} = this.state;

        if (editCategory.origin) {
            let info = {id:editCategory.origin.id,...editCategory};
            delete info.origin;
            delete info.group
            if(Object.getOwnPropertyNames(info).length  <2){
                return  message.warn(`未做任何修改`)
            }
            soReq.updateSite(info)
                .then(category => {
                    let groups = this.state.groups;
                    for (let index = 0; index < groups.length; index++) {
                        let group = groups[index];
                        if (group.sites && group.sites.length) {
                            for (let i = 0; i < group.sites.length; i++) {
                                let category = group.sites[i];
                                if (category.id === editCategory.origin.id) {
                                    for (let key in info){
                                        category[key] = info[key]
                                    }

                                    break;
                                }
                            }
                        }
                    }
                    this.setState({
                        groups,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已更新网站信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名网站，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            if (!editCategory.title) {
                return message.warning(`网站名称不能为空`)
            }

            if (!editCategory.url) {
                return message.warning(`网站地址不能为空`)
            }
            let info = {groupId: editCategory.group.id,...editCategory};
            delete info.group
            soReq.addNewSiteToGroup(info)
                .then(category => {

                    this.setState({

                        showEditModal: false,
                        editCategory: {}
                    })
                    this.getAllGroups()
                    message.success(`已添加新网站到分组`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名网站，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    submitGroup = () => {
        const {editGroup} = this.state;
        if (editGroup.origin) {

            if (!editGroup.title && !editGroup.keywords) {
                return message.warn(`未做任何修改`)
            }
            let info = {id: editGroup.origin.id}
            if (editGroup.title) {
                info.title = editGroup.title
            }
            if (editGroup.keywords) {
                info.keywords = editGroup.keywords
            }

            soReq.updateGroup(info)
                .then(res => {
                    let groups = this.state.groups;
                    let index = groups.findIndex(item => item.id === editGroup.origin.id);
                    let group = groups[index];
                    if (editGroup.title) {
                        group.title = editGroup.title
                    }
                    if (editGroup.keywords) {
                        group.keywords = editGroup.keywords
                    }
                    this.setState({
                        groups,
                        showEditGroupModal: false,
                        editGroup: {}
                    })
                    message.success(`分组信息已更新`)
                })
                .catch(error => {
                    if (error && error.code === 7204) {
                        return message.error(error.message)
                    }
                    return message.error(`发生错误，请重试`)
                })
        } else {
            if (!editGroup.title) {
                return message.warning(`请填写分组名称，不得重名`)
            } else {
                soReq.createGroup({title: editGroup.title, keywords: editGroup.keywords})
                    .then(newGroup => {

                        this.setState({

                            showEditGroupModal: false,
                            editGroup: {}
                        }, () => {
                            this.getAllGroups()
                        })
                        message.success(`已成功创建新分组`)
                    })
                    .catch(error => {
                        if (error && error.code === 7203) {
                            return message.error("已存在同名分组，请使用其他名称")
                        }
                        return message.error(`发生错误，请重试`)
                    })
            }
        }
    };

    willDeleteCategory = null
    deleteCategory = () => {
        soReq.deleteSite(this.willDeleteCategory.id)
            .then(res => {
                let groups = this.state.groups;
                for (let index = 0; index < groups.length; index++) {
                    let group = groups[index];
                    if (group.sites && group.sites.length) {
                        for (let i = 0; i < group.sites.length; i++) {
                            let category = group.sites[i];
                            if (category.id === this.willDeleteCategory.id) {
                                group.sites.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
                this.setState({
                    groups
                })
                this.willDeleteCategory = null;
                message.success(`网站已删除，并从分组中移除`)
            })
            .catch(error => {
                this.willDeleteCategory = null;
                if (error.code === 7209) {
                    return message.error(error.message)
                }
                message.error(`删除失败`)
            })
    };

    willDeleteGroup = null
    deleteGroup = () => {
        soReq.deleteGroup(this.willDeleteGroup.id)
            .then(res => {
                let groups = this.state.groups;
                let index = groups.findIndex(item => item.id === this.willDeleteGroup.id);
                groups.splice(index, 1);
                this.willDeleteGroup = null;
                this.setState({
                    groups
                })
                message.success(`分组已删除`)
            })
            .catch(error => {
                this.willDeleteGroup = null;
                message.error(`分组删除失败`)
            })
    }

    draggingType = "";


    appIconUploaderBeforeUpload = (fileInfo) => {
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let editCategory = this.state.editCategory || {};
                editCategory.logo = url;
                this.setState({
                    editCategory
                })

            },
            progress: (e) => {

            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    appIconUploading: false
                })
            },
        })
    };



    setSaveTimer = () => {
        this.clearSaveTimer()
        this.dragTimer = setTimeout(() => {
            this.saveDragSortResult()
        }, 2000)
    };
    clearSaveTimer = () => {
        if (this.dragTimer) {
            clearTimeout(this.dragTimer);
        }
    };
    saveDragSortResult = () => {
        let newData = [];
        for (let g of this.state.groups) {
            let group = {
                id: g.id,
                categories: []
            }
            if (g.sites && g.sites.length) {
                for (let c of g.sites) {
                    group.categories.push({
                        id: c.id
                    })
                }
            }
            newData.push(group);
        }
        console.log(`newDAta`,newData)
        soReq.saveGroupAndSiteSort(newData)
            .then(res => {
                message.success(`保存排序成功`);
            })
            .catch(error => {
                if (error.message) {
                    return message.error(error.message);
                }
                message.error(`保存排序失败`);
            })
    };


    previewUploaderBeforeUpload = (fileInfo) => {
        this.setState({
            uploadingPreviewStatus: "ing", previewProgress: 0
        })
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let editCategory = this.state.editCategory || {};
                editCategory.cover = url;
                this.setState({
                    editCategory,
                    uploadingPreviewStatus: "init", previewProgress: 0
                })

            },
            progress: (e) => {
                console.log(`上传封面图`, e)
                this.setState({
                    uploadingPreviewStatus: "ing", previewProgress: parseInt(e.total.percent)
                })
            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    uploadingPreviewStatus: "error"
                })
            },
        })
    };

    render() {

        let {showEditModal, editCategory, groups, loadingGroupsData, showEditGroupModal, editGroup, editCourseApp, appIconUploading, uploadingPreviewStatus, previewProgress} = this.state;


        let groupList = groups.map((group, index) => {
            let childList = [];
            if (group.sites && group.sites.length) {
                childList = group.sites.map((child, idx) => {
                    return <div className={'child-item'} key={idx} draggable={true}
                                onDragStart={(e) => {
                                    this.draggingType = 'child';
                                    this.setState({
                                        draggingCategory: child,
                                    });
                                    this.clearSaveTimer();

                                }}
                                onDragEnter={(e) => {

                                    if (this.draggingType !== 'child') {
                                        return
                                    }
                                    let draggingCategory = this.state.draggingCategory;
                                    if (child.id !== draggingCategory.id) {
                                        // 获取拖拽网站所在分组，
                                        let oldDragIndexPathGroup = null;
                                        let oldDragIndexPathCategory = null;

                                        let oldEnterIndexPathGroup = null;
                                        let oldEnterIndexPathCategory = null;

                                        for (let index = 0; index < this.state.groups.length; index++) {
                                            let g = this.state.groups[index];
                                            if (g.sites && g.sites.length) {
                                                for (let j = 0; j < g.sites.length; j++) {
                                                    let c = g.sites[j];
                                                    if (c.id === draggingCategory.id) {
                                                        oldDragIndexPathGroup = index
                                                        oldDragIndexPathCategory = j;
                                                    }
                                                    if (c.id === child.id) {
                                                        oldEnterIndexPathGroup = index;
                                                        oldEnterIndexPathCategory = j;
                                                    }

                                                }
                                            }
                                        }

                                        let groups = this.state.groups;
                                        let oldGroup = groups[oldDragIndexPathGroup];
                                        oldGroup.sites = oldGroup.sites.filter(item => item.id !== draggingCategory.id);
                                        groups[oldDragIndexPathGroup] = oldGroup;
                                        let newGroup = groups[oldEnterIndexPathGroup];
                                        newGroup.sites.splice(oldEnterIndexPathCategory, 0, draggingCategory);
                                        groups[oldEnterIndexPathGroup] = newGroup;
                                        this.setState({
                                            groups,
                                            isConfigDirty: true,
                                        });
                                    }


                                }}
                                onDragEnd={(e) => {
                                    console.log(`onDragEnd`)
                                    this.setState({
                                        draggingCategory: null,

                                    })
                                    this.draggingType = null
                                }}
                                onDrop={() => {
                                    this.setState({
                                        draggingCategory: null,

                                    })
                                    this.draggingType = null
                                    this.setSaveTimer()
                                }}
                    >
                        <div className='flex-l'>
                            <a href={child.url} target={"_blank"} rel="noopener noreferrer" title={child.title}>
                                <img src={child.logo} alt=""
                                     style={{backgroundColor: child.logo ? "white" : "#8f9bb3"}}/>
                                <div>{child.title}</div>
                            </a>

                            <div className='desc'>
                                {child.description}
                            </div>
                        </div>


                        <div className={'actions'}>
                            <a onClick={() => {
                                let editCategory = this.state.editCategory || {};
                                editCategory.group = group;
                                editCategory.origin = child;
                                this.setState({
                                    showEditModal: true,
                                    editCategory
                                })
                            }}>编辑</a>
                            <Popover placement="leftBottom" content={<img src={child.cover}/>} trigger="focus">
                                <Button className='cover-btn' disabled={!child.cover}>看封面</Button>
                            </Popover>
                            <Popconfirm title={`你确定要删除该网站吗？`} onCancel={() => {
                                this.willDeleteCategory = null;
                            }} onConfirm={this.deleteCategory}>
                                <a onClick={() => {
                                    this.willDeleteCategory = child;

                                }}>删除</a>
                            </Popconfirm>

                        </div>
                    </div>
                })

            }
            return <div className={'group-item'} draggable={true} key={index}
                        onDragStart={() => {
                            if (this.draggingType) {
                                return
                            }
                            console.log('group onDragStart')
                            this.draggingType = 'group';
                            this.setState({
                                draggingGroup: group,
                            });
                            this.clearSaveTimer()

                        }}
                        onDragEnter={() => {
                            console.log('group onDragEnter');
                            if (this.draggingType === 'child' && (!group.sites || !group.sites.length)) {
                                // return;
                                let draggingCategory = this.state.draggingCategory;
                                let oldDragIndexPathGroup = null;
                                let oldDragIndexPathCategory = null;


                                for (let m = 0; m < this.state.groups.length; m++) {
                                    let g = this.state.groups[m];
                                    if (g.sites && g.sites.length) {
                                        for (let j = 0; j < g.sites.length; j++) {
                                            let c = g.sites[j];
                                            if (c.id === draggingCategory.id) {
                                                oldDragIndexPathGroup = m
                                                oldDragIndexPathCategory = j;
                                            }

                                        }
                                    }
                                }

                                let groups = this.state.groups;
                                let oldGroup = groups[oldDragIndexPathGroup];
                                oldGroup.sites = oldGroup.sites.filter(item => item.id !== draggingCategory.id);
                                groups[oldDragIndexPathGroup] = oldGroup;
                                group.sites = [this.state.draggingCategory];
                                groups[index] = group;
                                this.setState({
                                    groups
                                })
                            }
                            if (this.draggingType !== 'group') {
                                return
                            }

                            let draggingGroup = this.state.draggingGroup;
                            if (group.id !== this.state.draggingGroup.id) {
                                const oldDragIndex = this.state.groups.findIndex(item => item.id === draggingGroup.id);
                                const oldEnterIndex = this.state.groups.findIndex(item => item.id === group.id);
                                if (oldDragIndex > oldEnterIndex) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id);
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                } else {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id) + 1;
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                }
                                this.setState({
                                    isConfigDirty: true,
                                });
                            }
                        }}
                        onDragLeave={() => {

                            if (this.draggingType !== 'group') {
                                return
                            }
                            console.log('group onDragLeave')
                            if (group.id !== this.state.draggingGroup.id) {
                                if (this.state.lock && group.id === this.state.groups[this.state.groups.length - 1]) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    newDataArray.push(this.state.draggingGroup);
                                    this.setState({
                                        lock: false,
                                    }, () => {
                                        this.setState({
                                            groups: newDataArray,
                                        });
                                    });
                                } else {
                                    this.setState({
                                        lock: true,
                                    });
                                }
                            }
                        }}
                        onDragEnd={() => {
                            console.log(`onDragEnd`)
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null

                        }}
                        onDrop={() => {
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null
                            this.setSaveTimer()
                        }}
            >
                <div className={'header'}>
                    <h3><i className={`fa fa-${group.keywords}`} style={{marginRight: 10}}/>{group.title}</h3>
                    <div className={'actions'}>
                        <a onClick={() => {
                            let editGroup = this.state.editGroup || {};
                            editGroup.origin = group;
                            this.setState({
                                showEditGroupModal: true,
                                editGroup
                            })
                        }}>编辑分组</a>
                        <a onClick={() => {
                            let editCategory = this.state.editCategory || {};
                            editCategory.group = group;
                            this.setState({
                                showEditModal: true,
                                editCategory
                            })
                        }}>添加网站</a>
                        <Popconfirm title={`你确定要删除该分组吗？`} onCancel={() => {
                            this.willDeleteGroup = null;
                        }} onConfirm={this.deleteGroup}>
                            <a hidden={group.sites && group.sites.length} onClick={() => {
                                this.willDeleteGroup = group;
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                </div>

                <div className={'child-list'}>
                    {childList}
                </div>
            </div>
        });

        return (
            <div className='page-so-uigreat-index'>
                <div className={'list-header'}>
                    <h2>so.uigreat.com网站网站</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editGroup: {},
                                showEditGroupModal: true
                            })
                        }}>+新分组</Button>
                        {/*<Button type={'primary'}>保存</Button>*/}
                    </div>
                </div>

                <div className={'list'}>
                    <Spin spinning={loadingGroupsData} className={`group-loading`} delay={200}/>
                    {groupList}
                </div>

                {/*    编辑分组*/}
                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('title') ? editGroup.title : editGroup && editGroup.origin ? editGroup.origin.title : ""}
                           placeholder={"分组名称"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.title = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>
                    <div className={'modal-item'}>
                        <i className={`fa fa-${editGroup && editGroup.hasOwnProperty('keywords') ? editGroup.keywords : editGroup && editGroup.origin ? editGroup.origin.keywords : ""}`}/>
                        <Input className={'input'}
                               value={editGroup && editGroup.hasOwnProperty('keywords') ? editGroup.keywords : editGroup && editGroup.origin ? editGroup.origin.keywords : ""}
                               placeholder={"分组图标（Font Awesome 图标）"} onChange={(e) => {
                            let editGroup = this.state.editGroup;
                            editGroup.keywords = e.target.value;
                            this.setState({
                                editGroup
                            })
                        }}/>
                    </div>

                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup}
                                disabled={!editGroup || !editGroup.title || !editGroup.title.length || (editGroup && editGroup.origin && editGroup.title === editGroup.origin.title)}>提交</Button>
                    </div>

                </Modal>

                {/*    编辑网站*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editCategory || !editCategory.origin ? "添加网站" : "编辑网站"}</h2>

                    <div className={'form-item'}>
                        <h3>网站名称</h3>
                        <Input className={'input'}
                               value={editCategory && editCategory.hasOwnProperty('title') ? editCategory.title : editCategory && editCategory.origin ? editCategory.origin.title : ""}
                               placeholder={"网站名称"} onChange={(e) => {
                            let editCategory = this.state.editCategory || {};
                            editCategory.title = e.target.value;
                            this.setState({
                                editCategory
                            })
                        }}/>
                    </div>

                    <div className={'form-item'}>
                        <h3>网站地址</h3>
                        <Input className={'input'}
                               value={editCategory && editCategory.hasOwnProperty('url') ? editCategory.url : editCategory && editCategory.origin ? editCategory.origin.url : ""}
                               placeholder={"网站地址"} onChange={(e) => {
                            let editCategory = this.state.editCategory || {};
                            editCategory.url = e.target.value;
                            this.setState({
                                editCategory
                            })
                        }}/>
                    </div>

                    <div className={'form-item'}>
                        <h3>网站介绍</h3>

                        <Input.TextArea className={'input'}
                                        value={editCategory && editCategory.hasOwnProperty('description') ? editCategory.description : editCategory && editCategory.origin ? editCategory.origin.description : ""}
                                        placeholder={"描述"} onChange={(e) => {
                            let editCategory = this.state.editCategory || {};
                            editCategory.description = e.target.value;
                            this.setState({
                                editCategory
                            })
                        }} style={{minHeight:80}}/>
                    </div>

                    <div className={'form-item'}>
                        <h3>LOGO</h3>
                        <Upload className={'app-icon-uploader'} accept={'image/*'}
                                showUploadList={false}
                                beforeUpload={this.appIconUploaderBeforeUpload}
                                disabled={appIconUploading}>
                            <div className={'app-icon-add'}
                                 hidden={editCategory && (editCategory.logo || (editCategory.origin && editCategory.origin.logo))}>
                                网站LOGO
                            </div>
                            <img
                                hidden={!editCategory || (!editCategory.logo && (!editCategory.origin || !editCategory.origin.logo))}
                                src={editCategory ? editCategory.logo ? editCategory.logo : editCategory.origin ? editCategory.origin.logo : "" : ""}
                                alt={''}/>
                        </Upload>
                    </div>
                    <div className={'form-item'}>
                        <h3>封面图</h3>
                        <Upload.Dragger className={'uploader'}
                                        accept={'image/jpeg,image/png,image/jpg,image/gif'}
                                        showUploadList={false}
                                        beforeUpload={this.previewUploaderBeforeUpload} disabled={this.state.uploading}>
                            <div className={'uploader-container'}
                            >
                                <div className={'tip'}
                                     hidden={editCategory ? editCategory.cover || (editCategory.origin && editCategory.origin.cover) : false}>

                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox"/>
                                    </p>
                                    <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                    <p className="ant-upload-hint">
                                        仅支持单张图片上传
                                    </p>
                                </div>

                                <div className={'course-preview'}
                                     hidden={!editCategory || (!editCategory.cover && (!editCategory.cover || !editCategory.origin.cover))}>
                                    <img
                                        src={editCategory ? editCategory.cover ? editCategory.cover : editCategory.origin ? editCategory.origin.cover : "" : ""}
                                        alt={''}/>
                                </div>
                                <Progress className={'progress'} hidden={uploadingPreviewStatus === 'init'}
                                          strokeColor='#3340ff' type="circle"
                                          percent={previewProgress} width={80}
                                          status={uploadingPreviewStatus === 'error' ? "exception" : "normal"}/>
                            </div>


                        </Upload.Dragger>
                    </div>


                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editCategory && editCategory.group ? editCategory.group.title : ""}`}</span>
                    </p>
                    <div className={'footer'}>

                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitCategory}>提交</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default SoIndex;
