import AppRequest from './AppRequest'

const urlGetGroupToolList = '/api_v1/admin-api/tool/list';
const urlChangeToolSortInGroup = '/api_v1/admin-api/tool/sort/update';
const urlCreateTool = '/api_v1/admin-api/tool/post';
const urlCreateToolGroup = '/api_v1/admin-api/tool/group/post';

class ToolRequest extends AppRequest {


    createToolGroup(info){
        return this.post(urlCreateToolGroup,info);
    }
    updateToolGroupInfo(info){
        return this.put(urlCreateToolGroup,info);
    }
    deleteToolGroup(toolGroupId){
        return this.delete(urlCreateToolGroup,{id:toolGroupId})
    }


    getGroupToolList(){
        return this.get(urlGetGroupToolList,{});
    }
    deleteTool(toolId){
        return this.delete(urlCreateTool,{id:toolId});
    }

    createTool(info){
        return this.post(urlCreateTool,info);
    }
    updateTool(info){
        return this.put(urlCreateTool,info);
    }
    changeToolSortInGroup(groupId,sortedToolIds){
        return this.post(urlChangeToolSortInGroup,{groupId,toolIds:sortedToolIds});
    }
}

const  toolReq = new ToolRequest();
export default toolReq;

