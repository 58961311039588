import AppRequest from './AppRequest'


const prefixPath = "/api_v1/admin-api/"
class QieManReq extends AppRequest {

    getAllProject(){
        return this.get(prefixPath + "qmwxserve/project/list")
    }
    getProjectInfo(projectId){
        return this.get(prefixPath + "qmwxserve/project/info",{projectId})
    }
    createNewProject(info){
        return this.post(prefixPath + "qmwxserve/project/item",info)
    }

    updateProject(info){
        return this.put(prefixPath + "qmwxserve/project/item",info)
    }

    deleteProject(projectId){
        return this.delete(prefixPath + "qmwxserve/project/item",{
            projectId
        })
    }

    sendProjectTemplateMessage(info) {
        return this.post(prefixPath + "qmwxserve/project/tempMsg/send",info)
    }

    getMediaList(type,page){
        return this.get(prefixPath + "qmwxserve/media/list",{
            type,
            page,
            perPage:20
        })
    }

    saveReplyImageMessage(info){
        return this.post(prefixPath + "qmwxserve/auto-reply/image",info)
    }

    getReplyImageMessage(){
        return this.get(prefixPath + "qmwxserve/auto-reply/image",{})
    }

    saveReplyMessageAfterFollow(info){
        return this.post(prefixPath + "qmwxserve/auto-reply/followed",info)
    }
    saveReplyMessageAfterScan(info){
        return this.post(prefixPath + "qmwxserve/auto-reply/project-scan",info)
    }

    getSettingReplyMessageAfterFollow(){
        return this.get(prefixPath + "qmwxserve/auto-reply/followed",{})
    }
    getSettingReplyMessageAfterScan(projectId){
        return this.get(prefixPath + "qmwxserve/auto-reply/project-scan",{projectId})
    }

    getMenu(){

        return this.get(prefixPath + "qmwxserve/menu/current")
    }

    setMenu(data){

        return this.post(prefixPath + "qmwxserve/menu/current",data)
    }
    deleteMenu(){

        return this.delete(prefixPath + "qmwxserve/menu/current")
    }

    createNewKeyword(data){
        return this.post(prefixPath + "qmwxserve/auto-reply/keyword/post",data)
    }

    getKeywordList(data){
        return this.get(prefixPath + "qmwxserve/auto-reply/keyword/list",data)
    }
    deleteKeyword(keywordId){
        return this.delete(prefixPath + "qmwxserve/auto-reply/keyword/post",{
            keywordId
        })
    }




}

const  soReq = new QieManReq();
export default soReq;

