import React from 'react';
import {
    Progress,
    Row,
    Col,
    Input,
    Icon,
    Button,
    Breadcrumb,
    Upload,
    Select,

    Switch,
    Modal, Spin, message,
} from 'antd'



import './audioEdit.scss'
import {connect} from 'react-redux'

import indexReq from "../../request/indexReq";
import audioReq from "../../request/audioReq";
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'
import 'braft-extensions/dist/code-highlighter.css'
import 'braft-extensions/dist/color-picker.css'
import CodeHighlighter from 'braft-extensions/dist/code-highlighter'
import Markdown from 'braft-extensions/dist/markdown'
import Table from 'braft-extensions/dist/table'
import ColorPicker from 'braft-extensions/dist/color-picker'
import HeaderId from 'braft-extensions/dist/header-id'
import * as qiniu from "qiniu-js";
import BKSQuillEditor from "../../components/quill/BKSQuillEditor";
const uuidv4 = require('uuid/v4');
const {Dragger} = Upload;

const editorOptions = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
};


const CPOptions = {
    theme: 'light', // 指定取色器样式主题，支持dark和light两种样式
}

const editorControls = [
    'undo', 'redo', 'separator', 'line-height', 'letter-spacing', 'separator',
    'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
    'link', 'separator', 'hr', 'separator',
    'media', 'separator', "fullscreen"
];

BraftEditor.use(ColorPicker(CPOptions));

BraftEditor.use(Table(editorOptions));
const MKOptions = {};
const codeHOptions = {};
BraftEditor.use(Markdown(MKOptions));
BraftEditor.use(CodeHighlighter(codeHOptions));
BraftEditor.use(HeaderId({}));
// 富文本编辑器媒体上传函数，接入七牛上传
const myUploadFn = (param) => {

    const progressFn = (event) => {
        param.progress(event.total.percent)
    };

    const errorFn = (response) => {
        param.error({
            msg: '上传失败'
        })
    };

    indexReq.getFileUploadToken()
        .then(info => {
            const successFn = (response) => {
                console.log(response.key);
                param.success({
                    url: `${info.host}${response.key}`
                })
            };
            let paths = param.file.name.split('.');
            let key = null
            if (paths.length > 1) {
                key = uuidv4() + "." + paths[paths.length - 1];
            }
            let observable = qiniu.upload(param.file, key, info.uploadToken, null, null);
            observable.subscribe(progressFn, errorFn, successFn);
        })
        .catch(error => {
            return errorFn(error);
        })
};

class AudioEdit extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)

    }

    state = {
        audioSeriesId: null,
        audioSeriesInfo: null,
        audioId: null,
        audioInfo: null,
        changedInfo: {},
        pageLoading: true,
        editType: "create", // update
        audioUploadInfo: "init",
        progress: 0,
        submitting: false,
        audioStatusLoading: false,
        uploadingFileInfo: null,
        editorState: BraftEditor.createEditorState(null),
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    handleEditorChange = (editorState) => {
        let changedInfo = this.state.changedInfo;
        let html = editorState.toHTML();

        if((!this.state.audioInfo && html != '<p></p>') || (this.state.audioInfo && this.state.audioInfo.content != html) ){

            changedInfo.content = html;
        }

        this.setState({
            changedInfo,
            editorState
        })



    }

    submitContent = () => {

    }

    /* life circle method */
    componentDidMount() {
        this.parsePatchName();



    }


    parsePatchName() {
        let audioSeriesId = this.props.match.params.audioSeriesId;
        let audioId = this.props.match.params.audioId;
        let audioSeriesInfo = null;
        if (!audioSeriesId || !parseInt(audioSeriesId)) {
            return this.setState({
                noFound: true
            })
        } else {
            audioSeriesId = parseInt(audioSeriesId);
        }
        if (!audioId) {
            return this.setState({
                noFound: true
            })
        }
        if (audioId === 'create') {
            return this.setState({
                pageLoading: false,
                audioSeriesInfo,
                audioSeriesId,
                editType: "create",
            }, () => {
                if (!audioSeriesInfo) {
                    this.getAudioSeriesDetail();
                }
            })
        } else if (!parseInt(audioSeriesId)) {
            return this.setState({
                noFound: true
            })
        } else {
            return this.setState({
                pageLoading: false,
                audioSeriesInfo,
                audioSeriesId,
                audioId,
                editType: "update",
                showEdit: true
            }, () => {
                if (!audioSeriesInfo) {
                    this.getAudioSeriesDetail();
                }
                this.getAudioDetail()
            })
        }

    }

    getAudioSeriesDetail = () => {

        audioReq.getSeriesDetail(this.state.audioSeriesId)
            .then(res => {
                this.setState({
                    audioSeriesInfo: res,
                })
            })
            .catch(error => {
                message.error(`无法获取音频系列信息，请刷新页面重试`)
            })
    };

    getAudioDetail() {
        this.setState({
            audioStatusLoading: true
        })
        audioReq.getAudioDetail(this.state.audioId)
            .then(res => {
                if (res && res.tags) {
                    res.tags = res.tags.split(',')
                }
                this.setState({
                    audioInfo: res,
                    pageLoading: false,
                    audioStatusLoading: false,
                    editorState: BraftEditor.createEditorState(res.content || null)
                }, () => {
                    if (this.state.audioInfo.sourceUrl) {
                        // this.checkVideoStatus()
                        // this.playVideo();
                    }

                })

            })
            .catch(error => {
                this.setState({
                    audioStatusLoading: false
                })
                message.error(`无法获取音频信息，请刷新页面重试`)
            })
    }


    previewUploaderOnChange = (files) => {
        console.log(`files`, files)
    };


    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    parseVideoDuration(duration) {
        let minute = parseInt(duration / 60);
        if (minute < 1) {
            minute = '00';
        } else if (minute < 10) {
            minute = `0${minute}`;
        }
        let second = duration % 60;
        if (second < 1) {
            second = '00';
        } else if (second < 10) {
            second = `0${second}`;
        }
        return `${minute}:${second}`
    }

    onSubmitAudio = () => {
        let {editType, audioInfo, changedInfo, audioSeriesInfo} = this.state;
        let info = {...changedInfo};

        if (info.tags) {
            info.tags = info.tags.toString()
        }

        if (editType === 'create') {
            if (!info.title) {
                return message.warn('请填写音频标题');
            }
            if (!info.sourceUrl) {
                return message.warn('请上传音频文件');
            }
            this.setState({
                submitting: true
            })
            info.audioSeriesId = audioSeriesInfo.id;
            audioReq.createAudio(info)
                .then(newAudio => {
                    this.setState({
                        editType: 'update',
                        changedInfo: {},
                        submitting: false
                    }, () => {
                        window.location.href = `/audioEdit/1/audio/${newAudio.id}`
                    });
                    message.success(`音频已成功创建`);
                })
                .catch(error => {
                    this.setState({
                        submitting: false
                    })
                    message.warn(`创建音频失败，请重试`)
                })
        }

        if (editType === 'update') {

            this.setState({
                submitting: true
            })
            info.id = audioInfo.id;
            audioReq.updateAudio(info)
                .then(res => {
                    if (res && res.tags) {
                        res.tags = res.tags.split(',')
                    }
                    this.setState({
                        audioInfo: res,
                        editorState: BraftEditor.createEditorState(res && res.content ? res.content : null),
                        changedInfo: {},
                        submitting: false
                    })
                    message.success(`已成功保存音频修改`);
                })
                .catch(error => {
                    this.setState({
                        submitting: false
                    })
                    message.warn(`保存修改失败，请稍后重试`)
                })
        }
    }

    audioUploaderBeforeUpload = (file) => {
        this.setState({
            audioUploadInfo: "ing", progress: 0,
            uploadingFileInfo: file
        })

        let url = URL.createObjectURL(file);
        let audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", () => {
            let duration = parseInt(audioElement.duration);
            indexReq.uploadFileToQiniu(file, file.name, {
                success: (url) => {
                    let changedInfo = this.state.changedInfo || {};
                    changedInfo.sourceUrl = url;
                    changedInfo.duration = parseInt(duration + "");
                    this.setState({
                        changedInfo,
                        audioUploadInfo: "init", progress: 0,
                    })


                },
                progress: (e) => {
                    console.log(`上传音频`, e)
                    this.setState({
                        audioUploadInfo: "ing", progress: parseInt(e.total.percent)
                    })
                },
                error: (error) => {
                    message.error('上传发生错误，请重试');
                    this.setState({
                        audioUploadInfo: "error", progress: 0
                    })
                },
            })
        })


    }

    tagsHandleChange = (e) => {
        let {changedInfo} = this.state;
        changedInfo = changedInfo || {};
        changedInfo.tags = e;
        this.setState({
            changedInfo
        })
    }



    render() {
        let {audioStatusLoading, editType, changedInfo, audioSeriesInfo, audioInfo, playerHeight, playerWidth, noFound, pageLoading, audioUploadInfo, showEdit, submitting, editorState} = this.state;
        audioSeriesInfo = audioSeriesInfo || {};
        audioInfo = audioInfo || {};
        changedInfo = changedInfo || {};

        if (pageLoading) {
            return <div className={'page-course-create'}>
                <Spin size={'large'}/>
            </div>
        }

        if (noFound) {
            return <div className={'page-course-create'}>
                <div className={'no-found'}>
                    <p>系列不存在或已被删除</p>
                    <Button type={'primary'}>查看所有系列</Button>
                </div>
            </div>
        }
        let videoSize = null
        // if(audioUploadInfo && audioUploadInfo.fileSize){
        //     videoSize  = `${parseInt(audioUploadInfo.fileSize / 1024 / 1024)}M`;
        // }

        // let videoDuration = this.parseVideoDuration(remoteVideoDuration ?  parseInt(remoteVideoDuration) : audioUploadInfo.duration);
        return (
            <div className='page-course-lesson-create'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a onClick={() => {
                            if (audioSeriesInfo.id) {

                                this.props.history.push(`/audio-series/${audioSeriesInfo.id}`);
                            }
                        }}>系列：{audioSeriesInfo.title}</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{editType === 'create' ? "创建音频" : `音频:${changedInfo.title || audioInfo.title || "音频编辑"}`}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                        this.props.history.goBack();
                    }}/>  <h1>{editType === 'create' ? "创建音频" : "编辑音频"}</h1>
                </div>

                <Row gutter={16}>
                    <Col span={12}>
                        <div className={'course-form-item '}>
                            <h3>音频 (时长：{0} {videoSize ? `文件大小：${videoSize}` : ''} )</h3>
                            <div className={'upload-item'} hidden={audioUploadInfo.status !== 'ing'}>
                                <Progress className={`progress`} percent={audioUploadInfo.progress}
                                          status={audioUploadInfo.status === 'error' ? "exception" : "active"}/>
                            </div>
                            <div className={'dragger-container'} style={{width: playerWidth, height: playerHeight}}
                            >
                                <Dragger className={'uploader'}
                                         accept={'audio/midi, audio/mpeg, audio/webm, audio/ogg, audio/wav'}
                                         showUploadList={false} onChange={this.previewUploaderOnChange}
                                         beforeUpload={this.audioUploaderBeforeUpload}
                                         disabled={this.state.audioUploadInfo.status === 'ing'}>
                                    <div className={'uploader-container'}>
                                        <div
                                            hidden={audioUploadInfo !== 'init' || changedInfo.sourceUrl || audioInfo.sourceInfo}>
                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox"/>
                                            </p>
                                            <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                            <p className="ant-upload-hint">
                                                仅支持单个音频文件上传
                                            </p>
                                        </div>

                                        <div
                                            hidden={(!changedInfo.sourceUrl && !audioInfo.sourceUrl) || audioUploadInfo == 'ing'}>
                                            <Button style={{border: "none", margin: "15px 0", color: "#3340ff"}}
                                                    onClick={(e) => {
                                                        window.open(changedInfo.sourceUrl || audioInfo.sourceUrl, '_blank')
                                                        e.preventDefault()
                                                    }}
                                                    hidden={!changedInfo.sourceUrl && !audioInfo.sourceUrl}>播放当前音频</Button>
                                        </div>

                                        <Progress className={'progress'}
                                                  hidden={!audioUploadInfo || audioUploadInfo === 'init'}
                                                  strokeColor='#3340ff' type="circle"
                                                  percent={this.state.progress} width={80}
                                                  status={audioUploadInfo === 'error' ? "exception" : "normal"}/>

                                    </div>
                                </Dragger>


                            </div>
                            <div id='lesson-edit-player-container'
                                 hidden={!audioInfo.sourceUrl && this.state.audioUploadInfo.status !== 'init'}/>
                            <Upload
                                showUploadList={false} onChange={this.previewUploaderOnChange}
                                beforeUpload={this.videoUploaderBeforeUpload}
                                disabled={this.state.audioUploadInfo.status === 'ing'}
                            ><Button
                                type={'primary'}
                                hidden={!audioInfo || !audioInfo.sourceUrl || this.state.audioUploadInfo.status !== 'init'}
                                style={{marginTop: 15}}>重新上传</Button></Upload>

                        </div>
                        <div className={'course-form-item '}>
                            <h3>音频标题</h3>
                            <Input value={changedInfo.hasOwnProperty(`title`) ? changedInfo.title : audioInfo.title}
                                   className={'input'} onChange={e => {
                                let changedInfo = this.state.changedInfo;
                                changedInfo.title = e.currentTarget.value;
                                this.setState({
                                    changedInfo
                                })
                            }}/>
                        </div>
                        <div className={'course-form-item'}>
                            <h3>标签</h3>
                            <Select
                                mode="tags"
                                size={'large'}
                                placeholder="音频标签，以逗号或回车分隔"
                                value={changedInfo.hasOwnProperty(`tags`) ? changedInfo.tags : audioInfo.tags || []}
                                onChange={this.tagsHandleChange}
                                style={{width: '100%'}}
                                allowClear={true}
                                tokenSeparators={['\n', ',', '，']}
                            >

                            </Select>
                        </div>
                        <div className={'course-form-item'}>
                            <h3>音频简介</h3>
                            <Input.TextArea
                                value={changedInfo.hasOwnProperty(`description`) ? changedInfo.description : audioInfo.description}
                                className={'input textarea'} onChange={e => {
                                let changedInfo = this.state.changedInfo;
                                changedInfo.description = e.currentTarget.value;
                                this.setState({
                                    changedInfo
                                })
                            }}/>
                        </div>


                    </Col>
                    <Col span={12}>
                        <div className={'form-item'}>
                            <h3>音频内容</h3>
                            {/*<BKSQuillEditor/>*/}
                            <BraftEditor className={'editor'}
                                         controls={editorControls}
                                         value={editorState}
                                         onChange={this.handleEditorChange}
                                         onSave={this.submitContent}
                                         placeholder={''}

                                         contentClassName={"editor-content"}
                                         componentBelowControlBar={<div ref={(ele) => {
                                             this.editBelowRef = ele;
                                         }} />}
                                         media={{ uploadFn: myUploadFn }}
                            />
                        </div>
                    </Col>
                </Row>

                <div className={'bottom-bar'}>
                    <Switch className={'publish-switch'} checkedChildren="音频已发布" unCheckedChildren="音频已下架"
                            size={'large'} hidden={editType === 'create'} checked={audioInfo.status === 'published'}
                            loading={audioStatusLoading} onChange={value => {
                        console.log('value', value)
                        let audioInfo = this.state.audioInfo;
                        if (audioInfo) {
                            audioInfo.status = audioInfo.status === 'published' ? "unpublished" : "published"
                            this.setState({
                                audioInfo,
                                audioStatusLoading: true
                            })
                            audioReq.changeAudioStatus(audioInfo.id, audioInfo.status == 'published')
                                .then(res => {

                                    this.setState({
                                        audioStatusLoading: false,
                                        audioInfo
                                    })
                                })
                                .catch(error => {
                                    audioInfo.status = audioInfo.status === 'published' ? "unpublished" : "published"
                                    this.setState({
                                        audioStatusLoading: false
                                    })
                                    message.error(`操作失败，请重试`)
                                })

                        }
                    }}/>
                    <Button type={'danger'} style={{marginRight: 15}}
                            hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                        Modal.confirm({
                            title: "你确定要放弃所有修改记录吗？",
                            okText: "确定放弃",
                            onOk: () => {
                                this.setState({
                                    changedInfo: {}
                                })
                            }
                        })
                    }}>放弃所有音频修改</Button>
                    <Button type={'primary'} onClick={this.onSubmitAudio}
                            disabled={!Object.getOwnPropertyNames(changedInfo).length}
                            loading={submitting}>保存音频</Button>
                </div>


            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        windowSize: state.system.windowSize,
    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AudioEdit);
