import axios from 'axios'
axios.defaults.withCredentials = true;
class BaseRequest {

  basePut(url, headers, query, body) {
    return this.request(url, "PUT", headers, query, body || {});
  }
  basePost(url, headers, query, body) {
    return this.request(url, "POST", headers, query, body || {});
  }
  baseDelete(url, headers, query) {
    return this.request(url, "DELETE", headers, query, null);
  }
  baseGet(url, headers, query) {
    return this.request(url, "GET", headers, query, null);
  }
  request(url, method, headers, query, body) {

    // 发送 POST 请求
    return axios({
      url: url || "/",
      method: method, // 默认是 get
      // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
      transformResponse: [function (data) {
        // 对 data 进行任意转换处理

        return data;
      }],
      headers: headers || {},
      params: query || {},
      data: body || {},
      timeout: 10000,
      withCredentials: true, // 默认的
      onUploadProgress: function (progressEvent) {
        // 对原生进度事件的处理
      },

      // `onDownloadProgress` 允许为下载处理进度事件
      onDownloadProgress: function (progressEvent) {
        // 对原生进度事件的处理
      },
      validateStatus: function (status) {
        return status < 500; // 状态码在大于或等于500时才会 reject
      },
      maxRedirects: 5, // 默认的
    });
  }
}

export default BaseRequest;