import React,{useState,useEffect} from "react";
import qiemanReq from "../../request/qieManReq"
import  "./project.scss"
import {Breadcrumb, Button, List, message,Modal,Input} from "antd";
import {Link} from "react-router-dom";
export  default  props =>{
    const [loadingList,setLoadingList] = useState(false);
    const [list,setList] = useState([]);
    const [count,setCount] = useState(0);
    const [showCreateModal,setShowCreateModal] = useState(false)
    const [editItem,setEditItem] = useState({})
    // 请求
    const getList = ()=>{
        setLoadingList(true)
        qiemanReq.getAllProject()
            .then(({rows,count}) =>{
                setLoadingList(false)
                setList(rows)
                setCount(count)
            })
            .catch(e =>{
                setLoadingList(false)
                message.error("获取列表失败")
            })
    }

    // 初始化
    useEffect(()=>{
        getList();
    },[])

    const submit = ()=>{
        if(editItem && editItem.origin) {
            let info = {projectId:editItem.origin.id}
            if(editItem.title) {
                info.title = editItem.title;

            }

            if(editItem.description) {
                info.description = editItem.description;

            }

            if(Object.getOwnPropertyNames(info).length < 2){
                return  message.warn("没有任何修改")
            }
            qiemanReq.updateProject(info)
                .then(res =>{
                    setShowCreateModal(false)
                    getList()
                })
                .catch(e =>{
                    message.error(`发生错误：${e & e.message || ""}`)
                })

        }else {
            if(!editItem || !editItem.title ){
                return message.warn("请填写项目名称")
            }
            qiemanReq.createNewProject({
                title:editItem.title,
                description:editItem.description || ""
            })
                .then(res =>{
                    setShowCreateModal(false)
                    getList()
                })
                .catch(e =>{
                    message.error(`发生错误：${e & e.message || ""}`)
                })

        }
    }

    return (
        <div className="page-qm-wx-project">
            <Breadcrumb>
                <Breadcrumb.Item>主页</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a>{"且曼设计圈项目"}</a>
                </Breadcrumb.Item>
            </Breadcrumb>
            {/*<div className={'page-header'}>*/}
            {/*    <h1>{"项目列表"}</h1>*/}
            {/*</div>*/}

            <div className={'header'}>
                <div className={'flex-l'}>
                    <h2>全部项目</h2>
                </div>
                <div className={'flex-r'}>
                    <Button type={'primary'} size="default" style={{height: 40}} onClick={() => {
                        setShowCreateModal(true)
                        setEditItem({})
                    }}>创建新项目</Button>
                </div>
            </div>

            <List
                className="list"
                itemLayout="horizontal"
                loading={loadingList}
                dataSource={list || []}
                rowKey={'id'}
                renderItem={(item,index) => (
                    <List.Item
                    actions={[<a onClick={()=>{
                      setEditItem({
                          origin:{...item}
                      })
                        setShowCreateModal(true)

                    }}>编辑</a>,<Link key="list-edit" to={`/qm-wx/project/${item.id}`}>管理</Link>,<a href={`/api_v1/string2qr?s=${encodeURIComponent(item.qrUrl || "")}`} target="_blank">二维码</a>,<a  style={{color:"red"}} onClick={()=>{
                        Modal.confirm({
                            okButtonProps:{
                                type:"danger"
                            },
                            title:`确定删除「 ${item.title}」?`,
                            okText:"删除",
                            onOk:()=>{
                                qiemanReq.deleteProject(item.id)
                                    .then(e =>{
                                        getList()
                                    })
                                    .catch(e =>{
                                        message.error(`发生错误：${e && e.message || ""}`)
                                    })
                            }
                        })
                    }} >删除</a>]}
                    >

                        <List.Item.Meta
                            avatar={null}
                            title={item.title || ""}
                            description={item.description || ""}
                        />


                    </List.Item>
                )}
                pagination={null}
                split={false}
            />

            <Modal

                visible={showCreateModal}
                onOk={null}
                onCancel={()=>{
                    setShowCreateModal(false)
                    setEditItem({})
                }}
                destroyOnClose={true}
                footer={null}
                className={'modal-edit-shot-category'}
                centered={true}
                maskClosable={false}
            >
                <h2>{!editItem || !editItem.origin ? "创建项目" : "编辑项目"}</h2>
                <Input className={'input'}
                       value={editItem && editItem.hasOwnProperty('title') ? editItem.title : editItem && editItem.origin ? editItem.origin.title : ""}
                       placeholder={"项目名称"} onChange={(e) => {
                    editItem.title = e.target.value;
                    setEditItem({...editItem})

                }} />
                <Input.TextArea className={'textarea'}
                       value={editItem && editItem.hasOwnProperty('description') ? editItem.description : editItem && editItem.origin ? editItem.origin.description : ""}
                       placeholder={"项目描述"} onChange={(e) => {

                    editItem.description = e.target.value;
                    setEditItem({...editItem})
                }} />
                <div className={'footer'}>
                    <Button className={'submit-btn'} type={'primary'} onClick={submit} >提交</Button>
                </div>

            </Modal>
        </div>
    )
}