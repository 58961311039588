import React from 'react';
import {Row, Col, Input, Statistic, List, Radio, Popconfirm, Breadcrumb, Divider, message, Modal, Avatar} from 'antd'
import {Link} from 'react-router-dom'
import {parseTimelineDate} from '../../utils/dataformat'
import './ShotDash.scss'
import {connect} from 'react-redux'
import CourseListItem from "../../components/courseListItem/CourseListItem";
import courseReq from "../../request/courseReq";
import shotReq from "../../request/shotReq";
import ShotListItem from "../../components/shotListItem/ShotListItem";
import config from '../../config.json'
const {Search} = Input;
const PerPage = 30;


class ShotDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        courseTotalNum: 0,
        groups: [],
        shots: [],
        shotCount: 0,
        loadingShots: false,
        condition: {
            perPage: PerPage,
            page: 0,
        },
        selectedGroup: null,
        selectedCategory: null,
        sortKey: 'latest',
        searchKeyword: '',
        editShot: null,
        showShotModal: false

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {

        this.getShots();
    }


    getShots = () => {

        this.setState({
            loadingShots: true
        })
        let condition = {...this.state.condition};
        if (this.state.selectedCategory) {
            condition.shotCategoryId = this.state.selectedCategory.id
        }
        if (this.state.sortKey) {
            switch (this.state.sortKey) {
                case "view-number":
                    condition.viewNum = true
                    break;
                default:
                    break;
            }
        }
        if (this.state.searchKeyword) {
            condition.keyword = this.state.searchKeyword;
        }
        shotReq.getShotList(condition)
            .then(res => {
                this.setState({
                    loadingShots: false,
                    shots: res.rows,
                    shotCount: res.count
                })
            })
            .catch(error => {
                this.setState({
                    loadingShots: false
                })
            })
    }

    sortConditionOnChange = (e) => {

        let key = e.target.value;
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            sortKey: key,
            condition
        }, () => {
            this.getCourses()
        })
    };
    onClickAll = () => {
        let condition = this.state.condition;
        condition.page = 0;
        this.setState({
            selectedGroup: null,
            selectedCategory: null,
            condition
        }, () => {
            this.getCourses()
        })
    }
    handlerShotModalCancel = () => {
        this.setState({
            editShot: null,
            showShotModal: false
        })
    }

    handlerShotModalOk = () => {
        this.setState({
            editShot: null,
            showShotModal: false
        })
    }

    getShotDetail = () => {
        shotReq.getShotDetail(this.state.editShot.id)
            .then(res => {
                let editShot = this.state.editShot;
                this.setState({
                    editShot: {...editShot, ...res}
                })
            })
            .catch(error => {
                message.error(`获取作品详情失败：${error ? error.message : ""}`)
            })
    }
    willBanShot = null;
    banShot = () => {

        shotReq.handlerShotBan(this.willBanShot.id, !this.willBanShot.ban)
            .then(res => {
                let shots = this.state.shots;
                shots[this.willBanShot.rowIndex].ban = !shots[this.willBanShot.rowIndex].ban;
                this.setState({
                    shots
                })
                message.success(`作品已被${shots[this.willBanShot.rowIndex].ban ? "封禁" : "解禁"}`);
                this.willBanShot = null;
            })
            .catch(error => {
                message.error(`操作失败：${error && error.message ? error.message : ""}`)
            })
    };
    handlerTop = e => {
        let index = e.currentTarget.dataset.index;
        let shot = this.state.shots[index];
        shotReq.handlerShotTop(shot.id, !shot.isTop)
            .then(res => {
                let shots = this.state.shots;
                shots[index].isTop = !shots[index].isTop;
                this.setState({
                    shots
                })
                message.success(`已${shots[index].isTop ? "置顶" : "取消置顶"}`);
            })
            .catch(error => {
                message.error(`操作失败：${error && error.message ? error.message : ""}`)
            })
    }
    handlerRecommend = e => {
        let index = e.currentTarget.dataset.index;
        let shot = this.state.shots[index];
        shotReq.handlerShotRecommend(shot.id, !shot.isRecommend)
            .then(res => {
                let shots = this.state.shots;
                shots[index].isRecommend = !shots[index].isRecommend;
                this.setState({
                    shots
                })
                message.success(`已${shots[index].isRecommend ? "推荐" : "取消推荐"}`);
            })
            .catch(error => {
                console.log(error);
                message.error(`操作失败：${error && error.message ? error.message : ""}`)
            })
    }

    willDeleteShot = null
    deleteShot = ()=>{

        shotReq.handlerShotDelete(this.willDeleteShot.id)
            .then(res => {
                let shots = this.state.shots;
               shots.splice(this.willDeleteShot.rowIndex,1)
                this.setState({
                    shots
                })
                message.success(`作品已被删除`);
                this.willBanShot = null;
            })
            .catch(error => {
                message.error(`操作失败：${error && error.message ? error.message : ""}`)
            })
    }


    render() {
        let {
            shots, loadingShots, shotCount, sortKey, selectedCategory, searchKeyword, editShot,
            showShotModal
        } = this.state;


        return (
            <div className='page-course-board'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>作品</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <h1>作品</h1>
                </div>
                <h2>作品统计</h2>

                <Row gutter={16} className={'statistic-section'}>
                    <Col span={4}>
                        <Statistic title="作品总数" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="今日上传" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="昨日上传" value={0}/>
                    </Col>

                    <Col span={4}>
                        <Statistic title="最近7天" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近30天" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="最近90天" value={0}/>
                    </Col>
                </Row>
                <h2>分类</h2>

                <Row gutter={16} className={'statistic-section'}>
                    <Col span={4}>
                        <div onClick={() => {
                            console.log(`作品总数点击`)
                        }}>
                            <Statistic title="作品总数" value={0}/>
                        </div>

                    </Col>
                    <Col span={4}>
                        <Statistic title="学员佳作" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="REDESIGN" value={0}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="未分类" value={0}/>
                    </Col>

                </Row>
                <div className={'header'}>
                    <div className={'flex-l'}>
                        <h2>全部作品</h2>
                        {/*<Radio.Group className={'presort-group'} value={sortKey} onChange={this.sortConditionOnChange}>*/}
                        {/*    <Radio.Button value="latest">最新</Radio.Button>*/}
                        {/*    <Radio.Button value="view-number">浏览量</Radio.Button>*/}
                        {/*</Radio.Group>*/}
                    </div>

                    <div className={'flex-r'}>
                        <Search placeholder="输入标题、用户名、昵称进行搜索" size="large" onSearch={value => {
                            let condition = this.state.condition;
                            condition.page = 0;
                            this.setState({
                                searchKeyword: value,
                                condition
                            }, () => {
                                this.getShots()
                            })
                        }} enterButton={true}
                                className={'search-input'} loading={!!searchKeyword && !!loadingShots}/>
                    </div>
                </div>

                <List
                    className="course-list"
                    itemLayout="horizontal"
                    loading={loadingShots}
                    dataSource={shots || []}
                    renderItem={(item, rowIndex) => (
                        <List.Item
                            rowkey={item.id}
                        >
                            <ShotListItem
                                onClickPreview={() => {
                                    this.setState({
                                        editShot: {...item, rowIndex},
                                        showShotModal: true
                                    }, () => {
                                        this.getShotDetail()
                                    })
                                }}
                                data={item}
                                actions={<span>
                                    <a key="list-edit" href={`${config.domainSiteHost}/shot/${item.id}`}
                                       target={'_blank'}>查看</a><Divider type={'vertical'}/>
                                    <a key="list-recommend" onClick={this.handlerRecommend}
                                       data-index={rowIndex}>{!!item.isRecommend ? '取消推荐' : "推荐"}</a><Divider
                                    type={'vertical'}/>
                                    <a key="list-top" onClick={this.handlerTop}
                                       data-index={rowIndex}>{!!item.isTop ? '取消置顶' : "置顶"}</a><Divider
                                    type={'vertical'}/>
                                    <Popconfirm title={`确定${item.ban ? '解禁' : "封禁"}该作品吗？`}
                                                okText={`${item.ban ? '解禁' : "封禁"}`}
                                                cancelText={'取消'}
                                                onConfirm={this.banShot}
                                    >
                                        <a key="list-ban" onClick={() => {
                                            this.willBanShot = {...item, rowIndex}
                                        }}>{item.ban ? '解禁' : "封禁"}</a>
                                    </Popconfirm>

                                    <Popconfirm title={`确定删除该作品吗？`}
                                                okText={`删除`}
                                                okType={`danger`}
                                                cancelText={'取消'}
                                                onConfirm={this.deleteShot}
                                    >
                                        <a key="list-ban" onClick={() => {
                                            this.willDeleteShot = {...item, rowIndex}
                                        }}>{`删除`}</a>
                                    </Popconfirm>
                                </span>
                                }
                            />
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            let condition = this.state.condition;
                            condition.page = page;
                            this.setState({
                                condition
                            }, () => {
                                this.getShots()
                            })

                        },
                        pageSize: PerPage,
                        total: shotCount
                    }}
                    split={false}
                />


                <Modal
                    width={'80%'}
                    visible={showShotModal}
                    onOk={this.handlerShotModalOk}
                    onCancel={this.handlerShotModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-modal'}
                    centered={true}
                >
                    <div className={`rich-text`}
                         dangerouslySetInnerHTML={{__html: editShot && editShot.content ? editShot.content : ""}}/>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShotDash);
