import AppRequest from './AppRequest'

const urlSaveShotCategorySort = '/api_v1/admin-api/shot/category/sort/update';
//todo:分类列表（新接口） => '/api_v1/admin-api/shot/category/post';
const urlHandlerShotCategory = '/api_v1/admin-api/shot/category/post';
const urlHandlerShotRecommend = '/api_v1/admin-api/shot/isRecommend/update';
const urlHandlerShotTop = '/api_v1/admin-api/shot/isTop/update';
const urlGetShotDetail = '/api_v1/admin-api/shot/detail';
const urlGetShotList = '/api_v1/admin-api/shot/list';
//todo:分类列表（老接口） => '/api_v1/admin-api/shot/category/list';
const urlGetShotCategories = '/api_v1/admin-api/shot/category/list';
const urlHandlerShotBan = '/api_v1/admin-api/shot/ban/update';
const urlHandlerShotDelete = '/api_v1/admin-api/shot/delete';
class ShotRequest extends AppRequest {



    getShotList(condition){
        return this.get(urlGetShotList,{...condition})
    }
    getShotDetail(shotId){
        return this.get(urlGetShotDetail,{id:shotId})
    }

    handlerShotTop(shotId,isTop){
        return this.post(urlHandlerShotTop,{id:shotId,status:!!isTop})
    }
    handlerShotRecommend(shotId,isTop){
        return this.post(urlHandlerShotRecommend,{id:shotId,status:!!isTop})
    }
    handlerShotBan(shotId,isBan){
        return this.post(urlHandlerShotBan,{id:shotId,status:!!isBan})
    }

    handlerShotDelete(shotId,isBan){
        return this.delete(urlHandlerShotDelete,{id:shotId})
    }

    getShotCategories(){
        return this.get(urlGetShotCategories)
    }

    createShotCategory(info){
        return this.post(urlHandlerShotCategory,{...info})
    }
    updateShotCategory(info){
        return this.put(urlHandlerShotCategory,{...info})
    }

    deleteShotCategory(shotCategoryId){
        return this.delete(urlHandlerShotCategory,{id:shotCategoryId})
    }

    saveShotCategorySort(shotCategoryIds){
        return this.post(urlSaveShotCategorySort,{ids:shotCategoryIds})
    }


}

const  shotReq = new ShotRequest();
export default shotReq;

