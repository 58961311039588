import React from 'react';
import {parseTimelineDate} from '../../utils/dataformat'
import bulletinReq from "../../request/bulletinReq";
import './bulletinDash.scss'
import {connect} from 'react-redux'
import {Button, Input, List, message, Modal, DatePicker, Switch, Popconfirm} from "antd";
import BulletinListItem from "../../components/bulletinListItem/BulletinListItem";
import moment from "moment";
import dateformat from 'dateformat'
const PerPage = 30;
const {RangePicker} = DatePicker
class BulletinDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {
        list:[],
        showBulletinModal:false,
        editBulletin:{}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount(){
        this.getList();
    }

    getList(){
        bulletinReq.getList()
            .then(res =>{
                if(res && res.length){
                    for(let i of res){
                        if(i.startAt){
                            i.startAt = dateformat(new Date(i.startAt),'yyyy-mm-dd HH:MM')
                        }
                        if(i.endAt){
                            i.endAt = dateformat(new Date(i.endAt),'yyyy-mm-dd HH:MM')
                        }
                    }
                }
                this.setState({
                    list:res
                })
            })
            .catch(error =>{
                message.error(`获取公告列表失败：${error && error.message ? error.message :""}`)
            })
    }

    onSubmitBulletin = ()=>{
        let editBulletin = this.state.editBulletin || {};
        if(editBulletin.origin){
            if(Object.getOwnPropertyNames(editBulletin).length < 2){
                return   message.warn(`没有修改任何内容`)
            }
            if( editBulletin.hasOwnProperty(`description`) && !editBulletin.description){
                return message.warn(`请输入公告内容`)
            }
            if( editBulletin.hasOwnProperty(`startAt`) && !editBulletin.startAt){
                return message.warn(`必须指定公示开始时间`)
            }
            if(editBulletin.hasOwnProperty(`endAt`) && !editBulletin.endAt){
                return message.warn(`必须指定公示结束时间`)
            }
            let body = {...editBulletin};
            body.id = editBulletin.origin.id;
            delete body.origin;
            bulletinReq.updateBulletin(body)
                .then(res =>{
                    let list = this.state.list;
                    let origin = list[editBulletin.origin.rowIndex]
                    if(editBulletin.description){
                        origin.description = editBulletin.description
                    }
                    if(editBulletin.startAt){
                        origin.startAt = editBulletin.startAt
                    }
                    if(editBulletin.endAt){
                        origin.endAt = editBulletin.endAt
                    }
                    if(editBulletin.status){
                        origin.status = editBulletin.status
                    }
                    delete  origin.origin;
                    this.setState({
                        list,
                        showBulletinModal:false,
                        editBulletin:{}
                    })
                    message.success(`已更新公告`)
                })
                .catch(error =>{
                    message.error(`更新公告失败：${error && error.message ? error.message :""}`)
                })
        }else {
            if(!editBulletin.description){
                return message.warn(`请输入公告内容`)
            }
            if(!editBulletin.startAt){
                return message.warn(`必须指定公示开始时间`)
            }
            if(!editBulletin.endAt){
                return message.warn(`必须指定公示结束时间`)
            }
            bulletinReq.createBulletin(editBulletin)
                .then(res =>{
                    let list = this.state.list;
                    list.unshift(res);
                    this.setState({
                       list,
                       showBulletinModal:false,
                        editBulletin:{}
                    })
                    message.success(`已创建新公告`)
                })
                .catch(error =>{
                    message.error(`创建新公告失败：${error && error.message ? error.message :""}`)
                })
        }
    }

    dateRangeOnChange = (e)=>{
        let editBulletin = this.state.editBulletin || {};
        if(e && e.length == 2){
            editBulletin.startAt = e[0].format("YYYY-MM-DD hh:mm");
            editBulletin.endAt = e[1].format("YYYY-MM-DD hh:mm");
        }else {
            editBulletin.startAt = e[0]? e[0].format("YYYY-MM-DD hh:mm"):null;
            editBulletin.endAt = e[1]? e[1].format("YYYY-MM-DD hh:mm"):null;
        }

        this.setState({
            editBulletin
        })
    }

    onChangeStatus = (checked)=>{
        let editBulletin = this.state.editBulletin || {};
        editBulletin.status = checked ?  "published":"unpublished"
        this.setState({
            editBulletin
        })
    }

    editBulletinModalOnCancel = ()=>{
        this.setState({
            showBulletinModal:false,
            editBulletin:{}
        })
    }
    editBulletinModalOnOk = ()=>{

    }
    deletingBulletin = null;
    deleteBulletin = ()=>{
        bulletinReq.deleteBulletin(this.deletingBulletin.id)
            .then(res =>{
                let list = this.state.list;
                list.splice(this.deletingBulletin.rowIndex,1);
                this.setState({
                    list
                })
                this.deletingBulletin = null;
                message.success(`删除成功`)
            })
            .catch(error =>{
                message.error(`删除公告失败：${error && error.message ? error.message :""}`)
            })
    }

    changeBulletinStatus = e=>{
        let index = e.currentTarget.dataset.index;
        let list = this.state.list
        let b = list[index];
        bulletinReq.updateBulletinStatus(b.id,b.status != "published")
            .then( res =>{
                b.status = b.status === 'published' ? "unpublished":"published";
                this.setState({
                    list
                })
            })
            .catch(error =>{
                message.error(`操作失败：${error && error.message ? error.message :""}`)
            })

    }
    render() {
        let { list,showBulletinModal,editBulletin} = this.state;
        return (
            <div className='page-bulletin-board' >

                <h2>首页公告</h2>
                <div className={'header-control'}>
                    <Button onClick={()=>{
                        this.setState({
                            showBulletinModal: true
                        })
                    }}>创建新公告</Button>
                </div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={list || []}
                    renderItem={(item,index) => (
                        <List.Item
                            actions={[<a key="list-loadmore-edit" onClick={()=>{
                                item.rowIndex = index;
                                this.setState({
                                    showBulletinModal: true,
                                    editBulletin: {origin:item}
                                })
                            }}>编辑</a>, <a key="list-loadmore-more" data-index={index}  onClick={this.changeBulletinStatus}>{item.status === 'published' ? "隐藏":"显示"}</a>,<Popconfirm     placement="leftBottom" title={`确定删除此公告？`} onConfirm={this.deleteBulletin}><a key="list-loadmore-more" onClick={()=>{
                                item.rowIndex = index;
                                this.deletingBulletin = item;
                            }}>删除</a></Popconfirm>]}
                        >
                            <BulletinListItem  data={item}/>
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {

                        },
                        pageSize: 20,
                    }}
                />

                <Modal

                    visible={showBulletinModal}
                    onOk={this.editBulletinModalOnOk}
                    onCancel={this.editBulletinModalOnCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-bulletin'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editBulletin || !editBulletin.origin ? "新公告" : "编辑公告"}</h2>

                        <div className={`item`} >
                    <div className={`title`}>公告内容：</div>
                    <Input.TextArea className={'textArea'}
                                    value={editBulletin && editBulletin.hasOwnProperty('description') ? editBulletin.description : editBulletin && editBulletin.origin ? editBulletin.origin.description : ""}
                                    placeholder={"请输入公告内容"} onChange={(e) => {
                        let editBulletin = this.state.editBulletin || {};
                        editBulletin.description = e.target.value;
                        this.setState({
                            editBulletin
                        })
                    }}/>
                </div>

                    <div className={`item`} >
                        <div className={`title`}>出示时间：</div>

                        <RangePicker value={editBulletin.hasOwnProperty(`startAt`) && editBulletin.hasOwnProperty(`endAt`) ? [editBulletin.startAt ? moment(editBulletin.startAt,'YYYY-MM-DD hh:mm'):null,editBulletin.endAt ? moment(editBulletin.endAt,'YYYY-MM-DD hh:mm'):null]:[editBulletin.origin && editBulletin.origin.startAt ? moment(editBulletin.origin.startAt,'YYYY-MM-DD hh:mm'):null,editBulletin.origin && editBulletin.origin.endAt ? moment(editBulletin.origin.endAt,'YYYY-MM-DD hh:mm') :null]}  onChange={this.dateRangeOnChange} className={`time-range-picker`}  showTime={true}/>
                    </div>

                    <div className={`item`} >
                        <div className={`title`}>显示/隐藏：</div>
                        <Switch checked={editBulletin.hasOwnProperty(`status`) ? editBulletin.status === 'published' : editBulletin.origin ? editBulletin.origin.status === 'published':false} onChange={this.onChangeStatus}  />
                    </div>





                    <div className={'footer'}>

                        <Button className={'submit-btn'} type={'primary'} onClick={this.onSubmitBulletin}>提交</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps)=>{
    return {

    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BulletinDash);
