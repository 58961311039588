import systemTypes from '../actionTypes/systemTypes'
import {combineReducers} from "redux";


const {SYSTEM_SET_WINDOW_SIZE, SYSTEM_FLAG_FOLD_MENUS} = systemTypes;

function systemSetWindowSizeReducer(state = {width: 0, height: 0}, action) {
    switch (action.type) {
        case SYSTEM_SET_WINDOW_SIZE:
            return action.payload;
        default:
            return state;
    }
}

function systemFlagFoldMenus(state = false, action) {
    switch (action.type) {
        case SYSTEM_FLAG_FOLD_MENUS:
            return action.payload;
        default:
            return state;
    }
}


const indexReducer = combineReducers({
    windowSize: systemSetWindowSizeReducer,
    foldMenus :systemFlagFoldMenus
})

export default indexReducer