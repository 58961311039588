import React from 'react';
import {Avatar, Divider, Tag} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './shotListItem.scss'
import IconFont from "../iconfont/icon-font";
import dateformat from 'dateformat'

const PerPage = 30;


class ShotListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        data: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            key: nextProps.key,
            data: nextProps.data
        };
    }


    /* life circle method */

    componentDidMount() {

    }


    render() {
        let {key, data} = this.state;


        return (
            <div className='comp-shot-list-item' key={this.props.rowKey}>
                <img className={'shot-preview'} onClick={this.props.onClickPreview} src={data.preview}/>
                <div className={'flex-m'}>
                    <div className={'flex-m-t'}>
                        <div className={'title'}>{data.title}</div>
                        {/*<p >{data.subtitle}</p>*/}
                    </div>
                    <div className={`controls`}>
                        <Tag color={'gold'} hidden={!data.isTop}>置顶</Tag>
                        <Tag color={'orange'}  hidden={!data.isRecommend}>推荐</Tag>
                    </div>
                    <div className={'flex-m-b'}>
                        <div className={'users'}>
                            <div className={'user'}>
                                <Avatar src={data && data.user ? data.user.avatar : ""} className={'avatar'}/>
                                <label>{data && data.user ? data.user.nickname || data.username : ""}</label>
                            </div>
                            <div className={`create-at`}>{data && data.createdAt ? dateformat(new Date(data.createdAt),'yyyy-mm-dd HH:MM') :""}</div>
                            {/*<div className={`create-at`}>{data && data.createdAt ? parseTimelineDate(data.createdAt) :""}</div>*/}
                        </div>

                        <div className={'flex-m-b-r'}>

                            <div className={'statistic'}>
                                <span><IconFont className={'icon'} type={'iconviews-'}/>{data.viewNum || 0}</span>
                                <Divider type="vertical"/>
                                <span><IconFont className={'icon'} type={'iconcomments-'}/>{data.commentNum || 0}</span>
                                <Divider type="vertical"/>
                                <span><IconFont className={'icon'} type={'iconshoucang'}/>{data.collectNum || 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex-r'}>
                    <div className={'status'}>
                        <IconFont type={'iconjin1'} hidden={!data.ban} style={{fontSize: 30,color:'red'}}/>
                        <IconFont type={'iconzhengchang1'} hidden={ data.ban || data.status !== 'published'} style={{fontSize: 30}}/>
                        <Tag color={'gray'}  hidden={data.ban || data.status !== 'unpublished'}>
                            已下架
                        </Tag>
                    </div>

                </div>
                <div className={'actions'}>
                    {this.props.actions || {}}
                </div>

            </div>
        )
    }
}


export default (ShotListItem);
