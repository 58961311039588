import React from 'react';
// import { Result, Button, Icon, Spin ,PageHeader} from 'antd'
// import {parseTimelineDate} from '../../utils/dataformat'
import './noFound.scss'
// import Menus from "../../components/menus/Menus";
import {connect} from 'react-redux'
// import storage from "../../storage/storage";
// const PerPage = 30;


class NoFound extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount(){

    }



    render() {
        let {} = this.state

        return (
            <div className='page-no-found' >
                <img src={'/icons/404.svg'} alt={''}/>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps)=>{

    return {
        windowSize:state.system.windowSize,
    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NoFound);
