import AppRequest from './AppRequest'

const urlGetAudioSeriesList =   '/api_v1/admin-api/audio/series/list';
const urlAudioSeriesPost =      '/api_v1/admin-api/audio/series/post';
const urlGetSeriesDetail =      "/api_v1/admin-api/audio/series/detail";
const urlChangeSeriesPublish =  '/api_v1/admin-api/admin/series/changeStatus';

const urlSaveAudioSort =        "/api_v1/admin-api/audio/sort";
const urlAudioPost =            '/api_v1/admin-api/audio/post';
const urlGetAudioDetail =       "/api_v1/admin-api/audio/detail";
const urlChangeAudioStatus =    '/api_v1/admin-api/audio/changeStatus';
class AudioReq extends AppRequest {

    getAudioSeriesList(){
        return this.get(urlGetAudioSeriesList,{})
    }
    createAudioSeries(info){
        return this.post(urlAudioSeriesPost,info)
    }
    updateAudioSeries(info){
        return this.put(urlAudioSeriesPost,info)
    }

    deleteAudioSeries(info){
        return this.delete(urlAudioSeriesPost,info)
    }

    getSeriesDetail(seriesId){
        return this.get(urlGetSeriesDetail,{id:seriesId})
    }

    changeSeriesPublish(isPublish,seriesId){
        return this.post(urlChangeSeriesPublish,{id:seriesId,status:isPublish})
    }
    saveAudioSort(seriesId,audioIds){
        return this.post(urlSaveAudioSort,{audioSeriesId:seriesId,audioIds})
    }

    getAudioDetail(audioId){
        return this.get(urlGetAudioDetail,{id:audioId})
    }

    createAudio(info){
        return this.post(urlAudioPost,info)
    }
    updateAudio(info){
        return this.put(urlAudioPost,info)
    }
    deleteAudio(audioId) {
        return super.delete(urlAudioPost, {id:audioId});
    }

    changeAudioStatus(audioId,isPublish){
        return this.post(urlChangeAudioStatus,{id:audioId,status:isPublish})
    }
}

const  audioReq = new AudioReq();
export default audioReq;

