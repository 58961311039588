
import systemTypes  from '../actionTypes/systemTypes'
const  {  SYSTEM_SET_WINDOW_SIZE,SYSTEM_FLAG_FOLD_MENUS } = systemTypes;
function systemSetWindowSize(size) {
    return { type: SYSTEM_SET_WINDOW_SIZE, payload:size }
}

function systemFlogFoldMenus(hasFold) {
    return { type: SYSTEM_FLAG_FOLD_MENUS, payload:hasFold }
}


export  default {
    systemSetWindowSize
}