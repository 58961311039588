import React from 'react';
import {Modal, Input, Button, Popconfirm, message, Spin} from 'antd'
import './footerLinks.scss'
import linkReq from "../../request/linkReq";

class FooterLinks extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    dragTimer = null
    state = {
        groups: [],
        showEditModal: false,
        editLink: null,
        editGroup: null,
        loadingGroupsData: false,
        showEditGroupModal: false,
        appIconUploading: false,
        apps: [],
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.addGlobalListener();
        this.getAllGroups();
    }

    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.links[e.target.dataset.index];
        this.setState({
            editLink: {...item, origin: item},
            showEditModal: true
        })
    };


    onDragEnd = (e) => {

    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editLink: null,
            showEditModal: false
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        linkReq.getAllFooterNavGroups()
            .then(resGroups => {
                this.setState({
                    loadingGroupsData: false
                })
                this.setState({
                    groups: resGroups
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };
    handlerEditGroupModalOk = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };

    submitLink = () => {
        let {editLink} = this.state;
        if (editLink.origin) {
            if(editLink.hasOwnProperty(`name`) && !editLink.name){
                return  message.warn(`链接名称不能为空`)
            }

            if(!editLink.name && !editLink.url ){
                return  message.warn(`没有修改的任何`)
            }
            let body = {...editLink};
            body.id = editLink.origin.id;
            delete  body.origin;
            delete  body.group;
            linkReq.updateLink(body)
                .then(link => {
                    let groups = this.state.groups;
                    for (let index = 0; index < groups.length; index++) {
                        let group = groups[index];
                        if (group.categories && group.categories.length) {
                            for (let i = 0; i < group.categories.length; i++) {
                                let link = group.categories[i];
                                if (link.id === editLink.origin.id) {
                                    if(  editLink.name ){
                                        link.name = editLink.name;
                                    }
                                    if(editLink.url) {
                                        link.url = editLink.url;
                                    }

                                    break;
                                }
                            }
                        }
                    }
                    this.setState({
                        groups,
                        showEditModal: false,
                        editLink: {}
                    })
                    message.success(`已更新链接信息`)
                })
                .catch(error => {
                    if (error && error.code === 6603) {
                        return message.error("已存在同名链接，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            if(!editLink.name  ){
                return  message.warn(`请填写链接名称`)
            }

            if(!editLink.url  ){
                return  message.warn(`请填写链接地址`)
            }
            linkReq.createNewLinkAndAddToGroup({name: editLink.name,groupId :editLink.group.id,url:editLink.url})
                .then(link => {
                    let groups = this.state.groups;
                    let groupIndex = groups.findIndex(item => item.id === editLink.group.id);
                    let group = groups[groupIndex];
                    group.categories = group.categories || [];
                    group.categories.push(link);
                    this.setState({
                        groups,
                        showEditModal: false,
                        editLink: {}
                    })
                    message.success(`已添加新链接到分组`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名链接，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    submitGroup = () => {
        const {editGroup} = this.state;
        if (editGroup.origin) {
            linkReq.updateFooterNavGroup({id:editGroup.origin.id, name:editGroup.name})
                .then(res => {
                    let groups = this.state.groups;
                    let index = groups.findIndex(item => item.id === editGroup.origin.id);
                    let group = groups[index];
                    group.name = editGroup.name;
                    this.setState({
                        groups,
                        showEditGroupModal: false,
                        editGroup: {}
                    })
                    message.success(`分组信息已更新`)
                })
                .catch(error => {
                    if (error && error.code === 7204) {
                        return message.error(error.message)
                    }
                    return message.error(`发生错误，请重试`)
                })
        } else {
            if (!editGroup.name) {
                return message.warning(`请填写分组名称，不得重名`)
            } else {
                linkReq.createFooterNavGroup({name:editGroup.name})
                    .then(newGroup => {
                        let groups = this.state.groups;
                        groups.push(newGroup);
                        this.setState({
                            groups,
                            showEditGroupModal: false,
                            editGroup: {}
                        })
                        message.success(`已成功创建新分组`)
                    })
                    .catch(error => {
                        if (error && error.code === 6603) {
                            return message.error("已存在同名分组，请使用其他名称")
                        }
                        return message.error(`发生错误，请重试`)
                    })
            }
        }
    };

    willDeleteLink = null
    deleteLink = () => {
        linkReq.deleteLink(this.willDeleteLink.id)
            .then(res => {
                let groups = this.state.groups;
                for (let index = 0; index < groups.length; index++) {
                    let group = groups[index];
                    if (group.categories && group.categories.length) {
                        for (let i = 0; i < group.categories.length; i++) {
                            let link = group.categories[i];
                            if (link.id === this.willDeleteLink.id) {
                                group.categories.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
                this.setState({
                    groups
                })
                this.willDeleteLink = null;
                message.success(`链接已删除，并从分组中移除`)
            })
            .catch(error => {
                console.log(`error`,error)
                this.willDeleteLink = null;
                if (error.code === 7209) {
                    return message.error(error.message)
                }
                message.error(`链接删除失败`)
            })
    };

    willDeleteGroup = null
    deleteGroup = () => {
        linkReq.deleteFooterNavGroup(this.willDeleteGroup.id)
            .then(res => {
                let groups = this.state.groups;
                let index = groups.findIndex(item => item.id === this.willDeleteGroup.id);
                groups.splice(index, 1);
                this.willDeleteGroup = null;
                this.setState({
                    groups
                })
                message.success(`分组已删除`)
            })
            .catch(error => {
                this.willDeleteGroup = null;
                message.error(`分组删除失败`)
            })
    }

    draggingType = "";

    setSaveTimer = () => {
        this.clearSaveTimer()
        this.dragTimer = setTimeout(() => {
            this.saveDragSortResult()
        }, 2000)
    };
    clearSaveTimer = () => {
        if (this.dragTimer) {
            clearTimeout(this.dragTimer);
        }
    };
    saveDragSortResult = () => {
        let newData = [];
        for (let g of this.state.groups) {
            let group = {
                id: g.id,
                categories: []
            }
            if (g.categories && g.categories.length) {
                for (let c of g.categories) {
                    group.categories.push({
                        id: c.id
                    })
                }
            }
            newData.push(group);
        }
        linkReq.updateFooterNavGroupSort(newData)
            .then(res => {
                message.success(`保存排序成功`);
            })
            .catch(error => {
                if (error.message) {
                    return message.error(error.message);
                }
                message.error(`保存排序失败`);
            })
    };

    render() {

        let {showEditModal, editLink, groups, loadingGroupsData, showEditGroupModal, editGroup} = this.state;
        let groupList = groups.map((group, index) => {
            let childList = [];
            if (group.categories && group.categories.length) {
                childList = group.categories.map((child, idx) => {
                    return <div className={'child-item'} key={idx} draggable={true}
                                onDragStart={(e) => {
                                    this.draggingType = 'child';
                                    this.setState({
                                        draggingLink: child,
                                    });
                                    this.clearSaveTimer();

                                }}
                                onDragEnter={(e) => {
                                    console.log('chidl onDragEnter', group.name, child.name)

                                    if (this.draggingType !== 'child') {
                                        return
                                    }
                                    let draggingLink = this.state.draggingLink;
                                    if (child.id !== draggingLink.id) {
                                        // 获取拖拽链接所在分组，
                                        let oldDragIndexPathGroup = null;
                                        let oldDragIndexPathLink = null;

                                        let oldEnterIndexPathGroup = null;
                                        let oldEnterIndexPathLink = null;

                                        for (let index = 0; index < this.state.groups.length; index++) {
                                            let g = this.state.groups[index];
                                            if (g.categories && g.categories.length) {
                                                for (let j = 0; j < g.categories.length; j++) {
                                                    let c = g.categories[j];
                                                    if (c.id === draggingLink.id) {
                                                        oldDragIndexPathGroup = index
                                                        oldDragIndexPathLink = j;
                                                    }
                                                    if (c.id === child.id) {
                                                        oldEnterIndexPathGroup = index;
                                                        oldEnterIndexPathLink = j;
                                                    }

                                                }
                                            }
                                        }

                                        let groups = this.state.groups;
                                        let oldGroup = groups[oldDragIndexPathGroup];
                                        oldGroup.categories = oldGroup.categories.filter(item => item.id !== draggingLink.id);
                                        groups[oldDragIndexPathGroup] = oldGroup;
                                        let newGroup = groups[oldEnterIndexPathGroup];
                                        newGroup.categories.splice(oldEnterIndexPathLink, 0, draggingLink);
                                        groups[oldEnterIndexPathGroup] = newGroup;
                                        this.setState({
                                            groups,
                                            isConfigDirty: true,
                                        });
                                    }


                                }}
                                onDragEnd={(e) => {
                                    console.log(`onDragEnd`)
                                    this.setState({
                                        draggingLink: null,

                                    })
                                    this.draggingType = null
                                }}
                                onDrop={() => {
                                    this.setState({
                                        draggingLink: null,

                                    })
                                    this.draggingType = null
                                    this.setSaveTimer()
                                }}
                    >
                        <a href={child.url || ""}  target={"_blank"} style={{color:"rgba(0, 0, 0, 0.65)"}}><div>{child.name}</div></a>
                        <div className={'actions'}>
                            <a onClick={() => {
                                let editLink = this.state.editLink || {};
                                editLink.group = group;
                                editLink.origin = child;
                                this.setState({
                                    showEditModal: true,
                                    editLink
                                })
                            }}>编辑</a>
                            <Popconfirm title={`你确定要删除该链接吗？`} onCancel={() => {
                                this.willDeleteLink = null;
                            }} onConfirm={this.deleteLink}>
                                <a onClick={() => {
                                    this.willDeleteLink = child;

                                }}>删除</a>
                            </Popconfirm>

                        </div>
                    </div>
                })

            }
            return <div className={'group-item'} draggable={true} key={index}
                        onDragStart={() => {
                            if (this.draggingType) {
                                return
                            }
                            console.log('group onDragStart')
                            this.draggingType = 'group';
                            this.setState({
                                draggingGroup: group,
                            });
                            this.clearSaveTimer()

                        }}
                        onDragEnter={() => {
                            console.log('group onDragEnter');
                            if (this.draggingType === 'child' && (!group.categories || !group.categories.length)) {
                                // return;
                                let draggingLink = this.state.draggingLink;
                                let oldDragIndexPathGroup = null;
                                let oldDragIndexPathLink = null;


                                for (let m = 0; m < this.state.groups.length; m++) {
                                    let g = this.state.groups[m];
                                    if (g.categories && g.categories.length) {
                                        for (let j = 0; j < g.categories.length; j++) {
                                            let c = g.categories[j];
                                            if (c.id === draggingLink.id) {
                                                oldDragIndexPathGroup = m
                                                oldDragIndexPathLink = j;
                                            }

                                        }
                                    }
                                }

                                let groups = this.state.groups;
                                let oldGroup = groups[oldDragIndexPathGroup];
                                oldGroup.categories = oldGroup.categories.filter(item => item.id !== draggingLink.id);
                                groups[oldDragIndexPathGroup] = oldGroup;
                                group.categories = [this.state.draggingLink];
                                groups[index] = group;
                                this.setState({
                                    groups
                                })
                            }
                            if (this.draggingType !== 'group') {
                                return
                            }

                            let draggingGroup = this.state.draggingGroup;
                            if (group.id !== this.state.draggingGroup.id) {
                                const oldDragIndex = this.state.groups.findIndex(item => item.id === draggingGroup.id);
                                const oldEnterIndex = this.state.groups.findIndex(item => item.id === group.id);
                                if (oldDragIndex > oldEnterIndex) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id);
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                } else {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id) + 1;
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                }
                                this.setState({
                                    isConfigDirty: true,
                                });
                            }
                        }}
                        onDragLeave={() => {

                            if (this.draggingType !== 'group') {
                                return
                            }
                            console.log('group onDragLeave')
                            if (group.id !== this.state.draggingGroup.id) {
                                if (this.state.lock && group.id === this.state.groups[this.state.groups.length - 1]) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    newDataArray.push(this.state.draggingGroup);
                                    this.setState({
                                        lock: false,
                                    }, () => {
                                        this.setState({
                                            groups: newDataArray,
                                        });
                                    });
                                } else {
                                    this.setState({
                                        lock: true,
                                    });
                                }
                            }
                        }}
                        onDragEnd={() => {
                            console.log(`onDragEnd`)
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null

                        }}
                        onDrop={() => {
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null
                            this.setSaveTimer()
                        }}
            >
                <div className={'header'}>
                    <h3>{group.name}</h3>
                    <div className={'actions'}>
                        <a onClick={() => {
                            let editGroup = this.state.editGroup || {};
                            editGroup.origin = group;
                            this.setState({
                                showEditGroupModal: true,
                                editGroup
                            })
                        }}>编辑分组</a>
                        <a onClick={() => {
                            let editLink = this.state.editLink || {};
                            editLink.group = group;
                            this.setState({
                                showEditModal: true,
                                editLink
                            })
                        }}>添加链接</a>
                        <Popconfirm title={`你确定要删除该分组吗？`} onCancel={() => {
                            this.willDeleteGroup = null;
                        }} onConfirm={this.deleteGroup}>
                            <a hidden={group.links && group.links.length} onClick={() => {
                                this.willDeleteGroup = group;
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                </div>

                <div className={'child-list'}>
                    {childList}
                </div>
            </div>
        });

        return (
            <div className='page-footer-links'>

                <div className={'list-header'}>
                    <h2>底部导航</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editGroup: {},
                                showEditGroupModal: true
                            })
                        }}>+新分组</Button>
                        {/*<Button type={'primary'}>保存</Button>*/}
                    </div>
                </div>

                <div className={'list'}>
                    <Spin spinning={loadingGroupsData} className={`group-loading`} delay={200}/>
                    {groupList}
                </div>

                {/*编辑链接模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-link-link'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editLink || !editLink.origin ? "添加链接" : "编辑链接"}</h2>
                    <Input className={'input'}
                           value={editLink && editLink.hasOwnProperty('name') ? editLink.name : editLink && editLink.origin ? editLink.origin.name : ""}
                           placeholder={"链接名称"} onChange={(e) => {
                        let editLink = this.state.editLink;
                        editLink.name = e.target.value;
                        this.setState({
                            editLink
                        })
                    }}/>

                    <Input className={'input'}
                           value={editLink && editLink.hasOwnProperty('url') ? editLink.url : editLink && editLink.origin ? editLink.origin.url : ""}
                           placeholder={"链接地址"} onChange={(e) => {
                        let editLink = this.state.editLink;
                        editLink.url = e.target.value;
                        this.setState({
                            editLink
                        })
                    }}/>
                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editLink && editLink.group ? editLink.group.name : ""}`}</span>
                    </p>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitLink}>提交</Button>
                    </div>

                </Modal>
                {/*    编辑分组*/}
                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-link-link'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('name') ? editGroup.name : editGroup && editGroup.origin ? editGroup.origin.name : ""}
                           placeholder={"分组名称"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.name = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup}
                                disabled={!editGroup || !editGroup.name || !editGroup.name.length || (editGroup && editGroup.origin && editGroup.name === editGroup.origin.name)}>提交</Button>
                    </div>

                </Modal>

              
            </div>
        )
    }
}


export default FooterLinks;
