import AppRequest from './AppRequest'


const urlGetList = '/api_v1/admin-api/bulletin/post';
const urlCreateBulletin = '/api_v1/admin-api/bulletin/post';
const urlUpdateBulletin = '/api_v1/admin-api/bulletin/post';
const urlDeleteBulletin = "/api_v1/admin-api/bulletin/post";
const urlUpdateBulletinStatus = "/api_v1/admin-api/bulletin/status/update";


class BulletinReq extends AppRequest {

    getList(){
        return this.get(urlGetList,{});
    }


    createBulletin(info){
        return this.post(urlCreateBulletin,{...info});
    }

    updateBulletin(info){
        return this.put(urlUpdateBulletin,{...info});
    }

    deleteBulletin(bulletinId) {
        return this.delete(urlDeleteBulletin,{id:bulletinId});
    }

    updateBulletinStatus(bulletinId,isPublish){
        return this.post(urlUpdateBulletinStatus,{id:bulletinId,status:!!isPublish})
    }


}

const  bulletinReq = new BulletinReq();
export default bulletinReq;

