import AppRequest from './AppRequest'

const urlGetUrlList = '/api_v1/development/url/list'
const urlCreateGroup = '/api_v1/development/urlGroup';
const urlUpdateGroup = "/api_v1/development/urlGroup"
const urlDeleteGroup = "/api_v1/development/urlGroup"

const urlCreateUrl = '/api_v1/development/url';
const urlUpdateUrl = "/api_v1/development/url"
const urlDeleteUrl = "/api_v1/development/url"
const urlGetAllAPI = '/api_v1/admin-api/account/roles/api/all-api'
class DevelopRequest extends AppRequest {

    getUrlList(){
        return this.get(urlGetUrlList)
    }

    getAllApi(){
        return this.get(urlGetAllAPI)
    }
    createGroup(info){
        return this.post(urlCreateGroup,info)
    }
    updateGroup(info){
        return this.put(urlUpdateGroup,info)
    }
    deleteGroup(id){
        return this.delete(urlDeleteGroup,{id})
    }

    createUrl(info){
        return this.post(urlCreateUrl,info)
    }
    updateUrl(info){
        return this.put(urlUpdateUrl,info)
    }
    deleteUrl(id){
        return this.delete(urlDeleteUrl,{id})
    }

    getWeixinShareDebugStatus(){
        return this.get("/api_v1/development/check/share/debug")
    }
    changeWeixinShareDebugStatus(isDebug){
        return this.post('/api_v1/admin-api/debug/weixin-share-debug-switch',{debug:isDebug})
    }
    changeQiemanCheckingModeStatus(isDebug){
        return this.post("/api_v1/admin-api/debug/qie-man-mini-checking-switch",{debug:isDebug})
    }
    getQiemanCheckingModeStatus(isDebug){
        return this.get("/api_v1/development/check/qiman/debug")
    }
}
const  developRequest = new DevelopRequest();
export default developRequest;