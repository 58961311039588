import React from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom'
import accountRequest from './request/accountReq'
import "./app.scss"
import actions from './redux/actions'

import {connect} from 'react-redux'

import storage from "./storage/storage";
import Index from "./pages/index/Index.jsx";
import DevLoginRegisterModal from "./pages/devLoginRegister/DevLoginRegisterModal";
import config from './config.json'
import Logout from "./pages/logout/Logout";
import {message} from "antd";
import menuData from "./pages/index/indexMenu";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: storage.getStorage(storage.keys.isLogin)
        }

    }

    componentDidMount() {

        this.observeWindowOnResize();
        this.checkLogin();
        window.appLogin = function () {
            window.location.href = config.environment === 'development' ? config.devLoginURL : config.loginURL;

        }

    }

    observeWindowOnResize() {
        const h = window.innerHeight;
        const w = window.innerWidth;
        this.props.recordWindowSize({
            width: w,
            height: h
        })
        window.onresize = () => {
            const h = window.innerHeight;
            const w = window.innerWidth;
            this.props.recordWindowSize({
                width: w,
                height: h
            })
        }
    }


    checkLogin() {

        if (config.environment === 'development') {
            if (window.location.pathname !== config.devLoginURL) {
                this.getAuthInfo()
            } else {
                this.hideLoading();
            }
        } else {
            this.getAuthInfo()
        }
    }

    getAuthInfo = () => {
        accountRequest.getAuthUserInfo()
            .then((info) => {


                this.props.addLoginUserInfo(info);
                this.props.flagLogin(true);
                storage.setStorage(storage.keys.isLogin, true);
                this.hideLoading();

                if (info && info.roles && info.roles.admin) {
                    this.props.setUserMenus(menuData);
                } else {
                    accountRequest.getUserMenu()
                        .then(res => {
                            let menuKeys = res.map(item => item.menuId)

                            let userMenu = [];
                            for (let g of menuData) {
                                let copyGroup = {...g}
                                let copyChild = [];
                                if (g.child && g.child.length) {
                                    for (let index = 0; index < g.child.length; index++) {
                                        let c = g.child[index]
                                        if (menuKeys.indexOf(c.key) >= 0) {
                                            copyChild.push(c)
                                        }
                                    }
                                }
                                if (copyChild.length) {
                                    copyGroup.child = copyChild
                                    userMenu.push(copyGroup);
                                }
                            }

                            this.props.setUserMenus(userMenu);

                        })
                }
            })
            .catch((error) => {
              console.log(`error`,error)
                this.props.removeLoginUserInfo();
                this.props.flagLogin(false);
                storage.setStorage(storage.keys.isLogin, false);
                window.appLogin();

            })
    }


    hideLoading() {
        window.hideInitLoading();
    }

    render() {
        return (
            <BrowserRouter basename={"/"}>
                <Switch>
                    <Route path={'/logout'} component={Logout}/>
                    <Route path={'/dev-login'} component={DevLoginRegisterModal}/>
                    <Route path={'/'} component={Index}/>
                </Switch>
            </BrowserRouter>

        );

    }

}


const mapDispatchToProps = {
    flagLogin: actions.accountFlagLoginStatus,
    addLoginUserInfo: actions.accountAddLoginUserInfo,
    removeLoginUserInfo: actions.accountRemoveLoginUserInfo,
    recordWindowSize: actions.systemSetWindowSize,
    setUserMenus: actions.accountUserMenus
}

export default connect(null, {
    ...mapDispatchToProps
})(App);
