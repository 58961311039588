import accountTypes from '../actionTypes/accountTypes'
import {combineReducers} from "redux";



const {ACCOUNT_ADD_LOGIN_USER_INFO,ACCOUNT_REMOVE_LOGIN_USER_INFO,ACCOUNT_FLAG_LOGIN_STATUS,ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,ACCOUNT_USER_MENUS} = accountTypes;


function loginUserInfoReducer(state = {}, action) {
    switch (action.type) {
        case ACCOUNT_REMOVE_LOGIN_USER_INFO:
            return {};
        case ACCOUNT_ADD_LOGIN_USER_INFO:
            return {
                ...action.payload
            }
            default:
                return state;
    }
}

function userMenusReducer(state = [], action) {
    switch (action.type) {
        case ACCOUNT_USER_MENUS:
            return [...action.payload];

        default:
            return state;
    }
}

function loginStatusReducer(state = false, action) {
    switch (action.type) {
        case ACCOUNT_FLAG_LOGIN_STATUS:
            return action.payload;
        default:
            return state;
    }
}
function showLoginRegisterModalReducers(state = 'none',action) {
    switch (action.type) {
        case ACCOUNT_SHOW_LOGIN_REGISTER_MODAL:
            return action.payload;
        default:
            return state;
    }
}



const indexReducer = combineReducers({
    loginUserInfo: loginUserInfoReducer,
    isLogin: loginStatusReducer,
    showLoginRegisterModal:showLoginRegisterModalReducers,
    userMenus:userMenusReducer
})

export default indexReducer