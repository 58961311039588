import React from 'react';
import { Row, Col, Input, Tabs, Radio, Button, Breadcrumb, PageHeader, message, Popconfirm, Divider, Modal, Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import './bannerDash.scss'
import { connect } from 'react-redux'
import CourseListItem from "../../components/courseListItem/CourseListItem";
import courseReq from "../../request/courseReq";
import BannerListItem from "../../components/bannerListItem/bannerListItem";
import bannerReq from "../../request/bannerReq";
import storage from '../../storage/storage'

const { Search } = Input;
const { TabPane } = Tabs
const PerPage = 30;

class BannerDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
        let tabKey = storage.getStorage(storage.keys.latestBannerTabKey) || 'no-group';
        this.state.currentTab = tabKey;
    }

    state = {

        bannerGroups: [],
        editGroup: {},

        showEditGroupModal: false,
        noGroupBanners: [],
        currentTab: 'no-group'

    };



    /* life circle method */
    componentDidMount() {
        this.getBanners();
        this.getNoInGroupBanners();
        this.addGlobalListener();
    }
    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };


    getNoInGroupBanners() {
        bannerReq.getNoInGroupBanners()
            .then(res => {
                this.setState({
                    noGroupBanners: res
                })
            })
    }
    getBanners() {
        bannerReq.getBannerList()
            .then(bannerGroups => {
                this.setState({
                    bannerGroups,

                }, () => {

                })
            })
            .catch(error => {
                message.error(error && error.message ? error.message : "获取banner失败")
            })
    }


    menuOnclick = (item) => {
        let key = item.key;
        console.log(`menuOnclick`, item, key)
        switch (key) {
            case "update": {
                if (this.state.currentTab === 'no-group') {
                    return
                }
                let index = this.state.bannerGroups.findIndex(item => item.key === this.state.currentTab);
                if (index < 0) {
                    return;
                }
                let group = this.state.bannerGroups[index];

                this.setState({
                    showEditGroupModal: true,
                    editGroup: {
                        origin: {
                            ...group,
                            rowIndex: index
                        }
                    }
                })
                break;
            }
            case "create": {
                this.setState({
                    showEditGroupModal: true,
                    editGroup: {

                    }
                })
                break;
            }
            case "delete": {
                if (this.state.currentTab === 'no-group') {
                    return
                }
                let index = this.state.bannerGroups.findIndex(item => item.key === this.state.currentTab);
                if (index < 0) {
                    return;
                }
                let group = this.state.bannerGroups[index];
                this.willDeleteGroup = {
                    ...group, rowIndex: index
                }
                Modal.confirm({
                    title: "确定要删除该分组吗？",
                    onOk: () => {
                        bannerReq.deleteBannerGroup(group.id)
                            .then(res => {
                                let bannerGroups = this.state.bannerGroups;
                                bannerGroups.splice(this.willDeleteGroup.rowIndex, 1);
                                this.setState({
                                    bannerGroups
                                })
                                message.success('分组已删除')
                            })
                            .catch(error => {
                                message.error(`分组删除失败：${error ? error.message : ""}`)
                            })
                    }
                })

                break;
            }
            default: break;
        }


    }
    onClickTab = value => {
        this.setState({
            currentTab: value
        })
        storage.setStorage(storage.keys.latestBannerTabKey, value);
    }

    handlerEditModalOk = () => {

    }
    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: null,
            showEditGroupModal: false
        })
    }

    submitGroup = () => {
        let { editGroup } = this.state;
        if (editGroup.origin) {
            if (!editGroup.name && !editGroup.key) {
                return
            }
            if (editGroup.hasOwnProperty(`name`) && !editGroup.name) {
                return message.warn('请填写分组名称')
            }
            if (editGroup.hasOwnProperty(`key`) && !editGroup.key) {
                return message.warn('请填写分组Key')
            }

            bannerReq.updateBannerGroupInfo({
                name: editGroup.name,
                key: editGroup.key,
                id: editGroup.origin.id
            })
                .then(res => {
                    let bannerGroups = this.state.bannerGroups;
                    let group = bannerGroups[editGroup.origin.rowIndex];
                    group.name = editGroup.name || group.name;
                    group.key = editGroup.key || group.key;
                    this.setState({
                        bannerGroups,
                        editGroup: {},
                        showEditGroupModal: false
                    })
                    message.success('分组已更新成功')
                })
                .catch(error => {
                    message.error(`分组更新失败：${error ? error.message : ""}`)
                })
        } else {

            if (!editGroup.name) {
                return message.warn('请填写分组名称')
            }
            if (!editGroup.key) {
                return message.warn('请填写分组Key')
            }
            bannerReq.createBannerGroup({
                name: editGroup.name,
                key: editGroup.key
            })
                .then(res => {
                    let bannerGroups = this.state.bannerGroups;
                    bannerGroups.push(res);
                    this.setState({
                        bannerGroups,
                        editGroup: {},
                        showEditGroupModal: false
                    })
                    message.success('分组创建成功')
                })
                .catch(error => {
                    message.error(`分组创建失败：${error ? error.message : ""}`)
                })

        }
    }

    clearBannerDraggingSaveTimer = () => {
        if (this.sortTimer) {
            clearTimeout(this.sortTimer);
        }
    }
    sortTimer = null
    startSaveSortTimer = () => {
        let draggingInGroupIndex = this.state.draggingInGroupIndex + "";
        this.sortTimer = setTimeout(() => {
            let group = this.state.bannerGroups[draggingInGroupIndex];
            let bannerIds = group.bannerInfo.map(item => item.id);
            bannerReq.saveSortedBannersOfGroup(group.id, bannerIds)
                .then(res => {
                    message.success(`已保存排序`)
                })
                .catch(error => {
                    message.error(`保存排序失败：${error ? error.message : ""}`)
                })
        }, 1000)
    }


    render() {
        let { loadingBanner, bannerGroups, showEditGroupModal, editGroup, noGroupBanners, currentTab } = this.state;

        bannerGroups = bannerGroups || [];
        let groups = bannerGroups.map((g, m) => {

            let banners = g.bannerInfo || [];
            banners = banners.map((b, n) => {
                return <Col key={n} span={6}>
                    <Link to={'/banner/' + b.id}>


                        <BannerListItem
                            rowKey={n}
                            data={b}
                            draggable={true}
                            onDragStart={() => {
                                this.setState({
                                    draggingBanner: b,
                                    draggingInGroupIndex: m
                                });
                                this.clearBannerDraggingSaveTimer()

                            }}
                            onDragEnter={() => {

                                let draggingBanner = this.state.draggingBanner;
                                if (b.id !== this.state.draggingBanner.id) {
                                    let bannerGroups = this.state.bannerGroups;
                                    let banners = bannerGroups[this.state.draggingInGroupIndex].bannerInfo;
                                    const oldDragIndex = banners.findIndex(item => item.id === draggingBanner.id);
                                    const oldEnterIndex = banners.findIndex(it => it.id === b.id);
                                    if (oldDragIndex > oldEnterIndex) {
                                        const newDataArray = banners.filter(item => item.id !== this.state.draggingBanner.id);
                                        const insertIndex = newDataArray.findIndex(it => it.id === b.id);

                                        newDataArray.splice(insertIndex, 0, this.state.draggingBanner);
                                        bannerGroups[this.state.draggingInGroupIndex].bannerInfo = newDataArray;
                                        this.setState({ bannerGroups });
                                    } else {
                                        const newDataArray = banners.filter(item => item.id !== this.state.draggingBanner.id);
                                        const insertIndex = newDataArray.findIndex(it => it.id === b.id) + 1;
                                        newDataArray.splice(insertIndex, 0, this.state.draggingBanner);
                                        bannerGroups[this.state.draggingInGroupIndex].bannerInfo = newDataArray;
                                        this.setState({ bannerGroups });
                                    }
                                    this.setState({
                                        isConfigDirty: true,
                                    });
                                }
                            }}
                            onDragLeave={() => {

                                if (b.id !== this.state.draggingBanner.id) {
                                    let bannerGroups = this.state.bannerGroups;
                                    let banners = bannerGroups[this.state.draggingInGroupIndex].bannerInfo;
                                    if (this.state.appLock && b.id === banners[banners.length - 1]) {
                                        const newDataArray = banners.filter(item => item.id !== this.state.draggingBanner.id);
                                        newDataArray.push(this.state.draggingBanner);
                                        this.setState({
                                            appLock: false,
                                        }, () => {
                                            bannerGroups[this.state.draggingInGroupIndex].bannerInfo = newDataArray;
                                            this.setState({ bannerGroups });
                                        });
                                    } else {
                                        this.setState({
                                            appLock: true,
                                        });
                                    }
                                }
                            }}
                            onDragEnd={() => {
                                this.setState({
                                    draggingBanner: null,
                                })

                            }}

                            onDrop={() => {
                                this.setState({
                                    draggingBanner: null,
                                })
                                this.startSaveSortTimer()
                            }}
                        />
                    </Link>
                </Col>
            })

            return <TabPane tab={g.name} key={g.key} >

                <Row gutter={[20, 20]}>
                    {banners}
                </Row>
            </TabPane>
        })

        return (
            <div className='page-banner-board'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>Banner</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <h1>Banner</h1>
                    <div className={'flex-r'}>
                        <Link to={'/banner/create'}><Button type={'primary'} >创建新Banner</Button></Link>
                    </div>

                </div>
                <Tabs onChange={this.onClickTab} activeKey={currentTab} type="card" tabBarExtraContent={
                    <Dropdown overlay={
                        <Menu onClick={this.menuOnclick}>
                            <Menu.Item key="update">
                                修改区域
                            </Menu.Item>
                            <Menu.Item key="delete">
                                删除区域
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="create">创建新区域</Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" href="#">
                            ...
                    </a>
                    </Dropdown>
                }>
                    {groups}
                    <TabPane tab={'未分区域'} key={'no-group'} >
                        <Row gutter={[20, 20]}>
                            {
                                noGroupBanners.map((b, n) => {
                                    return <Col key={n} span={6}>
                                        <Link to={'/banner/' + b.id}>
                                            <BannerListItem
                                                data={b}

                                            />
                                        </Link>
                                    </Col>
                                })
                            }
                        </Row>
                    </TabPane>
                </Tabs>

                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                        value={editGroup && editGroup.hasOwnProperty('name') ? editGroup.name : editGroup && editGroup.origin ? editGroup.origin.name : ""}
                        placeholder={"分组名称"} onChange={(e) => {
                            let editGroup = this.state.editGroup;
                            editGroup.name = e.target.value;
                            this.setState({
                                editGroup
                            })
                        }} />
                    <Input className={'input'}
                        value={editGroup && editGroup.hasOwnProperty('key') ? editGroup.key : editGroup && editGroup.origin ? editGroup.origin.key : ""}
                        placeholder={"分组Key"} onChange={(e) => {
                            let editGroup = this.state.editGroup;
                            editGroup.key = e.target.value;
                            this.setState({
                                editGroup
                            })
                        }} />
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup} >提交</Button>
                    </div>

                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BannerDash);
