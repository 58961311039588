import AppRequest from './AppRequest'


const urlGetAllGroup = '/api_v1/admin-api/course/group/category/list';
const urlGetCourses = '/api_v1/admin-api/course/list';
const urlCreateGroup = '/api_v1/admin-api/course/group/create';
const urlDeleteGroup = '/api_v1/admin-api/course/group/delete';
const urlUpdateCategory = '/api_v1/admin-api/course/group/category/update';
const urlAddNewCategoryToGroup = '/api_v1/admin-api/course/group/category/create';
const urlRemoveCategory = '/api_v1/admin-api/course/group/category/remove';
const urlUpdateGroup = '/api_v1/admin-api/course/group/update';
const urlGetCourseDetail = '/api_v1/admin-api/course/detail';
const urlGetAppsList = '/api_v1/admin-api/course/softWare/list';
const urlDeleteApp = '/api_v1/admin-api/course/softWare/delete';
const urlCreateApp = '/api_v1/admin-api/course/softWare/create';
const urlUpdateApp = '/api_v1/admin-api/course/softWare/update';
const urlSaveUpdatedCourse = '/api_v1/admin-api/course/edit';
const urlCreateCourse = '/api_v1/admin-api/course/create';
const urlChangeCoursePublish = '/api_v1/admin-api/course/status/update';
const urlSaveCourseGroupCategorySort = '/api_v1/admin-api/course/group/category/sort/update';
const urlGetLessonDetail = '/api_v1/admin-api/course/lesson/detail';
const  urlUpdateLesson = '/api_v1/admin-api/course/lesson/edit';
const urlCreateLesson = '/api_v1/admin-api/course/lesson/create';
const urlCreateUploadVideo = '/api_v1/admin-api/course/lesson/video';
const urlRefreshUploadAuth = '/api_v1/admin-api/course/lesson/video/refresh-auth';
const urlGetAliyunUploaderUserId = '/api_v1/admin-api/course/lesson/video/userId';
const urlGetVideoPlayerAuth = '/api_v1/admin-api/course/lesson/video/playAuth';
const urlGetVideoStatus = '/api_v1/admin-api/course/lesson/video/status';
const urlChangeLessonStatus = '/api_v1/admin-api/course/lesson/status';
const urlRemoveLesson = '/api_v1/admin-api/course/lesson/remove';
const urlSaveAppSort = '/api_v1/admin-api/course/softWare/sort/update';
const urlSaveLessonSort = '/api_v1/admin-api/course/lesson/sort/update';
const urlCreateNewSource = '/api_v1/admin-api/course/source/create';
const urlUpdateSourceInfo = '/api_v1/admin-api/course/source/update';
const urlLinkSourceToLesson = '/api_v1/admin-api/course/lesson/source/update';
const urlRemoveSource = '/api_v1/admin-api/course/source/delete';
const urlCreateSeries = "/api_v1/admin-api/course/series/post";
const urlUpdateSeriesInfo = "/api_v1/admin-api/course/series/post";
const urlDeleteSeries = "/api_v1/admin-api/course/series/post";
const urlGetAllSeries = "/api_v1/admin-api/course/series/post";
const urlSaveSeriesSort = "/api_v1/admin-api/course/series/sort/update";
//'Created','Uploading','UploadFail','UploadSucc','Transcoding','TranscodeFail','Checking','Blocked','Normal','NoFound'
const videoStatus = {
    Created:"等待上传",
    Uploading:"上传中",
    UploadFail:"上传失败",
    UploadSucc:"等待转码",
    Transcoding:"转码中",
    TranscodeFail:"转码失败",
    Checking:"审核中",
    Blocked:"被屏蔽",
    Normal:"正常",
    NoFound:"不存在或已被删除"

}
class CourseRequest extends AppRequest {

    saveSeriesSort(ids){
        return this.post(urlSaveSeriesSort,{ids});
    }
    createSeries(info){
        return this.post(urlCreateSeries,{...info});
    }
    updateSeriesInfo(info){
        return this.put(urlUpdateSeriesInfo,{...info});
    }
    deleteSeries(seriesId){
        return this.delete(urlDeleteSeries,{id:seriesId});
    }
    getAllSeries(){
        return this.get(urlGetAllSeries,{});
    }

    createNewSource(info){
        return this.post(urlCreateNewSource,{...info});
    }

    updateSourceInfo(info){
        return this.put(urlUpdateSourceInfo,info);
    }

    linkSourceToLesson(lessonId,sourceId){
        return this.put(urlLinkSourceToLesson,{lessonId,sourceId});
    }

    remoteVideoStatus = videoStatus;
    removeLesson(lessonId){
        return this.delete(urlRemoveLesson,{lessonId})
    }
    changeLessonStatus(lessonId,status){
        return this.put(urlChangeLessonStatus,{
            lessonId,status
        })
    }
    checkVideoStatus(videoId){
        return this.get( urlGetVideoStatus,{videoId});
    }
    getVideoPlayerAuth(videoId){
        return this.get(urlGetVideoPlayerAuth,{videoId});
    }
    getAliyunUploaderUserId(){
        return this.get(urlGetAliyunUploaderUserId)
    }
    /**
     *  创建上传视频，并获取上传凭据
     * @param title 视频标题
     * @param fileNameWithExtName 视频文件名，必须带视频格式后缀
     * @param courseId 所属课程的id
     * @returns {*}
     */
    createUploadVideo(title,fileNameWithExtName,courseId){
        return this.post(urlCreateUploadVideo,{title,fileNameWithExtName,courseId});
    }
    refreshUploadAuth(videoId){
        return this.post(urlRefreshUploadAuth,{videoId});
    }
    saveCourseGroupCategorySort(newData){
        return this.put(urlSaveCourseGroupCategorySort,{  newData });
    }
    changeCoursePublish(isPublish,courseId){
        return this.put(urlChangeCoursePublish,{status:!!isPublish,courseId})
    }

    createCourse(info){
        return this.post(urlCreateCourse,info)
    }
    saveUpdatedCourse(info){
       return this.put(urlSaveUpdatedCourse,info)
    }
    getAllGroup(courseType){
        let query = {};
        if(courseType){
             query.courseType = courseType;
        }
        return this.get(urlGetAllGroup,query);
    }

    getCourses(condition){
        return this.get(urlGetCourses,{...condition})
    }

    createGroup(name){
        return this.post(urlCreateGroup,{name});
    }

    deleteGroup(groupId){
        return this.delete(urlDeleteGroup,{groupId});
    }

    updateGroup(groupId,groupName){
        return this.put(urlUpdateGroup,{groupId,name:groupName});
    }
    updateCategory(newName,categoryId){
        return this.put(urlUpdateCategory,{name:newName,categoryId});
    }

    addNewCategoryToGroup(newCategoryInfo,groupId){
        return this.post(urlAddNewCategoryToGroup,{...newCategoryInfo,courseCategoryGroupId:groupId});
    }
    removeCategory(categoryId){
        return this.delete(urlRemoveCategory,{categoryId});
    }
    getLessonDetail(lessonId){
        return this.get(urlGetLessonDetail,{lessonId})
    }
    createLesson(info){
        return this.post(urlCreateLesson,{...info});
    }

    updateLesson(info){
        return this.put(urlUpdateLesson,{...info});
    }
    getCourseDetail(courseId){
        return this.get(urlGetCourseDetail,{courseId})
    }

    getAppsList(){
        return this.get(urlGetAppsList);
    }

    deleteApp(appId){
        return this.delete(urlDeleteApp,{id:appId});
    }
    createApp(name,iconUrl){
        return this.post(urlCreateApp,{name,iconUrl});
    }

    updateApp(appInfo){
        return this.put(urlUpdateApp,{...appInfo});
    }
    saveAppSort(sortIds){
        return this.put(urlSaveAppSort,{softWareIds:sortIds});
    }

    saveLessonSort(sortIds){
        return this.put(urlSaveLessonSort,{lessonIds:sortIds});
    }

    removeSource(sourceId){
        return this.delete(urlRemoveSource,{id:sourceId});
    }



}

const  courseReq = new CourseRequest();
export default courseReq;

