import React from 'react';
import {Modal, Input, Button, Popconfirm, Icon, message, Spin, Upload,Row,Col} from 'antd'
import './courseCategory.scss'
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import AppCardItem from "../../components/appCardItem/AppCardItem";

class CourseCategory extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    dragTimer = null
    state = {
        groups: [],
        showEditModal: false,
        editCategory: null,
        editGroup: null,
        editCourseApp: null,
        loadingGroupsData: false,
        showEditGroupModal: false,
        showEditCourseAppModal: false,
        appIconUploading: false,
        apps: [],
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.addGlobalListener();
        this.getAllGroups();
        this.getApps();
    }

    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.categories[e.target.dataset.index];
        this.setState({
            editCategory: {...item, origin: item},
            showEditModal: true
        })
    };


    onDragEnd = (e) => {

    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editCategory: null,
            showEditModal: false
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        courseReq.getAllGroup()
            .then(resGroups => {
                if(resGroups && resGroups.length){
                    resGroups = resGroups.filter(item => item.id)
                }

                this.setState({
                    loadingGroupsData: false
                })
                this.setState({
                    groups: resGroups
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    getApps = () => {
        courseReq.getAppsList()
            .then(apps => {
                this.setState({
                    apps
                })
            })
            .catch(error => {
                message.error(`获取课程软件，发生错误`)
            })
    }

    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };
    handlerEditGroupModalOk = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };

    submitCategory = () => {
        let {editCategory} = this.state;
        if (!editCategory.name) {
            return message.warning(`分类名称不能为空`)
        }
        if (editCategory.origin) {
            if (editCategory.name === editCategory.origin.name) {
                return message.warning(`没有任何修改可以提交`)
            }
            courseReq.updateCategory(editCategory.name, editCategory.origin.id)
                .then(category => {
                    let groups = this.state.groups;
                    for (let index = 0; index < groups.length; index++) {
                        let group = groups[index];
                        if (group.categories && group.categories.length) {
                            for (let i = 0; i < group.categories.length; i++) {
                                let category = group.categories[i];
                                if (category.id === editCategory.origin.id) {
                                    category.name = editCategory.name;
                                    break;
                                }
                            }
                        }
                    }
                    this.setState({
                        groups,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已更新分类信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {

            courseReq.addNewCategoryToGroup({name: editCategory.name}, editCategory.group.id)
                .then(category => {
                    let groups = this.state.groups;
                    let groupIndex = groups.findIndex(item => item.id === editCategory.group.id);
                    let group = groups[groupIndex];
                    group.categories = group.categories || [];
                    group.categories.push(category);
                    this.setState({
                        groups,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已添加新分类到分组`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    submitGroup = () => {
        const {editGroup} = this.state;
        if (editGroup.origin) {
            courseReq.updateGroup(editGroup.origin.id, editGroup.name)
                .then(res => {
                    let groups = this.state.groups;
                    let index = groups.findIndex(item => item.id === editGroup.origin.id);
                    let group = groups[index];
                    group.name = editGroup.name;
                    this.setState({
                        groups,
                        showEditGroupModal: false,
                        editGroup: {}
                    })
                    message.success(`分组信息已更新`)
                })
                .catch(error => {
                    if (error && error.code === 7204) {
                        return message.error(error.message)
                    }
                    return message.error(`发生错误，请重试`)
                })
        } else {
            if (!editGroup.name) {
                return message.warning(`请填写分组名称，不得重名`)
            } else {
                courseReq.createGroup(editGroup.name)
                    .then(newGroup => {
                        let groups = this.state.groups;
                        groups.unshift(newGroup);
                        this.setState({
                            groups,
                            showEditGroupModal: false,
                            editGroup: {}
                        })
                        message.success(`已成功创建新分组`)
                    })
                    .catch(error => {
                        if (error && error.code === 7203) {
                            return message.error("已存在同名分组，请使用其他名称")
                        }
                        return message.error(`发生错误，请重试`)
                    })
            }
        }
    };

    willDeleteCategory = null
    deleteCategory = () => {
        courseReq.removeCategory(this.willDeleteCategory.id)
            .then(res => {
                let groups = this.state.groups;
                for (let index = 0; index < groups.length; index++) {
                    let group = groups[index];
                    if (group.categories && group.categories.length) {
                        for (let i = 0; i < group.categories.length; i++) {
                            let category = group.categories[i];
                            if (category.id === this.willDeleteCategory.id) {
                                group.categories.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
                this.setState({
                    groups
                })
                this.willDeleteCategory = null;
                message.success(`分类已删除，并从分组中移除`)
            })
            .catch(error => {
                this.willDeleteCategory = null;
                if (error.code === 7209) {
                    return message.error(error.message)
                }
                message.error(`分组删除失败`)
            })
    };

    willDeleteGroup = null
    deleteGroup = () => {
        courseReq.deleteGroup(this.willDeleteGroup.id)
            .then(res => {
                let groups = this.state.groups;
                let index = groups.findIndex(item => item.id === this.willDeleteGroup.id);
                groups.splice(index, 1);
                this.willDeleteGroup = null;
                this.setState({
                    groups
                })
                message.success(`分组已删除`)
            })
            .catch(error => {
                this.willDeleteGroup = null;
                message.error(`分组删除失败`)
            })
    }

    draggingType = "";
    handlerEditAppModalCancel = () => {
        this.setState({
            showEditCourseAppModal: false,
            editCourseApp: null
        })
    }
    handlerEditAppModalOk = () => {
        this.setState({
            showEditCourseAppModal: false
        })
    }

    appIconUploaderOnChange = () => {


    }

    appIconUploaderBeforeUpload = (fileInfo) => {
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let editCourseApp = this.state.editCourseApp || {};
                editCourseApp.iconUrl = url;
                this.setState({
                    editCourseApp
                })

            },
            progress: (e) => {

            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    appIconUploading: false
                })
            },
        })
    };

    willDeleteApp = null;
    handlerDeleteApp = () => {
        courseReq.deleteApp(this.willDeleteApp.id)
            .then(res => {
                let apps = this.state.apps;
                let index = apps.findIndex(item => item.id === this.willDeleteApp.id);
                apps.splice(index, 1);
                this.willDeleteApp = null;
                this.setState({
                    apps,

                })
                message.success(`软件已删除`)
            })
            .catch(error => {
                this.willDeleteApp = null;
                message.error(`软件删除失败`)
            })
    }

    submittingApp = false;
    onSubmitCourseApp = () => {
        let {editCourseApp} = this.state;
        editCourseApp = editCourseApp || {};
        if(this.submittingApp) {
            return
        };
        if (editCourseApp.origin) {

            if (!editCourseApp.name && !editCourseApp.iconUrl) {
                return message.warning(`没有修改任何内容`)
            }
            let newInfo = {
                ...editCourseApp
            }
            let origin = {...editCourseApp.origin};
            delete newInfo.origin;
            this.submittingApp = true
            courseReq.updateApp({
                ...newInfo, id: origin.id
            })
                .then(newApp => {
                    this.submittingApp = false
                    let apps = this.state.apps;
                    let idx = apps.findIndex(item => item.id === origin.id);

                    if (editCourseApp.name) {
                        origin.name = editCourseApp.name;
                    }
                    if (editCourseApp.iconUrl) {
                        origin.iconUrl = editCourseApp.iconUrl;
                    }
                    apps.splice(idx, 1, origin)
                    this.setState({
                        apps,
                        showEditCourseAppModal: false,
                        editCourseApp: {}
                    })
                    message.success(`已成功修改课程软件`)
                })
                .catch(error => {
                    this.submittingApp = false
                    if (error && error.code === 7312) {
                        return message.error("已存在同名软件，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })


        } else {
            if (!editCourseApp.name) {
                return message.warning(`请填写软件名称，不得重名`)
            }
            if (!editCourseApp.iconUrl) {
                return message.warning(`请上传软件Icon`)
            }
            this.submittingApp = true
            courseReq.createApp(editCourseApp.name, editCourseApp.iconUrl)
                .then(newApp => {
                    this.submittingApp = false
                    let apps = this.state.apps;
                    apps.push(newApp);
                    this.setState({
                        apps,
                        showEditCourseAppModal: false,
                        editCourseApp: {}
                    })
                    message.success(`已成功添加课程软件`)
                })
                .catch(error => {
                    this.submittingApp = false
                    if (error && error.code === 7312) {
                        return message.error("已存在同名软件，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        }
    }

    setSaveTimer = () => {
        this.clearSaveTimer()
        this.dragTimer = setTimeout(() => {
            this.saveDragSortResult()
        }, 2000)
    };
    clearSaveTimer = () => {
        if (this.dragTimer) {
            clearTimeout(this.dragTimer);
        }
    };
    saveDragSortResult = () => {
        let newData = [];
        for (let g of this.state.groups) {
            let group = {
                id: g.id,
                categories: []
            }
            if (g.categories && g.categories.length) {
                for (let c of g.categories) {
                    group.categories.push({
                        id: c.id
                    })
                }
            }
            newData.push(group);
        }
        courseReq.saveCourseGroupCategorySort(newData)
            .then(res => {
                message.success(`保存排序成功`);
            })
            .catch(error => {
                if (error.message) {
                    return message.error(error.message);
                }
                message.error(`保存排序失败`);
            })
    };
    clearAppSaveTimer = () => {
        if (this.saveAppSortTimer) {
            clearTimeout(this.saveAppSortTimer);
        }
    }
    saveAppSortTimer = null;
    startSaveAppSortTimer = () => {
        if (this.saveAppSortTimer) {
            clearTimeout(this.saveAppSortTimer);
        }

        this.saveAppSortTimer = setTimeout(() => {
            let apps = this.state.apps;
            courseReq.saveAppSort(apps.map(item => item.id))
                .then(res => {
                    message.success(`保存软件排序`)
                })
                .catch(error => {
                    message.success(`保存软件排序失败`)
                })

        }, 1000)
    }

    render() {

        let {showEditModal, editCategory, groups, loadingGroupsData, showEditGroupModal, editGroup, editCourseApp, showEditCourseAppModal, appIconUploading, apps} = this.state;
        apps = apps || [];
        let appList = apps.map((item, index) => {
            return <Col span={4} key={index}><AppCardItem data={item} rowKey={index} iconKey={'iconUrl'}

                                draggable={true}
                                onDragStart={() => {
                                    this.setState({
                                        draggingApp: item,
                                    });
                                    this.clearAppSaveTimer()

                                }}
                                onDragEnter={() => {
                                    console.log('app onDragEnter');

                                    let draggingApp = this.state.draggingApp;
                                    if (item.id !== this.state.draggingApp.id) {
                                        const oldDragIndex = this.state.apps.findIndex(item => item.id === draggingApp.id);
                                        const oldEnterIndex = this.state.groups.findIndex(it => it.id === item.id);
                                        if (oldDragIndex > oldEnterIndex) {
                                            const newDataArray = this.state.apps.filter(item => item.id !== this.state.draggingApp.id);
                                            const insertIndex = newDataArray.findIndex(it => it.id === item.id);

                                            newDataArray.splice(insertIndex, 0, this.state.draggingApp);
                                            this.setState({apps: newDataArray});
                                        } else {
                                            const newDataArray = this.state.apps.filter(item => item.id !== this.state.draggingApp.id);
                                            const insertIndex = newDataArray.findIndex(it => it.id === item.id) + 1;
                                            newDataArray.splice(insertIndex, 0, this.state.draggingApp);
                                            this.setState({apps: newDataArray});
                                        }
                                        this.setState({
                                            isConfigDirty: true,
                                        });
                                    }
                                }}
                                onDragLeave={() => {

                                    console.log('app onDragLeave')
                                    if (item.id !== this.state.draggingApp.id) {
                                        if (this.state.appLock && apps.id === this.state.apps[this.state.groups.length - 1]) {
                                            const newDataArray = this.state.apps.filter(item => item.id !== this.state.draggingApp.id);
                                            newDataArray.push(this.state.draggingApp);
                                            this.setState({
                                                appLock: false,
                                            }, () => {
                                                this.setState({
                                                    apps: newDataArray,
                                                });
                                            });
                                        } else {
                                            this.setState({
                                                appLock: true,
                                            });
                                        }
                                    }
                                }}
                                onDragEnd={() => {
                                    console.log(`onDragEnd`)
                                    this.setState({
                                        draggingApp: null,

                                    })

                                }}

                                onDrop={() => {
                                    this.setState({
                                        draggingApp: null,

                                    })
                                    this.startSaveAppSortTimer()
                                }}
                                onEdit={(data, rowKey) => {

                                    this.setState({
                                        showEditCourseAppModal: true,
                                        editCourseApp: {
                                            origin: item
                                        }
                                    })
                                }}

                                handlerDeleteApp={() => {
                                    this.willDeleteApp = item;
                                    this.handlerDeleteApp();
                                }
                                }

            />
            </Col>
        })

        let groupList = groups.map((group, index) => {
            let childList = [];
            if (group.categories && group.categories.length) {
                childList = group.categories.map((child, idx) => {
                    return <div className={'child-item'} key={idx} draggable={true}
                                onDragStart={(e) => {
                                    this.draggingType = 'child';
                                    this.setState({
                                        draggingCategory: child,
                                    });
                                    this.clearSaveTimer();

                                }}
                                onDragEnter={(e) => {
                                    console.log('chidl onDragEnter', group.name, child.name)

                                    if (this.draggingType !== 'child') {
                                        return
                                    }
                                    let draggingCategory = this.state.draggingCategory;
                                    if (child.id !== draggingCategory.id) {
                                        // 获取拖拽分类所在分组，
                                        let oldDragIndexPathGroup = null;
                                        let oldDragIndexPathCategory = null;

                                        let oldEnterIndexPathGroup = null;
                                        let oldEnterIndexPathCategory = null;

                                        for (let index = 0; index < this.state.groups.length; index++) {
                                            let g = this.state.groups[index];
                                            if (g.categories && g.categories.length) {
                                                for (let j = 0; j < g.categories.length; j++) {
                                                    let c = g.categories[j];
                                                    if (c.id === draggingCategory.id) {
                                                        oldDragIndexPathGroup = index
                                                        oldDragIndexPathCategory = j;
                                                    }
                                                    if (c.id === child.id) {
                                                        oldEnterIndexPathGroup = index;
                                                        oldEnterIndexPathCategory = j;
                                                    }

                                                }
                                            }
                                        }

                                        let groups = this.state.groups;
                                        let oldGroup = groups[oldDragIndexPathGroup];
                                        oldGroup.categories = oldGroup.categories.filter(item => item.id !== draggingCategory.id);
                                        groups[oldDragIndexPathGroup] = oldGroup;
                                        let newGroup = groups[oldEnterIndexPathGroup];
                                        newGroup.categories.splice(oldEnterIndexPathCategory, 0, draggingCategory);
                                        groups[oldEnterIndexPathGroup] = newGroup;
                                        this.setState({
                                            groups,
                                            isConfigDirty: true,
                                        });
                                    }


                                }}
                                onDragEnd={(e) => {
                                    console.log(`onDragEnd`)
                                    this.setState({
                                        draggingCategory: null,

                                    })
                                    this.draggingType = null
                                }}
                                onDrop={() => {
                                    this.setState({
                                        draggingCategory: null,

                                    })
                                    this.draggingType = null
                                    this.setSaveTimer()
                                }}
                    >
                        <div>{child.name}</div>
                        <div className={'actions'}>
                            <a onClick={() => {
                                let editCategory = this.state.editCategory || {};
                                editCategory.group = group;
                                editCategory.origin = child;
                                this.setState({
                                    showEditModal: true,
                                    editCategory
                                })
                            }}>编辑</a>
                            <Popconfirm title={`你确定要删除该分类吗？`} onCancel={() => {
                                this.willDeleteCategory = null;
                            }} onConfirm={this.deleteCategory}>
                                <a onClick={() => {
                                    this.willDeleteCategory = child;

                                }}>删除</a>
                            </Popconfirm>

                        </div>
                    </div>
                })

            }
            return <div className={'group-item'} draggable={true} key={index}
                        onDragStart={() => {
                            if (this.draggingType) {
                                return
                            }
                            console.log('group onDragStart')
                            this.draggingType = 'group';
                            this.setState({
                                draggingGroup: group,
                            });
                            this.clearSaveTimer()

                        }}
                        onDragEnter={() => {
                            console.log('group onDragEnter');
                            if (this.draggingType === 'child' && (!group.categories || !group.categories.length)) {
                                // return;
                                let draggingCategory = this.state.draggingCategory;
                                let oldDragIndexPathGroup = null;
                                let oldDragIndexPathCategory = null;


                                for (let m = 0; m < this.state.groups.length; m++) {
                                    let g = this.state.groups[m];
                                    if (g.categories && g.categories.length) {
                                        for (let j = 0; j < g.categories.length; j++) {
                                            let c = g.categories[j];
                                            if (c.id === draggingCategory.id) {
                                                oldDragIndexPathGroup = m
                                                oldDragIndexPathCategory = j;
                                            }

                                        }
                                    }
                                }

                                let groups = this.state.groups;
                                let oldGroup = groups[oldDragIndexPathGroup];
                                oldGroup.categories = oldGroup.categories.filter(item => item.id !== draggingCategory.id);
                                groups[oldDragIndexPathGroup] = oldGroup;
                                group.categories = [this.state.draggingCategory];
                                groups[index] = group;
                                this.setState({
                                    groups
                                })
                            }
                            if (this.draggingType !== 'group') {
                                return
                            }

                            let draggingGroup = this.state.draggingGroup;
                            if (group.id !== this.state.draggingGroup.id) {
                                const oldDragIndex = this.state.groups.findIndex(item => item.id === draggingGroup.id);
                                const oldEnterIndex = this.state.groups.findIndex(item => item.id === group.id);
                                if (oldDragIndex > oldEnterIndex) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id);
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                } else {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id) + 1;
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                }
                                this.setState({
                                    isConfigDirty: true,
                                });
                            }
                        }}
                        onDragLeave={() => {

                            if (this.draggingType !== 'group') {
                                return
                            }
                            console.log('group onDragLeave')
                            if (group.id !== this.state.draggingGroup.id) {
                                if (this.state.lock && group.id === this.state.groups[this.state.groups.length - 1]) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    newDataArray.push(this.state.draggingGroup);
                                    this.setState({
                                        lock: false,
                                    }, () => {
                                        this.setState({
                                            groups: newDataArray,
                                        });
                                    });
                                } else {
                                    this.setState({
                                        lock: true,
                                    });
                                }
                            }
                        }}
                        onDragEnd={() => {
                            console.log(`onDragEnd`)
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null

                        }}
                        onDrop={() => {
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null
                            this.setSaveTimer()
                        }}
            >
                <div className={'header'}>
                    <h3>{group.name}</h3>
                    <div className={'actions'}>
                        <a onClick={() => {
                            let editGroup = this.state.editGroup || {};
                            editGroup.origin = group;
                            this.setState({
                                showEditGroupModal: true,
                                editGroup
                            })
                        }}>编辑分组</a>
                        <a onClick={() => {
                            let editCategory = this.state.editCategory || {};
                            editCategory.group = group;
                            this.setState({
                                showEditModal: true,
                                editCategory
                            })
                        }}>添加分类</a>
                        <Popconfirm title={`你确定要删除该分组吗？`} onCancel={() => {
                            this.willDeleteGroup = null;
                        }} onConfirm={this.deleteGroup}>
                            <a hidden={group.categories && group.categories.length} onClick={() => {
                                this.willDeleteGroup = group;
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                </div>

                <div className={'child-list'}>
                    {childList}
                </div>
            </div>
        });

        return (
            <div className='page-course-category'>

                <h2>课程软件</h2>
                <div className={'soft-list'}>
                    <Row type="flex" gutter={[10,10]}  align="middle">
                        {appList}
                        <Col span={4} className={`col-flex`}>
                            <div className={'soft-item soft-add'} onClick={e => {
                                this.setState({
                                    showEditCourseAppModal: true
                                })
                            }}>
                                <Icon className={'icon'} type={'plus'}/>
                            </div>
                        </Col>
                    </Row>

                </div>

                <div className={'list-header'}>
                    <h2>课程分类</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editGroup: {},
                                showEditGroupModal: true
                            })
                        }}>+新分组</Button>
                        {/*<Button type={'primary'}>保存</Button>*/}
                    </div>
                </div>

                <div className={'list'}>
                    <Spin spinning={loadingGroupsData} className={`group-loading`} delay={200}/>
                    {groupList}
                </div>

                {/*编辑分类模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editCategory || !editCategory.origin ? "添加分类" : "编辑分类"}</h2>
                    <Input className={'input'}
                           value={editCategory && editCategory.hasOwnProperty('name') ? editCategory.name : editCategory && editCategory.origin ? editCategory.origin.name : ""}
                           placeholder={"分类名称"} onChange={(e) => {
                        let editCategory = this.state.editCategory;
                        editCategory.name = e.target.value;
                        this.setState({
                            editCategory
                        })
                    }}/>
                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editCategory && editCategory.group ? editCategory.group.name : ""}`}</span>
                    </p>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitCategory}>提交</Button>
                    </div>

                </Modal>
                {/*    编辑分组*/}
                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('name') ? editGroup.name : editGroup && editGroup.origin ? editGroup.origin.name : ""}
                           placeholder={"分组名称"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.name = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup}
                                disabled={!editGroup || !editGroup.name || !editGroup.name.length || (editGroup && editGroup.origin && editGroup.name === editGroup.origin.name)}>提交</Button>
                    </div>

                </Modal>

                {/*    编辑课程软件*/}
                <Modal

                    visible={showEditCourseAppModal}
                    onOk={this.handlerEditAppModalOk}
                    onCancel={this.handlerEditAppModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editCourseApp || !editCourseApp.origin ? "添加课程软件" : "编辑课程软件"}</h2>

                    <div className={'flex-l'}>
                        <Upload className={'app-icon-uploader'} accept={'image/*'}
                                showUploadList={false}
                                beforeUpload={this.appIconUploaderBeforeUpload}
                                disabled={appIconUploading}>
                            <div className={'app-icon-add'}
                                 hidden={editCourseApp && (editCourseApp.iconUrl || (editCourseApp.origin && editCourseApp.origin.iconUrl))}>
                                Icon Image
                            </div>
                            <img
                                hidden={!editCourseApp || (!editCourseApp.iconUrl && (!editCourseApp.origin || !editCourseApp.origin.iconUrl))}
                                src={editCourseApp ? editCourseApp.iconUrl ? editCourseApp.iconUrl : editCourseApp.origin ? editCourseApp.origin.iconUrl : "" : ""}
                                alt={''}/>
                        </Upload>
                        <Input className={'input'}
                               value={editCourseApp && editCourseApp.hasOwnProperty('name') ? editCourseApp.name : editCourseApp && editCourseApp.origin ? editCourseApp.origin.name : ""}
                               placeholder={"软件名称"} onChange={(e) => {
                            let editCourseApp = this.state.editCourseApp || {};
                            editCourseApp.name = e.target.value;
                            this.setState({
                                editCourseApp
                            })
                        }}/>
                    </div>

                    <div className={'footer'}>

                        <Button className={'submit-btn'} type={'primary'} onClick={this.onSubmitCourseApp}>提交</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default CourseCategory;
