import AppRequest from './AppRequest'

const urlLogin = '/api_v1/account/admin-login';
const urlGetAuthUserInfo = '/api_v1/account/admin/auth/info';
const urlLogout = '/api_v1/account/logout';
const urlSearchUser = '/api_v1/account/search';
const urlGetUserList = '/api_v1/admin-api/account/list/info';
const urlGetRoleList = '/api_v1/account/admin/roles/info';
const urlGetUserPowerList = '/api_v1/admin-api/account/power/info';
const urlChangeUserRoleAndPower = "/api_v1/admin-api/account/operation/user";
const urlGetUserRegisterStatistics = '/api_v1/admin-api/account/user/register/statistics';
const urlGetUserActiveStatistics = '/api_v1/admin-api/account/user/active/statistics';
const urlGetAllTeacher = '/api_v1/admin-api/course/teacher/list'

const urlCreateRole = '/api_v1/admin-api/account/roles/post';
const urlUpdateRole = "/api_v1/admin-api/account/roles/post";
const urlDeleteRole = "/api_v1/admin-api/account/roles/post";
const urlGetAllRoleList = '/api_v1/admin-api/account/roles/post';

const urlGetRoleApiList = "/api_v1/admin-api/account/roles/api/list";
const urlAddApiPowerToRole = '/api_v1/admin-api/account/roles/api/create';
const urlRemoveApiPowerFromRole = "/api_v1/admin-api/account/roles/api/delete";
const urlAddMenuOptionToRoleMenu = "/api_v1/admin-api/account/roles/menu/create";
const urlRemoveMenuOptionFromRoleMenu = "/api_v1/admin-api/account/roles/menu/delete";
const urlGetRoleMenu = "/api_v1/admin-api/account/roles/menu/list";

const urlGetUserMenu = "/api_v1/account/admin/use/menu/list";
class AccountRequest extends AppRequest {
    getAllTeacher(){
        return this.get(urlGetAllTeacher)
    }
    getUserRegisterStatistics(){
        return this.get(urlGetUserRegisterStatistics);
    }
    getUserActiveStatistics(){
        return this.get(urlGetUserActiveStatistics);
    }
    changeUserRoleAndPower(info){
        return this.post(urlChangeUserRoleAndPower,info);
    }
    getRoleList(){
        return this.get(urlGetRoleList);
    }
    getUserPowerList(){
        return this.get(urlGetUserPowerList);
    }
    getUserList(condition){
        return this.get(urlGetUserList,condition)
    }
    searchUser(nickname){
        return this.get(urlSearchUser,{nickName:nickname})
    }
    logout(){
        return this.get(urlLogout);
    }
    login(account,password){
        return this.post(urlLogin,{account,password})
    }
    getAuthUserInfo(){
        return  this.get(urlGetAuthUserInfo);
    }

    /*角色*/

    getAllRoleList(){
        return this.get(urlGetAllRoleList)
    }
    createRole(info){
        return this.post(urlCreateRole,info)
    }
    updateRole(info){
        return this.put(urlUpdateRole,info)
    }
    deleteRole(id){
        return this.delete(urlDeleteRole,{id})
    }

    getRoleApiList(roleKey){
        return this.get(urlGetRoleApiList,{roleKey})
    }

    addApiPowerToRole(roleKey,apiIds){
        return this.post(urlAddApiPowerToRole,{roleKey,ids:apiIds})
    }

    removeApiPowerFromRole(roleKey,apiIds){
        return this.post(urlRemoveApiPowerFromRole,{roleKey,ids:apiIds})
    }

    addMenuOptionToRoleMenu(roleKey,menuKeys){
        return this.post(urlAddMenuOptionToRoleMenu,{roleKey,ids:menuKeys})
    }

    removeMenuOptionFromRoleMenu(roleKey,menuKeys){
        return this.post(urlRemoveMenuOptionFromRoleMenu,{roleKey,ids:menuKeys})
    }

    getRoleMenu(roleKey){
        return this.get(urlGetRoleMenu,{roleKey});
    }

    getUserMenu(){
        return this.get(urlGetUserMenu);
    }

    updateMyInfo(info){
      return  this.put('/api_v1/account/info/update',info)
    }



}

const  accountReq = new AccountRequest();
export default accountReq;