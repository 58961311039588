const storageKey = "MY_APP_COMMON_STORAGE_KEY";

class Storage {
    constructor(props) {
        this.windowLocalStorage = window.localStorage;
       this.keys = {
           hasFoldMenus:'hasFoldMenus',
           isLogin:'isLogin',
           latestBannerTabKey:"latest-banner-tab"
       }
       let storageData = this.windowLocalStorage.getItem(storageKey) || "{}";
       try {
           storageData = JSON.parse(storageData)
       }catch (e) {
            storageData = {}
       }
       this.storageData = storageData;

       this.cachedData = {

       }

    }

    setStorage = (key,value)=>{
        this.storageData[key] = value;
        this.sync();
    }

    getStorage = (key)=>{
        return this.storageData[key];
    }

    setCached = (key,value)=>{
        this.cachedData[key] = value;
    }
    getCached = (key)=>{
        return this.cachedData[key];
    }

    sync = ()=>{
        let jsonString = JSON.stringify(this.storageData);
        this.windowLocalStorage.setItem(storageKey,jsonString);
    }

}


export  default  new Storage()