
import React from 'react';
import {connect} from 'react-redux'
import accountReq from '../../request/accountReq'
import actions from '../../redux/actions'
import {message} from 'antd'
import config from '../../config.json'
class Logout extends React.Component {
    
    componentDidMount(){
        //  退出登录，改变redux状态
        accountReq.logout()
        .then(()=>{
            this.props.removeLoginUserInfo({});
            this.props.flagLogin(false);
            window.appLogin();

        })
        .catch(()=>{
            message.error('操作异常,返回首页');
            this.props.history.push(`/`)
        })
    }
    render(){
        return <div></div>
    }
}

export default connect(null,{
    removeLoginUserInfo:actions.accountRemoveLoginUserInfo,
    flagLogin:actions.accountFlagLoginStatus
})(Logout)