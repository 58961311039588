import React from 'react';
import {Modal, Input, Button, Popconfirm, message, Spin, Tree, Icon,Tag,Radio} from 'antd'
import './adminApi.scss'
import linkReq from "../../request/linkReq";
import developRequest from "../../request/developRequest";

const {TreeNode} = Tree;

class AdminApi extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    dragTimer = null
    state = {
        groups: [],
        showEditModal: false,
        editApi: null,
        editGroup: null,
        loadingGroupsData: false,
        showEditGroupModal: false,
        appIconUploading: false,

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.addGlobalListener();
        this.getAllUrl()
    }

    getAllUrl = () => {
        developRequest.getUrlList()
            .then(res => {
                console.log(res);
              if(res && Array.isArray(res) ){
                  this.setState({
                      groups: res
                  })
              }else {
                  message.error(`API管理仅限本地开发时查看`)
              }

            })
            .catch(error => {

            })
    }

    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.links[e.target.dataset.index];
        this.setState({
            editApi: {...item, origin: item},
            showEditModal: true
        })
    };


    onDragEnd = (e) => {

    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editApi: null,
            showEditModal: false
        })
    }


    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };
    handlerEditGroupModalOk = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };

    submitLink = () => {
        let {editApi} = this.state;
        if (editApi.origin) {
            if (editApi.hasOwnProperty(`name`) && !editApi.name) {
                return message.warn(`api 说明不能为空`)
            }

            if (editApi.hasOwnProperty(`method`) && !editApi.method) {
                return message.warn(`api 请求方法不能为空`)
            }

            if (editApi.hasOwnProperty(`path`) && !editApi.path) {
                return message.warn(`api路径不能为空`)
            }

            if (!editApi.name && !editApi.path && !editApi.method && !editApi.func) {
                return message.warn(`没有修改的任何`)
            }
            let body = {...editApi}

            body.id = editApi.origin.id;
            delete body.origin;
            delete body.group;
            developRequest.updateUrl(body)
                .then(link => {

                    this.setState({

                        showEditModal: false,
                        editApi: {}
                    })
                    this.getAllUrl()
                    message.success(`已更新API信息`)
                })
                .catch(error => {
                    if (error && error.code === 6603) {
                        return message.error("已存在同名链接，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            if (!editApi.name) {
                return message.warn(`请填写api说明`)
            }

            if (!editApi.path) {
                return message.warn(`请填写api路径`)
            }
            if(!editApi.method) {
                return message.warn(   `请选择api请求方法`)
            }

            let body = {name: editApi.name, groupId: editApi.group.id, path: editApi.path,method:editApi.method}
            if(editApi.func){
                body.func = editApi.func
            }
            developRequest.createUrl(body)
                .then(link => {

                    this.setState({
                        showEditModal: false,
                        editApi: {}
                    })
                    this.getAllUrl()
                    message.success(`已创建API并添加至分组`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名链接，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    submitGroup = () => {
        const {editGroup} = this.state;
        if (editGroup.origin) {
            let body = {id: editGroup.origin.id}
            if(editGroup.name){
                body.name = editGroup.name;
            }
            if(editGroup.basePath){
                body.basePath = editGroup.basePath;
            }
            developRequest.updateGroup(body)
                .then(res => {
                    this.getAllUrl()
                    this.setState({
                        showEditGroupModal: false,
                        editGroup: {}
                    })
                    message.success(`分组信息已更新`)
                })
                .catch(error => {
                    if (error && error.code === 7204) {
                        return message.error(error.message)
                    }
                    return message.error(`发生错误，请重试`)
                })
        } else {
            if (!editGroup.name) {
                return message.warning(`请填写分组名称，不得重名`)
            } else if(!editGroup.basePath){
                return message.warning(`请填写分组路径`)
            }else {
                let body = {name: editGroup.name,basePath:editGroup.basePath};
                if(editGroup.parentGroup){
                    body.pid = editGroup.parentGroup.id;
                }
                developRequest.createGroup(body)
                    .then(newGroup => {
                        this.getAllUrl()
                        this.setState({
                            showEditGroupModal: false,
                            editGroup: {}
                        })
                        message.success(`已成功创建新分组`)
                    })
                    .catch(error => {
                        if (error && error.code === 6603) {
                            return message.error("已存在同名分组，请使用其他名称")
                        }
                        return message.error(`发生错误，请重试`)
                    })
            }
        }
    };



    methodOnChange = e =>{
        let editApi  = this.state.editApi || {};
        editApi.method = e.target.value;
        this.setState({
            editApi
        })
    }

    render() {

        let {showEditModal, editApi, groups, loadingGroupsData, showEditGroupModal, editGroup} = this.state;


        if(window.location.href.indexOf('http://localhost') < 0 ){
            return  <div>只能在本地开发中管理API</div>
        }

        let loop = (group,key,absolutePath) => {
            let urls = [];
            let child = [];

            if (group.urls && group.urls.length) {
                urls = group.urls.map((item, index) => {
                    return <TreeNode icon={<Icon type="carry-out"/>} title={
                        <div className={`tree-node-container`}>
                            <div className={`flex-t`}>
                                <div className={`path`}><div className={`method ${item.method}`}>{item.method}</div> {`${absolutePath || '/'}/${item.path}`}</div>
                                <div className={`title`}>{item.name}</div>
                            </div>


                            <div className={`action`}>
                                <a className={`action-btn`} onClick={()=>{
                                    this.setState({
                                        editApi: {origin:item,group},
                                        showEditModal: true
                                    })
                                }} >编辑</a>
                                <Popconfirm title={`确认删除这个API吗？`}
                                okType={`danger`}
                                okText={`删除`}
                                onConfirm={()=>{
                                    developRequest.deleteUrl(item.id)
                                        .then((res)=>{
                                            this.getAllUrl()
                                        })
                                        .catch(error=>{
                                            message.error(`删除失败：${error && error.message ? error.message:""}`)
                                        })
                                }}>
                                    <a className={`action-btn`}  >删除</a>
                                </Popconfirm>

                            </div>
                        </div>
                    } key={`${key}-u${index}`}/>
                })


            }
            group.absolutePath = absolutePath
            if(group.child && group.child.length){
                child = group.child.map((item, index) => {
                    return loop(item,`${key}-g${index}`,absolutePath + "/" + item.basePath)
                })

            }


             return   <TreeNode icon={<Icon type="carry-out"/>} title={
                <div className={`tree-node-container`}>
                    <div className={`title group-title`}>{group.name}</div>
                    <div className={`action`}>
                        <a className={`action-btn`} size={`small`} onClick={()=>{
                            this.setState({
                                editGroup: {origin:group},
                                showEditGroupModal: true
                            })
                        }}>编辑</a>
                        <a className={`action-btn`} size={`small`} onClick={()=>{

                            this.setState({
                                editGroup: {parentGroup:group},
                                showEditGroupModal: true
                            })
                        }}>添加分组</a>
                        <a className={`action-btn`} onClick={()=>{
                            this.setState({
                                editApi: {group},
                                showEditModal: true
                            })
                        }} >创建API</a>
                        <Popconfirm title={`确认删除分组以及它的全部api吗？`}
                                    okText={`删除`}
                                    okType={`danger`}
                        onConfirm={()=>{
                            developRequest.deleteGroup(group.id)
                                .then(res =>{
                                    this.getAllUrl()

                                })
                                .catch(error=>{
                                    message.error(`删除失败：${error && error.message ? error.message:""}`)
                                })
                        }}>
                            <a className={`action-btn`}  data-key={key}>删除</a>
                        </Popconfirm>

                    </div>

                </div>
            } key={key}>
                {urls}
                 {child}
            </TreeNode>


        }



        groups = groups || [];
        let treeNodes =  groups.map((g,idx)=>{
            return loop(g,`g${idx}`,g.basePath);
        })

        return (
            <div className='page-admin-api'>

                <div className={'list-header'}>
                    <h2>AdminApi列表</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editGroup: {},
                                showEditGroupModal: true
                            })
                        }}>+新分组</Button>
                        {/*<Button type={'primary'}>保存</Button>*/}
                    </div>
                </div>

                <div className={'list'}>
                    <Spin spinning={loadingGroupsData} className={`group-loading`} delay={200}/>
                    <Tree className={`api-tree`}>
                        {treeNodes}
                    </Tree>
                </div>

                {/*编辑链接模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-api-link'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editApi || !editApi.origin ? "添加API" : "编辑API"}</h2>
                    <div className={`item`}>
                        <div className={`title`}>API简介</div>
                        <Input.TextArea className={'input'} style={{minHeight:100}}
                               value={editApi && editApi.hasOwnProperty('name') ? editApi.name : editApi && editApi.origin ? editApi.origin.name : ""}
                               placeholder={"请简洁描述API的功能"} onChange={(e) => {
                            let editApi = this.state.editApi || {};
                            editApi.name = e.target.value;
                            this.setState({
                                editApi
                            })
                        }}/>
                    </div>
                    <div className={`item`}>
                        <div className={`title`}>API路径</div>
                        <Input className={'input'}
                               value={editApi && editApi.hasOwnProperty('path') ? editApi.path : editApi && editApi.origin ? editApi.origin.path : ""}
                               placeholder={"请输入API路径"} onChange={(e) => {
                            let editApi = this.state.editApi || {};
                            editApi.path = e.target.value;
                            this.setState({
                                editApi
                            })
                        }}/>
                    </div>

                    <div className={`item`}>
                        <div className={`title`}>请求方法</div>
                        <Radio.Group className={`radio-group`} onChange={this.methodOnChange}  value={editApi && editApi.hasOwnProperty('method') ? editApi.method : editApi && editApi.origin ? editApi.origin.method : ""}>
                            <Radio value={`get`}>GET</Radio>
                            <Radio value={`post`}>POST</Radio>
                            <Radio value={`put`}>PUT</Radio>
                            <Radio value={`delete`}>DELETE</Radio>
                            <Radio value={`all`}>ALL</Radio>
                        </Radio.Group>
                    </div>
                    <div className={`item`}>
                        <div className={`title`}>映射函数</div>
                        <Input className={'input'}
                               value={editApi && editApi.hasOwnProperty('func') ? editApi.func : editApi && editApi.origin ? editApi.origin.func : ""}
                               placeholder={"请填写映射函数。样例：userController.getUserInfo"} onChange={(e) => {
                            let editApi = this.state.editApi || {};
                            editApi.func = e.target.value;
                            this.setState({
                                editApi
                            })
                        }}/>
                    </div>

                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editApi && editApi.group ? editApi.group.name : ""}`}</span>
                    </p>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitLink}>提交</Button>
                    </div>

                </Modal>
                {/*    编辑分组*/}
                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-api-link'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('name') ? editGroup.name : editGroup && editGroup.origin ? editGroup.origin.name : ""}
                           placeholder={"分组名称"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.name = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('basePath') ? editGroup.basePath : editGroup && editGroup.origin ? editGroup.origin.basePath : ""}
                           placeholder={"分组路径"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.basePath = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>

                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editGroup && editGroup.parentGroup ? editGroup.parentGroup.name : ""}`}</span>
                    </p>

                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup}
                                >提交</Button>
                    </div>

                </Modal>


            </div>
        )
    }
}


export default AdminApi;
