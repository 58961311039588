import React from 'react';
// import { Result, Button, Icon, Spin ,PageHeader} from 'antd'
// import {parseTimelineDate} from '../../utils/dataformat'
import './dashboard.scss'
// import Menus from "../../components/menus/Menus";
import {connect} from 'react-redux'
import storage from "../../storage/storage";
// const PerPage = 30;


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {
        windowSize:{width:0,height:0},
        menusCollapsed:storage.getStorage(storage.keys.hasFoldMenus)
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount(){

    }


    render() {
        let {} = this.state

        return (
            <div className='index-dash-board' >
                这是仪表盘
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps)=>{

    return {

    }
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
