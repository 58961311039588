import React from 'react';
import { Avatar,Divider} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import './seriesListItem.scss'
import IconFont from "../iconfont/icon-font";
const PerPage = 30;


class SeriesListItem extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state,props)
    }

    state = {

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount(){

    }


    render() {
        let {data} = this.state

        return (
            <div className='comp-series-list-item'  >
                <label>{data && data.name ? data.name : ""}</label>
            </div>
        )
    }
}



export default (SeriesListItem);
