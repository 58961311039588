import React from 'react';
import ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd'
import {Provider} from 'react-redux'
import './index.css';
import 'antd/es/button/style';
import "antd/es/input/style"
import 'antd/es/modal/style'
import 'antd/es/radio/style'
import 'antd/es/avatar/style'
import 'antd/es/progress/style'
import 'antd/es/icon/style'
import 'antd/lib/result/style'
import 'antd/lib/button/style'
import 'antd/lib/spin/style'
import 'antd/lib/message/style'
import "antd/lib/form/style"
import 'antd/lib/dropdown/style'
import 'antd/lib/menu/style'
import 'antd/lib/checkbox/style'
import 'antd/lib/select/style'
import 'antd/lib/upload/style'
import 'antd/lib/row/style'
import 'antd/lib/col/style'
import 'antd/lib/page-header/style'
import 'antd/lib/statistic/style'
import 'antd/lib/list/style'
import 'antd/lib/skeleton/style'
import 'antd/lib/divider/style'
import 'antd/lib/popconfirm/style'
import 'antd/lib/tabs/style'
import 'antd/lib/breadcrumb/style'
import 'antd/lib/input-number/style'
import 'antd/lib/switch/style'
import 'antd/lib/radio/style'
import 'antd/lib/tabs/style'
import 'antd/lib/date-picker/style'
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/zh-cn';
import store from './redux/store'
import zhCN from 'antd/es/locale/zh_CN';

import moment from 'moment';
moment.locale('zh-cn');

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN} >
            <App/>
        </ConfigProvider>
    </Provider>
    , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
