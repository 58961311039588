import React from 'react';
import {List, Modal, Input, Button, Popconfirm, message} from 'antd'
import './courseSeries.scss'
import SeriesListItem from "../../components/seriesListItem/SeriesListItem.jsx";
import courseReq from "../../request/courseReq";
import shotReq from "../../request/shotReq";


const PerPage = 30;


class CourseSeries extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        seriesList: [],
        showEditModal: false,
        editSeries: null

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    /* life circle method */

    componentDidMount() {
        this.addGlobalListener();
        this.getSeriesList();
    }

    componentWillUnmount() {
        this.removeGlobalListener();
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.seriesList[e.target.dataset.index];
        this.setState({
            editSeries: {...item, origin: item},
            showEditModal: true
        })

    }
    willDeleteSeries = null;
    deleteSeries = ()=>{
        courseReq.deleteSeries(this.willDeleteSeries.id)
            .then(res =>{
                let seriesList =  this.state.seriesList;
                seriesList.splice(this.willDeleteSeries.rowIndex,1);
                this.setState({
                    seriesList
                })
                message.success(`已删除系列`);
            })
            .catch(error =>{
                message.error(`删除系列失败：${error && error.message ? error.message:""}`)
            })
    }
    sortableItem = (sortableInfo, index) => {
        const {id} = sortableInfo;
        return (
            <List.Item
                actions={[<a key="list-loadmore-edit" onClick={this.handlerEditItem} data-index={index}>编辑</a>,
                    <Popconfirm
                        placement="leftBottom"
                        title={`确认删除该系列？${sortableInfo.courseCount > 0 ? `该系列已关联${sortableInfo.courseCount}个课程`:""}`}
                
                        onConfirm={this.deleteSeries}
                        okText="删除"
                        cancelText="取消"
                    ><a key="list-loadmore-more" onClick={()=>{
                        this.willDeleteSeries = {...sortableInfo,rowIndex:index}
                    }}>删除</a></Popconfirm>]}
                className={'list-item'}
                draggable
                onDragStart={() => {
                    this.setState({
                        dragElement: sortableInfo,
                    });
                }}
                onDragEnd={this.onDragEnd}
                onDragEnter={() => {
                    if (id !== this.state.dragElement.id) {
                        const oldDragIndex = this.state.seriesList.findIndex(item => item.id === this.state.dragElement.id);
                        const oldEnterIndex = this.state.seriesList.findIndex(item => item.id === sortableInfo.id);
                        if (oldDragIndex > oldEnterIndex) {
                            const newDataArray = this.state.seriesList.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id);
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({seriesList: newDataArray});
                        } else {
                            const newDataArray = this.state.seriesList.filter(item => item.id !== this.state.dragElement.id);
                            const insertIndex = newDataArray.findIndex(item => item.id === sortableInfo.id) + 1;
                            newDataArray.splice(insertIndex, 0, this.state.dragElement);
                            this.setState({seriesList: newDataArray});
                        }
                        this.setState({
                            isConfigDirty: true,
                        });
                    }
                }}
                onDragLeave={() => {
                    if (sortableInfo.id !== this.state.dragElement.id) {
                        if (this.state.lock && sortableInfo.id === this.state.seriesList[this.state.seriesList.length - 1]) {
                            const newDataArray = this.state.seriesList.filter(item => item.id !== this.state.dragElement.id);
                            newDataArray.push(this.state.dragElement);
                            this.setState({
                                lock: false,
                            }, () => {
                                this.setState({
                                    seriesList: newDataArray,
                                });
                            });
                        } else {
                            this.setState({
                                lock: true,
                            });
                        }
                    }
                }}

            >

                <SeriesListItem data={sortableInfo}/>
            </List.Item>

        )
    }
    SeriesSortTimer = null;
    clearSortTimer = ()=>{
        if(this.SeriesSortTimer){
            clearTimeout(this.SeriesSortTimer);
        }
    }
    startSortTimer = ()=>{
        this.clearSortTimer();
        this.SeriesSortTimer = setTimeout(()=>{
            courseReq.saveSeriesSort(this.state.seriesList.map(item =>item.id))
                .then(res =>{
                    message.success(`已保存系列排序`)
                })
                .catch(error =>{
                    message.error(`系列排序保存失败：${error ? error.message:""}`)
                })
        },1000)
    }
    onDragEnd = (e) => {
        this.startSortTimer();
    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editSeries: null,
            showEditModal: false
        })
    }

    getSeriesList(){
        courseReq.getAllSeries()
            .then(res =>{
                this.setState({
                    seriesList:res
                })
            })
            .catch(error =>{
                message.error(`获取作品系列失败：${error ? error.message:""}`)
            })
    }

    saveSeries = () => {
        let {editSeries} = this.state;
        if (!editSeries.name) {
            return message.warning(`系列名称不能为空`)
        }
        if (editSeries.origin) {
            if (editSeries.name === editSeries.origin.name) {
                return message.warning(`没有任何修改可以提交`)
            }
            let body = {
                id: editSeries.origin.id,
                name: editSeries.name
            }
            courseReq.updateSeriesInfo(body)
                .then(Series => {
                    let seriesList = this.state.seriesList;
                    if (seriesList && seriesList.length) {
                        for (let i = 0; i < seriesList.length; i++) {
                            let Series = seriesList[i];
                            if (Series.id === editSeries.origin.id) {
                                Series.name = editSeries.name;
                                break;
                            }
                        }
                    }

                    this.setState({
                        seriesList,
                        showEditModal: false,
                        editSeries: {}
                    })
                    message.success(`已更新系列信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名系列，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {
            let body = {
                name: editSeries.name
            }
            courseReq.createSeries(body)
                .then(Series => {
                    let seriesList = this.state.seriesList;
                    seriesList.push(Series);
                    this.setState({
                        seriesList,
                        showEditModal: false,
                        editSeries: {}
                    })
                    message.success(`已创建新系列`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名系列，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    render() {

        let {seriesList, showEditModal, editSeries} = this.state;

        return (
            <div className='page-course-series'>
                <div className={'list-header'}>
                    <h2>课程系列</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editSeries: {},
                                showEditModal: true
                            })
                        }}>+新系列</Button>
                    </div>
                </div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={seriesList}
                    footer={null}
                    renderItem={(item, index) => {
                        return this.sortableItem(item, index)
                    }}
                />
                {/*编辑模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-course-series'}
                    centered={true}
                >
                    <h2>编辑系列</h2>
                    <Input className={'input'}
                           value={editSeries && editSeries.hasOwnProperty('name') ? editSeries.name : editSeries && editSeries.origin ? editSeries.origin.name : ""}
                           placeholder={"系列名称"} onChange={(e) => {
                        let editSeries = this.state.editSeries;
                        editSeries.name = e.target.value;
                        this.setState({
                            editSeries
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.saveSeries}>保存</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default CourseSeries;
