import React from 'react';
import {
    Row,
    Col,
    Input,
    Icon,
    Radio,
    Button,
    Breadcrumb,
    Upload,
    Select,
    Cascader,
    Avatar,
    Tag,
    InputNumber,
    Switch,
    Modal, Spin, message,
    Progress,
    Popconfirm, DatePicker,
    Tabs
} from 'antd'
import UserSearcherModal from "../../components/SearchUserModal/UserSearcherModal";
import { parseTimelineDate } from '../../utils/dataformat'
import courseReq from "../../request/courseReq";
import { Link } from 'react-router-dom'
import './audioSeriesEdit.scss'
import { connect } from 'react-redux'


import indexReq from '../../request/indexReq'
import * as qiniu from 'qiniu-js'
import storage from "../../storage/storage";
import IconFont from "../../components/iconfont/icon-font";
import moment from "moment";
import audioReq from "../../request/audioReq";

const uuidv4 = require('uuid/v4');

const { TabPane } = Tabs;
const { Dragger } = Upload;


class AudioSeriesEdit extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        showTeacherAddModal: false,
        uploadingPreviewStatus: 'init', // ing ,error,
        previewProgress: 0,
        audioSeriesId: 0,
        noFound: false,
        pageLoading: true,
        seriesInfo: {},
        changedInfo: {},
        editType: 'create', // 'create','update'
        loadingSeriesStatus: false,
        saving: false,
        currentTab:"base-info"
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {

        this.parsePathName();
        window.onbeforeunload = this.routerWillLeave;
        this.addGlobalListener();

        this.props.history.block((location, action) => {
            console.log('您要放弃修改吗', location)
            return this.routerWillLeave(location)
        });
    }

    getAllSeries() {
        courseReq.getAllSeries()
            .then(res => {

                let seriesCascaderOptions = [];
                let seriesCascaderValues = []
                if (res && res.length) {
                    for (let series of res) {
                        seriesCascaderOptions.push({
                            value: series.id,
                            label: series.name,
                        })
                    }
                }
                seriesCascaderValues = this.state.seriesInfo && this.state.seriesInfo.courseSeriesId ?[ this.state.seriesInfo.courseSeriesId ]:[]
                this.setState({
                    courseSeriesList: [],
                    seriesCascaderOptions,
                    seriesCascaderValues
                })
            })
            .catch(error => {
                message.error(`获取系列系列发生错误`)
            })
    }


    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    routerWillLeave = (nextLocation) => {

        if (this.state.changedInfo && Object.getOwnPropertyNames(this.state.changedInfo).length) {
            return '您有修改未保存，您要放弃修改吗？'
        } else {

            return null;
        }
    };

    parsePathName = () => {
        let pathname = this.props.location.pathname;
        if (pathname === '/audio-series/create') {
            this.setState({
                editType: "create",
                pageLoading: false,
            })
        } else {
            let audioSeriesId = this.props.match.params.audioSeriesId;
            let hash = this.props.location.hash || "#base-info"
            if (audioSeriesId && parseInt(audioSeriesId)) {
                this.setState({
                    editType: "update",
                    audioSeriesId: audioSeriesId,
                    currentTab:hash.replace(`#`,"")
                }, () => {
                    this.getSeriesDetail()
                })

            } else {
                this.setState({
                    pageLoading: false,
                    noFound: true
                })
            }
        }
    }

    previewUploaderOnChange = () => {

    };
    previewUploaderBeforeUpload = (fileInfo) => {
        this.setState({
            uploadingPreviewStatus: "ing", previewProgress: 0
        })
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let changedInfo = this.state.changedInfo || {};
                changedInfo.preview = url;
                this.setState({
                    changedInfo,
                    uploadingPreviewStatus: "init", previewProgress: 0
                })

            },
            progress: (e) => {
                console.log(`上传封面图`, e)
                this.setState({
                    uploadingPreviewStatus: "ing", previewProgress: parseInt(e.total.percent)
                })
            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    uploadingPreviewStatus: "error"
                })
            },
        })
    };


    getSeriesDetail = () => {

        audioReq.getSeriesDetail(this.state.audioSeriesId)
            .then(res => {
                res = this.fetchSeriesDetailInfo(res);
                this.setState({
                    seriesInfo: res,
                    pageLoading: false,
                })

            })
            .catch(error => {
                message.error(`获取播客系列详情发生错误，请刷新页面重试`)
            })
    };

    fetchSeriesDetailInfo(info) {
        if (info && info.tags && typeof info.tags === 'string') {
            info.tags = info.tags.split(',')
        }
        
        if(info.otherSources){
            try{
                info.otherSources = JSON.parse(info.otherSources)
            }catch(e){
                info.otherSources = null;
            }
           
        }

        return info;
    }

    saveSeries = () => {
        let { changedInfo, editType, seriesInfo } = this.state;
        let info = { ...changedInfo };

        if (info.teacherInfo && info.teacherInfo.length) {
            info.teacherInfo = info.teacherInfo.map(item => item.id);
        }
        if (info.tags) {
            info.tags = info.tags.toString();
        }
        if(info.otherSources){
            info.otherSources = JSON.stringify(info.otherSources);
        }
        if (editType === 'create') {
            if (!info.title) {
                return message.warning(`系列的标题不能为空`)
            }
            this.setState({
                saving: true
            })
            
            audioReq.createAudioSeries(info)
                .then(res => {
                    message.success(`系列已创建`)
                    this.setState({
                        changedInfo: {}
                    },()=>{
                        window.location.href = `/course-series/${seriesInfo.id}`
                    })

                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`创建失败，请重试`)
                })
        }

        if (editType === 'update') {

            if (info.hasOwnProperty(`title`) && !info.title) {
                return message.warning(`标题不能为空`)
            }

            this.setState({
                saving: false
            })
            info.id = this.state.seriesInfo.id
            audioReq.updateAudioSeries(info)
                .then(res => {
                    console.log(`sdfsdfsd`,res)
                    let seriesInfo = this.fetchSeriesDetailInfo(res);
                    this.setState({

                        seriesInfo: seriesInfo,
                        saving: false
                    }, () => {
                        this.setState({
                            changedInfo: {},
                        })
                    })

                    message.success(`修改已保存`)
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        saving: false
                    })
                    message.error(`保存失败，请重试`)
                })
        }
    };

    changeSeriesPublish = value => {
        this.setState({
            loadingSeriesStatus: true,
        })
        audioReq.changeSeriesPublish(value, this.state.seriesInfo.id)
            .then(() => {
                let seriesInfo = this.state.seriesInfo;
                seriesInfo.status = value ? 'published' : "unpublished"
                this.setState({
                    loadingSeriesStatus: false,
                })
                message.success(`操作成功`)
            }).catch(error => {
                this.setState({
                    loadingSeriesStatus: false,
                })
                message.error(`操作失败，请重试`)
            });
    }
    audioSortSaveTimer = null;
    clearAudioSortSaveTimer = () => {
        if (this.audioSortSaveTimer) {
            clearTimeout(this.audioSortSaveTimer);
        }
    };

    startSeriesSortSaveTimer = () => {
        this.clearAudioSortSaveTimer();
        this.audioSortSaveTimer = setTimeout(() => {
            let audios = this.state.seriesInfo.audios;
            audioReq.saveAudioSort(this.state.seriesInfo.id,audios.map(item => item.id))
                .then(res => {
                    message.success(`保存音频排序成功`)
                })
                .catch(error => {
                    message.success(`保存音频排序失败`)
                })
        }, 1000)
    }

    tabOnChange = (key) => {
        console.log(`path`,this.props.location)
        this.setState({
            currentTab:key
        })
        this.props.history.replace(this.props.location.pathname + "#" + key);
    }

    tagsHandleChange = (e) => {
        let { changedInfo } = this.state;
        changedInfo = changedInfo || {};
        changedInfo.tags = e;
        this.setState({
            changedInfo
        })
    }


    parseVideoDuration(duration) {
        let minute = parseInt(duration / 60);
        if (minute < 1) {
            minute = '00';
        } else if (minute < 10) {
            minute = `0${minute}`;
        }
        let second = duration % 60;
        if (second < 1) {
            second = '00';
        } else if (second < 10) {
            second = `0${second}`;
        }
        return `${minute}:${second}`
    }

    render() {
        let { seriesCascaderValues, seriesCascaderOptions, editorState, showSoftAddModal, noFound, pageLoading, changedInfo, seriesInfo, editType, categoryCascaderOptions, categoryCascaderValues, apps, previewProgress, uploadingPreviewStatus, showTeacherAddModal, loadingSeriesStatus, courseType, courseSeriesList } = this.state;

        if (pageLoading) {
            return <div className={'page-course-create'}>
                <Spin size={'large'} />
            </div>
        }

        if (noFound) {
            return <div className={'page-course-create'}>
                <div className={'no-found'}>
                    <p>播客系列不存在或已被删除</p>
                    <Button type={'primary'}>查看所有播客系列</Button>
                </div>
            </div>
        }
        changedInfo = changedInfo || {};

        let teachers = changedInfo.teacherInfo || seriesInfo.teacherInfo || []
        let courseTeachers = teachers.map((one, index) => {
            return <div className={'item'} key={index} style={{ marginRight: 10, marginBottom: 10 }}>
                <Avatar className={'avatar'}
                    src={one.avatar} />
                <p className='nickname'>{one.nickname}</p>
                <Icon className={'close-icon'} type={'close'} onClick={() => {
                    let changedInfo = this.state.changedInfo || {};
                    let teachers = changedInfo.teacherInfo || seriesInfo.teacherInfo || [];
                    let idx = teachers.findIndex(item => item.id === one.id);
                    teachers = [...teachers];
                    teachers.splice(idx, 1);
                    changedInfo.teacherInfo = teachers;
                    this.setState({
                        changedInfo
                    })
                }} />
            </div>
        })
        let audios = seriesInfo.audios || []
        let audioList = audios.map((one, index) => {
            // let courseFree = changedInfo.hasOwnProperty(`free`) ? !!changedInfo.free : !!seriesInfo.free;
            // let showFree = courseFree || !!one.free;
            return <div className={'lesson-item'} key={index}

                draggable={true}
                onDragStart={() => {
                    this.setState({
                        draggingAudioItem: one,
                    });
                    this.clearAudioSortSaveTimer()

                }}
                onDragEnter={() => {
                    console.log('app onDragEnter');

                    let draggingAudioItem = this.state.draggingAudioItem;
                    if (one.id !== this.state.draggingAudioItem.id) {
                        let seriesInfo = this.state.seriesInfo;
                        const oldDragIndex = seriesInfo.audios.findIndex(item => item.id === draggingAudioItem.id);
                        const oldEnterIndex = seriesInfo.audios.findIndex(it => it.id === one.id);
                        if (oldDragIndex > oldEnterIndex) {
                            const newDataArray = seriesInfo.audios.filter(item => item.id !== this.state.draggingAudioItem.id);
                            const insertIndex = newDataArray.findIndex(it => it.id === one.id);

                            newDataArray.splice(insertIndex, 0, this.state.draggingAudioItem);
                            seriesInfo.audios = newDataArray;
                            this.setState({ seriesInfo });
                        } else {
                            const newDataArray = seriesInfo.audios.filter(item => item.id !== this.state.draggingAudioItem.id);
                            const insertIndex = newDataArray.findIndex(it => it.id === one.id) + 1;
                            newDataArray.splice(insertIndex, 0, this.state.draggingAudioItem);
                            seriesInfo.audios = newDataArray;
                            this.setState({ seriesInfo });
                        }
                        this.setState({
                            isConfigDirty: true,
                        });
                    }
                }}
                onDragLeave={() => {

                    console.log('app onDragLeave')
                    if (one.id !== this.state.draggingAudioItem.id) {
                        let seriesInfo = this.state.seriesInfo;
                        if (this.state.appLock && one.id === seriesInfo.audios[seriesInfo.audios.length - 1]) {
                            const newDataArray = seriesInfo.audios.filter(item => item.id !== this.state.draggingAudioItem.id);
                            newDataArray.push(this.state.draggingAudioItem);
                            seriesInfo.audios = newDataArray;
                            this.setState({
                                appLock: false,
                            }, () => {
                                this.setState({
                                    seriesInfo
                                });
                            });
                        } else {
                            this.setState({
                                appLock: true,
                            });
                        }
                    }
                }}
                onDragEnd={() => {
                    console.log(`onDragEnd`)
                    this.setState({
                        draggingAudioItem: null,

                    })

                }}

                onDrop={() => {
                    this.setState({
                        draggingAudioItem: null,

                    })
                    this.startSeriesSortSaveTimer()
                }}

            >
                <div className={'title'}>{one.title}</div>
                <div className={'flex-r'}>
                    <div className={'item-props'}>
                        <div className={'duration'}>{ one.duration ? this.parseVideoDuration(one.duration ) : ""}</div>
                        <div className={'status'}>
                            {/*<Tag color={'gold'} hidden={!showFree}>免费</Tag>*/}
                            <Tag color={'gray'} hidden={one.status === 'published'}>已下架</Tag>
                        </div>

                    </div>
                    <div className={'item-actions'}>
                        <a onClick={() => {
                            let changedInfo = this.state.changedInfo;
                            if (changedInfo && Object.getOwnPropertyNames(changedInfo).length) {
                                return Modal.warning({
                                    title: '请保存你的系列',
                                    content: '当前系列已被修改，请保存或取消保存后，再进行操作',
                                });
                            } else {
                              
                                this.props.history.push(`/audioEdit/${seriesInfo.id}/audio/${one.id}`)
                            }
                        }}>编辑</a>
                        <a  onClick={e => {

                            let seriesInfo = this.state.seriesInfo;
                            let audio = seriesInfo.audios[index]
                            if (audio) {
                                audio.status = audio.status === 'published' ? "unpublished" : "published"
                                this.setState({
                                    seriesInfo,
                                })
                                audioReq.changeAudioStatus(audio.id, audio.status === 'published')
                                    .then(lesson => {

                                    })
                                    .catch(error => {
                                        audio.status = audio.status === 'published' ? "unpublished" : "published"
                                        this.setState({
                                            seriesInfo
                                        })
                                        message.error(`操作失败，请重试`)
                                    })

                            }
                        }}>{one.status === 'published' ? "下架" : "发布"}</a>
                        {/*<a hidden={courseFree}>{showFree ? "免费试看" : "取消免费"}</a>*/}
                        <Popconfirm
                            placement="leftBottom"
                            title="你确定要删除这个音频吗？"
                            onConfirm={() => {
                                audioReq.deleteAudio(one.id)
                                    .then(() => {
                                        let course = this.state.seriesInfo;
                                        course.audios.splice(index, 1);
                                        this.setState({
                                            seriesInfo: course
                                        })
                                        message.success(`音频已删除！`)
                                    })
                                    .catch(error => {
                                        message.error(`删除失败！`)
                                    })

                            }}
                            okText="确认删除"
                            cancelText="取消"
                        >
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        })
        return (
            <div className='page-course-create'>
                <Breadcrumb>
                    <Breadcrumb.Item>主页</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>{editType === "create"? "创建播客系列" : "编辑播客系列"}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className={'page-header'}>
                    <Icon type="arrow-left" className={`back-icon`} onClick={() => {
                        this.props.history.goBack();
                    }} />
                    <h1>{editType === "create"? "创建播客系列" : "编辑播客系列"}</h1>
                </div>

                <div className={`page-content`}>


                            <Row gutter={16}>
                                <Col span={12}>

                                    <div className={'form-item'}>
                                        <h3>系列标题</h3>
                                        <Input
                                            value={changedInfo.hasOwnProperty(`title`) ? changedInfo.title : seriesInfo.title || ""}
                                            className={'input'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.title = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>标签</h3>
                                        <Select
                                            mode="tags"
                                            size={'large'}
                                            placeholder="系列标签，以逗号或回车分隔"
                                            value={changedInfo.hasOwnProperty(`tags`) ? changedInfo.tags :   seriesInfo.tags || []}
                                            onChange={this.tagsHandleChange}
                                            style={{ width: '100%' }}
                                            allowClear={true}
                                            tokenSeparators={['\n', ',', '，']}
                                        >

                                        </Select>
                                    </div>
                                    <div className={'form-item'} >
                                        <h3>系列摘要</h3>
                                        <Input.TextArea
                                            value={changedInfo.hasOwnProperty(`description`) ? changedInfo.description : seriesInfo.description || ""}
                                            className={'input textarea'} onChange={e => {
                                                let changedInfo = this.state.changedInfo;
                                                changedInfo.description = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                    </div>

                                    <div className={'form-item'}>
                                        <h3>封面图</h3>
                                        <Dragger className={'uploader'}
                                                 accept={'image/jpeg,image/png,image/jpg,image/gif'}
                                                 showUploadList={false}
                                                 beforeUpload={this.previewUploaderBeforeUpload} disabled={this.state.uploading}>
                                            <div className={'uploader-container'}
                                            >
                                                <div className={'tip'} hidden={changedInfo.preview || seriesInfo.preview}>

                                                    <p className="ant-upload-drag-icon">
                                                        <Icon type="inbox" />
                                                    </p>
                                                    <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                                    <p className="ant-upload-hint">
                                                        仅支持单张图片上传
                                                    </p>
                                                </div>

                                                <div className={'course-preview'}
                                                     hidden={!changedInfo.preview && !seriesInfo.preview}>
                                                    <img src={changedInfo.preview || seriesInfo.preview || ""} alt={''} />
                                                </div>
                                                <Progress className={'progress'} hidden={uploadingPreviewStatus === 'init'}
                                                          strokeColor='#3340ff' type="circle"
                                                          percent={previewProgress} width={80}
                                                          status={uploadingPreviewStatus === 'error' ? "exception" : "normal"} />
                                            </div>


                                        </Dragger>
                                    </div>
                                    <div className={'form-item'}>
                                        <h3>其他播客源</h3>

                                        <div className='child-item'>
                                            <div className='child-item-title'>网易云音乐</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.wangyiMusic : seriesInfo.otherSources ? seriesInfo.otherSources.wangyiMusic : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources ;
                                                otherSources.wangyiMusic = e.currentTarget.value;
                                                console.log(`ddddd`,changedInfo)
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                        <div className='child-item'>
                                            <div className='child-item-title'>喜马拉雅听书</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.ximalayaFM : seriesInfo.otherSources ? seriesInfo.otherSources.ximalayaFM : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.ximalayaFM = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                        <div className='child-item'>
                                            <div className='child-item-title'>哔哩哔哩</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.bilibili : seriesInfo.otherSources ? seriesInfo.otherSources.bilibili : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.bilibili = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                        <div className='child-item'>
                                            <div className='child-item-title'>iTunes播客</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.iTunesPodcasts : seriesInfo.otherSources ? seriesInfo.otherSources.iTunesPodcasts : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.iTunesPodcasts = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                        <div className='child-item'>
                                            <div className='child-item-title'>Google Podcasts</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.googlePodcasts : seriesInfo.otherSources ? seriesInfo.otherSources.googlePodcasts : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.googlePodcasts = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>

                                        <div className='child-item'>
                                            <div className='child-item-title'>Overcast</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.overcast : seriesInfo.otherSources ? seriesInfo.otherSources.overcast : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.overcast = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>

                                        <div className='child-item'>
                                            <div className='child-item-title'>Poctet Casts</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.poctetCasts : seriesInfo.otherSources ? seriesInfo.otherSources.poctetCasts : ""}
                                            className={'input '} placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.poctetCasts = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>

                                        <div className='child-item'>
                                            <div className='child-item-title'>Spotify</div>
                                     
                                            <Input
                                            value={changedInfo.hasOwnProperty(`otherSources`) ? changedInfo.otherSources.spotify : seriesInfo.otherSources ? seriesInfo.otherSources.spotify : ""}
                                            className={'input '}  placeholder='请填写链接地址' onChange={e => {
                                                let changedInfo = this.state.changedInfo || {};
                                                changedInfo.otherSources = changedInfo.otherSources ? changedInfo.otherSources : seriesInfo  && seriesInfo.otherSources ? seriesInfo.otherSources: {}
                                                let otherSources = changedInfo.otherSources || {};
                                                otherSources.spotify = e.currentTarget.value;
                                                this.setState({
                                                    changedInfo
                                                })
                                            }} />
                                        </div>
                                      
                                    </div>
                                    <div className={'form-item'}>
                                        <h3>系列老师</h3>
                                        <div className={'course-owners'}>
                                            {courseTeachers}

                                            <div className={'item owner-add'} onClick={() => {
                                                this.setState({
                                                    showTeacherAddModal: true
                                                })
                                            }}>
                                                <Icon type={'plus'} style={{ color: "#3340ff" }} />
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col span={12}>


                                    <div className={'form-item'} hidden={courseType === 'live-course'}>
                                        <h3>音频列表（{seriesInfo && seriesInfo.audios ? seriesInfo.audios.length : 0}节）</h3>

                                        <div className={'lesson-list'}>
                                            {audioList}
                                            <div className={'flex-b'}>
                                                <div className={'add-lesson'} onClick={() => {
                                                    if (changedInfo && Object.getOwnPropertyNames(changedInfo).length) {
                                                        return Modal.warning({
                                                            title: '请保存你的系列',
                                                            content: '当前系列已被修改，请保存或取消保存后，再进行操作',
                                                        });
                                                    } else {
                                                        this.props.history.push(`/audioEdit/${seriesInfo.id}/audio/create`)
                                                    }
                                                }}> + 添加音频
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                            </Row>


                </div>



                <div className={'bottom-bar'}>
                    <Switch className={'publish-switch'} checkedChildren="上架" unCheckedChildren="下架"
                        checked={seriesInfo && seriesInfo.status === 'published'}
                        disabled={!seriesInfo || editType === 'create'}
                        size={'large'}
                        hidden={editType === 'create'}
                        loading={loadingSeriesStatus}
                        onChange={this.changeSeriesPublish}
                    />
                    <Button type={'danger'} style={{ marginRight: 15 }}
                        hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                            Modal.confirm({
                                title: "你确定要放弃所有修改记录吗？",
                                okText: "确定放弃",
                                onOk: () => {
                                    this.setState({
                                        changedInfo: {}
                                    })
                                }
                            })
                        }}>放弃所有修改</Button>
                    <Button type={'primary'} disabled={!changedInfo || !Object.getOwnPropertyNames(changedInfo).length}
                        onClick={this.saveSeries} loading={this.state.saving}>保存</Button>
                </div>
                {/*添加系列软件弹窗*/}
                <Modal

                    visible={showSoftAddModal}
                    // onOk={}
                    onCancel={() => {
                        this.setState({
                            showSoftAddModal: false
                        })
                    }}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                >
                    <h2>编辑音频</h2>

                </Modal>
                {/*    添加老师*/}
                <UserSearcherModal visible={showTeacherAddModal} title={'添加老师'} selectText={'已添加'} normalText={`添加`}
                    onCancel={() => {
                        this.setState({
                            showTeacherAddModal: false
                        })
                        Modal.destroyAll()
                    }} defaultSelected={changedInfo.teacherInfo || seriesInfo.teacherInfo || []}
                    changedSelected={selectedUser => {
                        let changedInfo = this.state.changedInfo || {};
                        let teacherInfo = changedInfo.teacherInfo || seriesInfo.teacherInfo || [];
                        teacherInfo = [...teacherInfo];
                        teacherInfo.push(selectedUser);
                        changedInfo.teacherInfo = teacherInfo;
                        this.setState({
                            changedInfo
                        })
                    }} />
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {}
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AudioSeriesEdit);
