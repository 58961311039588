import React from 'react';
import {Modal, Input, Button, Popconfirm, Icon, message, Spin, Upload, Row, Col} from 'antd'
import './toolCategory.scss'
import indexReq from "../../request/indexReq";
import toolReq from "../../request/toolReq";
import AppCardItem from "../../components/appCardItem/AppCardItem";

const {TextArea} = Input;
class ToolDash extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    dragTimer = null
    state = {
        groups: [],
        showEditModal: false,
        editCategory: null,
        editGroup: null,
        editCourseApp: null,
        loadingGroupsData: false,
        showEditGroupModal: false,
        showEditCourseAppModal: false,
        appIconUploading: false,
        apps: [],
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    /* life circle method */
    componentDidMount() {
        this.addGlobalListener();
        this.getAllGroups();
    }

    componentWillUnmount() {
        this.removeGlobalListener()
    }

    addGlobalListener = () => {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    };
    removeGlobalListener = () => {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    };
    preventDefault = (e) => {
        e.preventDefault();
    };

    handlerEditItem = (e) => {
        console.log('handlerEditItem', e.target.dataset)
        let item = this.state.categories[e.target.dataset.index];
        this.setState({
            editCategory: {...item, origin: item},
            showEditModal: true
        })
    };


    onDragEnd = (e) => {

    }

    handlerEditModalOk = () => {

    }
    handlerEditModalCancel = () => {
        this.setState({
            editCategory: null,
            showEditModal: false
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        toolReq.getGroupToolList()
            .then(resGroups => {
                this.setState({
                    loadingGroupsData: false
                })
                this.setState({
                    groups: resGroups
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    handlerEditGroupModalCancel = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };
    handlerEditGroupModalOk = () => {
        this.setState({
            editGroup: {},
            showEditGroupModal: false
        })
    };

    submitCategory = () => {
        let {editCategory} = this.state;
        if (!editCategory.name) {
            return message.warning(`分类名称不能为空`)
        }
        if (editCategory.origin) {
            if (editCategory.name === editCategory.origin.name) {
                return message.warning(`没有任何修改可以提交`)
            }
            toolReq.updateCategory(editCategory.name, editCategory.origin.id)
                .then(category => {
                    let groups = this.state.groups;
                    for (let index = 0; index < groups.length; index++) {
                        let group = groups[index];
                        if (group.categories && group.categories.length) {
                            for (let i = 0; i < group.categories.length; i++) {
                                let category = group.categories[i];
                                if (category.id === editCategory.origin.id) {
                                    category.name = editCategory.name;
                                    break;
                                }
                            }
                        }
                    }
                    this.setState({
                        groups,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已更新分类信息`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })

        } else {

            toolReq.addNewCategoryToGroup({name: editCategory.name}, editCategory.group.id)
                .then(category => {
                    let groups = this.state.groups;
                    let groupIndex = groups.findIndex(item => item.id === editCategory.group.id);
                    let group = groups[groupIndex];
                    group.categories = group.categories || [];
                    group.categories.push(category);
                    this.setState({
                        groups,
                        showEditModal: false,
                        editCategory: {}
                    })
                    message.success(`已添加新分类到分组`)
                })
                .catch(error => {
                    if (error && error.code === 7208) {
                        return message.error("已存在同名分类，请使用其他名称")
                    }
                    return message.error(`发生错误，请重试`)
                })
        }
    }

    submitGroup = () => {
        const {editGroup} = this.state;
        if (editGroup.origin) {
            toolReq.updateToolGroupInfo({id:editGroup.origin.id,name:editGroup.name})
                .then(res =>{
                    let groups = this.state.groups;
                    let index = groups.findIndex(item => item.id === editGroup.origin.id);
                    let group = groups[index];
                    group.name = editGroup.name;
                    this.setState({
                        groups,
                        showEditGroupModal: false,
                        editGroup: {}
                    })
                    message.success(`分组信息已更新`)
                })
                .catch(error => {
                    if (error && error.code === 7204) {
                        return message.error(error.message)
                    }
                    return message.error(`发生错误，请重试`)
                })
        } else {
            if (!editGroup.name) {
                return message.warning(`请填写分组名称，不得重名`)
            } else {
                toolReq.createToolGroup({name:editGroup.name})
                    .then(newGroup => {
                        let groups = this.state.groups;
                        groups.unshift(newGroup);
                        this.setState({
                            groups,
                            showEditGroupModal: false,
                            editGroup: {}
                        })
                        message.success(`已成功创建新分组`)
                    })
                    .catch(error => {
                        if (error && error.code === 7203) {
                            return message.error("已存在同名分组，请使用其他名称")
                        }
                        return message.error(`发生错误，请重试`)
                    })
            }
        }
    };

    willDeleteTool = null
    deleteTool = () => {
        toolReq.deleteTool(this.willDeleteTool.id)
            .then(res => {
                let groups = this.state.groups;
                for (let index = 0; index < groups.length; index++) {
                    let group = groups[index];
                    if (group.toolInfo && group.toolInfo.length) {
                        for (let i = 0; i < group.toolInfo.length; i++) {
                            let category = group.toolInfo[i];
                            if (category.id === this.willDeleteTool.id) {
                                group.toolInfo.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
                this.setState({
                    groups
                })
                this.willDeleteTool = null;
                message.success(`工具已删除，并从分组中移除`)
            })
            .catch(error => {
                this.willDeleteCategory = null;
                if(error.code === 7209){
                    return   message.error(error.message)
                }
                message.error(`工具删除失败`)
            })
    };

    willDeleteGroup = null
    deleteGroup = () => {
        toolReq.deleteToolGroup(this.willDeleteGroup.id)
            .then(res => {
                let groups = this.state.groups;
                let index = groups.findIndex(item => item.id === this.willDeleteGroup.id);
                groups.splice(index, 1);
                this.willDeleteGroup = null;
                this.setState({
                    groups
                })
                message.success(`分组已删除`)
            })
            .catch(error => {
                this.willDeleteGroup = null;
                message.error(`分组删除失败`)
            })
    }

    draggingType = "";
    handlerEditAppModalCancel = () => {
        this.setState({
            showEditCourseAppModal: false,
            editCourseApp: null
        })
    }
    handlerEditAppModalOk = () => {
        this.setState({
            showEditCourseAppModal: false
        })
    }

    appIconUploaderOnChange = () => {


    }

    appIconUploaderBeforeUpload = (fileInfo) => {
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let editCourseApp = this.state.editCourseApp || {};
                editCourseApp.preview = url;
                this.setState({
                    editCourseApp
                })

            },
            progress: (e) => {

            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    appIconUploading: false
                })
            },
        })
    };

    willDeleteApp = null;
    handlerDeleteApp = () => {
        toolReq.deleteApp(this.willDeleteApp.id)
            .then(res => {
                let apps = this.state.apps;
                let index = apps.findIndex(item => item.id === this.willDeleteApp.id);
                apps.splice(index, 1);
                this.willDeleteApp = null;
                this.setState({
                    apps,

                })
                message.success(`软件已删除`)
            })
            .catch(error => {
                this.willDeleteApp = null;
                message.error(`软件删除失败`)
            })
    }

    onSubmitCourseApp = () => {
        let {editCourseApp} = this.state;
        editCourseApp = editCourseApp || {};
        if (editCourseApp.origin) {

            if (!editCourseApp.name && !editCourseApp.preview && !editCourseApp.subtitle && !editCourseApp.url) {
                return message.warning(`没有修改任何内容`)
            }

            if (editCourseApp.hasOwnProperty(`name`) && !editCourseApp.name) {
                return message.warning(`工具名称不能为空`)
            }
            if (editCourseApp.hasOwnProperty(`preview`) && !editCourseApp.preview) {
                return message.warning(`请上传工具icon`)
            }
            if (editCourseApp.hasOwnProperty(`url`) && !editCourseApp.url) {
                return message.warning(`请填写链接`)
            }
           
            let origin = {...editCourseApp.origin};
            let group = {...editCourseApp.group};
            delete editCourseApp.origin;
            delete editCourseApp.group;
            toolReq.updateTool({
                ...editCourseApp, id: origin.id
            })
                .then(newApp => {
                    let groups = this.state.groups;
                    let gidx = groups.findIndex(item => item.id === group.id);
                    let g = groups[gidx];
                    let toolIndex = g.toolInfo.findIndex(item => item.id === origin.id);
                    if (editCourseApp.name) {
                        origin.name = editCourseApp.name;
                    }
                    if (editCourseApp.preview) {
                        origin.preview = editCourseApp.preview;
                    }
                    if (editCourseApp.subtitle) {
                        origin.subtitle = editCourseApp.subtitle;
                    }
                    if (editCourseApp.url) {
                        origin.url = editCourseApp.url;
                    }
                    g.toolInfo.splice(toolIndex, 1, origin)
                    this.setState({
                        groups,
                        showEditCourseAppModal: false,
                        editCourseApp: {}
                    })
                    message.success(`已成功修改工具`)
                })
               .catch(error=>{
                message.error(`修改工具失败：${error ? error.message:""}`)
             })


        } else {
            if (!editCourseApp.name) {
                return message.warning(`请填写工具名称`)
            }
            if (!editCourseApp.url) {
                return message.warning(`请填写工具链接`)
            }
            if (!editCourseApp.preview) {
                return message.warning(`请上传工具Icon`)
            }
            let info = {
                name:editCourseApp.name,
                preview:editCourseApp.preview,
                subtitle:editCourseApp.subtitle || "",
                groupId:editCourseApp.group.id,
                url:editCourseApp.url
            }
            toolReq.createTool(info)
                .then(newApp => {
                    let groups = this.state.groups;
                    let gidx = groups.findIndex(item => item.id === editCourseApp.group.id);
                    let g = groups[gidx];
                    g.toolInfo  = g.toolInfo || [];
                    g.toolInfo.push(newApp)
                    this.setState({
                        groups,
                        showEditCourseAppModal: false,
                        editCourseApp: {}
                    })
                    message.success(`已成功添加工具`)
                })
                .catch(error => {
                    message.error(`添加工具失败：${error ? error.message:""}`)
                })

        }
    }

    setSaveTimer = () => {
        this.clearSaveTimer()
        this.dragTimer = setTimeout(() => {
            this.saveDragSortResult()
        }, 2000)
    };
    clearSaveTimer = () => {
        if (this.dragTimer) {
            clearTimeout(this.dragTimer);
        }
    };
    saveDragSortResult = () => {

        return
        let newData = [];
        for (let g of this.state.groups) {
            let group = {
                id: g.id,
                categories: []
            }
            if (g.categories && g.categories.length) {
                for (let c of g.categories) {
                    group.categories.push({
                        id: c.id
                    })
                }
            }
            newData.push(group);
        }
        toolReq.saveCourseGroupCategorySort(newData)
            .then(res => {
                message.success(`保存排序成功`);
            })
            .catch(error => {
                if (error.message) {
                    return message.error(error.message);
                }
                message.error(`保存排序失败`);
            })
    };
    clearAppSaveTimer = () => {
        if (this.saveAppSortTimer) {
            clearTimeout(this.saveAppSortTimer);
        }
    }
    saveAppSortTimer = null;
    startSaveAppSortTimer = () => {

        if (this.saveAppSortTimer) {
            clearTimeout(this.saveAppSortTimer);
        }
        let group = this.state.groups[this.state.draggingAppInGroupIndex];
        let g = {...group};

        this.saveAppSortTimer = setTimeout(() => {

            toolReq.changeToolSortInGroup(g.id,g.toolInfo.map(item =>item.id))
                .then(res => {
                    message.success(`已保存工具排序`)
                })
                .catch(error => {
                    message.success(`保存工具排序失败:${error.message  || ""}`)
                })

        }, 1000)
    }

    render() {

        let {showEditModal, editCategory, groups, loadingGroupsData, showEditGroupModal, editGroup, editCourseApp, showEditCourseAppModal, appIconUploading, apps} = this.state;

        let groupList = groups.map((group, index) => {
            let childList = [];
            let gIndex = index;
            if (group.toolInfo && group.toolInfo.length) {
                childList = group.toolInfo.map((item, index) => {


                    return <Col span={4} key={index}>
                        <AppCardItem rowKey={index} handlerDeleteApp={()=>{
                            this.willDeleteTool = item;
                            this.deleteTool();
                        }} data={item} onEdit={()=>{
                            let editCourseApp = this.state.editCourseApp || {};
                            editCourseApp.group = group;
                            editCourseApp.origin = item;
                            this.setState({
                                showEditCourseAppModal: true,
                                editCourseApp
                            })
                        }}

                                     draggable={true}
                                     onDragStart={() => {
                                         this.setState({
                                             draggingApp: item,
                                             draggingAppInGroupIndex:gIndex
                                         });
                                         this.clearAppSaveTimer()

                                     }}
                                     onDragEnter={() => {
                                         console.log('app onDragEnter');

                                         let draggingApp = this.state.draggingApp;
                                         if(this.state.draggingAppInGroupIndex !== gIndex){
                                             return
                                         }
                                         if (item.id !== this.state.draggingApp.id) {
                                             let groups = this.state.groups;
                                             let dragInGroup = groups[gIndex];

                                             const oldDragIndex = dragInGroup.toolInfo.findIndex(item => item.id === draggingApp.id);
                                             const oldEnterIndex = dragInGroup.toolInfo.findIndex(it => it.id === item.id);
                                             if (oldDragIndex > oldEnterIndex) {
                                                 const newDataArray = dragInGroup.toolInfo.filter(item => item.id !== this.state.draggingApp.id);
                                                 const insertIndex = newDataArray.findIndex(it => it.id === item.id);

                                                 newDataArray.splice(insertIndex, 0, this.state.draggingApp);
                                                 dragInGroup.toolInfo = newDataArray;
                                                 this.setState({groups});
                                             } else {
                                                 const newDataArray = dragInGroup.toolInfo.filter(item => item.id !== this.state.draggingApp.id);
                                                 const insertIndex = newDataArray.findIndex(it => it.id === item.id) + 1;
                                                 newDataArray.splice(insertIndex, 0, this.state.draggingApp);
                                                 dragInGroup.toolInfo = newDataArray;
                                                 this.setState({groups});
                                             }
                                             this.setState({
                                                 isConfigDirty: true,
                                             });
                                         }
                                     }}
                                     onDragLeave={() => {

                                         // console.log('app onDragLeave')
                                         // if (item.id !== this.state.draggingApp.id) {
                                         //     let groups = this.state.groups;
                                         //     let dragInGroup = groups[gIndex];
                                         //     if (this.state.appLock && apps.id === this.state.apps[this.state.groups.length - 1]) {
                                         //         const newDataArray = this.state.apps.filter(item => item.id !== this.state.draggingApp.id);
                                         //         newDataArray.push(this.state.draggingApp);
                                         //         this.setState({
                                         //             appLock: false,
                                         //         }, () => {
                                         //             this.setState({
                                         //                 apps: newDataArray,
                                         //             });
                                         //         });
                                         //     } else {
                                         //         this.setState({
                                         //             appLock: true,
                                         //         });
                                         //     }
                                         // }
                                     }}
                                     onDragEnd={() => {
                                         console.log(`onDragEnd`)
                                         this.setState({
                                             draggingApp: null,

                                         })

                                     }}

                                     onDrop={() => {
                                         this.setState({
                                             draggingApp: null,

                                         })
                                         this.startSaveAppSortTimer()
                                     }}
                        />
                    </Col>
                })

            }
            return <div className={'group-item'}  key={index}
                        onDragStart={() => {
                            if (this.draggingType) {
                                return
                            }
                            console.log('group onDragStart')
                            this.draggingType = 'group';
                            this.setState({
                                draggingGroup: group,
                            });
                            this.clearSaveTimer()

                        }}
                        onDragEnter={() => {

                            if (this.draggingType !== 'group') {
                                return
                            }
                            console.log(`进入组`,group.name)
                            let draggingGroup = this.state.draggingGroup;
                            if (group.id !== this.state.draggingGroup.id) {
                                const oldDragIndex = this.state.groups.findIndex(item => item.id === draggingGroup.id);
                                const oldEnterIndex = this.state.groups.findIndex(item => item.id === group.id);
                                if (oldDragIndex > oldEnterIndex) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id);
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                } else {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    const insertIndex = newDataArray.findIndex(item => item.id === group.id) + 1;
                                    newDataArray.splice(insertIndex, 0, this.state.draggingGroup);
                                    this.setState({groups: newDataArray});
                                }
                                this.setState({
                                    isConfigDirty: true,
                                });
                            }
                        }}
                        onDragLeave={() => {

                            if (this.draggingType !== 'group') {
                                return
                            }
                            if (group.id !== this.state.draggingGroup.id) {
                                if (this.state.lock && group.id === this.state.groups[this.state.groups.length - 1]) {
                                    const newDataArray = this.state.groups.filter(item => item.id !== this.state.draggingGroup.id);
                                    newDataArray.push(this.state.draggingGroup);
                                    this.setState({
                                        lock: false,
                                    }, () => {
                                        this.setState({
                                            groups: newDataArray,
                                        });
                                    });
                                } else {
                                    this.setState({
                                        lock: true,
                                    });
                                }
                            }
                        }}
                        onDragEnd={() => {
                            console.log(`onDragEnd`)
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null

                        }}
                        onDrop={() => {
                            this.setState({
                                draggingGroup: null,

                            })
                            this.draggingType = null
                            this.setSaveTimer()
                        }}
            >
                <div className={'header'}>
                    <h3>{group.name}</h3>
                    <div className={'actions'}>
                        <a onClick={() => {
                            let editGroup = this.state.editGroup || {};
                            editGroup.origin = group;
                            this.setState({
                                showEditGroupModal: true,
                                editGroup
                            })
                        }}>编辑分组</a>
                        <a onClick={() => {
                            let editCourseApp = this.state.editCourseApp || {};
                            editCourseApp.group = group;
                            this.setState({
                                showEditCourseAppModal: true,
                                editCourseApp
                            })
                        }}>添加工具</a>
                        <Popconfirm title={`你确定要删除该分组吗？`} onCancel={() => {
                            this.willDeleteGroup = null;
                        }} onConfirm={this.deleteGroup}>
                            <a hidden={group.categories && group.categories.length} onClick={() => {
                                this.willDeleteGroup = group;
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                </div>

                <div className={'child-list'}>
                    <Row gutter={[10,10]}>
                    {childList}
                    </Row>
                </div>
            </div>
        });

        return (
            <div className='page-tool'>

                <div className={'list-header'}>
                    <h2>工具</h2>
                    <div className={'flex-r'}>
                        <Button onClick={() => {
                            this.setState({
                                editGroup: {},
                                showEditGroupModal: true
                            })
                        }}>+新分组</Button>
                        {/*<Button type={'primary'}>保存</Button>*/}
                    </div>
                </div>

                <div className={'list'}>
                    <Spin spinning={loadingGroupsData} className={`group-loading`} delay={200}/>
                    {groupList}
                </div>

                {/*编辑分类模态窗*/}
                <Modal

                    visible={showEditModal}
                    onOk={this.handlerEditModalOk}
                    onCancel={this.handlerEditModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editCategory || !editCategory.origin ? "添加分类" : "编辑分类"}</h2>
                    <Input className={'input'}
                           value={editCategory && editCategory.hasOwnProperty('name') ? editCategory.name : editCategory && editCategory.origin ? editCategory.origin.name : ""}
                           placeholder={"分类名称"} onChange={(e) => {
                        let editCategory = this.state.editCategory;
                        editCategory.name = e.target.value;
                        this.setState({
                            editCategory
                        })
                    }}/>
                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editCategory && editCategory.group ? editCategory.group.name : ""}`}</span>
                    </p>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitCategory}>提交</Button>
                    </div>

                </Modal>
                {/*    编辑分组*/}
                <Modal

                    visible={showEditGroupModal}
                    onOk={this.handlerEditGroupModalOk}
                    onCancel={this.handlerEditGroupModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editGroup || !editGroup.origin ? "创建分组" : "编辑分组"}</h2>
                    <Input className={'input'}
                           value={editGroup && editGroup.hasOwnProperty('name') ? editGroup.name : editGroup && editGroup.origin ? editGroup.origin.name : ""}
                           placeholder={"分组名称"} onChange={(e) => {
                        let editGroup = this.state.editGroup;
                        editGroup.name = e.target.value;
                        this.setState({
                            editGroup
                        })
                    }}/>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.submitGroup} disabled={!editGroup ||  !editGroup.name || !editGroup.name.length || (editGroup &&  editGroup.origin && editGroup.name === editGroup.origin.name) }>提交</Button>
                    </div>

                </Modal>

                {/*    编辑课程软件*/}
                <Modal

                    visible={showEditCourseAppModal}
                    onOk={this.handlerEditAppModalOk}
                    onCancel={this.handlerEditAppModalCancel}
                    destroyOnClose={true}
                    footer={null}
                    className={'modal-edit-shot-category'}
                    centered={true}
                    maskClosable={false}
                >
                    <h2>{!editCourseApp || !editCourseApp.origin ? "添加工具" : "编辑工具信息"}</h2>

                    <div className={'flex-l'}>
                        <Upload className={'app-icon-uploader'} accept={'image/*'}
                                showUploadList={false}
                                beforeUpload={this.appIconUploaderBeforeUpload}
                                disabled={appIconUploading}>
                            <div className={'app-icon-add'}
                                 hidden={editCourseApp && (editCourseApp.preview || (editCourseApp.origin && editCourseApp.origin.preview))}>
                                Icon Image
                            </div>
                            <img
                                hidden={!editCourseApp || (!editCourseApp.preview && (!editCourseApp.origin || !editCourseApp.origin.preview))}
                                src={editCourseApp ? editCourseApp.preview ? editCourseApp.preview : editCourseApp.origin ? editCourseApp.origin.preview : "" : ""}
                                alt={''}/>
                        </Upload>
                        <div>
                            <Input className={'input'}
                                   value={editCourseApp && editCourseApp.hasOwnProperty('name') ? editCourseApp.name : editCourseApp && editCourseApp.origin ? editCourseApp.origin.name : ""}
                                   placeholder={"工具名称"} onChange={(e) => {
                                let editCourseApp = this.state.editCourseApp || {};
                                editCourseApp.name = e.target.value;
                                this.setState({
                                    editCourseApp
                                })
                            }}/>
                             <Input className={'input'}
                                   value={editCourseApp && editCourseApp.hasOwnProperty('url') ? editCourseApp.url : editCourseApp && editCourseApp.origin ? editCourseApp.origin.url : ""}
                                   placeholder={"工具链接"} onChange={(e) => {
                                let editCourseApp = this.state.editCourseApp || {};
                                editCourseApp.url = e.target.value;
                                this.setState({
                                    editCourseApp
                                })
                            }}/>
                            <TextArea  style={{marginTop:10,minHeight:100}}
                                value={editCourseApp && editCourseApp.hasOwnProperty('subtitle') ? editCourseApp.subtitle : editCourseApp && editCourseApp.origin ? editCourseApp.origin.subtitle : ""}
                                placeholder={"工具介绍"} onChange={(e) => {
                                let editCourseApp = this.state.editCourseApp || {};
                                editCourseApp.subtitle = e.target.value;
                                this.setState({
                                    editCourseApp
                                })
                            }}/>

                        </div>

                    </div>
                    <p style={{marginTop: 15}}>所属分组 ：<span
                        style={{color: '#3340ff'}}>{` ${editCourseApp && editCourseApp.group ? editCourseApp.group.name : ""}`}</span>
                    </p>
                    <div className={'footer'}>
                        <Button className={'submit-btn'} type={'primary'} onClick={this.onSubmitCourseApp}>提交</Button>
                    </div>

                </Modal>
            </div>
        )
    }
}


export default ToolDash;
