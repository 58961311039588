import AppRequest from './AppRequest'

const urlGetBannerList = '/api_v1/admin-api/banner/list';
const urlDeleteBanner = '/api_v1/admin-api/banner/remove';
const urlCreateBanner = '/api_v1/admin-api/banner/create';
const urlUpdateBanner = '/api_v1/admin-api/banner/update';
const urlChangeBannerPublish = '/api_v1/admin-api/banner/status/update';
const urlGetBannerDetail = '/api_v1/admin-api/banner/detail';
const urlCreateBannerGroup = '/api_v1/admin-api/banner/group/create';
const urlUpdateBannerGroupInfo = "/api_v1/admin-api/banner/group/update";
const urlDeleteBannerGroup = '/api_v1/admin-api/banner/group/delete';
const urlGetBannerGroupList = '/api_v1/admin-api/banner/group/list';
const urlGetNoInGroupBanners = '/api_v1/admin-api/banner/group/not/in/group/list';
const urlSaveSortedBannersOfGroup = '/api_v1/admin-api/banner/sort/update';
class BannerRequest extends AppRequest {

    saveSortedBannersOfGroup(groupId,bannerIds){
        return this.put(urlSaveSortedBannersOfGroup,{groupId,bannerIds})
    }
    getNoInGroupBanners(){
        return this.get(urlGetNoInGroupBanners);
    }

    getBannerGroupList(){
        return this.get(urlGetBannerGroupList);
    }

    createBannerGroup(info){
        return this.post(urlCreateBannerGroup,info);
    }
    updateBannerGroupInfo(info){
        return this.put(urlUpdateBannerGroupInfo,info);
    }
    deleteBannerGroup(bannerGroupId){
        return this.delete(urlDeleteBannerGroup,{id:bannerGroupId})
    }


    getBannerDetail(bannerId){
        return this.get(urlGetBannerDetail,{id:bannerId})
    }
    getBannerList(type){
        return this.get(urlGetBannerList,{type});
    }
    deleteBanner(bannerId){
        return this.delete(urlDeleteBanner,{id:bannerId});
    }

    createBanner(info){
        return this.post(urlCreateBanner,info);
    }
    updateBanner(info){
        return this.put(urlUpdateBanner,info);
    }
    changeBannerPublish(isPublish,bannerId){
        return this.put(urlChangeBannerPublish,{status:isPublish,id:bannerId});
    }
}

const  bannerReq = new BannerRequest();
export default bannerReq;

